import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Divider from "@material-ui/core/Divider";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Box, IconButton } from "@material-ui/core";
import MUICard from "@material-ui/core/Card";
import MUICardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import MUITypography from "@material-ui/core/Typography";
// @material-ui/icons
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import UpdateIcon from "@material-ui/icons/Update";
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import ShoppingBasketRoundedIcon from "@material-ui/icons/ShoppingBasketRounded";
import Typo from "@material-ui/core/Typography";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import dayjs from "dayjs";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import Collapse from "@material-ui/core/Collapse";

import { ReactComponent as Tool1 } from "assets/icons/tool1.svg";
import { ReactComponent as Tool2 } from "assets/icons/tool2.svg";
import { ReactComponent as Tool3 } from "assets/icons/tool3.svg";
import { ReactComponent as Tool4 } from "assets/icons/tool4.svg";
import { ReactComponent as Tool5 } from "assets/icons/tool5.svg";
import { ReactComponent as Cog } from "assets/icons/cog.svg";
import { ReactComponent as Module } from "assets/icons/modicon.svg";

import { ReactComponent as Module1Icon } from "assets/icons/module1icon.svg";
import { ReactComponent as Module2Icon } from "assets/icons/module2icon.svg";
import { ReactComponent as Module3Icon } from "assets/icons/module3icon.svg";
import { ReactComponent as Module4Icon } from "assets/icons/module4icon.svg";
import { ReactComponent as Module5Icon } from "assets/icons/module5icon.svg";

import module1 from "assets/img/module1.png";
import module2 from "assets/img/module2.png";
import module3 from "assets/img/module3.png";
import module4 from "assets/img/module4.png";
import module5 from "assets/img/module5.png";

import allStyles from "assets/jss/elite-feats-cms/views/dashboardStyle.js";

import { withRouter } from "react-router-dom";
import axios from "axios";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/FadeLoader";
import Typography from "components/Typography/Muted.js";
import AnimateHeight from "react-animate-height";

import SweetAlert from "react-bootstrap-sweetalert";
import alertStyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import PriorityHigh from "@material-ui/icons/PriorityHigh";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import AppContext from "../../AppContext";
import { useDebouncedCallback } from "use-debounce";
import ToolsOne from "./Tools/Tools1";
import ToolsTwo from "./Tools/Tools2";
import ToolsThree from "./Tools/Tools3";
import ToolsFour from "./Tools/Tools4";
import ToolsFive from "./Tools/Tools5";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },

  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));
const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1440,
      xl: 1536,
    },
  },
});

function ShopModules(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  let cartContext = React.useContext(AppContext);

  return (
    <div>
      <Typo align="center" style={{ fontSize: 24, fontWeight: "bold" }}>
        {t("shop:tools1Title")}
      </Typo>
      <ToolsOne setCartValues={props.setCartValues} setOpen={props.setOpen} />
      <Divider
        variant="middle"
        style={{ marginBottom: 25, marginLeft: 100, marginRight: 100 }}
      />
      <Typo align="center" style={{ fontSize: 24, fontWeight: "bold" }}>
        {t("shop:tools2Title")}
      </Typo>
      <ToolsTwo setCartValues={props.setCartValues} setOpen={props.setOpen} />
      <Divider
        variant="middle"
        style={{ marginBottom: 25, marginLeft: 100, marginRight: 100 }}
      />
      <Typo align="center" style={{ fontSize: 24, fontWeight: "bold" }}>
        {t("shop:tools3Title")}
      </Typo>
      <ToolsThree setCartValues={props.setCartValues} setOpen={props.setOpen} />
      <Divider
        variant="middle"
        style={{ marginBottom: 25, marginLeft: 100, marginRight: 100 }}
      />
      <Typo align="center" style={{ fontSize: 24, fontWeight: "bold" }}>
        {t("shop:tools4Title")}
      </Typo>
      <ToolsFour setCartValues={props.setCartValues} setOpen={props.setOpen} />
      <Divider
        variant="middle"
        style={{ marginBottom: 25, marginLeft: 100, marginRight: 100 }}
      />
      <Typo align="center" style={{ fontSize: 24, fontWeight: "bold" }}>
        {t("shop:tools5Title")}
      </Typo>
      <ToolsFive setCartValues={props.setCartValues} setOpen={props.setOpen} />
    </div>
  );
}

export default withRouter(ShopModules);
