import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import { Card, Divider, CardHeader, Grid } from "@material-ui/core";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { useFormik } from "formik";
import ClientForm from "./ClientForm";
import { set } from "lodash";
import FormGroup from "@material-ui/core/FormGroup";
import Review from "./Review";
import LoadingModal from "./MultipleLoading";
import { object } from "yup/lib/locale";
import axios from "axios";

import { useDebouncedCallback } from "use-debounce";
import validator from "validator";
import SweetAlert from "react-bootstrap-sweetalert";
import alertStyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  ...alertStyles,
}));

export default function HorizontalLabelPositionBelowStepper(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [values, setValues] = React.useState({
    client1: { first: "", email: "", client2: { first: "", email: "" } },
  });
  const [formMap, setFormMap] = React.useState([]);
  const [alert, setAlert] = React.useState(null);
  const [wentBack, setWentBack] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const steps = [
    t("clients:alerts.multiClients.steps.0"),
    t("clients:alerts.multiClients.steps.1"),
    t("clients:alerts.multiClients.steps.2"),
  ];
  let numbers = [];
  for (let i = 2; i < 11; i++) {
    numbers.push(i);
  }
  const [clientNo, setClient] = React.useState(2);
  const handleChange1 = (event) => {
    setClient(event.target.value);
  };

  const handleFormChange = (event, form) => {
    let object = { ...values };

    object[`client${form}`][event.target.name] = event.target.value;
    setValues(object);
  };

  React.useEffect(() => {
    let obj = {};
    for (let i = 0; i < clientNo; i++) {
      obj[`client${i + 1}`] = { email: "", first: "", last: "" };
    }
    setValues(obj);
    let arr = [];
    for (let i = 0; i < clientNo; i++) {
      arr.push(i);
    }
    setFormMap(arr);
  }, [clientNo]);

  const validateAndNext = () => {
    let error = {};
    let hasErrors = false;
    let allEmpty = true;
    Object.keys(values).forEach((key) => {
      error[key] = [];
    });

    Object.keys(values).forEach((key) => {
      if (
        !validator.isEmpty(values[key].email) ||
        !validator.isEmpty(values[key].first) ||
        !validator.isEmpty(values[key].last)
      ) {
        if (!validator.isEmail(values[key].email)) {
          error[key].push("Invalid email");
          hasErrors = true;
        }
        if (validator.isEmpty(values[key].first)) {
          error[key].push("First name cannot be empty");
          hasErrors = true;
        }
        if (validator.isEmpty(values[key].last)) {
          error[key].push("Last name cannot be empty");
          hasErrors = true;
        }
      }

      if (
        !validator.isEmpty(values[key].email) &&
        !validator.isEmpty(values[key].first) &&
        !validator.isEmpty(values[key].last)
      ) {
        allEmpty = false;
      }
    });
    if (hasErrors) {
      ErrorsAlert(error);
    } else {
      if (allEmpty) {
        ErrorsAlert2();
      } else {
        handleNext();
      }
    }
  };

  React.useEffect(() => {
    if (wentBack) {
      ErrorsAlert3();
    }
  }, [wentBack]);

  const hideAlert = (loc) => {
    if (loc === "success") {
      props.setReloadTable(Math.random());
      props.openModal(false);
      setClient(2);
      setActiveStep(0);
    }
    setAlert(null);
  };

  const handleSubmit = useDebouncedCallback((values) => {
    axios
      .post("/api/client/create-multiple-clients", values, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setOpen(false);
        if (!res.data.fail.length) {
          successAlert();
        } else if (!res.data.success.length) {
          dangerAlert();
        } else {
          successWithErrorsAlert(res.data.success, res.data.fail);
        }
      });
  }, 500);

  const ErrorsAlert = (errors) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("clients:multiClients.errorsAlert.title")}
        onConfirm={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.warning}
        confirmBtnText={t("clients:multiClients.errorsAlert.confirmBtnText")}
      >
        {t("clients:multiClients.errorsAlert.description")}
        <Grid
          container
          style={{
            maxHeight: 300,
            overflow: "auto",
            border: "1px solid #dedede",
            borderRadius: 5,
            marginTop: 10,
            padding: 10,
            paddingBottom: 20,
          }}
        >
          {Object.keys(errors).map((client) => {
            let details = errors[client];

            if (details.length) {
              return (
                <Card
                  variant="outlined"
                  style={{ width: "100%", marginTop: 10 }}
                >
                  <CardHeader
                    title={
                      <Typography style={{ color: "#fff", fontWeight: "bold" }}>
                        {t("clients:multiClients.errorsAlert.clientTitle", {
                          client: client[6],
                        })}
                      </Typography>
                    }
                    style={{
                      padding: 5,
                      borderBottom: "1px solid #dedede ",
                      background: "#fcb316",
                    }}
                  />
                  {details.map((error, index) => {
                    return (
                      <React.Fragment>
                        <Typography>{error}</Typography>
                        {index !== details.length - 1 ? <Divider /> : false}
                      </React.Fragment>
                    );
                  })}
                </Card>
              );
            }
          })}
        </Grid>
      </SweetAlert>
    );
  };

  const ErrorsAlert2 = () => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("clients:multiClients.errorsAlert2.title")}
        onConfirm={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("clients:multiClients.errorsAlert2.confirmBtnText")}
      >
        {t("clients:multiClients.errorsAlert2.description")}
      </SweetAlert>
    );
  };

  const ErrorsAlert3 = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("clients:multiClients.errorsAlert3.title")}
        onConfirm={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.warning}
        confirmBtnText={t("clients:multiClients.errorsAlert3.confirmBtnText")}
      >
        {t("clients:multiClients.errorsAlert3.description")}
      </SweetAlert>
    );
  };

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("clients:multiClients.successAlert.title")}
        onConfirm={() => hideAlert("success")}
        onCancel={() => hideAlert("success")}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        {t("clients:multiClients.successAlert.description")}
      </SweetAlert>
    );
  };

  const successWithErrorsAlert = (created, fails) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("clients:multiClients.successWithErrorsAlert.title")}
        onConfirm={() => failsAlert(fails)}
        onCancel={() => hideAlert("success")}
        confirmBtnCssClass={classes.button + " " + classes.warning}
        confirmBtnText={t(
          "clients:multiClients.successWithErrorsAlert.confirmBtnText"
        )}
        cancelBtnCssClass={classes.button + " " + classes.success}
        cancelBtnText={t(
          "clients:multiClients.successWithErrorsAlert.cancelBtnText"
        )}
      >
        {t("clients:multiClients.successWithErrorsAlert.description")}
        <Grid
          container
          style={{
            maxHeight: 300,
            overflow: "auto",
            border: "1px solid #dedede",
            borderRadius: 5,
            marginTop: 10,
            padding: 10,
            paddingBottom: 20,
          }}
        >
          <Card variant="outlined" style={{ width: "100%", marginTop: 10 }}>
            <CardHeader
              title={
                <Typography style={{ color: "#fff", fontWeight: "bold" }}>
                  {t(
                    "clients:multiClients.successWithErrorsAlert.successTitle"
                  )}
                </Typography>
              }
              style={{
                padding: 5,
                borderBottom: "1px solid #dedede ",
                background: "#23934b",
              }}
            />
            {created.map((client, index) => {
              return (
                <React.Fragment>
                  <Typography>{client}</Typography>
                  {index !== created.length - 1 ? <Divider /> : false}
                </React.Fragment>
              );
            })}
          </Card>
        </Grid>
      </SweetAlert>
    );
  };

  const failsAlert = (fails) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("clients:multiClients.failsAlert.title")}
        onConfirm={() => hideAlert("success")}
        onCancel={() => hideAlert("success")}
        confirmBtnCssClass={classes.button + " " + classes.warning}
        confirmBtnText={t("clients:multiClients.failsAlert.confirmBtnText")}
      >
        {t("clients:multiClients.failsAlert.description")}
        <Grid
          container
          style={{
            maxHeight: 300,
            overflow: "auto",
            border: "1px solid #dedede",
            borderRadius: 5,
            marginTop: 10,
            padding: 10,
            paddingBottom: 20,
          }}
        >
          <Card variant="outlined" style={{ width: "100%", marginTop: 10 }}>
            <CardHeader
              title={
                <Typography style={{ color: "#fff", fontWeight: "bold" }}>
                  {t("clients:multiClients.failsAlert.failTitle")}
                </Typography>
              }
              style={{
                padding: 5,
                borderBottom: "1px solid #dedede ",
                background: "#b13c3a",
              }}
            />
            {fails.map((client, index) => {
              return (
                <React.Fragment>
                  <Typography>{client}</Typography>
                  {index !== fails.length - 1 ? <Divider /> : false}
                </React.Fragment>
              );
            })}
          </Card>
        </Grid>
      </SweetAlert>
    );
  };

  const dangerAlert = () => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("clients:multiClients.dangerAlert.title")}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.danger}
      >
        {t("clients:multiClients.dangerAlert.description")}
      </SweetAlert>
    );
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 1) {
      setWentBack(true);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <Card variant="outlined" style={{ padding: 30 }}>
            <Typography
              style={{
                fontSize: 18,
                fontWeight: "bold",
                color: "#3a4052",
                marginBottom: 20,
              }}
            >
              {t("clients:alerts.multiClients.numberOfClients")}
            </Typography>
            <FormControl style={{ minWidth: 150 }}>
              <Select
                id="outlined-select"
                select
                value={clientNo}
                onChange={handleChange1}
                variant="outlined"
              >
                {numbers.map((number) => (
                  <MenuItem key={number} value={number}>
                    {number}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Card>
        );
      case 1:
        return (
          <Card
            variant="outlined"
            style={{ padding: 15, paddingTop: 10, paddingBottom: 10 }}
          >
            <FormGroup>
              {formMap.map((formNumber) => (
                <ClientForm
                  key={formNumber}
                  values={values}
                  formName={formNumber + 1}
                  onBlur={handleFormChange}
                />
              ))}
            </FormGroup>
          </Card>
        );
      case 2:
        return (
          <Card
            variant="outlined"
            style={{ padding: 15, paddingTop: 10, paddingBottom: 10 }}
          >
            {Object.values(values).map((client, index) =>
              !client.first.length ||
              !client.email.length ||
              !client.last.length ? null : (
                <React.Fragment>
                  <Review key={index} client={client} formName={index + 1} />
                </React.Fragment>
              )
            )}
          </Card>
        );
      default:
        return "Unknown stepIndex";
    }
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>
              {t(`clients:alerts.multiClients.steps.${index}`)}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography className={classes.instructions}>
            {getStepContent(activeStep)}
          </Typography>
          <div style={{ marginTop: 20 }}>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              className={classes.backButton}
            >
              {t("clients:alerts.multiClients.back")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={
                activeStep === 1
                  ? validateAndNext
                  : activeStep === 2
                  ? () => {
                      setOpen(true);
                      handleSubmit(values);
                    }
                  : handleNext
              }
            >
              {activeStep === steps.length - 1
                ? t("clients:alerts.multiClients.finish")
                : t("clients:alerts.multiClients.next")}
            </Button>
          </div>
        </div>
      </div>
      {alert}
      <LoadingModal open={open} setOpen={setOpen} />
    </div>
  );
}
