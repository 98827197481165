/*eslint-disable*/
import React from "react";
import {
  useTable,
  useFilters,
  useAsyncDebounce,
  useSortBy,
  usePagination,
} from "react-table";
import classnames from "classnames";
// A great library for fuzzy filtering/sorting items
import matchSorter from "match-sorter";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import BlueTypo from "components/Typography/Info";
import styles from "assets/jss/elite-feats-cms/customSelectStyle.js";

import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";

const newStyles = {
  ...styles,

  formControlMargins: {
    margin: "3px 0 !important",
  },
  gridContainer: {
    justifyContent: "center",
  },
  tbcell: {
    display: "flex",
    alignItems: "center",
    paddingTop: 7,
    paddingBottom: 7,
    width: 50,
  },
  groupscell: {
    display: "flex",
    alignItems: "center",
    paddingTop: 7,
    paddingBottom: 7,
  },
  compcell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 110,
  },
  actionscell: {
    display: "flex",
    justifyContent: "flex-end",

    width: 160,
  },
  compheader: {
    display: "flex",
    alignItems: "center",
    marginTop: -50,
    width: 110,
  },
};

const useStyles = makeStyles(newStyles);




function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data, reload2, clientTour, setReloadTable }) {
  const [reload, setReload] = React.useState(0);
  const {t} = useTranslation()
  const [numberOfRows, setNumberOfRows] = React.useState(10);
  const [pageSelect, handlePageSelect] = React.useState(0);
  const firstRender = React.useRef(true);
  React.useEffect(() => {
    if (!firstRender.current) {
      if (clientTour === -5) {
        setPageSize(5);
        setNumberOfRows(5);
      } else if (clientTour === -10) {
        /*    setPageSize(10);
        setNumberOfRows(10); */
      }
    } else {
      firstRender.current = false;
    }
  }, [clientTour]);

  React.useEffect(() => {}, [numberOfRows]);
  const classes = useStyles();
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;
  
    return (
      <CustomInput
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          value: filterValue || "",
          onChange: (e) => {
            setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
          },
          placeholder: t('clients:searchPlaceholder', { count }),
        }}
      />
    );
  }


  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    visibleColumns,
    nextPage,
    pageOptions,
    pageCount,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageSize: 10, pageIndex: 0 },
    },
    useFilters, // useFilters!
    useSortBy,
    usePagination
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  // const firstPageRows = rows.slice(0, 10);
  let pageSelectData = Array.apply(
    null,
    Array(pageOptions.length)
  ).map(function () {});
  let numberOfRowsData = [5, 10, 20, 25, 50, 100];

  return (
    <>
      <div className="ReactTable -striped -highlight">
        <table {...getTableProps()} className="rt-table">
          <thead className="rt-thead -header">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                {headerGroup.headers.map((column, key) => {
                  return (
                    <th
                      {...column.getHeaderProps()}
                      className={
                        column.id === "select"
                          ? classes.tbcell
                          : column.id === "completed"
                          ? classes.compheader
                          : column.id === "actions"
                          ? classes.actionscell
                          : classnames("rt-th rt-resizable-header")
                      }
                    >
                      <div
                        {...column.getSortByToggleProps()}
                        className={
                          column.id === "select"
                            ? classes.tbcell
                            : column.id === "completed"
                            ? classes.compcell
                            : column.id === "actions"
                            ? classes.actionscell
                            : classnames({
                                "-cursor-pointer":
                                  headerGroup.headers.length - 1 !== key,

                                "-sort-asc":
                                  column.isSorted && !column.isSortedDesc,

                                "-sort-desc":
                                  column.isSorted && column.isSortedDesc,
                              })
                        }
                        style={{
                          display: "flex",
                          justifyContent:
                            column.id === "completed" || column.id === "group"
                              ? "center"
                              : column.id === "actions"
                              ? "flex-end"
                              : null,
                          paddingRight: column.id === "actions" ? 30 : null,
                        }}
                      >
                        <Typography
                          style={{
                            display: "flex",
                            alignItems: "center",

                            fontSize: 18,
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          {column.render("Header")}
                        </Typography>
                      </div>{" "}
                      {/* Render the columns filter UI */}
                      <div
                        style={{
                          marginTop:
                            column.id === "actions"
                              ? 0
                              : column.id === "completed"
                              ? 50
                              : -30,
                          maxWidth: column.id === "group" ? 200 : 150,
                          display: "flex",
                        }}
                      >
                        {headerGroup.headers.length - 1 === key
                          ? null
                          : column.id === "select" ||
                            column.id === "actions" ||
                            column.id === "completed"
                          ? null
                          : column.canFilter
                          ? column.render("Filter")
                          : null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="rt-tbody"
            style={{
              marginTop: -15,
              maxHeight: 360,
              overflow: "auto !important",
              border: "1px solid #e9e9e9",
              borderRadius: 5,
            }}
          >
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={classnames(
                    "rt-tr",
                    { " -odd": i % 2 === 0 },
                    { " -even": i % 2 === 1 },
                    i === 0 ? "latest-client" : null
                  )}
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                >
                  {" "}
                  {row.cells.map((cell) => {
                    return (
                      <td
                        style={{
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                        {...cell.getCellProps()}
                        className={
                          cell.column.id === "select"
                            ? classes.tbcell
                            : cell.column.id === "actions"
                            ? classes.actionscell
                            : cell.column.id === "completed"
                            ? classes.compcell
                            : "rt-td"
                        }
                      >
                        {cell.column.id === "group" ? (
                          cell.render("Cell").props.cell.value === " - " ? (
                            <Typography
                              align="center"
                              style={{
                                fontWeight: 600,
                                color: "#000",
                              }}
                            >
                              {" "}
                              {cell.render("Cell")}{" "}
                            </Typography>
                          ) : (
                            <Grid
                              container
                              style={{
                                padding: 5,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {cell
                                .render("Cell")
                                .props.cell.value.map((group) => {
                                  return (
                                    <Grid item style={{ margin: 5 }}>
                                      <Typography
                                        align="center"
                                        style={{
                                          borderRadius: 10,
                                          paddingLeft: 10,
                                          paddingRight: 10,
                                          background: "#40628d",
                                          display: "flex",
                                          fontSize: 12,
                                          fontWeight: 400,

                                          color: "#f7f7f7",
                                        }}
                                      >
                                        {" "}
                                        {group}
                                      </Typography>
                                    </Grid>
                                  );
                                })}
                            </Grid>
                          )
                        ) : cell.render("Cell").props.cell.value === " - " ? (
                          <Typography
                            style={{
                              marginLeft: 40,
                              paddingLeft: 5,
                              fontWeight: 600,
                              color: "#000",
                            }}
                          >
                            {" "}
                            {cell.render("Cell")}{" "}
                          </Typography>
                        ) : (
                          <Typography
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                              color: "#000",
                            }}
                          >
                            {" "}
                            {cell.render("Cell")}{" "}
                          </Typography>
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination-top">
          <div className="-pagination" style={{ marginTop: 5 }}>
            <div className="-previous">
              {canPreviousPage ? (
                <Button
                  size="sm"
                  color="info"
                  onClick={() => {
                    previousPage();
                    handlePageSelect(pageSelect - 1);
                  }}
                  disabled={!canPreviousPage}
                >
                  {t("clients:pagination.previous")}
                </Button>
              ) : (
                false
              )}
            </div>
            <div className="-center">
              <GridContainer className={classes.gridContainer}>
                <GridItem xs={12} sm={6} md={4}>
                  <FormControl
                    fullWidth
                    className={
                      classes.selectFormControl +
                      " " +
                      classes.formControlMargins
                    }
                  >
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={pageSelect}
                      onChange={(event) => {
                        gotoPage(event.target.value);
                        handlePageSelect(event.target.value);
                      }}
                      inputProps={{
                        name: "pageSelect",
                        id: "page-select",
                      }}
                    >
                      {pageSelectData.map((prop, key) => {
                        return (
                          <MenuItem
                            key={key}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={key}
                          >
                            {t("clients:pagination.page", { number: key + 1 })}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <FormControl
                    fullWidth
                    className={
                      classes.selectFormControl +
                      " " +
                      classes.formControlMargins
                    }
                  >
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={numberOfRows}
                      onChange={(event) => {
                        setPageSize(event.target.value);
                        setNumberOfRows(event.target.value);
                      }}
                      inputProps={{
                        name: "numberOfRows",
                        id: "number-of-rows",
                      }}
                    >
                      {numberOfRowsData.map((prop) => {
                        return (
                          <MenuItem
                            key={prop}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={prop}
                          >
                            {t("clients:pagination.rows", { count: prop })} 
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </div>

            <div className="-next">
              {canNextPage ? (
                <Button
                  size="sm"
                  color="info"
                  onClick={() => {
                    nextPage();
                    handlePageSelect(pageSelect + 1);
                  }}
                  disabled={!canNextPage}
                >
                  {t("clients:pagination.nextPage")} 
                </Button>
              ) : (
                false
              )}
            </div>
          </div>
        </div>

        <div className="pagination-bottom"></div>
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

export default Table;
