import React from "react";
import { makeStyles } from "@material-ui/core/styles";
/* import Button from "@material-ui/core/Button"; */
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import {
  Box,
  Typography,
  Divider,
  Grid,
  Card as MUICard,
} from "@material-ui/core";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import SweetAlert from "react-bootstrap-sweetalert";
import alertStyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";
import sweetAlertStyle from "assets/jss/elite-feats-cms/views/sweetAlertStyle";
import Accordion from "components/Accordion/Accordion.js";
import buttonStyles from "assets/jss/elite-feats-cms/views/buttonsStyle.js";
import { useTranslation } from "react-i18next";

const styles = {
  ...sweetAlertStyle,
  ...buttonStyles,

  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
};

const useStyles = makeStyles(styles);

export default function MaxWidthDialog(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [alert, setAlert] = React.useState(null);

  React.useEffect(() => {
    if (props.hide !== 0) {
      hideAlert();
    }
    if (props.hide === -1) {
      props.setOpen(false);
    }
  }, [props.hide]);
  const assign = [
    {
      title: "Module 1",
      content: (
        <MUICard elevation={0}>
          <ol style={{ color: "#000" }}>
            <Typography
              align="left"
              style={{
                padding: 5,
                paddingLeft: 10,
                paddingRight: 10,
                fontSize: 15,
              }}
            >
              {" "}
              <li>Jane Smith - j.smith@email.com </li>
            </Typography>
          </ol>
        </MUICard>
      ),
    },

    {
      title: "Tool 4.2",
      content: (
        <MUICard elevation={0}>
          <ol style={{ color: "#000" }}>
            <Typography
              align="left"
              style={{
                padding: 5,
                paddingLeft: 10,
                paddingRight: 10,
                fontSize: 15,
              }}
            >
              {" "}
              <li> John Doe - j.doe@email.com </li>
              <li>Jane Smith - j.smith@email.com </li>
            </Typography>
          </ol>
        </MUICard>
      ),
    },
  ];
  const errors = [
    {
      title: "Module 1",
      content: (
        <MUICard elevation={0}>
          <ol style={{ color: "#000" }}>
            <Typography
              align="left"
              style={{
                padding: 5,
                paddingLeft: 10,
                paddingRight: 10,
                fontSize: 15,
              }}
            >
              {" "}
              <li> John Doe - j.doe@email.com </li>
            </Typography>
          </ol>
        </MUICard>
      ),
    },
  ];

  const hideAlert = () => {
    setAlert(null);
  };
  const assignSuccess = (tabs) => {
    setAlert(
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          maxHeight: 630,
          overflow: "auto",
        }}
        title={t("tour:assignmentDialog.successfullyAssigned")}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.info}
      >
        <Typography
          style={{
            color: "#b2b2b2",
            fontStyle: "italic",
            fontSize: 14,
            fontWeight: 200,
          }}
        >
          {t("tour:assignmentDialog.clickAssessmentName")}
        </Typography>
        <Accordion collapses={assign} />
      </SweetAlert>
    );
  };

  const assignErrors = (tabs) => {
    setAlert(
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          maxHeight: 630,
          overflow: "auto",
          width: 525,
        }}
        title={t("tour:assignmentDialog.notAssigned")}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.info}
      >
        <Typography
          align="justify"
          style={{
            fontSize: 16,
          }}
        >
          {t("tour:assignmentDialog.assignErrorDetails")}
        </Typography>
        <Typography
          align="justify"
          style={{
            color: "#b2b2b2",
            fontStyle: "italic",
            fontSize: 14,
            fontWeight: 200,
            marginTop: 10,
          }}
        >
          {t("tour:assignmentDialog.clickAssessmentNameError")}
        </Typography>
        <Accordion collapses={errors} />
      </SweetAlert>
    );
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  /*   const handleMaxWidthChange = (event) => {
    setMaxWidth(event.target.value);
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  }; */

  return (
    <div className="assignment-dialog">
      <Dialog
        fullWidth
        maxWidth="sm"
        open={props.open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          {t("tour:assignmentDialog.title")}
        </DialogTitle>
        <DialogContent className="tour-box">
          <DialogContentText></DialogContentText>
          <Box
            style={{
              padding: 20,
              borderRadius: 10,
              background: "#f7f5f5",
              border: "1px #e4e4e4 solid",
            }}
          >
            <Card
              className="successful-assign"
              style={{ padding: 20, maxWidth: 685 }}
            >
              <Typography
                style={{
                  color: "#fff",
                  fontSize: 12,
                  fontWeight: 500,
                  display: "flex",
                }}
              >
                <Box
                  style={{
                    padding: "5px 12px 5px 12px",
                    background: "#4caf50",
                    borderRadius: 50,
                  }}
                >
                  {t("tour:assignmentDialog.allAssignedSuccessfully")}
                </Box>
              </Typography>
              <Typography
                style={{
                  fontSize: 18,
                  marginTop: 15,
                  fontWeight: 900,
                  marginBottom: 15,
                }}
              >
                {t("tour:assignmentDialog.assignedOn", {
                  date: "May 17, 2021",
                  time: "4.55 PM",
                })}
              </Typography>
              <Divider fullWidth />
              <Button
                style={{
                  marginTop: 15,
                  marginBottom: 5,
                  display: "flex",
                  maxWidth: 145,
                }}
                onClick={assignSuccess}
                color="info"
              >
                {t("tour:assignmentDialog.viewAssigned")}
              </Button>
            </Card>

            <Card
              className="some-assign"
              style={{ padding: 20, maxWidth: 685, marginTop: 20 }}
            >
              <Typography
                style={{
                  color: "#fff",
                  fontSize: 12,
                  fontWeight: 500,
                  display: "flex",
                }}
              >
                <Box
                  style={{
                    padding: "5px 12px 5px 12px",
                    background: "#ff9800",
                    borderRadius: 50,
                  }}
                >
                  {t("tour:assignmentDialog.assignedWithErrors")}
                </Box>
              </Typography>
              <Typography
                style={{
                  fontSize: 18,
                  marginTop: 15,
                  fontWeight: 900,
                  marginBottom: 15,
                }}
              >
                {t("tour:assignmentDialog.assignedOn", {
                  date: "May 17, 2021",
                  time: "4.55 PM",
                })}
              </Typography>
              <Divider fullWidth />
              <Grid container>
                <Grid item>
                  <Button
                    className="success-button"
                    style={{
                      marginTop: 15,
                      marginBottom: 5,
                      display: "flex",
                      maxWidth: 145,
                    }}
                    onClick={() => {
                      props.setTriggerStep(-2);
                      assignSuccess();
                      setTimeout(() => {
                        props.setTriggerStep(7);
                      }, 600);
                    }}
                    color="info"
                  >
                    {t("tour:assignmentDialog.viewAssigned")}
                  </Button>
                </Grid>
                <Grid item style={{ marginLeft: 15 }}>
                  <Button
                    className="error-button"
                    style={{
                      marginTop: 15,
                      marginBottom: 5,
                      display: "flex",
                      maxWidth: 145,
                    }}
                    onClick={assignErrors}
                    color="warning"
                  >
                    {t("tour:assignmentDialog.viewErrors")}
                  </Button>
                </Grid>
              </Grid>
            </Card>
            <Card
              className="error-assign"
              style={{ padding: 20, maxWidth: 685, marginTop: 20 }}
            >
              <Typography
                style={{
                  color: "#fff",
                  fontSize: 12,
                  fontWeight: 500,
                  display: "flex",
                }}
              >
                <Box
                  style={{
                    padding: "5px 12px 5px 12px",
                    background: "#f44336",
                    borderRadius: 50,
                  }}
                >
                  {t("tour:assignmentDialog.notAssigned")}
                </Box>
              </Typography>
              <Typography
                style={{
                  fontSize: 18,
                  marginTop: 15,
                  fontWeight: 900,
                  marginBottom: 15,
                }}
              >
                {t("tour:assignmentDialog.assignedOn", {
                  date: "May 17, 2021",
                  time: "4.55 PM",
                })}
              </Typography>
              <Divider fullWidth />
              <Button
                style={{
                  marginTop: 15,
                  marginBottom: 5,
                  display: "flex",
                  maxWidth: 145,
                }}
                onClick={assignErrors}
                color="warning"
              >
                {t("tour:assignmentDialog.viewErrors")}
              </Button>
            </Card>
          </Box>
        </DialogContent>
        <DialogActions>
          {/* Uncomment if you want a close button */}
          {/* <Button onClick={handleClose} color='primary'>
            {t('tour:assignmentDialog.close')}
          </Button> */}
        </DialogActions>
      </Dialog>
      {alert}
    </div>
  );
}
