import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Typography, SvgIcon } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import UserDetails from "./User/UserDetails";

import RecentActorsIcon from "@material-ui/icons/RecentActors";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import EmailIcon from "@material-ui/icons/Email";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import GroupRoundedIcon from "@material-ui/icons/GroupRounded";
import { ReactComponent as Cog } from "assets/icons/cog.svg";
import { ReactComponent as Module } from "assets/icons/modicon.svg";
import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "variables/charts";

import styles from "assets/jss/elite-feats-cms/views/dashboardStyle.js";
import UserTable from "./UserTable";
import ClientsTable from "./ClientsTable";
import GroupsTable from "./GroupsTable";
import axios from "axios";
import PersonIcon from "@material-ui/icons/Person";
import ModTools from "./ModTools";
import ClipLoader from "react-spinners/FadeLoader";
import { css } from "@emotion/react";
/* import priceImage1 from "assets/img/card-2.jpeg";
import priceImage2 from "assets/img/card-3.jpeg";
import priceImage3 from "assets/img/card-1.jpeg"; */

const us_flag = require("assets/img/flags/US.png").default;
const de_flag = require("assets/img/flags/DE.png").default;
const au_flag = require("assets/img/flags/AU.png").default;
const gb_flag = require("assets/img/flags/GB.png").default;
const ro_flag = require("assets/img/flags/RO.png").default;
const br_flag = require("assets/img/flags/BR.png").default;

var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920,
};

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const [salesInfo, setSalesInfo] = React.useState({});

  const [openUser, setOpenUser] = React.useState(false);
  const [totalModules, setTotalModules] = React.useState(0);
  const [totalTools, setTotalTools] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    axios
      .get("/api/user/sales-info", {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setLoading(false);
        setSalesInfo(res.data);
        let modTot = 0;
        let toolTot = 0;
        for (let assessment in res.data) {
          if (assessment[0] === "m") {
            modTot += res.data[assessment].total;
          } else if (assessment[0] === "t") {
            toolTot += res.data[assessment].total;
          }
        }
        setTotalModules(modTot);
        setTotalTools(toolTot);
      });
  }, []);
  const openUserDetails = (user) => {
    /*  setCurrentUser(user); */
    setOpenUser(true);
  };
  const ModuleIcon = () => (
    <SvgIcon style={{ fontSize: 36 }} component={Module} />
  );
  const ToolIcon = () => <SvgIcon style={{ fontSize: 36 }} component={Cog} />;
  const classes = useStyles();
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <div>
      <GridContainer style={{ justifyContent: "center" }}>
        <GridItem xs={12} sm={6} md lg>
          <Card>
            <CardHeader
              color='modules'
              stats
              icon
              style={{
                display: "flex",
                justifyContent: "center",
                marginRight: 0,
              }}
            >
              <CardIcon color='modules'>
                <ModuleIcon />
              </CardIcon>
            </CardHeader>

            <Typography className={classes.adminTitle} style={{ fontSize: 22 }}>
              Modules Sales
            </Typography>

            {loading ? (
              <React.Fragment>
                <ClipLoader
                  color={"#398bd6"}
                  loading={loading}
                  css={override}
                  size={150}
                />{" "}
              </React.Fragment>
            ) : (
              <Typography
                className={classes.cardTitle}
                style={{ fontSize: 25, padding: 0, color: "#7c828b" }}
              >
                {totalModules}
              </Typography>
            )}

            <CardFooter style={{ marginTop: 10 }} stats></CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md lg>
          <Card>
            <CardHeader
              color='primary'
              stats
              icon
              style={{
                display: "flex",
                justifyContent: "center",
                marginRight: 0,
              }}
            >
              <CardIcon color='primary'>
                <ToolIcon />
              </CardIcon>
            </CardHeader>

            <Typography className={classes.adminTitle} style={{ fontSize: 22 }}>
              Tools Sales
            </Typography>
            {loading ? (
              <React.Fragment>
                <ClipLoader
                  color={"#398bd6"}
                  loading={loading}
                  css={override}
                  size={150}
                />{" "}
              </React.Fragment>
            ) : (
              <Typography
                className={classes.cardTitle}
                style={{ fontSize: 25, padding: 0, color: "#7c828b" }}
              >
                {totalTools}
              </Typography>
            )}
            <CardFooter style={{ marginTop: 10 }} stats></CardFooter>
          </Card>
        </GridItem>{" "}
      </GridContainer>
      <ModTools data={salesInfo} loading={loading} />
      {/*  <UserDetails user={currentUser} open={openUser} setOpen={setOpenUser} /> */}
    </div>
  );
}
