import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Typography, Grid, Divider } from "@material-ui/core";

import SweetAlert from "react-bootstrap-sweetalert";
import Chip from "@material-ui/core/Chip";
import Snackbar from "@material-ui/core/Snackbar";

import DoneIcon from "@material-ui/icons/Done";
import MuiAlert from "@material-ui/lab/Alert";
import Email from "assets/img/email.svg";
import alertstyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";
import TextField from "@material-ui/core/TextField";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import SendMailLoading from "./SendMailLoading";

import CustButton from "components/CustomButtons/Button.js";
import axios from "axios";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const TField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#2b8cc6",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#3c9778",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#2b8cc6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#2b8cc6",
      },
    },
  },
})(TextField);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  ...alertstyles,
});
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function CustomizedDialogs(props) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(null);
  const [initialValues, setInitial] = React.useState({
    message: "",
    subject: "",
  });

  const handleClose = () => {
    props.setOpen(false);
  };

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("common:mailSentSuccessfully")}
        onConfirm={() => {
          setAlert(null);
          props.setOpen(false);
        }}
        onCancel={() => {
          setAlert(null);
          props.setOpen(false);
        }}
        confirmBtnCssClass={classes.button + " " + classes.success}
      ></SweetAlert>
    );
  };

  const handleSubmit = (values) => {
    setOpen(true);
    props.setOpen(false);
    axios
      .post(
        "/api/client/send-message",
        {
          send_to: [props.client.email],
          subject: values.subject,
          message: values.message,
          language: i18n.language,
        },
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        props.setOpen(true);
        setOpen(false);
        successAlert();
        setInitial({
          subject: "",
          message: "",
        });
      });
  };
  const validationSchema = Yup.object({
    message: Yup.string().required(t("common:enterMessageError")),
  });

  return (
    <div>
      <Dialog
        maxWidth="sm"
        onClose={handleClose}
        fullWidth
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t("clients:sendMailToClient")}
        </DialogTitle>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form style={{ padding: 10 }}>
              <DialogContent
                dividers
                id="modal-slide-description"
                className={classes.modalBody}
              >
                <Typography style={{ marginBottom: 10 }}>
                  <span style={{ fontWeight: "bold" }}>
                    {t("clients:to")}:{" "}
                  </span>
                  <span>
                    {props.client?.first_name + " " + props.client?.last_name}
                  </span>
                </Typography>

                <Field
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  margin="normal"
                  as={TField}
                  id="subject"
                  label={t("clients:subject")}
                  name="subject"
                  autoFocus
                  fullWidth
                  helperText={
                    <ErrorMessage
                      component="div"
                      style={{ color: "#c5473f" }}
                      name="subject"
                    />
                  }
                />
                <Field
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  margin="normal"
                  required
                  as={TField}
                  id="message"
                  multiline
                  rows={5}
                  label={t("clients:message")}
                  name="message"
                  autoFocus
                  fullWidth
                  helperText={
                    <ErrorMessage
                      component="div"
                      style={{ color: "#c5473f" }}
                      name="message"
                    />
                  }
                />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={Email} style={{ width: 90 }} />
                </div>
              </DialogContent>
              <Divider light style={{ marginBottom: 10 }} />
              <DialogActions
                className={
                  classes.modalFooter + " " + classes.modalFooterCenter
                }
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: 20,
                }}
              >
                <CustButton
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    props.setOpen(false);
                  }}
                >
                  {t("clients:cancel")}
                </CustButton>
                <CustButton
                  style={{ marginLeft: 10 }}
                  type="submit"
                  color="success"
                >
                  {t("clients:send")}
                </CustButton>
              </DialogActions>
            </Form>
          )}
        </Formik>

        {alert}
      </Dialog>

      <SendMailLoading open={open} />
    </div>
  );
}

export default withRouter(CustomizedDialogs);
