import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Divider from "@material-ui/core/Divider";
import MUIButton from "@material-ui/core/Button";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Box, IconButton } from "@material-ui/core";
import MUICard from "@material-ui/core/Card";
import MUICardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import MUITypography from "@material-ui/core/Typography";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },

  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

function SalesGrid(props) {
  const data = props.data;
  const classes = useStyles();
  let color;

  if (props.id[0] === "m") {
    if (props.id[6] === "1") {
      color = "#bb3737";
    } else if (props.id[6] === "2") {
      color = "#e7842c";
    } else if (props.id[6] === "3") {
      color = "#037593";
    } else if (props.id[6] === "4") {
      color = "#0c7853";
    } else if (props.id[6] === "5") {
      color = "#794879";
    }
  } else if (props.id[0] === "t") {
    if (props.id[4] === "1") {
      color = "#bb3737";
    } else if (props.id[4] === "2") {
      color = "#ef7e28";
    } else if (props.id[4] === "3") {
      color = "#0c8197";
    } else if (props.id[4] === "4") {
      color = "#098359";
    } else if (props.id[4] === "5") {
      color = "#7f4a7f";
    }
  }
  return (
    <MUICard variant='outlined'>
      <Grid container>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "center",
            borderBottom: "1px solid #e4e4e4",
            borderRight: "1px solid #e4e4e4",
            background: `${color}`,
            paddingTop: 2,
            paddingBottom: 2,
          }}
        >
          <MUITypography style={{ fontWeight: "bold", color: "#fff" }}>
            Period
          </MUITypography>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "center",
            background: `${color}`,
            borderBottom: "1px solid #e4e4e4",
            paddingTop: 2,
            paddingBottom: 2,
          }}
        >
          <MUITypography style={{ fontWeight: "bold", color: "#fff" }}>
            Sales
          </MUITypography>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            padding: 3,
            paddingTop: 6,
            paddingLeft: 15,
            paddingRight: 15,
            borderBottom: "1px solid #e4e4e4",
            borderRight: "1px solid #e4e4e4",
          }}
        >
          <MUITypography>Last 7 Days</MUITypography>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: 6,
            borderBottom: "1px solid #e4e4e4",
          }}
        >
          <MUITypography>
            {Object.values(data).length
              ? data[props.id].last_7
              : "Calculating.."}
          </MUITypography>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            padding: 3,

            paddingLeft: 15,
            paddingRight: 15,
            borderBottom: "1px solid #e4e4e4",
            borderRight: "1px solid #e4e4e4",
          }}
        >
          <MUITypography>Last 30 Days</MUITypography>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            borderBottom: "1px solid #e4e4e4",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <MUITypography>
            {" "}
            {Object.values(data).length
              ? data[props.id].last_30
              : "Calculating.."}
          </MUITypography>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            padding: 3,
            paddingLeft: 15,
            paddingRight: 15,
            borderBottom: "1px solid #e4e4e4",
            borderRight: "1px solid #e4e4e4",
          }}
        >
          <MUITypography>Last 180 Days</MUITypography>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            borderBottom: "1px solid #e4e4e4",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <MUITypography>
            {" "}
            {Object.values(data).length
              ? data[props.id].last_180
              : "Calculating.."}
          </MUITypography>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            padding: 3,
            paddingBottom: 6,
            paddingLeft: 15,
            paddingRight: 15,
            borderBottom: "1px solid #e4e4e4",
            borderRight: "1px solid #e4e4e4",
          }}
        >
          <MUITypography>Last 360 Days</MUITypography>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            borderBottom: "1px solid #e4e4e4",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <MUITypography>
            {" "}
            {Object.values(data).length
              ? data[props.id].last_360
              : "Calculating.."}
          </MUITypography>
        </Grid>
      </Grid>
    </MUICard>
  );
}

export default SalesGrid;
