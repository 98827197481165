import React from "react";
// material-ui components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import MUIButton from "@material-ui/core/Button";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";

import modalStyles from "assets/jss/elite-feats-cms/modalStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Tooltip from "@material-ui/core/Tooltip";

import * as Yup from "yup";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { withRouter } from "react-router-dom";
import { Card, Divider, SvgIcon, Typography } from "@material-ui/core";

import { ReactComponent as Tool1 } from "assets/icons/tool1.svg";
import { ReactComponent as Tool2 } from "assets/icons/tool2.svg";
import { ReactComponent as Tool3 } from "assets/icons/tool3.svg";
import { ReactComponent as Tool4 } from "assets/icons/tool4.svg";
import { ReactComponent as Tool5 } from "assets/icons/tool5.svg";
import { ReactComponent as Cog } from "assets/icons/cog.svg";
import { ReactComponent as Module } from "assets/icons/modicon.svg";
import EmailIcon from "@material-ui/icons/Email";

import { useDebouncedCallback } from "use-debounce";

import alertStyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";
import assessmentNames from "../../Dashboard/assessmentNamesReversed";
import identifiers from "../../Dashboard/identifiers";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import dayjs from "dayjs";
import ClientDetails from "./ClientDetails";
import TablePagination from "@material-ui/core/TablePagination";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

const styles = {
  ...modalStyles,
  ...alertStyles,
};

const useStyles = makeStyles(styles);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#da5526",
    color: theme.palette.common.white,
    maxWidth: 275,
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
  body: {
    fontSize: 14,
    maxWidth: 275,
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

export default function Modal(props) {
  const classes = useStyles();

  const rows = props.clients;
  let assigned = [];
  const [open, setOpen] = React.useState(false);
  const [client, setClient] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={props.open}
        maxWidth='md'
        fullWidth
        transition={Transition}
        keepMounted
        onClose={() => props.setOpen(false)}
        aria-labelledby='modal-slide-title'
        aria-describedby='modal-slide-description'
      >
        <DialogTitle
          id='classic-modal-slide-title'
          disableTypography
          className={classes.modalHeader}
          style={{
            paddingTop: 20,
            borderBottom: "1px solid #dddddd",
            paddingBottom: 20,
          }}
        >
          <Typography align='left' style={{ fontWeight: 900, fontSize: 22 }}>
            {props.groupName}
          </Typography>
        </DialogTitle>{" "}
        <DialogContent
          id='modal-slide-description'
          className={classes.modalBody}
          style={{ paddingBottom: 20, paddingTop: 20 }}
        >
          <div>
            <Typography
              style={{ fontWeight: 900, fontSize: 19, marginBottom: 10 }}
            >
              Group Members
            </Typography>
            <Paper className={classes.root} variant='outlined'>
              <TableContainer style={{ maxHeight: 440 }}>
                <Table className={classes.table} aria-label='customized table'>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align='left' style={{}}>
                        Name
                      </StyledTableCell>
                      <StyledTableCell style={{}}>Email</StyledTableCell>
                      <StyledTableCell align='center' style={{}}>
                        Created
                      </StyledTableCell>
                      <StyledTableCell align='center' style={{}}>
                        Assignment Status
                      </StyledTableCell>

                      <StyledTableCell align='center' style={{}}>
                        Assignments
                      </StyledTableCell>
                      <StyledTableCell align='center'>
                        LMS Accessed
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <StyledTableRow key={row.email}>
                          <StyledTableCell
                            component='th'
                            scope='row'
                            style={{
                              borderRight: "1px solid #e3e3e3",
                              paddingTop: 10,
                              paddingBottom: 10,
                            }}
                          >
                            {row.first_name + " " + row.last_name}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              borderRight: "1px solid #e3e3e3",
                              paddingTop: 10,
                              paddingBottom: 10,
                            }}
                          >
                            {row.email}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              borderRight: "1px solid #e3e3e3",
                              paddingTop: 10,
                              paddingBottom: 10,
                            }}
                          >
                            {dayjs(row.start_date).format("DD/MM/YYYY")}
                          </StyledTableCell>
                          <StyledTableCell
                            align='center'
                            style={{
                              borderRight: "1px solid #e3e3e3",
                              paddingTop: 10,
                              paddingBottom: 10,
                            }}
                          >
                            {!row.hasAssessments ? (
                              <span
                                style={{
                                  padding: 3,
                                  paddingLeft: 15,
                                  paddingRight: 15,
                                  borderRadius: 20,
                                  fontWeight: "bold",
                                  color: "#b94946",
                                }}
                              >
                                None Assigned
                              </span>
                            ) : row.hasPending ? (
                              <span
                                style={{
                                  background: "#fcb316",
                                  padding: 3,
                                  paddingLeft: 15,
                                  paddingRight: 15,
                                  borderRadius: 20,
                                  color: "#fff",
                                }}
                              >
                                Pending
                              </span>
                            ) : (
                              <span
                                style={{
                                  background: "#03aab1",
                                  padding: 3,
                                  paddingLeft: 15,
                                  paddingRight: 15,
                                  borderRadius: 20,
                                  color: "#fff",
                                }}
                              >
                                All Completed
                              </span>
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            align='center'
                            style={{
                              borderRight: "1px solid #e3e3e3",
                              paddingTop: 10,
                              paddingBottom: 10,
                            }}
                          >
                            {" "}
                            <MUIButton
                              variant='outlined'
                              size='small'
                              disabled={row.hasAssessments ? false : true}
                              onClick={() => {
                                setClient(row);
                                setOpen(true);
                              }}
                            >
                              View
                            </MUIButton>
                          </StyledTableCell>

                          <StyledTableCell
                            align='center'
                            style={{
                              paddingTop: 10,
                              paddingBottom: 10,
                            }}
                          >
                            {!row.lms_accessed ? (
                              <CancelIcon style={{ color: " #c73c3c" }} />
                            ) : (
                              <CheckCircleIcon style={{ color: "  #49a87b" }} />
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
                <ClientDetails client={client} open={open} setOpen={setOpen} />
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 7, 14, 50]}
                style={{
                  borderTop: "1px solid #e3e3e3",
                  background: "#fafafa",
                }}
                component='div'
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />{" "}
            </Paper>
          </div>
        </DialogContent>
        <Divider light style={{ marginBottom: 10, marginTop: 5 }} />
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: 20,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Button
            style={{ marginRight: 10 }}
            onClick={() => {
              props.setOpen(false);
            }}
          >
            Close
          </Button>
        </DialogActions>{" "}
      </Dialog>
    </div>
  );
}
