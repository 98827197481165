import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import axios from "axios";
import BannedModal from "../../views/Pages/Modals/BannedModal";

export const LoggedRoute = ({ component: Component, ...rest }) => {
  const [scrollTo, setScrollTo] = React.useState("");
  const [openBannedModal, setOpenBannedModal] = React.useState(false);
  const [redirectToLogin, setRedirectToLogin] = React.useState(false);

  useEffect(() => {
    (async () => {
      let token = localStorage.getItem("token");
      await axios
        .get("/api/auth/check-banned", {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        })
        .then((res) => {
          if (res.data) {
            setOpenBannedModal(true);
          }
        })
        .catch(function (error) {
          //setLoading(false);
        });
    })();
  }, []);

  const dashboardProps = {
    history: {
      action: "POP",
      length: 8,
      location: {},
    },
    location: {
      hash: "",
      key: "y4arca",
      pathname: "/admin/dashboard",
      search: "",
      state: null,
    },
    match: {
      isExact: true,
      params: {},
      path: "/admin/dashboard",
      url: "/admin/dashboard",
    },
    scrollTo: scrollTo,
  };
  let token = localStorage.getItem("token");

  return (
    <>
      <Route
        {...rest}
        render={(props) => {
          if (token === null || redirectToLogin) {
            return <Redirect push to='/auth/login' />;
          } else {
            if (props.location.pathname === "/admin/clients") {
              setScrollTo("clientsTable");
              return <Component {...dashboardProps} />;
            }
            return <Component {...props} />;
          }
        }}
      />
      <BannedModal
        open={openBannedModal}
        setOpen={setOpenBannedModal}
        setRedirectToLogin={setRedirectToLogin}
      />
    </>
  );
};
