import React from "react";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Card as MUICard } from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Schedule from "@material-ui/icons/Schedule";
import Info from "@material-ui/icons/Info";
import LocationOn from "@material-ui/icons/LocationOn";
import Gavel from "@material-ui/icons/Gavel";
import HelpOutline from "@material-ui/icons/HelpOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Accordion from "components/Accordion/Accordion.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import Pagination from "@material-ui/lab/Pagination";
import ClientTimeline from "views/Activity/Timelines/ClientTimeline.js";

import { ReactComponent as Tool1 } from "assets/icons/tool1.svg";
import { ReactComponent as Tool2 } from "assets/icons/tool2.svg";
import { ReactComponent as Tool3 } from "assets/icons/tool3.svg";
import { ReactComponent as Tool4 } from "assets/icons/tool4.svg";
import { ReactComponent as Tool5 } from "assets/icons/tool5.svg";
import { ReactComponent as Cog } from "assets/icons/cog.svg";
import { ReactComponent as Module } from "assets/icons/modicon.svg";
import { ReactComponent as tickCross } from "assets/icons/tickcross.svg";
import { ReactComponent as checked } from "assets/icons/checked.svg";
import { ReactComponent as close } from "assets/icons/close.svg";
import { ReactComponent as discount } from "assets/icons/discount.svg";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { cardTitle } from "assets/jss/elite-feats-cms.js";
import { Divider, Typography, Tooltip } from "@material-ui/core";
import { widgetStories } from "variables/general.js";
import ChromeReaderModeIcon from "@material-ui/icons/ChromeReaderMode";
import axios from "axios";
import dayjs from "dayjs";
import assessmentTitles from "./assessmentTitles";
import assessmentColors from "./assessmentColors";
import assessmentNames from "./assessmentNamesReversed";
import AssessmentIcon from "@material-ui/icons/Assessment";
import PurchaseTable from "./PurchaseTable";
import buttonStyles from "assets/jss/elite-feats-cms/views/buttonsStyle.js";
import empty from "assets/img/empty-folder.svg";
import historyImg from "assets/img/history.svg";
import purchase from "assets/img/price-tag.svg";
import {
  ControlPointDuplicateRounded,
  LocalActivityOutlined,
} from "@material-ui/icons";
import SweetAlert from "react-bootstrap-sweetalert";
import alertStyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";
import sweetAlertStyle from "assets/jss/elite-feats-cms/views/sweetAlertStyle";
import TextField from "@material-ui/core/TextField";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DatePicker from "./DatePicker";
import ClipLoader from "react-spinners/PuffLoader";
import { css } from "@emotion/react";

import IconButton from "@material-ui/core/IconButton";

import { ReactComponent as question } from "assets/icons/question.svg";
import ActivityTour from "./ClientActivityTour";
import HistoryTour from "./HistoryTour";
import AssignmentDialog from "./tourDialogs/AssignmentDialog";
import GroupMembers from "./GroupMembers";
import GroupLoading from "./GroupLoading";
import { useTranslation } from "react-i18next";

const TField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#63ac1d",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#3c9778",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#63ac1d",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#63ac1d",
      },
    },
  },
})(TextField);

const ModuleIcon = () => (
  <SvgIcon style={{ fontSize: 29, marginTop: -3 }} component={Module} />
);
const Tool1Icon = () => <SvgIcon style={{ fontSize: 30 }} component={Tool1} />;
const Tool2Icon = () => <SvgIcon style={{ fontSize: 30 }} component={Tool2} />;
const Tool3Icon = () => <SvgIcon style={{ fontSize: 30 }} component={Tool3} />;
const Tool4Icon = () => <SvgIcon style={{ fontSize: 30 }} component={Tool4} />;
const Tool5Icon = () => <SvgIcon style={{ fontSize: 30 }} component={Tool5} />;
const tickCrossIcon = () => (
  <SvgIcon style={{ fontSize: 30 }} component={tickCross} />
);
const checkedIcon = () => (
  <SvgIcon style={{ fontSize: 25 }} component={checked} />
);
const closeIcon = () => <SvgIcon style={{ fontSize: 27 }} component={close} />;
const discountIcon = () => (
  <SvgIcon style={{ fontSize: 27 }} component={discount} />
);

const iconMap = {
  m: ModuleIcon,
  "1": Tool1Icon,
  "2": Tool2Icon,
  "3": Tool3Icon,
  "4": Tool4Icon,
  "5": Tool5Icon,
};

const styles = {
  ...sweetAlertStyle,
  ...buttonStyles,
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
};

const useStyles = makeStyles(styles);

export default function Panels() {
  const { t } = useTranslation();
  const [triggerStep, setTriggerStep] = React.useState(0);
  const [activity, setActivity] = React.useState([]);
  const [loadingActivity, setLoadingActivity] = React.useState(false);
  const [assignmentHistory, setAssignmentHistory] = React.useState([]);
  const [loadingHistory, setLoadingHistory] = React.useState(false);
  const [purchaseHistory, setPurchaseHistory] = React.useState([]);
  const [loadingPurchase, setLoadingPurchase] = React.useState(false);
  const [reload, setReload] = React.useState(Math.random());
  const [purchasePage, setPurchasePage] = React.useState(1);
  const [historyPage, setHistoryPage] = React.useState(1);
  const [activityPage, setActivityPage] = React.useState(1);
  const [alert, setAlert] = React.useState(null);
  const [activityFilter, setActivityFilter] = React.useState("");
  const [cardHeight, setCardHeight] = React.useState(800);
  const [reloadTimeline, setReloadTimeline] = React.useState(Math.random());
  const [purchaseHeight, setPurchaseHeight] = React.useState(Math.random());
  const [historyHeight, setHistoryHeight] = React.useState(Math.random());
  const [activityHeight, setActivityHeight] = React.useState(Math.random());
  const [openActivityPicker, setOpenActivityPicker] = React.useState(false);
  const [activityDateRange, setActivityDateRange] = React.useState({});
  const [openHistoryPicker, setOpenHistoryPicker] = React.useState(false);
  const [historyDateRange, setHistoryDateRange] = React.useState({});
  const [openPurchasePicker, setOpenPurchasePicker] = React.useState(false);
  const [purchaseDateRange, setPurchaseDateRange] = React.useState({});
  const [openAssignedDialog, setOpenAssignedDialog] = React.useState(false);
  const [hide, setHide] = React.useState(0);
  const [pageChanged, setPageChanged] = React.useState(0);
  const [openMembers, setOpenMembers] = React.useState(false);
  const [members, setMembers] = React.useState([]);
  const [groupName, setGroupName] = React.useState("");
  const [openGroupLoading, setOpenGroupLoading] = React.useState(false);

  let successTabs = {};
  let errorsTabs = {};
  let purchaseTabs = {};
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const handleTogglePicker = (id) => {
    if (id === 1) {
      setOpenActivityPicker(!openActivityPicker);
    }
    if (id === 2) {
      setOpenHistoryPicker(!openHistoryPicker);
    }
    if (id === 3) {
      setOpenPurchasePicker(!openPurchasePicker);
    }
  };
  const handlePageHistory = (event, value) => {
    setHistoryPage(value);
  };
  const handlePagePurchase = (event, value) => {
    setPurchasePage(value);
  };
  const handlePageActivity = (event, value) => {
    setReload(Math.random());
    setActivityPage(value);
  };
  const hideAlert = () => {
    setAlert(null);
  };
  const updateCardHeight = (index) => {
    if (index === 0) {
      setActivityHeight(Math.random());
    }
    if (index === 1) {
      setHistoryHeight(Math.random());
    }
    if (index === 2) {
      setPurchaseHeight(Math.random());
    }
  };
  const assignSuccess = (tabs) => {
    setAlert(
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          maxHeight: 630,
          overflow: "auto",
        }}
        title={t("activity:alerts.successfullyAssigned")}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.info}
      >
        <Typography
          style={{
            color: "#b2b2b2",
            fontStyle: "italic",
            fontSize: 14,
            fontWeight: 200,
          }}
        >
          {t("activity:messages.clickOnAssessment")}
        </Typography>
        <Accordion collapses={Object.values(tabs).map((tab) => tab)} />
      </SweetAlert>
    );
  };

  const assignErrors = (tabs) => {
    setAlert(
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          maxHeight: 630,
          overflow: "auto",
          width: 525,
        }}
        title={t("activity:alerts.notAssigned")}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.info}
      >
        <Typography
          align="justify"
          style={{
            fontSize: 16,
          }}
        >
          {t("activity:messages.assignErrorExplanation")}
        </Typography>
        <Typography
          align="justify"
          style={{
            color: "#b2b2b2",
            fontStyle: "italic",
            fontSize: 14,
            fontWeight: 200,
            marginTop: 10,
          }}
        >
          {t("activity:messages.clickOnAssessmentError")}
        </Typography>
        <Accordion collapses={Object.values(tabs).map((tab) => tab)} />
      </SweetAlert>
    );
  };

  const purchasedItems = (table) => {
    setAlert(
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          maxHeight: 630,
          overflow: "auto",
          width: 800,
        }}
        title={t("activity:alerts.purchasedItems")}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.info}
      >
        {table.content}
      </SweetAlert>
    );
  };

  const getGroupMembers = (group_name) => {
    setOpenGroupLoading(true);
    axios
      .post(
        "/api/client/get-members",
        { group: group_name },
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setMembers(res.data);
        setOpenGroupLoading(false);
        setGroupName(group_name);
        setOpenMembers(true);
      });
  };

  const firstRender = React.useRef(true);

  React.useEffect(() => {
    setLoadingActivity(true);
    axios
      .get("/api/client/all-client-activity", {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setLoadingActivity(false);
        let completed = res.data;

        let timelines = [];
        completed.forEach((page, j) => {
          let timeline = [];
          page.forEach((entry, k) => {
            let date = dayjs(entry.date).format("MMM D, YYYY");
            let time = dayjs(entry.date).format("h:mm A");
            let object = { inverted: true };
            object["date"] = entry.date;
            object["badgeColor"] = assessmentColors[entry.assessment];
            if (entry.assessment[0] === "t") {
              object["badgeIcon"] = iconMap[entry.assessment[4]];
            } else if (entry.assessment[0] === "m") {
              object["badgeIcon"] = iconMap["m"];
            }
            object["fullTitle"] = t(
              `assignments:assessmentNames.${entry.assessment}`
            );
            object["title"] = t(
              `assignments:assessmentTitles.${entry.assessment}`
            );
            object["titleColor"] = assessmentColors[entry.assessment];
            object["name"] = entry.name;
            object["group"] = entry.group;
            object["body"] = (
              <div>
                <Typography
                  className={`assessment-name-${k}`}
                  style={{ fontSize: 18, fontWeight: 600, marginBottom: 10 }}
                >
                  {t(`assignments:assessmentNames.${entry.assessment}`)}
                </Typography>
                <Typography className={`person-name-${k}`}>
                  <span style={{ fontSize: 15, fontWeight: 500 }}>
                    {entry.name}
                  </span>
                  <span style={{ fontSize: 15, fontWeight: 400 }}>
                    {t("activity:completedAssessment")}
                  </span>
                  <span style={{ fontSize: 15, fontWeight: 400 }}>
                    {t("activity:on")}
                  </span>
                  <span
                    className={`completed-date-${k}`}
                    style={{ fontSize: 15, fontWeight: 500 }}
                  >
                    {date}
                  </span>
                  <span style={{ fontSize: 15, fontWeight: 400 }}>
                    {t("activity:at")}
                  </span>
                  <span
                    className={`completed-time-${k}`}
                    style={{ fontSize: 15, fontWeight: 500 }}
                  >
                    {time}
                  </span>
                </Typography>
                {entry.group ? (
                  <Box
                    style={{
                      marginTop: 12,
                      display: "flex",
                    }}
                  >
                    <span
                      style={{ fontWeight: 500, marginRight: 5, fontSize: 15 }}
                    >
                      {t("activity:group")}:
                    </span>
                    <Typography
                      align="center"
                      style={{
                        borderRadius: 10,
                        paddingLeft: 10,
                        paddingTop: 1,
                        paddingRight: 10,
                        background: "#40628d",
                        display: "flex",
                        flexGrow: 0,
                        fontSize: 12,
                        fontWeight: 400,
                        cursor: "pointer",
                        color: "#f7f7f7",
                      }}
                      onClick={() => {
                        getGroupMembers(entry.group);
                      }}
                    >
                      {entry.group}
                    </Typography>
                  </Box>
                ) : null}

                <Divider style={{ marginTop: 10, marginBottom: 10 }} light />

                <Button
                  className={`view-report-${k}`}
                  size="md"
                  color="success"
                  onClick={() =>
                    window.open(
                      `https://learn.elitefeats.com.au/report/${entry.accessor}`,
                      "_blank"
                    )
                  }
                >
                  <ChromeReaderModeIcon
                    className={classes.icons}
                    style={{ marginRight: 10, fontSize: 30 }}
                  />
                  {t("activity:viewReport")}
                </Button>
              </div>
            );
            /*   object["footer"] = (
              <Typography align='center'>
               
              </Typography>
            ); */
            timeline.push(object);
          });
          timelines.push(timeline);
        });

        setActivity(timelines);
      });

    setLoadingHistory(true);
    axios
      .get("/api/client/assignment-history", {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setLoadingHistory(false);
        let history = res.data;

        let timelines = [];

        history.forEach((page) => {
          let timeline = [];
          page.forEach((entry, index) => {
            let date = dayjs(entry.assigned_date).format("MMM D, YYYY");
            let time = dayjs(entry.assigned_date).format("h:mm A");
            let groups = entry.group;
            let object = { inverted: true };
            object["date"] = entry.assigned_date;
            successTabs[`${entry.assigned_date}`] = {};
            errorsTabs[`${entry.assigned_date}`] = {};
            let successes = false;
            let fails = false;
            for (let assessment in entry.success) {
              if (entry.success[assessment].length) {
                let successObj = {};
                successes = true;
                successObj["title"] = t(
                  `assignments:assessmentTitles.${assessment}`
                );
                successObj["content"] = (
                  <MUICard elevation={0}>
                    <ol style={{ color: "#000" }}>
                      {entry.success[assessment].map((client, index) => (
                        <Typography
                          key={client}
                          align="left"
                          style={{
                            padding: 5,
                            paddingLeft: 10,
                            paddingRight: 10,
                            fontSize: 15,
                          }}
                        >
                          {" "}
                          <li> {client} </li>
                        </Typography>
                      ))}
                    </ol>
                  </MUICard>
                );
                successTabs[`${entry.assigned_date}`][assessment] = successObj;
              }
            }

            for (let assessment in entry.error) {
              if (entry.error[assessment].length) {
                let errorsObj = {};
                fails = true;
                errorsObj["title"] = t(
                  `assignments:assessmentTitles.${assessment}`
                );
                errorsObj["content"] = (
                  <MUICard elevation={0}>
                    <ol style={{ color: "#000" }}>
                      {entry.error[assessment].map((client, index) => (
                        <Typography
                          key={client}
                          align="left"
                          style={{
                            padding: 5,
                            paddingLeft: 10,
                            paddingRight: 10,
                            fontSize: 15,
                          }}
                        >
                          {" "}
                          <li> {client} </li>
                        </Typography>
                      ))}
                    </ol>
                  </MUICard>
                );
                errorsTabs[`${entry.assigned_date}`][assessment] = errorsObj;
              }
            }
            if (successes && fails) {
              object["badgeColor"] = "warning";
              object["badgeIcon"] = tickCrossIcon;
              object["title"] = t("activity:status.assignedWithErrors");
              object["titleColor"] = "warning";
            }
            if (!fails) {
              object["badgeColor"] = "success";
              object["badgeIcon"] = checkedIcon;
              object["title"] = t("activity:status.allAssignedSuccessfully");
              object["titleColor"] = "success";
            }
            if (!successes) {
              object["badgeColor"] = "danger";
              object["badgeIcon"] = closeIcon;
              object["title"] = t("activity:status.notAssigned");
              object["titleColor"] = "danger";
            }

            object["body"] = (
              <div style={{ width: "100%" }}>
                <Typography
                  style={{ fontSize: 18, fontWeight: 600, marginBottom: 10 }}
                >
                  {t("activity:assigned.details", { date: date, time: time })}
                </Typography>
                {groups.length ? (
                  <Typography
                    style={{
                      fontSize: 18,
                      fontWeight: 600,
                      marginBottom: 10,
                      color: "#054362",
                    }}
                  >
                    {t("activity:assigned.toGroups", {
                      groups: groups.join(", "),
                    })}
                  </Typography>
                ) : null}

                <Divider style={{ marginTop: 10, marginBottom: 10 }} light />

                <Grid container spacing={2}>
                  {successes ? (
                    <Grid item>
                      <Button
                        size="md"
                        color="info"
                        onClick={() => {
                          assignSuccess(successTabs[entry.assigned_date]);
                        }}
                      >
                        {t("activity:actions.viewAssigned")}
                      </Button>
                    </Grid>
                  ) : null}

                  {fails ? (
                    <Grid item>
                      <Button
                        size="md"
                        color="warning"
                        onClick={() => {
                          assignErrors(errorsTabs[entry.assigned_date]);
                        }}
                      >
                        {t("activity:actions.viewErrors")}
                      </Button>
                    </Grid>
                  ) : null}
                </Grid>
              </div>
            );

            timeline.push(object);
          });
          timelines.push(timeline);
        });

        setAssignmentHistory(timelines);
      });

    setLoadingPurchase(true);
    axios
      .get("/api/user/get-all-orders", {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setLoadingPurchase(false);
        let history = res.data;

        let timelines = [];

        history.forEach((page) => {
          let timeline = [];
          page.forEach((entry, index) => {
            let date = dayjs(entry.date_paid).format("MMM D, YYYY");
            let time = dayjs(entry.date_paid).format("h:mm A");
            let object = { inverted: true };
            object["date"] = entry.date_paid;

            purchaseTabs[`${entry.date_paid}`] = {};

            let discounted = false;

            let purchaseObj = {};
            if (entry.discount_total !== "0.00") {
              discounted = true;
            }

            purchaseObj["content"] = (
              <PurchaseTable
                total={entry.total}
                discount={entry.discount_total}
                currency={entry.currency_symbol}
                rows={entry.line_items}
              />
            );
            purchaseTabs[`${entry.date_paid}`] = purchaseObj;

            if (!discounted) {
              object["badgeColor"] = "success";
              object["badgeIcon"] = AttachMoneyIcon;
              object["title"] = t("activity:status.paid");
              object["titleColor"] = "success";
            }
            if (discounted) {
              object["badgeColor"] = "info";
              object["badgeIcon"] = discountIcon;
              object["title"] = t("activity:status.discounted");
              object["titleColor"] = "info";
            }

            object["body"] = (
              <div style={{ width: "100%" }}>
                <Typography
                  style={{ fontSize: 18, fontWeight: 600, marginBottom: 10 }}
                >
                  {t("activity:purchase.completed", { date: date, time: time })}
                </Typography>
                <Grid container style={{ marginTop: 20 }}>
                  <Grid item xs={6}>
                    <Box style={{ display: "flex" }}>
                      <Typography
                        style={{
                          background: "#5a78ac",
                          color: "#fff",
                          fontSize: 16,
                          padding: 3,
                          paddingLeft: 10,
                          paddingRight: 10,
                          borderRadius: 4,
                          marginBottom: 10,
                        }}
                      >
                        {t("activity:purchase.totalPaid", {
                          total: entry.total,
                          currency: entry.currency_symbol,
                        })}
                      </Typography>
                    </Box>
                  </Grid>
                  {discounted ? (
                    <Grid item xs={6}>
                      <Box style={{ display: "flex" }}>
                        <Typography
                          style={{
                            border: "1px solid #757575",
                            color: "#757575",
                            fontSize: 16,
                            padding: 2,
                            paddingLeft: 10,
                            paddingRight: 10,
                            borderRadius: 4,
                            marginBottom: 10,
                          }}
                        >
                          {t("activity:purchase.totalDiscount", {
                            discount: entry.discount_total,
                            currency: entry.currency_symbol,
                          })}
                        </Typography>
                      </Box>
                    </Grid>
                  ) : null}
                </Grid>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} light />

                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      size="md"
                      color={discounted ? "success" : "info"}
                      onClick={() => {
                        purchasedItems(purchaseTabs[entry.date_paid]);
                      }}
                    >
                      {t("activity:actions.viewPurchased")}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            );

            timeline.push(object);
          });
          timelines.push(timeline);
        });

        setPurchaseHistory(timelines);
      });
  }, []);

  React.useEffect(() => {
    if (pageChanged === 0 && !loadingActivity) {
      axios
        .get("/api/user/get-tour-info", {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        })
        .then((res) => {
          let tourInfo = res.data;
          if (!tourInfo.clientActivity && activity.length) {
            setStartTour(1);
          }
        });
    }
    if (pageChanged === 1 && !loadingHistory) {
      axios
        .get("/api/user/get-tour-info", {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        })
        .then((res) => {
          let tourInfo = res.data;
          if (
            !tourInfo.assignmentHistory &&
            startTour === 0 &&
            assignmentHistory.length
          ) {
            setStartTour(2);
          }
        });
    }
    /* if (document.getElementById("purchaseTimeline") && !loadingPurchase) {
      axios
        .get("/api/user/get-tour-info", {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        })
        .then((res) => {
          let tourInfo = res.data;
          if (!tourInfo.purchaseHistory) {
            setStartTour(3);
          }
        });
    } */
  }, [pageChanged, loadingActivity, loadingHistory]);

  React.useEffect(() => {
    let timelineHeight = document.getElementById("activityTimeline")
      .clientHeight;

    setCardHeight(timelineHeight + 55);
  }, [reload, activityFilter, activityHeight, activity, activityDateRange]);

  React.useEffect(() => {
    if (document.getElementById("historyTimeline")) {
      let timelineHeight = document.getElementById("historyTimeline")
        .clientHeight;
      setCardHeight(timelineHeight + 55);
    }
  }, [historyHeight, historyPage, assignmentHistory, historyDateRange]);

  React.useEffect(() => {
    if (document.getElementById("purchaseTimeline")) {
      let timelineHeight = document.getElementById("purchaseTimeline")
        .clientHeight;
      setCardHeight(timelineHeight + 55);
    }
  }, [purchaseHeight, purchasePage, purchaseHistory, purchaseDateRange]);

  React.useEffect(() => {
    setReloadTimeline(Math.random());
  }, [cardHeight]);

  React.useEffect(() => {
    if (!firstRender.current) {
      setReload(Math.random());
    }
    firstRender.current = false;
  }, [activity]);

  const filterActivity = (filter) => {
    if (filter.length && activityPage !== 1) {
      setActivityPage(1);
    }
    let flattened = activity.flat();

    let filtered = flattened.filter(
      (entry) =>
        entry.name.toLowerCase().includes(filter.toLowerCase()) ||
        entry.title.toLowerCase().includes(filter.toLowerCase()) ||
        entry.fullTitle.toLowerCase().includes(filter.toLowerCase()) ||
        entry.group?.toLowerCase().includes(filter.toLowerCase())
    );

    if (Object.keys(activityDateRange).length !== 0) {
      filtered = filtered.filter((a) => {
        let activityDate = new Date(a.date);
        return (
          activityDate >= activityDateRange.startDate &&
          activityDate <= activityDateRange.endDate
        );
      });
    }

    let spliced = [];
    while (filtered.length) {
      spliced.push(filtered.splice(0, 10));
    }
    return spliced;
  };

  const filterHistory = () => {
    let flattened = assignmentHistory.flat();

    if (Object.keys(historyDateRange).length !== 0) {
      flattened = flattened.filter((a) => {
        let historyDate = new Date(a.date);
        return (
          historyDate >= historyDateRange.startDate &&
          historyDate <= historyDateRange.endDate
        );
      });
    }
    let spliced = [];

    while (flattened.length) {
      spliced.push(flattened.splice(0, 10));
    }

    return spliced;
  };

  const filterPurchase = () => {
    let flattened = purchaseHistory.flat();

    if (Object.keys(purchaseDateRange).length !== 0) {
      flattened = flattened.filter((a) => {
        let purchaseDate = new Date(a.date);
        return (
          purchaseDate >= purchaseDateRange.startDate &&
          purchaseDate <= purchaseDateRange.endDate
        );
      });
    }
    let spliced = [];

    while (flattened.length) {
      spliced.push(flattened.splice(0, 10));
    }

    return spliced;
  };

  const classes = useStyles();
  const Question = () => (
    <SvgIcon component={question} style={{ fontSize: 22, padding: 3 }} />
  );

  const [startTour, setStartTour] = React.useState(0);
  return (
    <div>
      <ActivityTour startTour={startTour} setStartTour={setStartTour} />
      <HistoryTour
        setHide={setHide}
        triggerStep={triggerStep}
        startTour={startTour}
        setStartTour={setStartTour}
        setOpen={setOpenAssignedDialog}
      />
      <GridContainer justify="center" style={{ marginTop: -30 }}>
        <GridItem xs={12} sm={12} md={12}>
          <div id={"Card"} style={{ minHeight: "calc(100vh - 100px)" }}>
            <Card
              style={{
                height: firstRender ? "auto" : `${cardHeight}px`,
                minHeight: "calc(100vh - 130px)",
              }}
            >
              <CardBody>
                <NavPills
                  setPageChanged={setPageChanged}
                  startTour={startTour}
                  updateHeight={updateCardHeight}
                  reloadTimeline={reloadTimeline}
                  reload={reload}
                  color="black"
                  horizontal={{
                    tabsGrid: { xs: 12, sm: 12, md: 2 },
                    contentGrid: { xs: 12, sm: 12, md: 10 },
                  }}
                  tabs={[
                    {
                      tabButton: t("activity:tabs.clientActivity"),
                      tabIcon: Schedule,
                      tabContent: (
                        <div
                          id={"activityTimeline"}
                          style={{ minHeight: "calc(100vh - 130px)" }}
                        >
                          {activity.length ? (
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginRight: 10,
                                marginBottom: 20,
                              }}
                            >
                              <Tooltip
                                title={t("activity:tooltips.guidedTour")}
                                placement="top"
                              >
                                <div>
                                  <IconButton
                                    className="activity-tour-button"
                                    style={{ border: "1px solid #757575" }}
                                    size="small"
                                    onClick={() => {
                                      setStartTour(1);
                                    }}
                                  >
                                    <Question />
                                  </IconButton>{" "}
                                </div>
                              </Tooltip>
                            </Grid>
                          ) : null}

                          <Typography
                            align="center"
                            className={"activity-timeline"}
                            style={{
                              fontSize: 24,
                              fontWeight: "bold",
                              marginBottom: 5,
                              textDecoration: "underline",
                            }}
                          >
                            {t("activity:labels.allClientActivity")}
                          </Typography>
                          {activity.length ? (
                            <Box>
                              <Grid
                                container
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  marginBottom: 15,
                                  alignItems: "center",
                                }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    maxWidth: 600,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginBottom: 10,
                                    paddingLeft: 20,
                                  }}
                                >
                                  <TField
                                    className="search"
                                    variant="outlined"
                                    margin="normal"
                                    as={TField}
                                    id="search_text"
                                    helperText={t(
                                      "activity:placeholders.searchBy"
                                    )}
                                    label={t("activity:labels.search")}
                                    onChange={(e) =>
                                      setActivityFilter(e.target.value)
                                    }
                                    fullWidth
                                  />
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: 15,
                                  }}
                                >
                                  {Object.keys(activityDateRange).length ? (
                                    <Button
                                      color=""
                                      round
                                      onClick={() => handleTogglePicker(1)}
                                      style={{ marginLeft: 10 }}
                                    >
                                      <DateRangeIcon />{" "}
                                      {`${dayjs(
                                        activityDateRange.startDate
                                      ).format("DD/MM/YYYY")} - ${dayjs(
                                        activityDateRange.endDate
                                      ).format("DD/MM/YYYY")}`}
                                    </Button>
                                  ) : (
                                    <Button
                                      className="date-filter"
                                      color=""
                                      round
                                      onClick={() => handleTogglePicker(1)}
                                      style={{ marginLeft: 10 }}
                                    >
                                      <DateRangeIcon />{" "}
                                      {t("activity:buttons.addDateFilter")}
                                    </Button>
                                  )}
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {filterActivity(activityFilter).length ? (
                                    <Pagination
                                      count={
                                        filterActivity(activityFilter).length
                                      }
                                      page={activityPage}
                                      onChange={handlePageActivity}
                                      size="large"
                                    />
                                  ) : null}
                                </Grid>
                              </Grid>

                              {filterActivity(activityFilter).length ? (
                                <MUICard
                                  elevation={0}
                                  style={{
                                    padding: 20,

                                    marginBottom: 5,
                                    background: "#f7f5f5",
                                    border: "1px solid #e7e7e7",
                                    borderRadius: 8,
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <ClientTimeline
                                    reload={reloadTimeline}
                                    simple
                                    stories={
                                      filterActivity(activityFilter).length
                                        ? filterActivity(activityFilter)[
                                            activityPage - 1
                                          ]
                                        : []
                                    }
                                  />
                                </MUICard>
                              ) : (
                                <MUICard
                                  elevation={0}
                                  style={{
                                    minHeight: "calc(100vh-130px)",
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    marginTop: 30,
                                  }}
                                >
                                  <img src={empty} style={{ height: 250 }} />
                                  <Typography
                                    align="center"
                                    style={{
                                      fontSize: 20,
                                      color: "#808080",
                                      fontWeight: 200,
                                      fontStyle: "italic",
                                    }}
                                  >
                                    {t("activity:messages.noResults")}
                                  </Typography>
                                </MUICard>
                              )}

                              <Grid
                                container
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  marginBottom: 10,
                                }}
                              >
                                <Grid item>
                                  {filterActivity(activityFilter).length ? (
                                    <Pagination
                                      count={
                                        filterActivity(activityFilter).length
                                      }
                                      page={activityPage}
                                      onChange={handlePageActivity}
                                      size="large"
                                    />
                                  ) : null}
                                </Grid>
                              </Grid>
                            </Box>
                          ) : loadingActivity ? (
                            <React.Fragment
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginTop: 50,
                                  minHeight: "calc(100vh - 320px)",
                                }}
                              >
                                <ClipLoader
                                  color={"#3F3F3F"}
                                  loading={loadingActivity}
                                  css={override}
                                  size={250}
                                />{" "}
                                <Typography
                                  align="center"
                                  style={{
                                    marginTop: 40,
                                    color: "#3F3F3F",
                                    fontStyle: "italic",
                                    fontWeight: 200,
                                  }}
                                >
                                  {t("activity:messages.retrievingInformation")}
                                </Typography>
                              </Box>
                            </React.Fragment>
                          ) : (
                            <MUICard
                              elevation={0}
                              style={{
                                minHeight: "calc(100vh - 200px)",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginTop: 30,
                              }}
                            >
                              <img src={empty} style={{ height: 250 }} />
                              <Typography
                                align="center"
                                style={{
                                  fontSize: 20,
                                  color: "#808080",
                                  fontWeight: 200,
                                  fontStyle: "italic",
                                }}
                              >
                                {t("activity:messages.noActivityToShow")}
                              </Typography>
                            </MUICard>
                          )}
                        </div>
                      ),
                    },

                    {
                      tabButton: t("activity:tabs.assignmentHistory"),
                      tabIcon: AssessmentIcon,
                      tabContent: (
                        <div
                          style={{
                            marginLeft: 2,
                            minHeight: "calc(100vh - 130px)",
                          }}
                          id={"historyTimeline"}
                        >
                          {assignmentHistory.length ? (
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginRight: 10,
                                marginBottom: 20,
                              }}
                            >
                              <Tooltip
                                title={t("activity:tooltips.guidedTour")}
                                placement="top"
                              >
                                <div>
                                  <IconButton
                                    className="history-tour-button"
                                    style={{ border: "1px solid #757575" }}
                                    size="small"
                                    onClick={() => {
                                      setStartTour(2);
                                    }}
                                  >
                                    <Question />
                                  </IconButton>{" "}
                                </div>
                              </Tooltip>
                            </Grid>
                          ) : null}
                          <Typography
                            align="center"
                            style={{
                              fontSize: 24,
                              fontWeight: "bold",
                              marginBottom: 15,
                              textDecoration: "underline",
                            }}
                          >
                            {t("activity:labels.assignmentHistory")}
                          </Typography>
                          {assignmentHistory.length ? (
                            <Box>
                              <Grid
                                container
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  marginBottom: 10,
                                }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginBottom: 10,
                                  }}
                                >
                                  {Object.keys(historyDateRange).length ? (
                                    <Button
                                      color=""
                                      round
                                      onClick={() => handleTogglePicker(2)}
                                      style={{ marginLeft: 10 }}
                                    >
                                      <DateRangeIcon />{" "}
                                      {`${dayjs(
                                        historyDateRange.startDate
                                      ).format("DD/MM/YYYY")} - ${dayjs(
                                        historyDateRange.endDate
                                      ).format("DD/MM/YYYY")}`}
                                    </Button>
                                  ) : (
                                    <Button
                                      className="date-filter"
                                      color=""
                                      round
                                      onClick={() => handleTogglePicker(2)}
                                      style={{ marginLeft: 10 }}
                                    >
                                      <DateRangeIcon />{" "}
                                      {t("activity:buttons.addDateFilter")}
                                    </Button>
                                  )}
                                </Grid>
                                <Grid item>
                                  {filterHistory().length ? (
                                    <Pagination
                                      count={filterHistory().length}
                                      page={historyPage}
                                      onChange={handlePageHistory}
                                      size="large"
                                    />
                                  ) : null}
                                </Grid>
                              </Grid>
                              {filterHistory().length ? (
                                <MUICard
                                  elevation={0}
                                  style={{
                                    padding: 20,
                                    marginBottom: 5,
                                    background: "#f7f5f5",
                                    border: "1px solid #e7e7e7",
                                    borderRadius: 8,
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <ClientTimeline
                                    simple
                                    stories={
                                      filterHistory().length
                                        ? filterHistory()[historyPage - 1]
                                        : []
                                    }
                                  />
                                </MUICard>
                              ) : (
                                <MUICard
                                  elevation={0}
                                  style={{
                                    minHeight: "calc(100vh - 200px)",
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    marginTop: 30,
                                  }}
                                >
                                  <img
                                    src={historyImg}
                                    style={{ height: 250 }}
                                  />
                                  <Typography
                                    align="center"
                                    style={{
                                      fontSize: 20,
                                      color: "#808080",
                                      fontWeight: 200,
                                      marginTop: 30,
                                      fontStyle: "italic",
                                    }}
                                  >
                                    {t("activity:messages.noResults")}
                                  </Typography>
                                </MUICard>
                              )}
                              <Grid
                                container
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              >
                                <Grid item>
                                  {filterHistory().length ? (
                                    <Pagination
                                      count={filterHistory().length}
                                      page={historyPage}
                                      onChange={handlePageHistory}
                                      size="large"
                                    />
                                  ) : null}
                                </Grid>
                              </Grid>
                            </Box>
                          ) : loadingHistory ? (
                            <React.Fragment
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginTop: 50,
                                  minHeight: "calc(100vh - 320px)",
                                }}
                              >
                                <ClipLoader
                                  color={"#3F3F3F"}
                                  loading={loadingHistory}
                                  css={override}
                                  size={250}
                                />
                                <Typography
                                  align="center"
                                  style={{
                                    marginTop: 40,
                                    color: "#3F3F3F",
                                    fontStyle: "italic",
                                    fontWeight: 200,
                                  }}
                                >
                                  {t("activity:messages.retrievingInformation")}
                                </Typography>
                              </Box>
                            </React.Fragment>
                          ) : (
                            <MUICard
                              elevation={0}
                              style={{
                                minHeight: "calc(100vh - 200px)",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginTop: 30,
                              }}
                            >
                              <img src={historyImg} style={{ height: 250 }} />
                              <Typography
                                align="center"
                                style={{
                                  fontSize: 20,
                                  color: "#808080",
                                  fontWeight: 200,
                                  marginTop: 30,
                                  fontStyle: "italic",
                                }}
                              >
                                {t(
                                  "activity:messages.noAssignmentHistoryToShow"
                                )}
                              </Typography>
                            </MUICard>
                          )}
                        </div>
                      ),
                    },
                    {
                      tabButton: t("activity:tabs.purchaseHistory"),
                      tabIcon: MonetizationOnIcon,
                      tabContent: (
                        <div
                          style={{
                            marginLeft: 2,
                            minHeight: "calc(100vh - 130px)",
                          }}
                          id={"purchaseTimeline"}
                        >
                          <Typography
                            align="center"
                            style={{
                              fontSize: 24,
                              fontWeight: "bold",
                              marginBottom: 15,
                              textDecoration: "underline",
                            }}
                          >
                            {t("activity:labels.purchaseHistory")}
                          </Typography>
                          {purchaseHistory.length ? (
                            <Box>
                              <Grid
                                container
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  marginBottom: 10,
                                }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginBottom: 10,
                                  }}
                                >
                                  {Object.keys(purchaseDateRange).length ? (
                                    <Button
                                      color=""
                                      round
                                      onClick={() => handleTogglePicker(3)}
                                      style={{ marginLeft: 10 }}
                                    >
                                      <DateRangeIcon />{" "}
                                      {`${dayjs(
                                        purchaseDateRange.startDate
                                      ).format("DD/MM/YYYY")} - ${dayjs(
                                        purchaseDateRange.endDate
                                      ).format("DD/MM/YYYY")}`}
                                    </Button>
                                  ) : (
                                    <Button
                                      color=""
                                      round
                                      onClick={() => handleTogglePicker(3)}
                                      style={{ marginLeft: 10 }}
                                    >
                                      <DateRangeIcon />{" "}
                                      {t("activity:buttons.addDateFilter")}
                                    </Button>
                                  )}
                                </Grid>
                                <Grid item>
                                  {filterPurchase().length ? (
                                    <Pagination
                                      count={filterPurchase().length}
                                      page={purchasePage}
                                      onChange={handlePagePurchase}
                                      size="large"
                                    />
                                  ) : null}
                                </Grid>
                              </Grid>
                              {filterPurchase().length ? (
                                <MUICard
                                  elevation={0}
                                  style={{
                                    padding: 20,
                                    marginBottom: 5,
                                    background: "#f7f5f5",
                                    border: "1px solid #e7e7e7",
                                    borderRadius: 8,
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <ClientTimeline
                                    simple
                                    stories={
                                      filterPurchase().length
                                        ? filterPurchase()[purchasePage - 1]
                                        : []
                                    }
                                  />
                                </MUICard>
                              ) : (
                                <MUICard
                                  elevation={0}
                                  style={{
                                    minHeight: "calc(100vh - 200px)",
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    marginTop: 30,
                                  }}
                                >
                                  <img src={purchase} style={{ height: 250 }} />
                                  <Typography
                                    align="center"
                                    style={{
                                      fontSize: 20,
                                      color: "#808080",
                                      fontWeight: 200,
                                      marginTop: 30,
                                      fontStyle: "italic",
                                    }}
                                  >
                                    {t("activity:messages.noResults")}
                                  </Typography>
                                </MUICard>
                              )}

                              <Grid
                                container
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              >
                                <Grid item>
                                  {filterPurchase().length ? (
                                    <Pagination
                                      count={filterPurchase().length}
                                      page={purchasePage}
                                      onChange={handlePagePurchase}
                                      size="large"
                                    />
                                  ) : null}
                                </Grid>
                              </Grid>
                            </Box>
                          ) : loadingPurchase ? (
                            <React.Fragment
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginTop: 50,
                                  minHeight: "calc(100vh - 320px)",
                                }}
                              >
                                <ClipLoader
                                  color={"#3F3F3F"}
                                  loading={loadingPurchase}
                                  css={override}
                                  size={250}
                                />
                                <Typography
                                  align="center"
                                  style={{
                                    marginTop: 40,
                                    color: "#3F3F3F",
                                    fontStyle: "italic",
                                    fontWeight: 200,
                                  }}
                                >
                                  {t("activity:messages.retrievingInformation")}
                                </Typography>
                              </Box>
                            </React.Fragment>
                          ) : (
                            <MUICard
                              elevation={0}
                              style={{
                                minHeight: "calc(100vh - 200px)",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginTop: 30,
                              }}
                            >
                              <img src={purchase} style={{ height: 250 }} />
                              <Typography
                                align="center"
                                style={{
                                  fontSize: 20,
                                  color: "#808080",
                                  fontWeight: 200,
                                  marginTop: 30,
                                  fontStyle: "italic",
                                }}
                              >
                                {t("activity:messages.noPurchaseHistoryToShow")}
                              </Typography>
                            </MUICard>
                          )}
                        </div>
                      ),
                    },
                  ]}
                />
              </CardBody>
            </Card>{" "}
          </div>
        </GridItem>
      </GridContainer>
      {alert}
      <DatePicker
        open={openActivityPicker}
        handleClose={() => handleTogglePicker(1)}
        dateRange={activityDateRange}
        setDateRange={setActivityDateRange}
      />
      <DatePicker
        open={openHistoryPicker}
        handleClose={() => handleTogglePicker(2)}
        dateRange={historyDateRange}
        setDateRange={setHistoryDateRange}
      />
      <DatePicker
        open={openPurchasePicker}
        handleClose={() => handleTogglePicker(3)}
        dateRange={purchaseDateRange}
        setDateRange={setPurchaseDateRange}
      />
      <AssignmentDialog
        hide={hide}
        setHide={setHide}
        setTriggerStep={setTriggerStep}
        open={openAssignedDialog}
        setOpen={setOpenAssignedDialog}
      />
      <GroupMembers
        open={openMembers}
        setOpen={setOpenMembers}
        members={members}
        groupName={groupName}
      />
      <GroupLoading open={openGroupLoading} setOpen={setOpenGroupLoading} />
    </div>
  );
}
