import React from "react";
// material-ui components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";

import modalStyles from "assets/jss/elite-feats-cms/modalStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";

import * as Yup from "yup";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { withRouter } from "react-router-dom";
import { Divider, Typography, Box, Card } from "@material-ui/core";

import { useDebouncedCallback } from "use-debounce";

import alertStyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";
import PlayForWorkIcon from "@material-ui/icons/PlayForWork";
import ControlPointDuplicateIcon from "@material-ui/icons/ControlPointDuplicate";
import { useTranslation } from "react-i18next";

const styles = { ...modalStyles, ...alertStyles };

const useStyles = makeStyles(styles);

const TField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#63ac1d",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#3c9778",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#63ac1d",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#63ac1d",
      },
    },
  },
})(TextField);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Modal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [alert, setAlert] = React.useState(null);
  const [initialValues, setInitial] = React.useState({
    first: "",
    last: "",
    email: "",
  });
  const [open, setOpen] = React.useState(false);
  const [groups, setGroups] = React.useState([]);
  const [deleted, setDeleted] = React.useState([]);
  const [directory, setDirectory] = React.useState({});

  const hideAlert = (loc) => {
    if (loc === "close") {
      props.openModal(false);
    }
    setAlert(null);
  };
  const dangerAlert = () => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("groups:restore.clientNotAdded")}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.danger}
      >
        {t("groups:restore.clientExistsError")}
      </SweetAlert>
    );
  };

  const warningAlert = () => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title={t("groups:restore.areYouSure")}
        onConfirm={() => hideAlert("close")}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.warning}
        cancelBtnCssClass={classes.button}
      >
        {t("groups:restore.informationLostWarning")}
      </SweetAlert>
    );
  };

  const groupExistsAlert = () => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("groups:restore.groupNotRestored")}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " "}
        cancelBtnCssClass={classes.button}
      >
        {t("groups:restore.groupExistsError")}
      </SweetAlert>
    );
  };

  const restoredAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("groups:restore.groupRestored")}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " "}
        cancelBtnCssClass={classes.button}
      >
        {t("groups:restore.groupRestoredSuccess")}
      </SweetAlert>
    );
  };

  const inputAlert = (group) => {
    setAlert(
      <SweetAlert
        input
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title={t("groups:restore.enterNewGroupName")}
        onConfirm={(e) => {
          let oldName = group.name;
          group.name = e;
          if (groupExists(group)) {
            groupExistsAlert();
          } else {
            handleRestore(group, oldName);
            hideAlert();
          }
          group.name = oldName;
        }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button}
      >
        {t("groups:restore.newNameForGroup")}
      </SweetAlert>
    );
  };

  React.useEffect(() => {
    axios
      .get("/api/client/get-deleted-groups", {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setGroups(res.data.groups.reverse());
        setDeleted(res.data.deleted.reverse());
        setDirectory(res.data.directory);
      });
  }, [props.groupsAdded]);

  const getMemberNames = (members) => {
    let names = [];
    members.forEach((member) => {
      names.push(directory[member]);
    });

    return names;
  };

  const groupExists = (restore_group) => {
    let exists = groups.filter((group) => {
      return group.name === restore_group.name;
    });

    if (exists.length) {
      return true;
    } else {
      return false;
    }
  };

  const handleRestore = (group, old_name) => {
    axios
      .post(
        "/api/client/restore-group",
        { group: group, oldName: old_name },
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.data === "restored") {
          restoredAlert();
          props.setGroupsAdded(Math.random());
        }
      });
  };

  return (
    <div>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={props.modal}
        transition={Transition}
        keepMounted
        fullWidth
        maxWidth="sm"
        disableEnforceFocus
        disableBackdropClick
        onClose={() => props.openModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
          style={{ paddingTop: 30, borderBottom: "1px solid #dedede" }}
        >
          <Typography style={{ fontWeight: 900, fontSize: 22 }}>
            {t("groups:restore.restoreDeletedGroupTitle")}
          </Typography>
        </DialogTitle>{" "}
        <DialogContent style={{ paddingBottom: 20, paddingTop: 20 }}>
          <Typography
            style={{ marginBottom: 10, fontStyle: "italic", color: "#b2b2b2" }}
          >
            {t("groups:restore.restoreDeletedGroupDescription")}
          </Typography>
          <Card
            variant="outlined"
            style={{ padding: 15, paddingTop: 10, paddingBottom: 10 }}
          >
            {deleted.length ? (
              deleted.map((group, index) => (
                <React.Fragment key={index}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        paddingLeft: 15,
                        paddingRight: 15,
                      }}
                    >
                      <Tooltip
                        title={
                          <Typography>
                            {group.members.length
                              ? getMemberNames(group.members).join(", ")
                              : t("groups:restore.noMembers")}
                          </Typography>
                        }
                        placement="top"
                      >
                        <div>
                          <Typography
                            align="left"
                            style={{ fontSize: 17, fontWeight: "bold" }}
                          >
                            {group.name}
                          </Typography>{" "}
                        </div>
                      </Tooltip>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                    >
                      <Button
                        color="success"
                        size="sm"
                        onClick={() => {
                          if (groupExists(group)) {
                            inputAlert(group);
                          } else {
                            handleRestore(group);
                          }
                        }}
                      >
                        {t("groups:restore.restoreButton")}
                      </Button>
                    </Grid>
                  </Grid>

                  {index !== deleted.length - 1 ? (
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                  ) : null}
                </React.Fragment>
              ))
            ) : (
              <Typography>{t("groups:restore.noGroupsDeleted")}</Typography>
            )}
          </Card>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: 15,
            paddingLeft: 30,
            borderTop: "1px solid #dedede",
          }}
        >
          <Button
            style={{ marginRight: 10, marginTop: 10 }}
            onClick={() => {
              props.openModal(false);
            }}
          >
            {t("groups:restore.closeButton")}
          </Button>
        </DialogActions>{" "}
      </Dialog>

      {alert}
    </div>
  );
}
