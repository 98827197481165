export default {
  module1: "tools1",
  module2: "tools2",
  module3: "tools3",
  module4: "tools4",
  module5: "tools5",
  tool1_1: "tools1",
  tool1_2: "tools1",
  tool1_3: "tools1",
  tool2_1: "tools2",
  tool2_2: "tools2",
  tool2_3: "tools2",
  tool3_1: "tools3",
  tool3_2: "tools3",
  tool3_3: "tools3",
  tool3_4: "tools3",
  tool4_1: "tools4",
  tool4_2: "tools4",
  tool4_3: "tools4",
  tool4_4: "tools4",
  tool4_5: "tools4",
  tool5_1: "tools5",
  tool5_2: "tools5",
  tool5_3: "tools5",
};
