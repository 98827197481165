import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import GroupClients from "../Modals/GroupClients";
import dayjs from "dayjs";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fd9b15",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function CustomizedTables(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [clients, setClients] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [groupName, setGroupName] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(7);
  const rows = props.groups.reverse();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <div>
      <Paper className={classes.root} variant='outlined'>
        <TableContainer style={{ maxHeight: 440 }}>
          <Table className={classes.table} aria-label='customized table'>
            <TableHead>
              <TableRow>
                <StyledTableCell style={{}}>Group Name</StyledTableCell>
                <StyledTableCell>Description</StyledTableCell>
                <StyledTableCell align='center' style={{}}>
                  Members
                </StyledTableCell>
                <StyledTableCell align='center' style={{}}>
                  Creation Date
                </StyledTableCell>
                <StyledTableCell align='center' style={{}}>
                  Details
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <StyledTableRow key={row.email}>
                    <StyledTableCell
                      component='th'
                      scope='row'
                      style={{
                        borderRight: "1px solid #e3e3e3",
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        borderRight: "1px solid #e3e3e3",
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      {row.description?.length
                        ? row.description
                        : "Not Specified"}
                    </StyledTableCell>
                    <StyledTableCell
                      align='center'
                      style={{
                        borderRight: "1px solid #e3e3e3",
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      {row.members.length}
                    </StyledTableCell>
                    <StyledTableCell
                      align='center'
                      style={{
                        borderRight: "1px solid #e3e3e3",
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      {dayjs(row.start_date).format("DD/MM/YYYY")}
                    </StyledTableCell>
                    <StyledTableCell
                      align='center'
                      style={{
                        borderRight: "1px solid #e3e3e3",
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      {" "}
                      <Button
                        variant='outlined'
                        size='small'
                        disabled={row.members.length ? false : true}
                        onClick={() => {
                          setClients(row.member_details);
                          setGroupName(row.name);
                          setOpen(true);
                        }}
                      >
                        View
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 7, 14, 50]}
          style={{ borderTop: "1px solid #e3e3e3", background: "#fafafa" }}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />{" "}
      </Paper>
      <GroupClients
        groupName={groupName}
        clients={clients}
        open={open}
        setOpen={setOpen}
      />
    </div>
  );
}
