import React from "react";
// material-ui components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
// core components
import Button from "components/CustomButtons/Button.js";
import Checkbox from "@material-ui/core/Checkbox";
import allStyles from "assets/jss/elite-feats-cms/views/extendedTablesStyle.js";

import modalStyles from "assets/jss/elite-feats-cms/modalStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";

import * as Yup from "yup";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { withRouter } from "react-router-dom";
import { Divider, Typography } from "@material-ui/core";
import SearchList from "../SearchList/SearchList";
import CreateClient from "../SearchList/CreateClient";

import { useDebouncedCallback } from "use-debounce";
import LoadingModal from "./GroupLoading";
import alertStyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";
import { ContactSupportOutlined } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const styles = { ...modalStyles, ...alertStyles, ...allStyles };

const useStyles = makeStyles(styles);

const TField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#2b8cc6",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#3c9778",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#2b8cc6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#2b8cc6",
      },
    },
  },
})(TextField);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Modal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [alert, setAlert] = React.useState(null);
  const [initialValues, setInitial] = React.useState({
    name: "",
    description: "",
  });
  const [openLoading, setOpenLoading] = React.useState(false);
  const [clients, setClients] = React.useState(props.clients);
  const [checked, setChecked] = React.useState({});
  const [openCreateClient, setOpenCreateClient] = React.useState(false);
  const [clientsChanged, setClientsChanged] = React.useState(0);
  const firstRender = React.useRef(true);
  const handleToggle = (event) => {
    setChecked({ ...checked, [event.target.name]: event.target.checked });
  };

  const deselectAll = () => {
    for (let email in checked) {
      checked[email] = false;
    }
    setChecked(checked);
  };
  React.useEffect(() => {
    if (props.open) {
      axios
        .get("/api/client/get-clients", {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        })
        .then((res) => {
          let newChecked = {};
          res.data.forEach((client) => {
            newChecked[`${client.email}`] = false;
          });
          setChecked(newChecked);
          setClients(res.data.reverse());
        });
    }
  }, [props.open]);

  React.useEffect(() => {
    let selected = false;
    for (let email in checked) {
      if (checked[email] === true) {
        selected = true;
      }
    }
    if (selected) {
      props.setMembersAdded(true);
    }
  }, [checked]);
  React.useEffect(() => {
    axios
      .get("/api/client/get-clients", {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        let newChecked = checked;
        res.data.forEach((client) => {
          if (typeof newChecked[`${client.email}`] === "undefined")
            newChecked[`${client.email}`] = true;
        });
        setChecked(newChecked);
        setClients(res.data.reverse());
      });
  }, [clientsChanged]);

  const hideAlert = (loc) => {
    if (loc === "success") {
      setAlert(null);
      props.setGroupsAdded(Math.random());
      /* setInitial({ name: "", description: "" }); */
    }
    setAlert(null);
  };
  const dangerAlert = () => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("groups:groupNotAdded")}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.danger}
      >
        {t("groups:groupExistsError")}
      </SweetAlert>
    );
  };

  const NameAlert = () => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("groups:groupNotAdded")}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.danger}
      >
        {t("groups:enterGroupNameError")}
      </SweetAlert>
    );
  };

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("groups:groupAdded")}
        onConfirm={() => hideAlert("success")}
        onCancel={() => hideAlert("success")}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        {t("groups:groupListUpdated")}
      </SweetAlert>
    );
  };

  const handleCreateGroup = useDebouncedCallback((values) => {
    if (values.name.length) {
      props.setOpen(false);
      setOpenLoading(true);
      let clients = [];
      for (let email in checked) {
        if (checked[email] === true) {
          clients.push(email);
        }
      }
      values["clients"] = clients;
      axios
        .post("/api/client/create-group", values, {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        })
        .then((res) => {
          setOpenLoading(false);

          if (res.data === "group exists") {
            if (props.tourRun) {
              props.setTriggerStep(-2);
            }
            dangerAlert();
          } else if (res.data === "added") {
            if (props.tourRun) {
              props.setGroupsAdded(Math.random());
              setTimeout(() => {
                props.setTriggerStep(15);
              }, 800);
            } else {
              successAlert();
            }
          }
        });

      let newChecked = checked;
      for (let email in newChecked) {
        newChecked[email] = false;
      }
      setChecked(newChecked);
      props.setGroupDetails({
        name: "",
        description: "",
      });
    } else {
      NameAlert();
    }
  }, 500);

  return (
    <div>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={props.open}
        transition={Transition}
        keepMounted
        fullWidth
        maxWidth="sm"
        disableBackdropClick
        onClose={() => props.setOpen(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
          style={{
            paddingTop: 20,
            borderBottom: "1px solid #dddddd",
            paddingBottom: 20,
          }}
        >
          <Typography style={{ fontWeight: 900, fontSize: 22 }}>
            {t("groups:addMembersToNewGroup")}
          </Typography>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
          style={{ paddingBottom: 20 }}
        >
          <Button
            className="groups-create-client"
            style={{
              marginLeft: 10,
              marginRight: 10,
              marginTop: 10,
            }}
            color="info"
            onClick={() => {
              setOpenCreateClient(true);
              if (props.tourRun) {
                props.setTriggerStep(-2);
                setTimeout(() => {
                  props.setTriggerStep(8);
                }, 500);
              }
            }}
          >
            {t("groups:createNewClient")}
          </Button>

          <SearchList
            clients={clients}
            handleToggle={handleToggle}
            checked={checked}
          />
        </DialogContent>
        <Divider light style={{ marginBottom: 10, marginTop: 5 }} />
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
          style={{
            display: "block",
            justifyContent: "center",
            paddingBottom: 20,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Button
            style={{ marginRight: 10 }}
            onClick={() => {
              props.setGroupDetails({ name: "", description: "" });
              props.setOpen(false);
              deselectAll();
            }}
          >
            {t("groups:cancel")}
          </Button>
          <Button
            style={{ marginLeft: 10, marginRight: 10 }}
            color="warning"
            onClick={() => {
              props.setOpen(false);
              props.openDetails(true);
            }}
          >
            {t("groups:groupDetails")}
          </Button>

          <Button
            className="create-group"
            color="success"
            style={{ marginRight: 10 }}
            onClick={() => {
              handleCreateGroup(props.groupDetails);
              if (props.tourRun) {
                props.setTriggerStep(-2);
              }
            }}
          >
            {t("groups:createGroup")}
          </Button>
        </DialogActions>
      </Dialog>

      <LoadingModal open={openLoading} setOpen={setOpenLoading} />
      <CreateClient
        openCreateClient={openCreateClient}
        setOpenCreateClient={setOpenCreateClient}
        setClientsChanged={setClientsChanged}
        tourRun={props.tourRun}
        setTriggerStep={props.setTriggerStep}
      />
      {alert}
    </div>
  );
}
