import React from "react";
// material-ui components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";

import Mail from "assets/img/mail.svg";
import modalStyles from "assets/jss/elite-feats-cms/modalStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { css } from "@emotion/react";
import * as Yup from "yup";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { withRouter } from "react-router-dom";
import { Divider, Typography } from "@material-ui/core";
import BarLoader from "react-spinners/BarLoader";
import alertStyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";
import { useTranslation } from "react-i18next";

const styles = { ...modalStyles, ...alertStyles };
const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Modal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <div>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        style={{ marginLeft: -18 }}
        open={props.open}
        transition={Transition}
        keepMounted
        disableBackdropClick
        onClose={() => props.setOpen(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
          style={{ marginBottom: 10 }}
        >
          <img src={Mail} style={{ width: 100, marginBottom: 20 }} />
          <BarLoader
            color={"#398bd6"}
            loading={true}
            width={260}
            height={15}
            css={override}
          />
          <Typography
            style={{
              marginTop: 15,
              fontSize: 18,
              color: "#71c386",
              fontWeight: 900,
            }}
          >
            {t("common:sendingMail")}
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
}
