import React from "react";
import Select from "react-select";

export default ({ onChange, options, value, className }) => {
  const defaultValue = (options, value) => {
    return options ? options.find((option) => option.value === value) : "";
  };

  return (
    <div className={className}>
      <Select
        placeholder='Select Coach/Handler'
        styles={{
          // Fixes the overlapping problem of the component
          menu: (provided) => ({ ...provided, zIndex: 9999, marginBottom: 20 }),
          control: (base) => ({
            ...base,
            marginTop: 15,
            marginBottom: 10,
            height: 55,
            minHeight: 35,
          }),
        }}
        value={defaultValue(options, value)}
        onChange={(value) => {
          onChange(value);
        }}
        options={options}
      />
    </div>
  );
};
