import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import GroupClients from "../Modals/GroupClients";
import dayjs from "dayjs";
import Purchase from "../Modals/Purchase";
import axios from "axios";
import ClipLoader from "react-spinners/FadeLoader";
import { css } from "@emotion/react";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#bb3d3d",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function CustomizedTables(props) {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [orders, setOrders] = React.useState([]);
  const [order, setOrder] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [groupName, setGroupName] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(7);

  React.useEffect(() => {
    if (props.userId) {
      setLoading(true);
      axios
        .post("/api/user/get-all-orders-from-id", {
          id: props.userId,
        })
        .then((res) => {
          setOrders(res.data);
          setLoading(false);
        });
    }
  }, [props.userId]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return loading ? (
    <React.Fragment>
      <ClipLoader
        color={"#398bd6"}
        loading={loading}
        css={override}
        size={150}
      />{" "}
      <Typography align='center' style={{ marginTop: 10, color: "#398bd6" }}>
        Retrieving purchase data...
      </Typography>
    </React.Fragment>
  ) : (
    <div>
      <Paper className={classes.root} variant='outlined'>
        <TableContainer style={{ maxHeight: 440 }}>
          <Table className={classes.table} aria-label='customized table'>
            <TableHead>
              <TableRow>
                <StyledTableCell align='center' style={{}}>
                  Order ID
                </StyledTableCell>
                <StyledTableCell align='center' style={{}}>
                  Order Date
                </StyledTableCell>
                <StyledTableCell align='center' style={{ maxWidth: 160 }}>
                  Number of Items
                </StyledTableCell>
                <StyledTableCell align='center' style={{}}>
                  Details
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {orders
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell
                      align='center'
                      component='th'
                      scope='row'
                      style={{
                        borderRight: "1px solid #e3e3e3",
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      {row.id}
                    </StyledTableCell>
                    <StyledTableCell
                      align='center'
                      style={{
                        borderRight: "1px solid #e3e3e3",
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      {dayjs(row.date_paid).format("DD/MM/YYYY")}
                    </StyledTableCell>
                    <StyledTableCell
                      align='center'
                      style={{
                        maxWidth: 160,
                        borderRight: "1px solid #e3e3e3",
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      {row.line_items.length}
                    </StyledTableCell>

                    <StyledTableCell
                      align='center'
                      style={{
                        borderRight: "1px solid #e3e3e3",
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      {" "}
                      <Button
                        variant='outlined'
                        size='small'
                        onClick={() => {
                          setOrder(row);

                          setOpen(true);
                        }}
                      >
                        View
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 7, 14, 50]}
          style={{ borderTop: "1px solid #e3e3e3", background: "#fafafa" }}
          component='div'
          count={orders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />{" "}
      </Paper>
      <Purchase order={order} open={open} setOpen={setOpen} />
    </div>
  );
}
