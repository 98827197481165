export default {
  1: "module1",
  2: "module2",
  3: "module3",
  4: "module4",
  5: "module5",
  1.1: "tool1_1",
  1.2: "tool1_2",
  1.3: "tool1_3",
  2.1: "tool2_1",
  2.2: "tool2_2",
  2.3: "tool2_3",
  3.1: "tool3_1",
  3.2: "tool3_2",
  3.3: "tool3_3",
  3.4: "tool3_4",
  4.1: "tool4_1",
  4.2: "tool4_2",
  4.3: "tool4_3",
  4.4: "tool4_4",
  4.5: "tool4_5",
  5.1: "tool5_1",
  5.2: "tool5_2",
  5.3: "tool5_3",
};
