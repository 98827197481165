import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import SvgIcon from "@material-ui/core/SvgIcon";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import assessmentNames from "../Dashboard/assessmentNamesReversed";
import identifiers from "../Dashboard/identifiers";
import AllAssigned from "../Clients/Modals/AllAssigned";
import Tooltip from "@material-ui/core/Tooltip";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import LiveHelpRoundedIcon from "@material-ui/icons/LiveHelpRounded";
import AssignmentRoundedIcon from "@material-ui/icons/AssignmentRounded";
import { ReactComponent as Tool1 } from "assets/icons/tool1.svg";
import { ReactComponent as Tool2 } from "assets/icons/tool2.svg";
import { ReactComponent as Tool3 } from "assets/icons/tool3.svg";
import { ReactComponent as Tool4 } from "assets/icons/tool4.svg";
import { ReactComponent as Tool5 } from "assets/icons/tool5.svg";
import { ReactComponent as Cog } from "assets/icons/cog.svg";
import { ReactComponent as Module } from "assets/icons/modicon.svg";

import SweetAlert from "react-bootstrap-sweetalert";

import styles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";

import AppContext from "../../AppContext";

import Button from "@material-ui/core/Button";
import CustButton from "components/CustomButtons/Button.js";
import { useTranslation } from "react-i18next";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2e3641",
    color: theme.palette.common.white,
    paddingTop: 5,
    paddingBottom: 5,
  },
  body: {
    paddingTop: 1,
    paddingBottom: 1,
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  ...styles,
});

export default function CustomizedTables(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [assignmentDetails, setAssignmentDetails] = React.useState(null);
  const [openAssigned, setOpenAssigned] = React.useState(false);

  props.rows.forEach((client) => {
    let assigned = [];
    let hasGroupAssessments = false;
    let allComplete = true;
    Object.values(identifiers).forEach((assessment) => {
      if (client[assessment].length) {
        client[assessment].forEach((assignment) => {
          if (assignment.group) {
            hasGroupAssessments = true;
          }

          let assigned_object = {
            lms: client.lms_accessed,
            name: assessmentNames[assessment],
            completed: assignment.completed,
            date: assignment.assigned_date,
            completed_date: assignment.completed_date,
            accessor: assignment.accessor,
            email: client.email,
            first_name: client.first_name,
            assessment_id: assessment,
            group: assignment.group,
          };

          assigned.push(assigned_object);
        });
      }
    });
    assigned = assigned.filter((assignment) => assignment.group);
    assigned.forEach((assignment) => {
      if (!assignment.completed) {
        allComplete = false;
      }
    });
    client.allComplete = allComplete;
    client.assigned = assigned;
    client.hasGroupAssessments = hasGroupAssessments;
  });

  return (
    <div>
      <TableContainer component={Paper} variant="outlined">
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ borderRight: "1px solid #fff" }}>
                {t("assignments:latestActivity.memberName")}
              </StyledTableCell>
              <StyledTableCell style={{ borderRight: "1px solid #fff" }}>
                {t("assignments:latestActivity.email")}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ borderRight: "1px solid #fff", width: 40 }}
              >
                {t("assignments:latestActivity.assessmentStatus")}
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props.rows.map((row, key) => (
              <StyledTableRow key={key}>
                <StyledTableCell
                  style={{ borderRight: "1px solid #e2e2e2" }}
                  component="th"
                  scope="row"
                >
                  {row.first_name + " " + row.last_name}
                </StyledTableCell>
                <StyledTableCell
                  style={{ borderRight: "1px solid #e2e2e2" }}
                  component="th"
                  scope="row"
                >
                  {row.email}
                </StyledTableCell>

                <StyledTableCell
                  style={{ borderRight: "1px solid #e2e2e2" }}
                  align="center"
                >
                  <div>
                    {!row.hasGroupAssessments ? (
                      <Tooltip
                        title={
                          <Typography>
                            {t("assignments:latestActivity.noGroupAssessments")}
                          </Typography>
                        }
                        placement="top"
                      >
                        <div className={`tour-assessments-${key}`}>
                          <CustButton
                            justIcon
                            color="darkBlue"
                            round
                            simple
                            className="edit"
                            disabled
                          >
                            <AssignmentRoundedIcon />
                          </CustButton>{" "}
                        </div>
                      </Tooltip>
                    ) : row.hasPending && !row.lms_accessed ? (
                      <Tooltip
                        title={
                          <React.Fragment>
                            <div>
                              <Typography style={{ fontSize: 14 }}>
                                {t(
                                  "assignments:latestActivity.noAssessmentsStarted"
                                )}
                              </Typography>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Typography style={{ fontSize: 14 }}>
                                {t("assignments:latestActivity.clickToView")}
                              </Typography>
                            </div>
                          </React.Fragment>
                        }
                        placement="top"
                      >
                        <div className={`tour-assessments-${key}`}>
                          <CustButton
                            justIcon
                            round
                            onClick={() => {
                              setAssignmentDetails(row);
                              setOpenAssigned(true);
                            }}
                            simple
                            color="danger"
                            className="edit"
                          >
                            <WarningRoundedIcon />
                          </CustButton>{" "}
                        </div>
                      </Tooltip>
                    ) : !row.allComplete && row.lms_accessed ? (
                      <Tooltip
                        title={
                          <React.Fragment>
                            <div className={`tour-assessments-${key}`}>
                              <Typography style={{ fontSize: 14 }}>
                                {t(
                                  "assignments:latestActivity.pendingAssessments"
                                )}
                              </Typography>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Typography style={{ fontSize: 14 }}>
                                {t("assignments:latestActivity.clickToView")}
                              </Typography>
                            </div>
                          </React.Fragment>
                        }
                        placement="top"
                      >
                        <div className={`tour-assessments-${key}`}>
                          <CustButton
                            justIcon
                            round
                            simple
                            color="warning"
                            onClick={() => {
                              setAssignmentDetails(row);
                              setOpenAssigned(true);
                            }}
                            className="edit"
                          >
                            <LiveHelpRoundedIcon />
                          </CustButton>{" "}
                        </div>
                      </Tooltip>
                    ) : !row.hasPending && row.lms_accessed ? (
                      <Tooltip
                        title={
                          <React.Fragment>
                            <div>
                              <Typography style={{ fontSize: 14 }}>
                                {t(
                                  "assignments:latestActivity.allAssessmentsComplete"
                                )}
                              </Typography>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Typography style={{ fontSize: 14 }}>
                                {t("assignments:latestActivity.clickToView")}
                              </Typography>
                            </div>
                          </React.Fragment>
                        }
                        placement="top"
                      >
                        <div className={`tour-assessments-${key}`}>
                          <CustButton
                            justIcon
                            round
                            simple
                            color="success"
                            onClick={() => {
                              setAssignmentDetails(row);
                              setOpenAssigned(true);
                            }}
                            className="edit"
                          >
                            <PlaylistAddCheckIcon />
                          </CustButton>{" "}
                        </div>
                      </Tooltip>
                    ) : null}
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {alert}
      </TableContainer>
      <AllAssigned
        assignmentDetails={assignmentDetails}
        open={openAssigned}
        setOpen={setOpenAssigned}
      />
    </div>
  );
}
