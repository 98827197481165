import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
// material-ui icons
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";

// core components
import AdminNavbarLinks from "./AdminNavbarLinks";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/elite-feats-cms/components/adminNavbarStyle.js";
import { useTranslation } from "react-i18next";
import LanguageSelector from "Language/language-selector";

const useStyles = makeStyles(styles);

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default function AdminNavbar(props) {
  const {t} = useTranslation()
  const classes = useStyles();
  const { color, rtlActive, brandText } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color,
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    " " +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive,
    });
  return (
    <ElevationScroll {...props}>
      <AppBar
        position='fixed'
        className={classes.appBar + appBarClasses}
        style={{
          borderRadius: 0,
          paddingTop: 8,
          paddingBottom: 8,
          position: "fixed",

          opacity: 0.98,
        }}
      >
        <Toolbar className={classes.container}>
          <Hidden smDown implementation='css'>
            <div className={sidebarMinimize}>
              {props.miniActive ? (
                <Button
                  justIcon
                  round
                  color='white'
                  onClick={props.sidebarMinimize}
                >
                  <ViewList className={classes.sidebarMiniIcon} />
                </Button>
              ) : (
                <Button
                  justIcon
                  round
                  color='white'
                  onClick={props.sidebarMinimize}
                >
                  <MoreVert className={classes.sidebarMiniIcon} />
                </Button>
              )}
            </div>
          </Hidden>
          <div className={classes.flex}>
            {/* Here we create navbar brand, based on route name */}
            <Typography
              style={{
                color: "#fff",
                marginLeft: 150,
                fontSize: 20,
                fontWeight: 500,
              }}
              align='center'
            >
              {t(`dashboard:routes.${brandText}`)}
            </Typography>
          </div>
          <LanguageSelector/>
          <Hidden smDown implementation='css'>
            <AdminNavbarLinks rtlActive={rtlActive} />
          </Hidden>
          <Hidden mdUp implementation='css'>
            <Button
              className={classes.appResponsive}
              color='transparent'
              justIcon
              aria-label='open drawer'
              onClick={props.handleDrawerToggle}
            >
              <Menu />
            </Button>
          </Hidden>
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
}

AdminNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func,
};
