import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "components/CustomButtons/Button.js";
import dayjs from "dayjs";
import MUIButton from "@material-ui/core/Button";
import { getName } from "country-list";
import axios from "axios";

const columns = [
  { id: "name", label: "Name", minWidth: 170, maxWidth: 200 },
  { id: "email", label: "Email", minWidth: 100, maxWidth: 250 },
  {
    id: "country",
    label: "Country",
    minWidth: 120,
    maxWidth: 180,
    /* align: "center", */
  },
  {
    id: "start",
    label: "Start Date",
    maxWidth: 170,
    minWidth: 120,
    align: "center",
  },
  {
    id: "details",
    label: "Details",
    maxWidth: 130,
    minWidth: 120,
    align: "center",
  },
  {
    id: "ban",
    label: "Ban",
    maxWidth: 130,
    minWidth: 120,
    align: "center",
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export default function StickyHeadTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { data } = props;
  let rows = [];

  const handleBan = (type, username) => {
    if (type === "ban") {
      axios.post(
        "/api/auth/ban",
        { username },
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );
    } else {
      axios.post(
        "/api/auth/unban",
        { username },
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );
    }
    props.setReload(Math.random());
  };

  data.forEach((user) => {
    rows.push({
      name:
        user.first_name || user.last_name
          ? user.first_name + " " + user.last_name
          : "Not Specified",
      email: user.username,
      country: user.country.length ? getName(user.country) : "Not Specified",
      start: dayjs(user.start_date).format("DD/MM/YYYY"),
      details: (
        <MUIButton
          variant='outlined'
          size='small'
          onClick={() => {
            props.setUser(user);
          }}
          style={{ border: "1px solid #7fa6b1", color: "#589aac" }}
        >
          View
        </MUIButton>
      ),
      ban: user.banned ? (
        <MUIButton
          variant='outlined'
          size='small'
          disabled={props.loading}
          onClick={() => {
            handleBan("unban", user.username);
          }}
          style={{ border: "1px solid #7fa6b1", color: "#019435" }}
        >
          Unban
        </MUIButton>
      ) : (
        <MUIButton
          variant='outlined'
          size='small'
          disabled={props.loading}
          onClick={() => {
            handleBan("ban", user.username);
          }}
          style={{ border: "1px solid #7fa6b1", color: "#c0004f" }}
        >
          Ban
        </MUIButton>
      ),
    });
  });
  rows = rows.reverse();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root} variant='outlined'>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    background: "#edf4f7",
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                    fontSize: 17,
                    borderRight: "1px solid #e3e3e3",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow role='checkbox' tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            maxWidth: column.maxWidth,
                            borderRight: "1px solid #e3e3e3",
                            paddingTop: 8,
                            paddingBottom: 8,
                          }}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        style={{ borderTop: "1px solid #e3e3e3", background: "#edf4f7" }}
        component='div'
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
