export default {
  module1: "Module 1",
  module2: "Module 2",
  module3: "Module 3",
  module4: "Module 4",
  module5: "Module 5",
  tool1_1: "Tool 1.1",
  tool1_2: "Tool 1.2",
  tool1_3: "Tool 1.3",
  tool2_1: "Tool 2.1",
  tool2_2: "Tool 2.2",
  tool2_3: "Tool 2.3",
  tool3_1: "Tool 3.1",
  tool3_2: "Tool 3.2",
  tool3_3: "Tool 3.3",
  tool3_4: "Tool 3.4",
  tool4_1: "Tool 4.1",
  tool4_2: "Tool 4.2",
  tool4_3: "Tool 4.3",
  tool4_4: "Tool 4.4",
  tool4_5: "Tool 4.5",
  tool5_1: "Tool 5.1",
  tool5_2: "Tool 5.2",
  tool5_3: "Tool 5.3",
};
