import React, { useReducer, useEffect } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

// Define the Tour component
const Tour = (props) => {
  const { t } = useTranslation();
  // Define the steps
  const TOUR_STEPS = [
    {
      target: ".assignment-table",
      placement: "center",
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:assessments.step1Explanation1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:assessments.step1Explanation2")}
            </span>
            {t("tour:assessments.step1Explanation3")}
          </Typography>
          <Typography
            align="justify"
            style={{ marginTop: 10, color: "#bb3737" }}
          >
            {t("tour:assessments.step1ActionRequired")}
          </Typography>
        </React.Fragment>
      ),
      disableOverlayClose: true,
      disableBeacon: true,
    },
    {
      target: ".all-tabs",
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:assessments.step2Navigation1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:assessments.step2Navigation2")}
            </span>
            {t("tour:assessments.step2Navigation3")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:assessments.step2Navigation4")}
            </span>
            .
          </Typography>
        </React.Fragment>
      ),
      disableOverlayClose: true,
      disableBeacon: true,
      placement: "bottom-start",
    },
    {
      target: ".tab-1",
      placement: "right",
      disableOverlayClose: true,
      spotlightClicks: true,
      hideFooter: true,
      content: (
        <React.Fragment>
          <Typography
            align="center"
            style={{ marginTop: 20, color: "#bb3737" }}
          >
            {t("tour:assessments.step3Tab1Explanation1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:assessments.step3Tab1Explanation2")}
            </span>
            {t("tour:assessments.step3Tab1Explanation3")}
          </Typography>
          <Typography align="justify">
            {t("tour:assessments.step3Tab1Details")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".tab-2",
      placement: "right",
      hideFooter: true,
      disableOverlayClose: true,
      spotlightClicks: true,
      content: (
        <React.Fragment>
          <Typography
            align="center"
            style={{ marginTop: 20, color: "#bb3737" }}
          >
            {t("tour:assessments.step4Tab2Explanation1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:assessments.step4Tab2Explanation2")}
            </span>
            {t("tour:assessments.step4Tab2Explanation3")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".tab-3",
      placement: "right",
      hideFooter: true,
      disableOverlayClose: true,
      spotlightClicks: true,
      content: (
        <React.Fragment>
          <Typography
            align="center"
            style={{ marginTop: 20, color: "#bb3737" }}
          >
            {t("tour:assessments.step5Tab3Explanation1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:assessments.step5Tab3Explanation2")}
            </span>
            {t("tour:assessments.step5Tab3Explanation3")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".tab-4",
      placement: "right",
      hideFooter: true,
      disableOverlayClose: true,
      spotlightClicks: true,
      content: (
        <React.Fragment>
          <Typography
            align="center"
            style={{ marginTop: 20, color: "#bb3737" }}
          >
            {t("tour:assessments.step6Tab4Explanation1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:assessments.step6Tab4Explanation2")}
            </span>
            {t("tour:assessments.step6Tab4Explanation3")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".tab-5",
      placement: "right",
      hideFooter: true,
      disableOverlayClose: true,
      spotlightClicks: true,
      content: (
        <React.Fragment>
          <Typography
            align="center"
            style={{ marginTop: 20, color: "#bb3737" }}
          >
            {t("tour:assessments.step7Tab5Explanation1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:assessments.step7Tab5Explanation2")}
            </span>
            {t("tour:assessments.step7Tab5Explanation3")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".tab-0",
      placement: "right",
      hideFooter: true,
      disableOverlayClose: true,
      spotlightClicks: true,
      content: (
        <React.Fragment>
          <Typography
            align="center"
            style={{ marginTop: 20, color: "#bb3737" }}
          >
            {t("tour:assessments.step8ModulesTabExplanation1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:assessments.step8ModulesTabExplanation2")}
            </span>
            {t("tour:assessments.step8ModulesTabExplanation3")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:assessments.step8ModulesTabExplanation4")}
            </span>
            {t("tour:assessments.step8ModulesTabExplanation5")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".tour-assessment-checkbox",
      placement: "bottom",
      hideFooter: true,
      disableOverlayClose: true,
      spotlightClicks: true,
      content: (
        <React.Fragment>
          <Typography
            align="center"
            style={{ marginTop: 20, color: "#bb3737" }}
          >
            {t("tour:assessments.step9CheckboxExplanation1")}
          </Typography>
          <Typography>
            <span style={{ fontWeight: "bold" }}>
              {t("tour:assessments.step9CheckboxTipBold")}
            </span>
            {t("tour:assessments.step9CheckboxTip")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".selected-assessments",
      placement: "left",
      disableOverlayClose: true,
      spotlightClicks: true,
      content: (
        <React.Fragment>
          <Typography align="center" style={{ marginTop: 20 }}>
            {t("tour:assessments.step10SelectedAssessments")}
          </Typography>
        </React.Fragment>
      ),
      styles: {
        spotlight: {
          height: "295px !important",
          marginTop: -10,
          padding: 20,
        },
      },
    },
    {
      target: ".tour-assign-button",
      placement: "top",
      disableOverlayClose: true,
      content: (
        <React.Fragment>
          <Typography align="center" style={{ marginTop: 20 }}>
            {t("tour:assessments.step11AssignButton")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".tour-assign-clear",
      placement: "top",
      disableOverlayClose: true,
      hideFooter: true,
      spotlightClicks: true,
      content: (
        <React.Fragment>
          <Typography
            align="center"
            style={{ marginTop: 20, color: "#bb3737" }}
          >
            {t("tour:assessments.step12ClearSelectedAssessments")}
          </Typography>
        </React.Fragment>
      ),
    },
  ];

  // Define our state
  const INITIAL_STATE = {
    key: new Date(),
    run: false,
    continuous: true,
    loading: false,
    stepIndex: 0,
    steps: TOUR_STEPS,
  };

  // Set up the reducer function
  const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "START":
        return { ...state, run: true };
      case "RESET":
        return { ...state, stepIndex: 0 };
      case "STOP":
        return { ...state, run: false };
      case "PAUSE":
        return { ...state, run: false };
      case "NEXT_OR_PREV":
        return { ...state, ...action.payload };
      case "RESTART":
        return {
          ...state,
          stepIndex: 0,
          run: true,
          loading: false,
          key: new Date(),
        };
      default:
        return state;
    }
  };

  const firstRender = React.useRef(true);
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
  useEffect(() => {
    if (!firstRender.current) {
      if (props.triggerStep === -3) {
        dispatch({ type: "STOP" });
        props.setTourRun(false);
      }
      if (props.triggerStep === 0) {
        dispatch({ type: "RESTART" });
      } else {
        if (props.triggerStep === -2) {
          dispatch({ type: "PAUSE" });
        } else {
          if (props.triggerStep !== -1) {
            if (tourState.run) {
              dispatch({
                type: "NEXT_OR_PREV",
                payload: { stepIndex: props.triggerStep },
              });
            } else {
              dispatch({
                type: "NEXT_OR_PREV",
                payload: { stepIndex: props.triggerStep, run: true },
              });
            }
          }
        }
      }
    } else {
      firstRender.current = false;
    }
  }, [props.triggerStep]);
  const callback = (data) => {
    const { action, index, type, status } = data;
    if (
      action === ACTIONS.CLOSE ||
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      dispatch({ type: "STOP" });
      props.setTourRun(false);
      props.setTriggerStep(-1);
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      let stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: stepIndex },
      });
    }
  };
  const startTour = () => {
    dispatch({ type: "RESTART" });
  };
  return (
    <>
      <JoyRide
        {...tourState}
        callback={callback}
        scrollOffset={120}
        showSkipButton={false}
        styles={{
          options: {
            zIndex: 2000,
            primaryColor: "#414141",
          },
          tooltipContainer: {
            textAlign: "left",
          },

          buttonBack: {
            marginRight: 10,
          },
        }}
        locale={{
          next: t("common:next"),
          back: t("common:back"),
          skip: t("common:skip"),
          last: t("common:endTour"),
        }}
      />
    </>
  );
};
export default Tour;
