/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import styles from "assets/jss/elite-feats-cms/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { fluid, white, rtlActive } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white,
  });
  var anchor =
    classes.a +
    cx({
      [" " + classes.whiteColor]: white,
    });
  var block = cx({
    [classes.block]: true,
    [classes.whiteColor]: white,
  });
  return (
    <footer className={classes.footer}>
      <div className={container}>
        <p style={{ display: "flex", justifyContent: "center" }}>
          <a
            href='https://elitefeats.com.au/'
            className={anchor}
            style={{ marginRight: 5 }}
            target='_blank'
          >
            {rtlActive ? "توقيت الإبداعية" : " Elite Feats"} &copy;{" "}
          </a>{" "}
          {1900 + new Date().getYear()}{" "}
          {rtlActive ? ", مصنوعة مع الحب لشبكة الإنترنت أفضل" : ""}
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool,
};
