import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/elite-feats-cms/components/adminNavbarLinksStyle.js";
import { withRouter } from "react-router";
import AppContext from "../../AppContext";

import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Cart from "../../views/Shop/Cart";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

function HeaderLinks(props) {
  const {t} = useTranslation()
  const [openNotification, setOpenNotification] = React.useState(null);
  const tourContext = React.useContext(AppContext);
  const cartContext = React.useContext(AppContext);
  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = (props) => {
    setOpenProfile(null);
  };
  const classes = useStyles();
  const { rtlActive } = props;
  const searchButton =
    classes.top +
    " " +
    classes.searchButton +
    " " +
    classNames({
      [classes.searchRTL]: rtlActive,
    });
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive,
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive,
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });
  const [cartCount, setCartCount] = React.useState(0);
  const [showCart, setShowCart] = React.useState(false);
  const [openCart, setOpenCart] = React.useState(false);
  React.useEffect(() => {
    let cart = cartContext.cart;
    let itemCount = 0;
    for (let item in cart) {
      itemCount += cart[item];
    }
    setCartCount(itemCount);
  }, [cartContext.cart]);

  React.useEffect(() => {
    if (cartCount) {
      setShowCart(true);
    }
  }, [cartCount]);
  return (
    <div className={wrapper}>
    <div className={managerClasses}>
      <Grow
        in={showCart}
        style={{ transformOrigin: "0 0 0" }}
        {...(showCart ? { timeout: 100 } : {})}
      >
        <Button
          color='transparent'
          aria-label={t('common:cartButtonLabel')}
          justIcon
          onClick={() => {
            setOpenCart(true);
          }}
          className={
            rtlActive
              ? classes.buttonLinkRTL
              : classes.buttonLink + " " + "logout"
          }
          muiClasses={{
            label: rtlActive ? classes.labelRTL : "",
          }}
        >
          <Badge badgeContent={cartCount} color='primary' max={300}>
            <ShoppingCartIcon
              className={
                classes.headerLinksSvg +
                " " +
                (rtlActive
                  ? classes.links + " " + classes.linksRTL
                  : classes.links)
              }
              style={{ zIndex: 0, marginRight: 5 }}
            />
          </Badge>
          <Hidden mdUp implementation='css'>
            <span className={classes.linkText}>
              {t('common:cart')}
            </span>
          </Hidden>
        </Button>
      </Grow>
  
      <Button
        color='transparent'
        aria-label={t('common:profileButtonLabel')}
        justIcon
        aria-owns={openProfile ? "profile-menu-list" : null}
        aria-haspopup='true'
        onClick={handleClickProfile}
        className={
          rtlActive
            ? classes.buttonLinkRTL
            : classes.buttonLink + " " + "logout"
        }
        muiClasses={{
          label: rtlActive ? classes.labelRTL : "",
        }}
      >
        <Person
          className={
            classes.headerLinksSvg +
            " " +
            (rtlActive
              ? classes.links + " " + classes.linksRTL
              : classes.links)
          }
        />
        <Hidden mdUp implementation='css'>
          <span onClick={handleClickProfile} className={classes.linkText}>
            {t('common:profile')}
          </span>
        </Hidden>
      </Button>
      <Popper
        open={Boolean(openProfile)}
        anchorEl={openProfile}
        transition
        disablePortal
        placement='bottom'
        className={classNames({
          [classes.popperClose]: !openProfile,
          [classes.popperResponsive]: true,
          [classes.popperNav]: true,
        })}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            id='profile-menu-list'
            style={{ transformOrigin: "0 0 0" }}
          >
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={handleCloseProfile}>
                <MenuList role='menu'>
                  {window.location.pathname === "/admin/dashboard" ? (
                    <React.Fragment>
                      <MenuItem
                        onClick={() => {
                          handleCloseProfile();
                          tourContext.setStartTour(Math.random());
                        }}
                        className={dropdownItem}
                      >
                        {t('common:retakeIntroTour')}
                      </MenuItem>
                      <Divider />
                    </React.Fragment>
                  ) : null}
  
                  <MenuItem
                    onClick={() => {
                      props.history.push("/auth/login");
                    }}
                    className={dropdownItem}
                  >
                    {t('common:logout')}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
    <Cart open={openCart} setOpen={setOpenCart} />
  </div>
  
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
};

export default withRouter(HeaderLinks);
