import React from "react";
// material-ui components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";

import modalStyles from "assets/jss/elite-feats-cms/modalStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";

import * as Yup from "yup";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { withRouter } from "react-router-dom";
import { Divider, Typography } from "@material-ui/core";

import alertStyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";
import EditLoading from "./EditLoading";
import { useTranslation } from "react-i18next";
const styles = { ...modalStyles, ...alertStyles };
const useStyles = makeStyles(styles);

const TField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#63ac1d",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#3c9778",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#63ac1d",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#63ac1d",
      },
    },
  },
})(TextField);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Modal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [alert, setAlert] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [initialValues, setInitial] = React.useState({
    first: "",
    last: "",
    email: "",
  });

  React.useEffect(() => {
    setInitial({
      first: props.edit.first_name,
      last: props.edit.last_name,
      email: props.edit.email,
    });
  }, [props.edit]);
  const hideAlert = (loc) => {
    if (loc === "success") {
      setAlert(null);
    }
    setAlert(null);
  };

  const dangerAlert = () => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("clients:edit.clientNotEdited")}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.danger}
      >
        {t("clients:edit.clientEmailExists")}
      </SweetAlert>
    );
  };

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("clients:edit.clientDetailsUpdated")}
        onConfirm={() => hideAlert("success")}
        onCancel={() => hideAlert("success")}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        {t("clients:edit.editComplete")}
      </SweetAlert>
    );
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t("clients:edit.invalidEmailFormat"))
      .required(t("clients:edit.enterValidEmail")),
    first: Yup.string()
      .min(2, t("clients:edit.minTwoChars"))
      .required(t("clients:edit.enterFirstName")),
    last: Yup.string()
      .min(2, t("clients:edit.minTwoChars"))
      .required(t("clients:edit.enterLastName")),
  });

  const handleSubmit = (values) => {
    props.openModal(false);
    setOpen(true);
    values["old_email"] = props.edit.email;
    axios
      .post("/api/client/edit-client", values, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setOpen(false);
        if (res.data === "not edited") {
          dangerAlert();
        } else if (res.data === "edited") {
          props.setReloadTable(Math.random());

          successAlert();
          setInitial({
            first: "",
            last: "",
            email: "",
          });
        }
      });
  };

  return (
    <div>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={props.modal}
        transition={Transition}
        keepMounted
        disableBackdropClick
        onClose={() => props.openModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
          style={{ paddingTop: 30 }}
        >
          <Typography style={{ fontWeight: 900, fontSize: 22 }}>
            {t("clients:edit.editDetails")}
          </Typography>
        </DialogTitle>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form style={{ maxWidth: 350 }}>
              <DialogContent
                id="modal-slide-description"
                className={classes.modalBody}
              >
                <Field
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  margin="normal"
                  required
                  as={TField}
                  id="first"
                  label={t("clients:edit.firstName")}
                  name="first"
                  autoFocus
                  fullWidth
                  helperText={
                    <ErrorMessage
                      component="div"
                      style={{ color: "#c5473f" }}
                      name="first"
                    />
                  }
                />

                <Field
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  margin="normal"
                  required
                  as={TField}
                  fullWidth
                  name="last"
                  label={t("clients:edit.lastName")}
                  id="last"
                  helperText={
                    <ErrorMessage
                      component="div"
                      style={{ color: "#c5473f" }}
                      name="last"
                    />
                  }
                />

                <Field
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  margin="normal"
                  required
                  autoComplete="email"
                  as={TField}
                  id="email"
                  label={t("clients:edit.emailAddress")}
                  name="email"
                  autoFocus
                  fullWidth
                  helperText={
                    <ErrorMessage
                      component="div"
                      style={{ color: "#c5473f" }}
                      name="email"
                    />
                  }
                />

                <Grid
                  style={{
                    marginBottom: "3em",
                    display: "flex",
                    justifyContent: "center",
                  }}
                ></Grid>
              </DialogContent>
              <Divider light style={{ marginBottom: 10 }} />
              <DialogActions
                className={
                  classes.modalFooter + " " + classes.modalFooterCenter
                }
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: 20,
                }}
              >
                <Button
                  round
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    props.openModal(false);
                  }}
                >
                  {t("clients:edit.cancel")}
                </Button>
                <Button
                  round
                  style={{ marginLeft: 10 }}
                  type="submit"
                  color="success"
                >
                  {t("clients:edit.update")}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
      <EditLoading open={open} setOpen={setOpen} />
      {alert}
    </div>
  );
}
