import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import styles from "assets/jss/elite-feats-cms/views/extendedTablesStyle.js";

import product1 from "assets/img/product1.jpg";
import product2 from "assets/img/product2.jpg";
import product3 from "assets/img/product3.jpg";
import AppContext from "../../AppContext";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

export default function ExtendedTables(props) {
  const {t} = useTranslation()
  let assignment = JSON.parse(localStorage.getItem("assignment"));
  let order = JSON.parse(localStorage.getItem("order"));
  const { totalPurchased, totalUsed, completed, pending } = props;
  const cartContext = React.useContext(AppContext);
  let initialChecked = [];
  for (let number in assignment) {
    if (assignment[number] === true) {
      initialChecked.push(number);
    }
  }

  const [checked, setChecked] = React.useState(initialChecked);
  React.useEffect(() => {
    if (!order.length && checked.length) {
      setChecked([]);
    }
  });
  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value.toString());
    const orderIndex = order.indexOf(value.toString());
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value.toString());
      order.push(value.toString());
      assignment[`${value}`] = true;
    } else {
      newChecked.splice(currentIndex, 1);
      order.splice(orderIndex, 1);
      assignment[`${value}`] = false;
    }
    setChecked(newChecked);
    localStorage.setItem("assignment", JSON.stringify(assignment));
    localStorage.setItem("order", JSON.stringify(order));
    props.triggerReload();
  };
  const classes = useStyles();
  const fillButtons = [
    { color: "info", icon: Person },
    { color: "success", icon: Edit },
    { color: "danger", icon: Close },
  ].map((prop, key) => {
    return (
      <Button color={prop.color} className={classes.actionButton} key={key}>
        <prop.icon className={classes.icon} />
      </Button>
    );
  });
  const simpleButtons = [
    { color: "info", icon: Person },
    { color: "success", icon: Edit },
    { color: "danger", icon: Close },
  ].map((prop, key) => {
    return (
      <Button
        color={prop.color}
        simple
        className={classes.actionButton}
        key={key}
      >
        <prop.icon className={classes.icon} />
      </Button>
    );
  });
  const roundButtons = [
    { color: "info", icon: Person },
    { color: "success", icon: Edit },
    { color: "danger", icon: Close },
  ].map((prop, key) => {
    return (
      <Button
        round
        color={prop.color}
        className={classes.actionButton + " " + classes.actionButtonRound}
        key={key}
      >
        <prop.icon className={classes.icon} />
      </Button>
    );
  });
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card plain style={{ marginTop: -10, marginBottom: 0 }}>
          <CardBody>
            <Table
              tableHead={[
                t("assignments:tableHeaders.assessment"),
                t("assignments:tableHeaders.purchased"),
                t("assignments:tableHeaders.used"),
                t("assignments:tableHeaders.available"),
                t("assignments:tableHeaders.pending"),
                t("assignments:tableHeaders.completed"),
                t("assignments:tableHeaders.assign"),
              ]}
              tableData={[
                [
                  t("assignments:assessmentNames.tool3_1"),
                  `${totalPurchased.tool3_1}`,
                  `${totalUsed.tool3_1}`,
                  `${totalPurchased.tool3_1 - totalUsed.tool3_1}`,
                  `${pending.tool3_1}`,
                  `${completed.tool3_1}`,
                  totalPurchased.tool3_1 !== totalUsed.tool3_1 ? (
                    <Checkbox
                      key='key'
                      tabIndex={-1}
                      style={{ padding: 7 }}
                      onClick={() => handleToggle(3.1)}
                      checked={assignment["3.1"]}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checkedBlue,
                        root: classes.checkRoot,
                      }}
                    />
                  ) : (
                    <Button
                      color='info'
                      round
                      size='sm'
                      style={{ padding: 4, paddingLeft: 15, paddingRight: 15 }}
                      onClick={() => {
                        let currentCart = JSON.parse(
                          localStorage.getItem("cart")
                        );
                        currentCart["tool3_1"] += 1;
                        localStorage.setItem(
                          "cart",
                          JSON.stringify(currentCart)
                        );
                        cartContext.setCart(currentCart);
                      }}
                    >
                      {t("assignments:addToCart")}
                    </Button>
                  ),
                ],
                [
                  t("assignments:assessmentNames.tool3_2"),
                  `${totalPurchased.tool3_2}`,
                  `${totalUsed.tool3_2}`,
                  `${totalPurchased.tool3_2 - totalUsed.tool3_2}`,
                  `${pending.tool3_2}`,
                  `${completed.tool3_2}`,
                  totalPurchased.tool3_2 !== totalUsed.tool3_2 ? (
                    <Checkbox
                      key='key'
                      tabIndex={-1}
                      style={{ padding: 7 }}
                      onClick={() => handleToggle(3.2)}
                      checked={assignment["3.2"]}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checkedBlue,
                        root: classes.checkRoot,
                      }}
                    />
                  ) : (
                    <Button
                      color='info'
                      round
                      size='sm'
                      style={{ padding: 4, paddingLeft: 15, paddingRight: 15 }}
                      onClick={() => {
                        let currentCart = JSON.parse(
                          localStorage.getItem("cart")
                        );
                        currentCart["tool3_2"] += 1;
                        localStorage.setItem(
                          "cart",
                          JSON.stringify(currentCart)
                        );
                        cartContext.setCart(currentCart);
                      }}
                    >
                      {t("assignments:addToCart")}
                    </Button>
                  ),
                ],
                [
                  t("assignments:assessmentNames.tool3_3"),
                  `${totalPurchased.tool3_3}`,
                  `${totalUsed.tool3_3}`,
                  `${totalPurchased.tool3_3 - totalUsed.tool3_3}`,
                  `${pending.tool3_3}`,
                  `${completed.tool3_3}`,
                  totalPurchased.tool3_3 !== totalUsed.tool3_3 ? (
                    <Checkbox
                      key='key'
                      tabIndex={-1}
                      style={{ padding: 7 }}
                      onClick={() => handleToggle(3.3)}
                      checked={assignment["3.3"]}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checkedBlue,
                        root: classes.checkRoot,
                      }}
                    />
                  ) : (
                    <Button
                      color='info'
                      round
                      size='sm'
                      style={{ padding: 4, paddingLeft: 15, paddingRight: 15 }}
                      onClick={() => {
                        let currentCart = JSON.parse(
                          localStorage.getItem("cart")
                        );
                        currentCart["tool3_3"] += 1;
                        localStorage.setItem(
                          "cart",
                          JSON.stringify(currentCart)
                        );
                        cartContext.setCart(currentCart);
                      }}
                    >
                      {t("assignments:addToCart")}
                    </Button>
                  ),
                ],
                [
                  t("assignments:assessmentNames.tool3_4"),
                  `${totalPurchased.tool3_4}`,
                  `${totalUsed.tool3_4}`,
                  `${totalPurchased.tool3_4 - totalUsed.tool3_4}`,
                  `${pending.tool3_4}`,
                  `${completed.tool3_4}`,
                  totalPurchased.tool3_4 !== totalUsed.tool3_4 ? (
                    <Checkbox
                      key='key'
                      tabIndex={-1}
                      style={{ padding: 7 }}
                      onClick={() => handleToggle(3.4)}
                      checked={assignment["3.4"]}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checkedBlue,
                        root: classes.checkRoot,
                      }}
                    />
                  ) : (
                    <Button
                      color='info'
                      round
                      size='sm'
                      style={{ padding: 4, paddingLeft: 15, paddingRight: 15 }}
                      onClick={() => {
                        let currentCart = JSON.parse(
                          localStorage.getItem("cart")
                        );
                        currentCart["tool3_4"] += 1;
                        localStorage.setItem(
                          "cart",
                          JSON.stringify(currentCart)
                        );
                        cartContext.setCart(currentCart);
                      }}
                    >
                      {t("assignments:addToCart")}
                    </Button>
                  ),
                ],
              ]}
              customCellClasses={[
                classes.left,
                classes.center,
                classes.center,
                classes.center,
                classes.center,
                classes.center,
                classes.center,
              ]}
              customClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
              customHeadCellClasses={[
                classes.left,
                classes.center,
                classes.center,
                classes.center,
                classes.center,
                classes.center,
                classes.center,
              ]}
              customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
