import React, { useContext } from "react";
import { FilterCtx } from "./SearchList";
import Grid from "@material-ui/core/Grid";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import allStyles from "assets/jss/elite-feats-cms/views/extendedTablesStyle.js";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useTranslation } from "react-i18next";

const styles = {
  ...allStyles,
  table: {
    maxWidth: 600,
    minHeight: 100,
  },
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    maxWidth: 275,
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
  body: {
    fontSize: 14,
    maxWidth: 275,
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles(styles);

function Clients(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [filter, setFilter] = useContext(FilterCtx);

  function gen_list(clients) {
    let filtered = clients.filter(
      (client) =>
        client.first_name.toLowerCase().includes(filter.toLowerCase()) ||
        client.last_name.toLowerCase().includes(filter.toLowerCase()) ||
        client.email.toLowerCase().includes(filter.toLowerCase())
    );

    let front = [];
    let back = [];
    //sorts all clients to bring group members to top of the list
    filtered.forEach((client) => {
      if (props.checked[client.email]) {
        front.push(client);
      } else {
        back.push(client);
      }
    });
    let processed = front.concat(back);

    return processed.map((client, i) => {
      return {
        checkBox: (
          <Checkbox
            key={Math.random()}
            style={{ padding: 5 }}
            name={`${client.email}`}
            onChange={props.handleToggle}
            checked={props.checked[`${client.email}`]}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checkedBlue,
              root: classes.checkBlue,
            }}
          />
        ),
        name: (
          <Typography style={{ fontSize: 14 }}>
            {`${client.first_name} ${client.last_name}`}{" "}
          </Typography>
        ),
        email: (
          <Typography style={{ fontSize: 14 }}>{`${client.email}`} </Typography>
        ),
      };
    });
  }

  return (
    <TableContainer
      className="search-list"
      component={Paper}
      style={{ marginTop: 10 }}
      variant="outlined"
    >
      <Table className={classes.table} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ width: 20 }}></StyledTableCell>
            <StyledTableCell align="center">
              {t("clients:name")}
            </StyledTableCell>
            <StyledTableCell align="center">
              {t("clients:email")}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {gen_list(props.data).map((row, index) => (
            <StyledTableRow
              key={`${Math.random()}`}
              className={`search-row-${index}`}
            >
              <StyledTableCell
                align="center"
                style={{
                  borderRight: "1px solid #dddddd",
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                <div className={`search-checkbox-${index}`}>{row.checkBox}</div>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  borderRight: "1px solid #dddddd",
                }}
              >
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="left">{row.email}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Clients;
