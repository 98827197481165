import React from "react";
// material-ui components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";

import modalStyles from "assets/jss/elite-feats-cms/modalStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";

import * as Yup from "yup";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { withRouter } from "react-router-dom";
import { Divider, Typography } from "@material-ui/core";

import { useDebouncedCallback } from "use-debounce";
import AddMembers from "./AddMembers";
import LoadingModal from "./GroupLoading";
import alertStyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";
import { useTranslation } from "react-i18next";

const styles = { ...modalStyles, ...alertStyles };

const useStyles = makeStyles(styles);

const TField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#2b8cc6",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#3c9778",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#2b8cc6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#2b8cc6",
      },
    },
  },
})(TextField);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Modal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [alert, setAlert] = React.useState(null);
  const [openAddMembers, setOpenAddMembers] = React.useState(false);
  const [members, setMembers] = React.useState([]);
  const [membersAdded, setMembersAdded] = React.useState(false);
  const [groupDetails, setGroupDetails] = React.useState({
    name: "",
    description: "",
  });
  const [open, setOpen] = React.useState(false);
  const hideAlert = (loc) => {
    if (loc === "success") {
      setAlert(null);
      props.setGroupsAdded(Math.random());
      setGroupDetails({ name: "", description: "" });
    }
    setAlert(null);
  };
  const dangerAlert = () => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("groups:groupNotAdded")}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.danger}
      >
        {t("groups:groupExistsError")}
      </SweetAlert>
    );
  };

  const NameAlert = () => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("groups:groupNotAdded")}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.danger}
      >
        {t("groups:enterGroupNameError")}
      </SweetAlert>
    );
  };

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("groups:groupAdded")}
        onConfirm={() => hideAlert("success")}
        onCancel={() => hideAlert("success")}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        {t("groups:groupListUpdated")}
      </SweetAlert>
    );
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(1, t("groups:minOneChar"))
      .required(t("groups:enterGroupName")),
  });

  const handleCreateGroup = useDebouncedCallback((values) => {
    if (values.name.length) {
      values["clients"] = [];
      props.openModal(false);
      setOpen(true);

      axios
        .post("/api/client/create-group", values, {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        })
        .then((res) => {
          setOpen(false);
          if (res.data === "group exists") {
            dangerAlert();
          } else if (res.data === "added") {
            successAlert();
          }
        });

      setGroupDetails({
        name: "",
        description: "",
      });
    } else {
      NameAlert();
    }
  }, 500);

  return (
    <div>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={props.modal}
        transition={Transition}
        keepMounted
        fullWidth
        disableEnforceFocus
        disableBackdropClick
        onClose={() => props.openModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
          style={{
            paddingTop: 20,
            borderBottom: "1px solid #dddddd",
            paddingBottom: 20,
          }}
        >
          <Typography style={{ fontWeight: 900, fontSize: 22 }}>
            {t("groups:createNewGroup")}
          </Typography>
        </DialogTitle>
        <Formik
          initialValues={groupDetails}
          onSubmit={handleCreateGroup}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form>
              <DialogContent
                id="modal-slide-description"
                className={classes.modalBody}
                style={{ paddingBottom: 20 }}
              >
                <Field
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="group-name"
                  variant="outlined"
                  margin="normal"
                  required
                  as={TField}
                  id="name"
                  label={t("groups:groupName")}
                  name="name"
                  autoFocus
                  onChange={(e) => {
                    setGroupDetails({
                      ...groupDetails,
                      [e.target.name]: e.target.value,
                    });
                  }}
                  fullWidth
                  helperText={
                    <ErrorMessage
                      component="div"
                      style={{ color: "#c5473f" }}
                      name="name"
                    />
                  }
                />
                <Field
                  className="group-description"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  margin="normal"
                  as={TField}
                  onChange={(e) => {
                    setGroupDetails({
                      ...groupDetails,
                      [e.target.name]: e.target.value,
                    });
                  }}
                  fullWidth
                  name="description"
                  label={t("groups:description")}
                  id="description"
                  helperText={
                    <ErrorMessage
                      component="div"
                      style={{ color: "#c5473f" }}
                      name="description"
                    />
                  }
                />
              </DialogContent>
              <Divider light style={{ marginBottom: 10, marginTop: 5 }} />
              <DialogActions
                className={
                  classes.modalFooter + " " + classes.modalFooterCenter
                }
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: 20,
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                <Button
                  round
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setGroupDetails({ name: "", description: "" });
                    props.openModal(false);
                  }}
                >
                  {t("groups:cancel")}
                </Button>
                <Button
                  className="create-group-no-members"
                  round
                  style={{ marginLeft: 10, marginRight: 10 }}
                  onClick={() => {
                    handleCreateGroup(groupDetails);
                  }}
                  color="info"
                >
                  {t("groups:createGroup", {
                    context: membersAdded ? "withMembers" : "withoutMembers",
                  })}
                </Button>
                <Button
                  className="add-members"
                  round
                  color="success"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    props.openModal(false);
                    setOpenAddMembers(true);
                    if (props.tourRun) {
                      props.setTriggerStep(-2);
                      setTimeout(() => {
                        props.setTriggerStep(6);
                      }, 500);
                    }
                  }}
                >
                  {t("groups:addMembers")}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
      <AddMembers
        clients={props.clients}
        setMembersAdded={setMembersAdded}
        setGroupsAdded={props.setGroupsAdded}
        groupDetails={groupDetails}
        setGroupDetails={setGroupDetails}
        openDetails={props.openModal}
        open={openAddMembers}
        setOpen={setOpenAddMembers}
        members={members}
        setMembers={setMembers}
        tourRun={props.tourRun}
        setTriggerStep={props.setTriggerStep}
      />
      <LoadingModal open={open} setOpen={setOpen} />
      {alert}
    </div>
  );
}
