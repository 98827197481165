import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Typography, SvgIcon } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import MUICard from "@material-ui/core/Card";
import MUITypography from "@material-ui/core/Typography";
// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import UserDetails from "./User/UserDetails";

import RecentActorsIcon from "@material-ui/icons/RecentActors";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import EmailIcon from "@material-ui/icons/Email";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import GroupRoundedIcon from "@material-ui/icons/GroupRounded";
import { ReactComponent as Cog } from "assets/icons/cog.svg";
import { ReactComponent as Module } from "assets/icons/modicon.svg";
import { FieldProps } from "formik";
import Select from "react-select";
import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "variables/charts";

import dbstyles from "assets/jss/elite-feats-cms/views/dashboardStyle.js";
import UserTable from "./UserTable";
import ClientsTable from "./ClientsTable";
import GroupsTable from "./GroupsTable";
import axios from "axios";
import PersonIcon from "@material-ui/icons/Person";
import StarsIcon from "@material-ui/icons/Stars";
import TextField from "@material-ui/core/TextField";
import * as Yup from "yup";
import { object, string } from "yup";
import SweetAlert from "react-bootstrap-sweetalert";
import alertStyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";
import SearchableSelect from "@dccs/react-searchable-select-mui";
/* import priceImage1 from "assets/img/card-2.jpeg";
import priceImage2 from "assets/img/card-3.jpeg";
import priceImage3 from "assets/img/card-1.jpeg"; */
import { useDebouncedCallback } from "use-debounce";

import CreateUserLoad from "./Loading/CreaterUserLoad.js";
import CustomSelect from "./CustomSelect";
const us_flag = require("assets/img/flags/US.png").default;
const de_flag = require("assets/img/flags/DE.png").default;
const au_flag = require("assets/img/flags/AU.png").default;
const gb_flag = require("assets/img/flags/GB.png").default;
const ro_flag = require("assets/img/flags/RO.png").default;
const br_flag = require("assets/img/flags/BR.png").default;

var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920,
};

const styles = {
  ...dbstyles,
  ...alertStyles,
};
const useStyles = makeStyles(styles);

export default function Dashboard() {
  const [users, setUsers] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  const [groups, setGroups] = React.useState([]);
  const [openCreateUserLoad, setOpenCreateUserLoad] = React.useState(false);
  const [openUser, setOpenUser] = React.useState(false);
  const [alert, setAlert] = React.useState(null);
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    axios
      .get("/api/user/all-users", {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        let coaches = res.data.map((coach) => {
          return {
            value: {
              username: coach.username,
              name:
                coach.first_name || coach.last_name
                  ? `${coach.first_name} ${coach.last_name}`
                  : coach.username,
            },
            label:
              coach.first_name || coach.last_name
                ? `${coach.first_name} ${coach.last_name}`
                : coach.username,
          };
        });
        setOptions(coaches);
      });
  }, []);
  const [newUserInit, setNewUserInit] = React.useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
  });
  const [newClientInit, setNewClientInit] = React.useState({
    email: "",
    firstName: "",
    lastName: "",
    coach: "",
  });
  const CreateUserSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Enter atleast 2 characters")
      .max(50, "Character limit exceeded")
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .required("Required"),
    lastName: Yup.string()
      .min(2, "Enter atleast 2 characters")
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .max(50, "Character limit exceeded")
      .required("Required"),

    email: Yup.string()
      .email("Invalid email format")
      .required("Please enter a valid email"),
    password: Yup.string()
      .required("Please enter a password")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/,
        "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and no special characters"
      ),
  });
  const CreateClientSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Enter atleast 2 characters")
      .max(50, "Character limit exceeded")
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .required("Required"),
    lastName: Yup.string()
      .min(2, "Enter atleast 2 characters")
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .max(50, "Character limit exceeded")
      .required("Required"),

    email: Yup.string()
      .email("Invalid email format")
      .required("Please enter a valid email"),
  });
  const handleSubmitNewUser = (values) => {
    confirmationAlert(values);
  };

  const handleSubmitNewClient = (values) => {
    confirmationAlert2(values);
  };
  const createUser = useDebouncedCallback((values) => {
    setOpenCreateUserLoad(true);
    axios
      .post("/api/user/create-new-user-adm", values, {
        headers: { "auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        setOpenCreateUserLoad(false);

        if (res.data === "successful") {
          successAlert();
        } else {
          errorAlert(res.data.message);
        }
      });
  }, 1000);
  const createClient = useDebouncedCallback((values) => {
    setOpenCreateUserLoad(true);
    axios
      .post("/api/user/create-new-client", values, {
        headers: { "auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        setOpenCreateUserLoad(false);
        if (res.data === "added") {
          successAlert2();
        } else {
          errorAlert2();
        }
      });
  }, 1000);
  const classes = useStyles();

  const confirmationAlert = (values) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title='Confirm User Details'
        onConfirm={() => {
          hideAlert();
          createUser(values);
          setNewUserInit({
            email: "",
            firstName: "",
            lastName: "",
            password: "",
          });
        }}
        onCancel={() => hideAlert()}
        showCancel
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
      >
        <MUICard variant='outlined' style={{ padding: 15 }}>
          <MUITypography align='left' style={{ fontWeight: "bold" }}>
            First Name:{" "}
            <span style={{ marginLeft: 10, fontWeight: 400 }}>
              {" "}
              {newUserInit.firstName}{" "}
            </span>
          </MUITypography>
          <MUITypography
            align='left'
            style={{ fontWeight: "bold", marginTop: 10 }}
          >
            Last Name:{" "}
            <span style={{ marginLeft: 10, fontWeight: 400 }}>
              {" "}
              {newUserInit.lastName}{" "}
            </span>
          </MUITypography>
          <MUITypography
            align='left'
            style={{ fontWeight: "bold", marginTop: 10 }}
          >
            {" "}
            Email:{" "}
            <span style={{ marginLeft: 10, fontWeight: 400 }}>
              {" "}
              {newUserInit.email}{" "}
            </span>
          </MUITypography>
          <MUITypography
            align='left'
            style={{ fontWeight: "bold", marginTop: 10 }}
          >
            Password:
            <span style={{ marginLeft: 10, fontWeight: 400 }}>
              {" "}
              {newUserInit.password}{" "}
            </span>
          </MUITypography>
        </MUICard>
      </SweetAlert>
    );
  };
  const confirmationAlert2 = (values) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title='Confirm User Details'
        onConfirm={() => {
          hideAlert();
          createClient(values);
          setNewClientInit({
            ...newClientInit,
            email: "",
            firstName: "",
            lastName: "",
          });
        }}
        onCancel={() => hideAlert()}
        showCancel
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
      >
        <MUICard variant='outlined' style={{ padding: 15 }}>
          <MUITypography align='left' style={{ fontWeight: "bold" }}>
            First Name:{" "}
            <span style={{ marginLeft: 10, fontWeight: 400 }}>
              {" "}
              {newClientInit.firstName}{" "}
            </span>
          </MUITypography>
          <MUITypography
            align='left'
            style={{ fontWeight: "bold", marginTop: 10 }}
          >
            Last Name:{" "}
            <span style={{ marginLeft: 10, fontWeight: 400 }}>
              {" "}
              {newClientInit.lastName}{" "}
            </span>
          </MUITypography>
          <MUITypography
            align='left'
            style={{ fontWeight: "bold", marginTop: 10 }}
          >
            {" "}
            Email:{" "}
            <span style={{ marginLeft: 10, fontWeight: 400 }}>
              {" "}
              {newClientInit.email}{" "}
            </span>
          </MUITypography>
          <MUITypography
            align='left'
            style={{ fontWeight: "bold", marginTop: 10 }}
          >
            Coach/handler:
            <span style={{ marginLeft: 10, fontWeight: 400 }}>
              {" "}
              {newClientInit.coach.name}
            </span>
          </MUITypography>
        </MUICard>
      </SweetAlert>
    );
  };
  const successAlert = (data) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title='Operation Completed Successfully'
        onConfirm={() => hideAlert("success")}
        onCancel={() => hideAlert("success")}
        confirmBtnCssClass={classes.button + " " + classes.success}
      ></SweetAlert>
    );
  };
  const successAlert2 = (data) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title='Operation Completed Successfully'
        onConfirm={() => hideAlert("success")}
        onCancel={() => hideAlert("success")}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        Client successfully added!
      </SweetAlert>
    );
  };
  const errorAlert = (message) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title='Operation Failed'
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.warning}
      >
        {message}
      </SweetAlert>
    );
  };
  const errorAlert2 = (message) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title='Operation Failed'
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.warning}
      >
        Client Already Exists
      </SweetAlert>
    );
  };

  const hideAlert = (loc) => {
    setAlert(null);
  };

  const ModuleIcon = () => (
    <SvgIcon style={{ fontSize: 36 }} component={Module} />
  );
  const ToolIcon = () => <SvgIcon style={{ fontSize: 36 }} component={Cog} />;

  return (
    <div>
      <GridContainer style={{ justifyContent: "center" }}>
        <GridItem xs={12} style={{ display: "flex", justifyContent: "center" }}>
          <Card style={{ maxWidth: 950 }}>
            <CardHeader color='info' style={{ padding: 0 }}>
              {" "}
              <h4
                style={{
                  color: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: 350,
                  fontSize: 21,
                }}
              >
                <PersonIcon style={{ marginRight: 10 }} />
                Create A New User
              </h4>
            </CardHeader>
            <CardBody style={{ display: "flex", justifyContent: "center" }}>
              <Card plain style={{ maxWidth: 850 }}>
                <Formik
                  initialValues={newUserInit}
                  enableReinitialize
                  onSubmit={handleSubmitNewUser}
                  validationSchema={CreateUserSchema}
                >
                  {({ isSubmitting, handleBlur, values, setFieldValue }) => (
                    <Form className={classes.form}>
                      <GridContainer>
                        <GridItem xs={12}>
                          <Field
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant='outlined'
                            type='text'
                            onChange={(e) => {
                              setNewUserInit({
                                ...newUserInit,
                                [e.target.name]: e.target.value,
                              });
                            }}
                            margin='normal'
                            autoComplete='firstName'
                            as={TextField}
                            id='firstName'
                            label='First Name'
                            name='firstName'
                            autoFocus
                            fullWidth
                            helperText={
                              <ErrorMessage
                                component='div'
                                style={{ color: "#c5473f" }}
                                name='firstName'
                              />
                            }
                          />

                          <Field
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant='outlined'
                            type='text'
                            onChange={(e) => {
                              setNewUserInit({
                                ...newUserInit,
                                [e.target.name]: e.target.value,
                              });
                            }}
                            margin='normal'
                            as={TextField}
                            fullWidth
                            name='lastName'
                            label='Last Name'
                            id='lastName'
                            autoComplete='lastName'
                            helperText={
                              <ErrorMessage
                                component='div'
                                style={{ color: "#c5473f" }}
                                name='lastName'
                              />
                            }
                          />

                          <Field
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant='outlined'
                            type='email'
                            onChange={(e) => {
                              setNewUserInit({
                                ...newUserInit,
                                [e.target.name]: e.target.value,
                              });
                            }}
                            margin='normal'
                            autoComplete='email'
                            as={TextField}
                            id='email'
                            label='Email'
                            name='email'
                            autoFocus
                            fullWidth
                            helperText={
                              <ErrorMessage
                                component='div'
                                style={{ color: "#c5473f" }}
                                name='email'
                              />
                            }
                          />

                          <Field
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant='outlined'
                            type='text'
                            onChange={(e) => {
                              setNewUserInit({
                                ...newUserInit,
                                [e.target.name]: e.target.value,
                              });
                            }}
                            margin='normal'
                            as={TextField}
                            fullWidth
                            name='password'
                            label='Password'
                            id='password'
                            autoComplete='off'
                            helperText={
                              <ErrorMessage
                                component='div'
                                style={{ color: "#c5473f" }}
                                name='password'
                              />
                            }
                          />
                          <GridItem
                            xs={12}
                            style={{
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Button
                              type='submit'
                              variant='contained'
                              color='info'
                              style={{ marginTop: 20, marginBottom: 10 }}
                            >
                              {" "}
                              Create User
                            </Button>
                          </GridItem>
                        </GridItem>
                      </GridContainer>
                    </Form>
                  )}
                </Formik>
              </Card>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} style={{ display: "flex", justifyContent: "center" }}>
          <Card style={{ maxWidth: 950 }}>
            <CardHeader color='success' style={{ padding: 0 }}>
              {" "}
              <h4
                style={{
                  color: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: 350,
                  fontSize: 21,
                }}
              >
                <AccountCircleRoundedIcon style={{ marginRight: 10 }} />
                Create A New Client
              </h4>
            </CardHeader>
            <CardBody style={{ display: "flex", justifyContent: "center" }}>
              <Card plain style={{ maxWidth: 850 }}>
                <Formik
                  initialValues={newClientInit}
                  enableReinitialize
                  onSubmit={handleSubmitNewClient}
                  validationSchema={CreateClientSchema}
                >
                  {({ isSubmitting, handleBlur, values, setFieldValue }) => (
                    <Form className={classes.form}>
                      <GridContainer>
                        <GridItem xs={12}>
                          <Field
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant='outlined'
                            type='text'
                            onChange={(e) => {
                              setNewClientInit({
                                ...newClientInit,
                                [e.target.name]: e.target.value,
                              });
                            }}
                            margin='normal'
                            autoComplete='firstName'
                            as={TextField}
                            id='firstName'
                            label='First Name'
                            name='firstName'
                            autoFocus
                            fullWidth
                            helperText={
                              <ErrorMessage
                                component='div'
                                style={{ color: "#c5473f" }}
                                name='firstName'
                              />
                            }
                          />

                          <Field
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant='outlined'
                            type='text'
                            onChange={(e) => {
                              setNewClientInit({
                                ...newClientInit,
                                [e.target.name]: e.target.value,
                              });
                            }}
                            margin='normal'
                            as={TextField}
                            fullWidth
                            name='lastName'
                            label='Last Name'
                            id='lastName'
                            autoComplete='lastName'
                            helperText={
                              <ErrorMessage
                                component='div'
                                style={{ color: "#c5473f" }}
                                name='lastName'
                              />
                            }
                          />

                          <Field
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant='outlined'
                            type='email'
                            onChange={(e) => {
                              setNewClientInit({
                                ...newClientInit,
                                [e.target.name]: e.target.value,
                              });
                            }}
                            margin='normal'
                            autoComplete='email'
                            as={TextField}
                            id='email'
                            label='Email'
                            name='email'
                            autoFocus
                            fullWidth
                            helperText={
                              <ErrorMessage
                                component='div'
                                style={{ color: "#c5473f" }}
                                name='email'
                              />
                            }
                          />

                          <Field
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant='outlined'
                            type='text'
                            onChange={(value) => {
                              setFieldValue("job", value.value);
                              setNewClientInit({
                                ...newClientInit,
                                coach: value.value,
                              });
                            }}
                            margin='normal'
                            component={CustomSelect}
                            fullWidth
                            options={options}
                            name='coach'
                            label='Coach'
                            id='coach'
                            autoComplete='off'
                            helperText={
                              <ErrorMessage
                                component='div'
                                style={{ color: "#c5473f" }}
                                name='coach'
                              />
                            }
                          />
                          <GridItem
                            xs={12}
                            style={{
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Button
                              type='submit'
                              variant='contained'
                              color='success'
                              style={{ marginTop: 20, marginBottom: 10 }}
                            >
                              {" "}
                              Create Client
                            </Button>
                          </GridItem>
                        </GridItem>
                      </GridContainer>
                    </Form>
                  )}
                </Formik>
              </Card>
            </CardBody>
          </Card>
        </GridItem>
        {/*  <GridItem xs={12} style={{ display: "flex", justifyContent: "center" }}>
          <Card style={{ maxWidth: 950 }}>
            <CardHeader color='warning' style={{ padding: 0 }}>
              {" "}
              <h4
                style={{
                  color: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: 350,
                  fontSize: 21,
                }}
              >
                <StarsIcon style={{ marginRight: 10 }} />
                Administrators
              </h4>
            </CardHeader>
            <CardBody>
              <GroupsTable data={groups} />
            </CardBody>
          </Card>
        </GridItem> */}
      </GridContainer>
      {alert}
      <CreateUserLoad
        open={openCreateUserLoad}
        setOpen={setOpenCreateUserLoad}
      />
    </div>
  );
}
