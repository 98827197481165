import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import SvgIcon from "@material-ui/core/SvgIcon";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import assessmentNames from "../Dashboard/assessmentNamesReversed";
import identifiers from "../Dashboard/identifiers";

import { ReactComponent as Tool1 } from "assets/icons/tool1.svg";
import { ReactComponent as Tool2 } from "assets/icons/tool2.svg";
import { ReactComponent as Tool3 } from "assets/icons/tool3.svg";
import { ReactComponent as Tool4 } from "assets/icons/tool4.svg";
import { ReactComponent as Tool5 } from "assets/icons/tool5.svg";
import { ReactComponent as Cog } from "assets/icons/cog.svg";
import { ReactComponent as Module } from "assets/icons/modicon.svg";

import SweetAlert from "react-bootstrap-sweetalert";

import styles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";

import AppContext from "../../AppContext";

import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#464646",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  ...styles,
});

export default function CustomizedTables(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const cartContext = React.useContext(AppContext);
  const [alert, setAlert] = React.useState(null);

  React.useEffect(() => {
    let cart = JSON.parse(localStorage.getItem("cart"));

    let subtotal = 0;

    for (let item in cart) {
      if (item[0] === "m") {
        let cost = cart[item] * 50;
        subtotal += cost;
      } else if (item[0] === "t") {
        let cost = cart[item] * 20;
        subtotal += cost;
      }
    }

    props.setTotal(subtotal);
  }, [cartContext.cart]);
  const warningWithConfirmMessage = (item) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("shop:cart.removeItemFromCartTitle")}
        onConfirm={() => successDelete(item)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.danger}
        cancelBtnCssClass={classes.button + " "}
        confirmBtnText={t("shop:cart.remove")}
        cancelBtnText={t("shop:cart.cancel")}
        showCancel
      >
        {t("shop:cart.confirmRemoveItemText")}
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const successDelete = (item) => {
    let currentCart = JSON.parse(localStorage.getItem("cart"));
    currentCart[item] = 0;
    localStorage.setItem("cart", JSON.stringify(currentCart));
    cartContext.setCart(currentCart);

    hideAlert();
  };
  return (
    <div>
      <TableContainer component={Paper} variant="outlined">
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ width: 40 }} align="center">
                {" "}
              </StyledTableCell>
              <StyledTableCell style={{ borderRight: "1px solid #fff" }}>
                {t("shop:cart.assessment")}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ borderRight: "1px solid #fff" }}
              >
                {t("shop:cart.pricePerUnit")}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ borderRight: "1px solid #fff" }}
              >
                {t("shop:cart.quantity")}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ borderRight: "1px solid #fff" }}
              >
                {t("shop:cart.subtotal")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {t("shop:cart.remove")}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(cartContext.cart).map((item, index) => {
              let color;

              if (item[0] === "m") {
                if (item[6] === "1") {
                  color = "#bb3737";
                } else if (item[6] === "2") {
                  color = "#ef7e28";
                } else if (item[6] === "3") {
                  color = "#0c8197";
                } else if (item[6] === "4") {
                  color = "#098359";
                } else if (item[6] === "5") {
                  color = "#7f4a7f";
                }
              } else if (item[0] === "t") {
                if (item[4] === "1") {
                  color = "#bb3737";
                } else if (item[4] === "2") {
                  color = "#ef7e28";
                } else if (item[4] === "3") {
                  color = "#0c8197";
                } else if (item[4] === "4") {
                  color = "#098359";
                } else if (item[4] === "5") {
                  color = "#7f4a7f";
                }
              }

              let price;
              let icon;
              if (item[0] === "m") {
                icon = Module;
                price = 50;
              } else if (item[0] === "t") {
                price = 20;
                if (item[4] === "1") {
                  icon = Tool1;
                } else if (item[4] === "2") {
                  icon = Tool2;
                } else if (item[4] === "3") {
                  icon = Tool3;
                } else if (item[4] === "4") {
                  icon = Tool4;
                } else if (item[4] === "5") {
                  icon = Tool5;
                }
              }

              return cartContext.cart[item] ? (
                <StyledTableRow key={index}>
                  <StyledTableCell
                    style={{
                      width: 40,
                      padding: 10,
                      paddingLeft: 15,
                    }}
                    align="center"
                  >
                    <SvgIcon
                      component={icon}
                      style={{
                        fontSize: 28,

                        color: `${color}`,
                      }}
                    />
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row">
                    {t(`assignments:assessmentNames.${item}`)}
                  </StyledTableCell>
                  <StyledTableCell align="center">&#36;{price}</StyledTableCell>
                  <StyledTableCell align="center">
                    <Grid
                      container
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                      }}
                    >
                      <Grid item xs={4}>
                        {cartContext.cart[item] !== 1 ? (
                          <Button
                            onClick={() => {
                              let currentCart = JSON.parse(
                                localStorage.getItem("cart")
                              );
                              currentCart[item] -= 1;
                              localStorage.setItem(
                                "cart",
                                JSON.stringify(currentCart)
                              );
                              cartContext.setCart(currentCart);
                            }}
                            style={{
                              maxWidth: "25px",
                              maxHeight: "25px",
                              minWidth: "25px",
                              minHeight: "25px",
                              color: "#797979",
                            }}
                          >
                            {" "}
                            &#8722;
                          </Button>
                        ) : null}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        style={{
                          marginLeft: 5,
                          marginRight: 5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {cartContext.cart[item]}
                      </Grid>
                      <Grid item xs={4}>
                        {" "}
                        <Button
                          onClick={() => {
                            let currentCart = JSON.parse(
                              localStorage.getItem("cart")
                            );
                            currentCart[item] += 1;
                            localStorage.setItem(
                              "cart",
                              JSON.stringify(currentCart)
                            );
                            cartContext.setCart(currentCart);
                          }}
                          style={{
                            maxWidth: "25px",
                            maxHeight: "25px",
                            minWidth: "25px",
                            minHeight: "25px",
                            color: "#797979",
                          }}
                        >
                          {" "}
                          &#43;{" "}
                        </Button>
                      </Grid>
                    </Grid>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    &#36;{cartContext.cart[item] * price}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Button
                      onClick={() => {
                        warningWithConfirmMessage(item);
                      }}
                      style={{
                        maxWidth: "25px",
                        maxHeight: "25px",
                        minWidth: "25px",
                        minHeight: "25px",
                        color: "#797979",
                        border: "1px solid #797979",
                        borderRadius: "50%",
                      }}
                    >
                      {" "}
                      &#10005;{" "}
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ) : null;
            })}
          </TableBody>
        </Table>
        {alert}
      </TableContainer>
    </div>
  );
}
