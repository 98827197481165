import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function Chart(props) {
  let data = props.data;
  let chartData = [
    {
      name: "Tool 1.1",
      "last 7 days": data?.tool1_1?.last_7,
      "last 30 days": data?.tool1_1?.last_30,
      "last 180 days": data?.tool1_1?.last_180,
      "last 360 days": data?.tool1_1?.last_360,
    },
    {
      name: "Tool 1.2",
      "last 7 days": data?.tool1_2?.last_7,
      "last 30 days": data?.tool1_2?.last_30,
      "last 180 days": data?.tool1_2?.last_180,
      "last 360 days": data?.tool1_2?.last_360,
    },
    {
      name: "Tool 1.3",
      "last 7 days": data?.tool1_3?.last_7,
      "last 30 days": data?.tool1_3?.last_30,
      "last 180 days": data?.tool1_3?.last_180,
      "last 360 days": data?.tool1_3?.last_360,
    },
  ];

  return (
    <Dialog fullWidth maxWidth='lg' open={props.open}>
      <DialogTitle
        style={{
          paddingTop: 20,
          borderBottom: "1px solid #dddddd",
          paddingBottom: 20,
        }}
      >
        <Typography style={{ fontWeight: 600, fontSize: 22 }} align='center'>
          Tool 1 Sales Graphs
        </Typography>
      </DialogTitle>
      <DialogContent style={{ paddingTop: 40 }}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <ResponsiveContainer width={500} height={400}>
              <LineChart
                width={300}
                height={300}
                data={chartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 20,
                }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='name' />
                <YAxis
                  label={{
                    value: "Units Sold",
                    angle: -90,
                    position: "insideLeft",
                  }}
                />
                <Tooltip />
                <Legend />
                <Line
                  type='monotone'
                  dataKey='last 7 days'
                  stroke='#f76d49'
                  activeDot={{ r: 8 }}
                  isAnimationActive={false}
                />
                <Line
                  type='monotone'
                  dataKey='last 30 days'
                  stroke='#02734c'
                  activeDot={{ r: 8 }}
                  isAnimationActive={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <ResponsiveContainer width={500} height={400}>
              <LineChart
                width={300}
                height={300}
                data={chartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 20,
                }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='name' />
                <YAxis
                  label={{
                    value: "Units Sold",
                    angle: -90,
                    position: "insideLeft",
                  }}
                />
                <Tooltip />
                <Legend />

                <Line
                  type='monotone'
                  dataKey='last 180 days'
                  stroke=' #4e79c4'
                  activeDot={{ r: 8 }}
                  isAnimationActive={false}
                />
                <Line
                  type='monotone'
                  dataKey='last 360 days'
                  stroke='#bb3737'
                  activeDot={{ r: 8 }}
                  isAnimationActive={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: 20,
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <Button onClick={() => props.setOpen(false)} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
