import React, { useReducer, useEffect } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { Typography, Grid } from "@material-ui/core";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import LiveHelpRoundedIcon from "@material-ui/icons/LiveHelpRounded";
import AssignmentRoundedIcon from "@material-ui/icons/AssignmentRounded";
import axios from "axios";
import { useTranslation } from "react-i18next";

// Define the Tour component
const Tour = (props) => {
  const { t } = useTranslation();
  // Define the steps
  const TOUR_STEPS = [
    {
      target: ".groups-table",
      placement: "center",
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:groups.step0Explanation1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:groups.step0Explanation2")}
            </span>
            {t("tour:groups.step0Explanation3")}
          </Typography>
          <Typography
            align="justify"
            style={{ marginTop: 10, color: "#bb3737" }}
          >
            {t("tour:groups.step0ActionRequired1")}
          </Typography>
        </React.Fragment>
      ),
      disableOverlayClose: true,
      disableBeacon: true,
    },
    {
      target: ".add-group-button",
      disableOverlayClose: true,
      disableBeacon: true,
      hideCloseButton: false,
      showSkipButton: false,
      hideFooter: true,
      placement: "top",
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:groups.step1Introduction1")}
          </Typography>
          <Typography
            align="center"
            style={{ marginTop: 20, color: "#bb3737" }}
          >
            {t("tour:groups.step1ClickInstruction1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:groups.step1ClickInstruction2")}
            </span>
            {t("tour:groups.step1ClickInstruction3")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".group-name",
      showSkipButton: false,
      disableOverlayClose: true,
      hideBackButton: true,
      placement: "right",
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:groups.step2NameEntry")}
          </Typography>
          <Typography align="center" style={{ marginTop: 20 }}>
            {t("tour:groups.step2NextInstruction1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:groups.step2NextInstruction2")}
            </span>
            {t("tour:groups.step2NextInstruction3")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".group-description",
      showSkipButton: false,
      disableOverlayClose: true,
      placement: "right",
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:groups.step3DescriptionEntry")}
          </Typography>
          <Typography align="center" style={{ marginTop: 20 }}>
            {t("tour:groups.step2NextInstruction1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:groups.step2NextInstruction2")}
            </span>
            {t("tour:groups.step2NextInstruction3")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".create-group-no-members",
      showSkipButton: false,
      disableOverlayClose: true,
      placement: "right",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:groups.step4CreateWithoutMembers")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".add-members",
      showSkipButton: false,
      disableOverlayClose: true,
      hideCloseButton: true,
      placement: "right",
      spotlightClicks: true,
      styles: {
        buttonNext: {
          display: "none",
        },
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography
            align="justify"
            style={{ marginTop: 20, color: "#bb3737" }}
          >
            {t("tour:groups.step5AddMembersWindow1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:groups.step5AddMembersWindow2")}
            </span>
            {t("tour:groups.step5AddMembersWindow3")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".search-list",
      showSkipButton: false,
      disableOverlayClose: true,
      hideBackButton: true,
      placement: "right",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify" style={{ marginTop: 20 }}>
            {t("tour:groups.step6SearchList")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".groups-create-client",
      showSkipButton: false,
      disableOverlayClose: true,
      hideFooter: true,
      spotlightClicks: true,
      placement: "top",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify" style={{ marginTop: 20 }}>
            {t("tour:groups.step7CreateClient1")}
          </Typography>
          <Typography
            align="justify"
            style={{ marginTop: 20, color: "#bb3737" }}
          >
            {t("tour:groups.step7CreateClient2")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".first-name",
      showSkipButton: false,
      disableOverlayClose: true,
      hideBackButton: true,
      placement: "right",
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:groups.step8FirstNameEntry")}
          </Typography>
          <Typography align="center" style={{ marginTop: 20 }}>
            {t("tour:groups.step2NextInstruction1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:groups.step2NextInstruction2")}
            </span>
            {t("tour:groups.step2NextInstruction3")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".last-name",
      showSkipButton: false,
      disableOverlayClose: true,
      placement: "right",
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:groups.step9LastNameEntry")}
          </Typography>
          <Typography align="center" style={{ marginTop: 20 }}>
            {t("tour:groups.step2NextInstruction1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:groups.step2NextInstruction2")}
            </span>
            {t("tour:groups.step2NextInstruction3")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".client-email",
      showSkipButton: false,
      disableOverlayClose: true,
      placement: "right",
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:groups.step10EmailEntry1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:groups.step10EmailEntry2")}
            </span>
            {t("tour:groups.step10EmailEntry3")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".client-create",
      showSkipButton: false,
      disableOverlayClose: true,
      hideCloseButton: true,
      placement: "right",
      spotlightClicks: true,
      styles: {
        buttonNext: {
          display: "none",
        },
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography
            align="justify"
            style={{ marginTop: 20, color: "#bb3737" }}
          >
            {t("tour:groups.step11CreateClient")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".search-row-0",
      showSkipButton: false,
      disableOverlayClose: true,
      hideBackButton: true,
      placement: "center",
      hideFooter: false,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify" style={{ marginTop: 20 }}>
            {t("tour:groups.step12ClientInTable")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".search-checkbox-0",
      showSkipButton: false,
      disableOverlayClose: true,
      hideFooter: false,
      placement: "right",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify" style={{ marginTop: 20 }}>
            {t("tour:groups.step13CheckboxChecked")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".create-group",
      showSkipButton: false,
      disableOverlayClose: true,
      hideFooter: true,
      spotlightClicks: true,
      placement: "right",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify" style={{ marginTop: 20 }}>
            {t("tour:groups.step14CreateGroup1")}
          </Typography>
          <Typography
            align="justify"
            style={{ marginTop: 20, color: "#bb3737" }}
          >
            {t("tour:groups.step14CreateGroup2")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".latest-group",
      showSkipButton: false,
      disableOverlayClose: true,
      hideFooter: false,
      placement: "top",
      hideBackButton: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify" style={{ marginTop: 20 }}>
            {t("tour:groups.step15GroupCreated")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".group-edit-0",
      showSkipButton: false,
      disableOverlayClose: true,
      placement: "top",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify" style={{ marginTop: 20 }}>
            {t("tour:groups.step16EditGroup")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".group-add-0",
      showSkipButton: false,
      disableOverlayClose: true,
      placement: "top",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify" style={{ marginTop: 20 }}>
            {t("tour:groups.step17ManageMembers")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".group-mail-0",
      showSkipButton: false,
      disableOverlayClose: true,
      placement: "top",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify" style={{ marginTop: 20 }}>
            {t("tour:groups.step18SendEmail")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".group-checkbox-0",
      showSkipButton: false,
      disableOverlayClose: true,
      hideFooter: true,
      spotlightClicks: true,
      placement: "top",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography
            align="justify"
            style={{ marginTop: 20, color: "#bb3737" }}
          >
            {t("tour:groups.step19SelectGroup")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".tour-group-actions",
      showSkipButton: false,
      disableOverlayClose: true,
      hideBackButton: true,
      placement: "left-start",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify" style={{ marginTop: 20 }}>
            {t("tour:groups.step20OpenMenu")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".tour-selected-groups",
      showSkipButton: false,
      disableOverlayClose: true,
      placement: "top",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify" style={{ marginTop: 20 }}>
            {t("tour:groups.step21GroupDetails")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".tour-group-clear",
      showSkipButton: false,
      disableOverlayClose: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify" style={{ marginTop: 20 }}>
            {t("tour:groups.step22ClearSelection")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".tour-group-assessments",
      showSkipButton: false,
      disableOverlayClose: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify" style={{ marginTop: 20 }}>
            {t("tour:groups.step23AssignAssessments1")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".tour-group-delete",
      showSkipButton: false,
      disableOverlayClose: true,
      spotlightClicks: true,
      hideFooter: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography
            align="justify"
            style={{ marginTop: 20, color: "#bb3737" }}
          >
            {t("tour:groups.step24DeleteGroup1")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".groups-table",
      showSkipButton: false,
      disableOverlayClose: true,
      hideBackButton: true,
      placement: "center",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify" style={{ marginTop: 20 }}>
            {t("tour:groups.step25GroupDeleted1")}
          </Typography>
        </React.Fragment>
      ),
    },
  ];

  // Define our state
  const INITIAL_STATE = {
    key: new Date(),
    run: false,
    continuous: true,
    loading: false,
    stepIndex: 0,
    steps: TOUR_STEPS,
  };

  // Set up the reducer function
  const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "START":
        return { ...state, run: true };
      case "RESET":
        return { ...state, stepIndex: 0 };
      case "STOP":
        return { ...state, run: false, stepIndex: 0 };
      case "PAUSE":
        return { ...state, run: false };
      case "NEXT_OR_PREV":
        return { ...state, ...action.payload };
      case "RESTART":
        return {
          ...state,
          stepIndex: 0,
          run: true,
          loading: false,
          key: new Date(),
        };
      default:
        return state;
    }
  };
  const firstRender = React.useRef(true);
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [slowLoad, setSlowLoad] = React.useState(false);
  /*   useEffect(() => {
    if (!firstRender.current) {
      dispatch({ type: "START" });
    }
  }, [props.triggerTour]); */
  const handleDelete = () => {
    axios
      .get("/api/client/pop-last-client", {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        props.setReloadTable(Math.random());
        props.setGroupsAdded(Math.random());
      });
  };

  useEffect(() => {
    if (!firstRender.current) {
      if (slowLoad) {
        setTimeout(() => {
          dispatch({ type: "START" });
        }, 400);
        setSlowLoad(false);
      }
    }
  }, [tourState]);

  useEffect(() => {
    if (!firstRender.current) {
      if (props.triggerStep === 0) {
        dispatch({ type: "RESTART" });
      } else {
        if (props.triggerStep === -2) {
          dispatch({ type: "PAUSE" });
        } else {
          if (props.triggerStep !== -1) {
            console.log(props.triggerStep);
            if (tourState.run) {
              dispatch({
                type: "NEXT_OR_PREV",
                payload: { stepIndex: props.triggerStep },
              });
            } else {
              dispatch({
                type: "NEXT_OR_PREV",
                payload: { stepIndex: props.triggerStep, run: true },
              });
            }
          }
        }
      }
    } else {
      firstRender.current = false;
    }
  }, [props.triggerStep]);

  const callback = (data) => {
    const { action, index, type, status } = data;
    /* console.log("current-index = " + index); */
    if (
      action === ACTIONS.CLOSE ||
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      dispatch({ type: "STOP" });
      props.setGroupTour(-10);
      props.setTourRun(false);
      props.setTriggerStep(-1);
      axios.post(
        "/api/user/update-tour-info",
        { completed: "groupsTable" },
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      let run = true;
      if (index === 25) {
        handleDelete();
      }

      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: stepIndex, run: true },
      });
    }
  };
  const startTour = () => {
    dispatch({ type: "RESTART" });
  };
  return (
    <>
      <JoyRide
        {...tourState}
        callback={callback}
        scrollOffset={120}
        showSkipButton={false}
        styles={{
          options: {
            zIndex: 2000,
            primaryColor: "#414141",
          },
          tooltipContainer: {
            textAlign: "left",
          },

          buttonBack: {
            marginRight: 10,
          },
        }}
        locale={{
          last: "End tour",
        }}
      />
    </>
  );
};
export default Tour;
