import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { Grid, Typography } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import dayjs from "dayjs";
import MUIButton from "@material-ui/core/Button";
import { getName } from "country-list";
import ClientDetails from "./Modals/ClientDetails";
import { typography } from "@material-ui/system";

const columns = [
  { id: "name", label: "Name", minWidth: 170, maxWidth: 200 },
  { id: "email", label: "Email", minWidth: 100, maxWidth: 250 },
  {
    id: "group",
    label: "Group(s)",
    minWidth: 130,
    maxWidth: 160,
    align: "center",
  },
  {
    id: "user",
    label: "Coach",
    minWidth: 120,
    maxWidth: 170,
    align: "center",
  },
  {
    id: "start",
    label: "Enrol Date",
    maxWidth: 170,
    minWidth: 120,
    align: "center",
  },
  {
    id: "details",
    label: "Assigned",
    maxWidth: 130,
    minWidth: 120,
    align: "center",
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export default function StickyHeadTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [client, setClient] = React.useState({});
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { data } = props;
  let rows = [];

  data.forEach((client) => {
    rows.push({
      name:
        client.first_name || client.last_name
          ? client.first_name + " " + client.last_name
          : "Not Specified",
      email: client.email,
      group: client.groups.length ? (
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {client.groups.map((group) => {
            return (
              <Grid item style={{ margin: 5 }}>
                <Typography
                  align='center'
                  style={{
                    borderRadius: 10,
                    paddingLeft: 10,
                    paddingRight: 10,
                    background: "#40628d",
                    display: "flex",
                    fontSize: 12,
                    fontWeight: 400,
                    color: "#f7f7f7",
                  }}
                >
                  {group}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        "N/A"
      ),
      user: client.user,
      start: dayjs(client.start_date).format("DD/MM/YYYY"),
      details: client.hasAssessments ? (
        <MUIButton
          variant='outlined'
          size='small'
          onClick={() => {
            setClient(client);
            setOpen(true);
          }}
          style={{ border: "1px solid #abcaad", color: "#6fb472" }}
        >
          View
        </MUIButton>
      ) : (
        <typography
          align='center'
          style={{
            color: "#af4343",

            fontSize: 13,
          }}
        >
          None Assigned
        </typography>
      ),
    });
  });
  rows = rows.reverse();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root} variant='outlined'>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                    fontSize: 17,
                    background: " #edf7f2",
                    borderRight: "1px solid #e3e3e3",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow role='checkbox' tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            maxWidth: column.maxWidth,
                            borderRight: "1px solid #e3e3e3",
                            paddingTop: 8,
                            paddingBottom: 8,
                          }}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <ClientDetails client={client} open={open} setOpen={setOpen} />
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        style={{ borderTop: "1px solid #e3e3e3", background: "#edf7f2" }}
        component='div'
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
