import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import SvgIcon from "@material-ui/core/SvgIcon";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import { ReactComponent as question } from "assets/icons/question.svg";

import AnimateHeight from "react-animate-height";

import styles from "assets/jss/elite-feats-cms/components/customTabsStyle.js";

const useStyles = makeStyles(styles);

export default function CustomTabs(props) {
  const [value, setValue] = React.useState(props.value);

  const [color, setColor] = React.useState("modules");
  const handleChange = (event, value) => {
    if (value === 0) {
      setColor("modules");
    } else if (value === 1) {
      setColor("toolsOne");
    } else if (value === 2) {
      setColor("toolsTwo");
    } else if (value === 3) {
      setColor("toolsThree");
    } else if (value === 4) {
      setColor("toolsFour");
    } else if (value === 5) {
      setColor("toolsFive");
    }

    setValue(value);
    props.triggerReload();
    if (props.tourRun) {
      props.setTriggerStep(-2);
      setTimeout(() => {
        if (value === 0) {
          props.setTriggerStep(8);
        } else {
          props.setTriggerStep(value + 2);
        }
      }, 800);
    }
  };
  const classes = useStyles();
  const { headerColor, plainTabs, tabs, title, rtlActive } = props;
  const cardTitle = classNames({
    [classes.cardTitle]: true,
    [classes.cardTitleRTL]: rtlActive,
  });

  const Question = () => (
    <SvgIcon component={question} style={{ fontSize: 22, padding: 3 }} />
  );
  return (
    <Card plain={plainTabs} style={{ minHeight: 375 }}>
      <CardHeader
        className='all-tabs'
        color={color}
        plain={plainTabs}
        style={{ padding: 8 }}
      >
        {title !== undefined ? <div className={cardTitle}>{title}</div> : null}
        <Grid container>
          <Grid item xs={11}>
            <Tabs
              value={value}
              onChange={props.changeValue ? props.changeValue : handleChange}
              classes={{
                root: classes.tabsRoot,
                indicator: classes.displayNone,
              }}
              variant='scrollable'
              scrollButtons='auto'
            >
              {tabs.map((prop, key) => {
                var icon = {};

                if (prop.tabIcon) {
                  icon = {
                    icon: <prop.tabIcon />,
                  };
                }
                return (
                  <Tab
                    className={`tab-${key}`}
                    classes={{
                      root: classes.tabRootButton,
                      selected: classes.tabSelected,
                      wrapper: classes.tabWrapper,
                    }}
                    key={"" + key}
                    label={prop.tabName}
                    {...icon}
                  />
                );
              })}
            </Tabs>
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <Tooltip title={"Take guided tour"} placement='top'>
              <React.Fragment>
                <IconButton
                  className='assessment-tutorial'
                  style={{ border: "1px solid rgba(0,0,0, 0.5)" }}
                  size='small'
                  onClick={() => {
                    props.clearAll();
                    setTimeout(() => {
                      props.setTriggerStep(0);
                      props.setTourRun(true);
                    }, 600);
                  }}
                >
                  <Question style={{ color: "#0f3058" }} />
                </IconButton>{" "}
              </React.Fragment>
            </Tooltip>
          </Grid>
        </Grid>
      </CardHeader>{" "}
      <CardBody
        style={{
          display: "flex",
          alignItems: "center",
          flexShrink: 0,
          justifyContent: "center",
        }}
      >
        {tabs.map((prop, key) => {
          if (key === value) {
            return (
              <Fade in={true} {...(true ? { timeout: 1000 } : {})}>
                <div
                  className='tab-content-0'
                  style={{ width: "100%" }}
                  key={key}
                >
                  {" "}
                  {prop.tabContent}
                </div>
              </Fade>
            );
          }
          return null;
        })}{" "}
      </CardBody>{" "}
    </Card>
  );
}

CustomTabs.defaultProps = {
  value: 0,
};

CustomTabs.propTypes = {
  // the default opened tab - index starts at 0
  value: PropTypes.number,
  // function for changing the value
  // note, if you pass this function,
  // the default function that changes the tabs will no longer work,
  // so you need to create the changing functionality as well
  changeValue: PropTypes.func,
  headerColor: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose",
    "modules",
    "toolsOne",
    "toolsTwo",
  ]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node.isRequired,
    })
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool,
};
