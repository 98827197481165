import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Divider from "@material-ui/core/Divider";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Box, IconButton } from "@material-ui/core";
import MUICard from "@material-ui/core/Card";
import MUICardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import MUITypography from "@material-ui/core/Typography";
// @material-ui/icons
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import UpdateIcon from "@material-ui/icons/Update";
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import ShoppingBasketRoundedIcon from "@material-ui/icons/ShoppingBasketRounded";
import Typo from "@material-ui/core/Typography";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import dayjs from "dayjs";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import Collapse from "@material-ui/core/Collapse";

import { ReactComponent as Tool1 } from "assets/icons/tool1.svg";
import { ReactComponent as Tool2 } from "assets/icons/tool2.svg";
import { ReactComponent as Tool3 } from "assets/icons/tool3.svg";
import { ReactComponent as Tool4 } from "assets/icons/tool4.svg";
import { ReactComponent as Tool5 } from "assets/icons/tool5.svg";
import { ReactComponent as Cog } from "assets/icons/cog.svg";
import { ReactComponent as Module } from "assets/icons/modicon.svg";

import { ReactComponent as Module1Icon } from "assets/icons/module1icon.svg";
import { ReactComponent as Module2Icon } from "assets/icons/module2icon.svg";
import { ReactComponent as Module3Icon } from "assets/icons/module3icon.svg";
import { ReactComponent as Module4Icon } from "assets/icons/module4icon.svg";
import { ReactComponent as Module5Icon } from "assets/icons/module5icon.svg";

import module1 from "assets/img/module1.png";
import module2 from "assets/img/module2.png";
import module3 from "assets/img/module3.png";
import module4 from "assets/img/module4.png";
import module5 from "assets/img/module5.png";

import allStyles from "assets/jss/elite-feats-cms/views/dashboardStyle.js";

import { withRouter } from "react-router-dom";
import axios from "axios";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/FadeLoader";
import Typography from "components/Typography/Muted.js";
import AnimateHeight from "react-animate-height";

import SweetAlert from "react-bootstrap-sweetalert";
import alertStyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import PriorityHigh from "@material-ui/icons/PriorityHigh";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import AppContext from "../../../AppContext";
import { useDebouncedCallback } from "use-debounce";
import ToolsOne from "./Tools/Tools1";
import ToolsTwo from "./Tools/Tools2";
import ToolsThree from "./Tools/Tools3";
import ToolsFour from "./Tools/Tools4";
import ToolsFive from "./Tools/Tools5";
import Tools1Chart from "./Charts/Tools1Chart";
import Tools2Chart from "./Charts/Tools2Chart";
import Tools3Chart from "./Charts/Tools3Chart";
import Tools4Chart from "./Charts/Tools4Chart";
import Tools5Chart from "./Charts/Tools5Chart";
import TimelineIcon from "@material-ui/icons/Timeline";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },

  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

function ShopModules(props) {
  const classes = useStyles();
  let cartContext = React.useContext(AppContext);

  const [openCharts1, setOpenCharts1] = React.useState(false);
  const [openCharts2, setOpenCharts2] = React.useState(false);
  const [openCharts3, setOpenCharts3] = React.useState(false);
  const [openCharts4, setOpenCharts4] = React.useState(false);
  const [openCharts5, setOpenCharts5] = React.useState(false);

  return (
    <div style={{ marginTop: -10 }}>
      <Typo align='center' style={{ fontSize: 24, fomtWeight: "bold" }}>
        Independent Tools 1 Sales Breakdown
      </Typo>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <Button
          color='tumblr'
          disabled={props.loading}
          className={classes.marginRight}
          onClick={() => {
            setOpenCharts1(true);
          }}
        >
          <TimelineIcon className={classes.icons} /> View Sales Chart
        </Button>
      </div>
      <ToolsOne data={props.data} />{" "}
      <Divider
        variant='middle'
        style={{ marginBottom: 25, marginLeft: 100, marginRight: 100 }}
      />
      <Typo align='center' style={{ fontSize: 24, fomtWeight: "bold" }}>
        Independent Tools 2 Sales Breakdown
      </Typo>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <Button
          color='tumblr'
          disabled={props.loading}
          className={classes.marginRight}
          onClick={() => {
            setOpenCharts2(true);
          }}
        >
          <TimelineIcon className={classes.icons} /> View Sales Chart
        </Button>
      </div>
      <ToolsTwo data={props.data} />
      <Divider
        variant='middle'
        style={{ marginBottom: 25, marginLeft: 100, marginRight: 100 }}
      />
      <Typo align='center' style={{ fontSize: 24, fomtWeight: "bold" }}>
        Independent Tools 3 Sales Breakdown
      </Typo>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <Button
          color='tumblr'
          disabled={props.loading}
          className={classes.marginRight}
          onClick={() => {
            setOpenCharts3(true);
          }}
        >
          <TimelineIcon className={classes.icons} /> View Sales Chart
        </Button>
      </div>
      <ToolsThree data={props.data} />
      <Divider
        variant='middle'
        style={{ marginBottom: 25, marginLeft: 100, marginRight: 100 }}
      />
      <Typo align='center' style={{ fontSize: 24, fomtWeight: "bold" }}>
        Independent Tools 4 Sales Breakdown
      </Typo>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <Button
          color='tumblr'
          disabled={props.loading}
          className={classes.marginRight}
          onClick={() => {
            setOpenCharts4(true);
          }}
        >
          <TimelineIcon className={classes.icons} /> View Sales Chart
        </Button>
      </div>
      <ToolsFour data={props.data} />
      <Divider
        variant='middle'
        style={{ marginBottom: 25, marginLeft: 100, marginRight: 100 }}
      />
      <Typo align='center' style={{ fontSize: 24, fomtWeight: "bold" }}>
        Independent Tools 5 Sales Breakdown
      </Typo>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <Button
          color='tumblr'
          disabled={props.loading}
          className={classes.marginRight}
          onClick={() => {
            setOpenCharts5(true);
          }}
        >
          <TimelineIcon className={classes.icons} /> View Sales Chart
        </Button>
      </div>
      <ToolsFive data={props.data} />
      <Tools1Chart
        data={props.data}
        open={openCharts1}
        setOpen={setOpenCharts1}
      />
      <Tools2Chart
        data={props.data}
        open={openCharts2}
        setOpen={setOpenCharts2}
      />
      <Tools3Chart
        data={props.data}
        open={openCharts3}
        setOpen={setOpenCharts3}
      />
      <Tools4Chart
        data={props.data}
        open={openCharts4}
        setOpen={setOpenCharts4}
      />
      <Tools5Chart
        data={props.data}
        open={openCharts5}
        setOpen={setOpenCharts5}
      />
    </div>
  );
}

export default withRouter(ShopModules);
