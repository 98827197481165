import React, { useReducer, useEffect } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { Typography, Grid } from "@material-ui/core";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import LiveHelpRoundedIcon from "@material-ui/icons/LiveHelpRounded";
import AssignmentRoundedIcon from "@material-ui/icons/AssignmentRounded";
import SvgIcon from "@material-ui/core/SvgIcon";
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as question } from "assets/icons/question.svg";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Question = () => (
  <SvgIcon component={question} style={{ fontSize: 22, padding: 3 }} />
);

// Define the Tour component
const Tour = (props) => {
  const firstRender = React.useRef(true);
  const { t } = useTranslation();
  // Define the steps
  const TOUR_STEPS = [
    {
      target: ".account-activity-0",
      placement: "center",
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:history.step0.part1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:history.step0.bold1")}
            </span>
            {t("tour:history.step0.part2")}
          </Typography>
        </React.Fragment>
      ),
      disableOverlayClose: true,
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: ".full-activity",
      disableOverlayClose: true,
      hideCloseButton: false,
      showSkipButton: false,
      placement: "left-start",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">{t("tour:history.step1")}</Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".tour-box",
      disableOverlayClose: true,
      hideCloseButton: false,
      showSkipButton: false,
      disableBeacon: true,
      hideBackButton: true,
      placement: "right",
      styles: {
        spotlight: {
          zIndex: 100000,
        },
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">{t("tour:history.step2")}</Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".successful-assign",
      disableOverlayClose: true,
      hideCloseButton: false,
      showSkipButton: false,
      disableBeacon: true,
      placement: "right",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">{t("tour:history.step3")}</Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".some-assign",
      disableOverlayClose: true,
      hideCloseButton: false,
      showSkipButton: false,
      disableBeacon: true,
      placement: "right",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">{t("tour:history.step4")}</Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".error-assign",
      disableOverlayClose: true,
      hideCloseButton: false,
      showSkipButton: false,
      disableBeacon: true,
      placement: "right",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">{t("tour:history.step5")}</Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".success-button",
      disableOverlayClose: true,
      hideCloseButton: false,
      showSkipButton: false,
      disableBeacon: true,
      hideFooter: true,
      spotlightClicks: true,
      placement: "right",
      styles: {
        buttonNext: {
          display: "none",
        },
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify" style={{ color: "#bb3737" }}>
            {t("tour:history.step6")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".sweet-alert",
      disableOverlayClose: true,
      hideCloseButton: false,
      showSkipButton: false,
      disableBeacon: true,
      placement: "right",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">{t("tour:history.step7")}</Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".accordion-content-0",
      disableOverlayClose: true,
      hideCloseButton: false,
      showSkipButton: false,
      disableBeacon: true,
      placement: "top",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">{t("tour:history.step8")}</Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".error-button",
      disableOverlayClose: true,
      hideCloseButton: false,
      showSkipButton: false,
      disableBeacon: true,
      placement: "right",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">{t("tour:history.step9")}</Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".account-activity-0",
      disableOverlayClose: true,
      hideCloseButton: false,
      showSkipButton: false,
      placement: "center",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">{t("tour:history.step10")}</Typography>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              className="history-tour-button"
              style={{ border: "1px solid #757575", marginTop: 20 }}
              size="small"
              disabled
            >
              <Question />
            </IconButton>{" "}
          </div>
        </React.Fragment>
      ),
    },
  ];

  // Define our state
  const INITIAL_STATE = {
    key: new Date(),
    run: false,
    continuous: true,
    loading: false,
    stepIndex: 0,
    steps: TOUR_STEPS,
  };

  // Set up the reducer function
  const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "START":
        return { ...state, run: true };
      case "RESET":
        return { ...state, stepIndex: 0 };
      case "STOP":
        return { ...state, run: false, stepIndex: 0 };
      case "PAUSE":
        return { ...state, run: false };
      case "NEXT_OR_PREV":
        return { ...state, ...action.payload };
      case "RESTART":
        return {
          ...state,
          stepIndex: 0,
          run: true,
          loading: false,
          key: new Date(),
        };
      default:
        return state;
    }
  };
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

  /*   useEffect(() => {
    if (!firstRender.current) {
      dispatch({ type: "START" });
    }
  }, [props.triggerTour]); */

  useEffect(() => {
    if (!firstRender.current) {
      if (props.startTour === 2) {
        dispatch({ type: "RESTART" });
      }
      if (props.startTour === 0) {
        dispatch({ type: "STOP" });
      }
    }
  }, [props.startTour]);

  useEffect(() => {
    if (!firstRender.current) {
      if (props.triggerStep === 0) {
        dispatch({ type: "RESTART" });
      } else {
        if (props.triggerStep === -3) {
          dispatch({ type: "STOP" });
        }
        if (props.triggerStep === -2) {
          dispatch({ type: "PAUSE" });
        } else {
          if (props.triggerStep !== -1) {
            if (tourState.run) {
              dispatch({
                type: "NEXT_OR_PREV",
                payload: { stepIndex: props.triggerStep },
              });
            } else {
              dispatch({
                type: "NEXT_OR_PREV",
                payload: { stepIndex: props.triggerStep, run: true },
              });
            }
          }
        }
      }
    } else {
      firstRender.current = false;
    }
  }, [props.triggerStep]);

  const callback = (data) => {
    const { action, index, type, status } = data;

    if (
      action === ACTIONS.CLOSE ||
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      dispatch({ type: "STOP" });
      props.setStartTour(0);
      axios.post(
        "/api/user/update-tour-info",
        { completed: "assignmentHistory" },
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      let run = true;
      if (index === 1 && action === ACTIONS.NEXT) {
        props.setOpen(true);
        setTimeout(() => {
          dispatch({
            type: "NEXT_OR_PREV",
            payload: { stepIndex: stepIndex, run: true },
          });
        }, 900);
      } else {
        if (index === 8 && action === ACTIONS.NEXT) {
          props.setHide(Math.random());
        }
        if (index === 9 && action === ACTIONS.NEXT) {
          props.setHide(-1);
        }
        dispatch({
          type: "NEXT_OR_PREV",
          payload: { stepIndex: stepIndex, run: true },
        });
      }
    }
  };
  const startTour = () => {
    dispatch({ type: "RESTART" });
  };
  return (
    <>
      <JoyRide
        {...tourState}
        callback={callback}
        scrollOffset={120}
        showSkipButton={false}
        styles={{
          options: {
            zIndex: 2000,
            primaryColor: "#414141",
          },
          tooltipContainer: {
            textAlign: "left",
          },

          buttonBack: {
            marginRight: 10,
          },
        }}
        locale={{
          last: "End tour",
        }}
      />
    </>
  );
};
export default Tour;
