import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";

import AccountCircle from "@material-ui/icons/AccountCircle";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
// import LockOutline from "@material-ui/icons/LockOutline";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import LoadingModal from "./Modals/LoadingModal";

import { object, string } from "yup";
import logo from "assets/img/logo-yellow.svg";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";
import { withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";
import CardIcon from "components/Card/CardIcon";
import * as Yup from "yup";

import styles from "assets/jss/elite-feats-cms/views/userProfileStyles.js";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

const digitsOnly = (value) => /^\d+$/.test(value);

const DisplayingErrorMessagesSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  suburb: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),

  country: Yup.string().required("Country is Required"),
  address: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  state: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  contact: Yup.string().test(
    "Digits only",
    "The field should have digits only",
    digitsOnly
  ),

  // .matches(phoneRegExp, "Phone number is not valid")
  // .required("Phone number is not valid"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Please enter a valid email"),
});

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const handleSubmit = (values) => {
  axios.post(
    "/api/user/update-info",

    values,
    {
      headers: { "auth-token": localStorage.getItem("token") },
    }
  );
};

function UserProfile(props) {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    country: "",
    suburb: "",
    address: "",
    state: "",
    contact: "",
    company: "",
    email: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get("/api/user/get-user-info", {
        headers: { "auth-token": localStorage.getItem("token") },
      });

      setInitialValues({
        firstName: result.data.first_name,
        lastName: result.data.last_name,
        country: result.data.country,
        suburb: result.data.suburb,
        address: result.data.address,
        state: result.data.state,
        contact: result.data.phone,
        company: result.data.company,
        email: result.data.email,
      });
    };
    fetchData();
  }, []); //setting new state and triggering hook manually

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader></CardHeader>

            <CardBody>
              <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={handleSubmit}
                validationSchema={DisplayingErrorMessagesSchema}
              >
                {({ isSubmitting, handleBlur, values, setFieldValue }) => (
                  <Form className={classes.form}>
                    <Field
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      type="text"
                      onChange={(e) => {
                        setInitialValues({
                          ...initialValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      margin="normal"
                      autoComplete="firstName"
                      as={TextField}
                      id="firstName"
                      label={t("common:updateProfile.firstName")}
                      name="firstName"
                      autoFocus
                      fullWidth
                      helperText={
                        <ErrorMessage
                          component="div"
                          style={{ color: "#c5473f" }}
                          name="firstName"
                        />
                      }
                    />

                    <Field
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      type="text"
                      onChange={(e) => {
                        setInitialValues({
                          ...initialValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      margin="normal"
                      as={TextField}
                      fullWidth
                      name="lastName"
                      label={t("common:updateProfile.lastName")}
                      id="lastName"
                      autoComplete="lastName"
                      helperText={
                        <ErrorMessage
                          component="div"
                          style={{ color: "#c5473f" }}
                          name="lastName"
                        />
                      }
                    />

                    <Field
                      disabled
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      type="email"
                      onChange={(e) => {
                        setInitialValues({
                          ...initialValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      margin="normal"
                      autoComplete="email"
                      as={TextField}
                      id="email"
                      label={t("common:updateProfile.email")}
                      name="email"
                      autoFocus
                      fullWidth
                      helperText={
                        <ErrorMessage
                          component="div"
                          style={{ color: "#c5473f" }}
                          name="email"
                        />
                      }
                    />

                    <Field
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      type="text"
                      onChange={(e) => {
                        setInitialValues({
                          ...initialValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      margin="normal"
                      as={TextField}
                      fullWidth
                      name="company"
                      label={t("common:updateProfile.company")}
                      id="company"
                      autoComplete="company"
                    />

                    <Autocomplete
                      id="country"
                      fullWidth
                      options={countries}
                      classes={{
                        option: classes.option,
                      }}
                      autoHighlight
                      name="country"
                      onChange={(e, value) => {
                        console.log(values.country);
                        setFieldValue("country", value);
                      }}
                      getOptionLabel={(option) => option.label}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option.label} ({option.code})
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("common:updateProfile.chooseCountry")}
                          name="country"
                          onBlur={(e) => {
                            setInitialValues({
                              ...initialValues,
                              [e.target.name]: e.target.value,
                            });
                          }}
                          margin="normal"
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                          }}
                          helperText={
                            <ErrorMessage
                              component="div"
                              style={{ color: "#c5473f" }}
                              name="country"
                            />
                          }
                        />
                      )}
                    />

                    <Field
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      type="text"
                      onChange={(e) => {
                        setInitialValues({
                          ...initialValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      margin="normal"
                      as={TextField}
                      fullWidth
                      name="suburb"
                      label={t("common:updateProfile.citySuburb")}
                      id="suburb"
                      autoComplete="suburb"
                      helperText={
                        <ErrorMessage
                          component="div"
                          style={{ color: "#c5473f" }}
                          name="suburb"
                        />
                      }
                    />

                    <Field
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      type="text"
                      onChange={(e) => {
                        setInitialValues({
                          ...initialValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      margin="normal"
                      as={TextField}
                      fullWidth
                      name="address"
                      label={t("common:updateProfile.address")}
                      id="address"
                      autoComplete="address"
                      helperText={
                        <ErrorMessage
                          component="div"
                          style={{ color: "#c5473f" }}
                          name="address"
                        />
                      }
                    />

                    <Field
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      type="text"
                      onChange={(e) => {
                        setInitialValues({
                          ...initialValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      margin="normal"
                      as={TextField}
                      fullWidth
                      name="state"
                      label={t("common:updateProfile.state")}
                      id="state"
                      autoComplete="state"
                      helperText={
                        <ErrorMessage
                          component="div"
                          style={{ color: "#c5473f" }}
                          name="state"
                        />
                      }
                    />

                    <Field
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      type="text"
                      onChange={(e) => {
                        setInitialValues({
                          ...initialValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      margin="normal"
                      as={TextField}
                      fullWidth
                      name="contact"
                      label={t("common:updateProfile.contactNumber")}
                      id="contact"
                      autoComplete="contact"
                      helperText={
                        <ErrorMessage
                          component="div"
                          style={{ color: "#c5473f" }}
                          name="contact"
                        />
                      }
                    />

                    <Button
                      type="submit"
                      variant="contained"
                      color="info"
                      style={{ marginTop: 20, marginBottom: 10 }}
                    >
                      {t("common:updateProfile.updateProfile")}
                    </Button>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const countries = [
  { code: "AD", label: "Andorra" },
  { code: "AE", label: "United Arab Emirates" },
  { code: "AF", label: "Afghanistan" },
  { code: "AG", label: "Antigua and Barbuda" },
  { code: "AI", label: "Anguilla" },
  { code: "AL", label: "Albania" },
  { code: "AM", label: "Armenia" },
  { code: "AO", label: "Angola" },
  { code: "AQ", label: "Antarctica" },
  { code: "AR", label: "Argentina" },
  { code: "AS", label: "American Samoa" },
  { code: "AT", label: "Austria" },
  { code: "AU", label: "Australia" },
  { code: "AW", label: "Aruba" },
  { code: "AX", label: "Alland Islands" },
  { code: "AZ", label: "Azerbaijan" },
  { code: "BA", label: "Bosnia and Herzegovina" },
  { code: "BB", label: "Barbados" },
  { code: "BD", label: "Bangladesh" },
  { code: "BE", label: "Belgium" },
  { code: "BF", label: "Burkina Faso" },
  { code: "BG", label: "Bulgaria" },
  { code: "BH", label: "Bahrain" },
  { code: "BI", label: "Burundi" },
  { code: "BJ", label: "Benin" },
  { code: "BL", label: "Saint Barthelemy" },
  { code: "BM", label: "Bermuda" },
  { code: "BN", label: "Brunei Darussalam" },
  { code: "BO", label: "Bolivia" },
  { code: "BR", label: "Brazil" },
  { code: "BS", label: "Bahamas" },
  { code: "BT", label: "Bhutan" },
  { code: "BV", label: "Bouvet Island" },
  { code: "BW", label: "Botswana" },
  { code: "BY", label: "Belarus" },
  { code: "BZ", label: "Belize" },
  { code: "CA", label: "Canada" },
  { code: "CC", label: "Cocos (Keeling) Islands" },
  { code: "CD", label: "Congo, Democratic Republic of the" },
  { code: "CF", label: "Central African Republic" },
  { code: "CG", label: "Congo, Republic of the" },
  { code: "CH", label: "Switzerland" },
  { code: "CI", label: "Cote d'Ivoire" },
  { code: "CK", label: "Cook Islands" },
  { code: "CL", label: "Chile" },
  { code: "CM", label: "Cameroon" },
  { code: "CN", label: "China" },
  { code: "CO", label: "Colombia" },
  { code: "CR", label: "Costa Rica" },
  { code: "CU", label: "Cuba" },
  { code: "CV", label: "Cape Verde" },
  { code: "CW", label: "Curacao" },
  { code: "CX", label: "Christmas Island" },
  { code: "CY", label: "Cyprus" },
  { code: "CZ", label: "Czech Republic" },
  { code: "DE", label: "Germany" },
  { code: "DJ", label: "Djibouti" },
  { code: "DK", label: "Denmark" },
  { code: "DM", label: "Dominica" },
  { code: "DO", label: "Dominican Republic" },
  { code: "DZ", label: "Algeria" },
  { code: "EC", label: "Ecuador" },
  { code: "EE", label: "Estonia" },
  { code: "EG", label: "Egypt" },
  { code: "EH", label: "Western Sahara" },
  { code: "ER", label: "Eritrea" },
  { code: "ES", label: "Spain" },
  { code: "ET", label: "Ethiopia" },
  { code: "FI", label: "Finland" },
  { code: "FJ", label: "Fiji" },
  { code: "FK", label: "Falkland Islands (Malvinas)" },
  { code: "FM", label: "Micronesia, Federated States of" },
  { code: "FO", label: "Faroe Islands" },
  { code: "FR", label: "France" },
  { code: "GA", label: "Gabon" },
  { code: "GB", label: "United Kingdom" },
  { code: "GD", label: "Grenada" },
  { code: "GE", label: "Georgia" },
  { code: "GF", label: "French Guiana" },
  { code: "GG", label: "Guernsey" },
  { code: "GH", label: "Ghana" },
  { code: "GI", label: "Gibraltar" },
  { code: "GL", label: "Greenland" },
  { code: "GM", label: "Gambia" },
  { code: "GN", label: "Guinea" },
  { code: "GP", label: "Guadeloupe" },
  { code: "GQ", label: "Equatorial Guinea" },
  { code: "GR", label: "Greece" },
  { code: "GS", label: "South Georgia and the South Sandwich Islands" },
  { code: "GT", label: "Guatemala" },
  { code: "GU", label: "Guam" },
  { code: "GW", label: "Guinea-Bissau" },
  { code: "GY", label: "Guyana" },
  { code: "HK", label: "Hong Kong" },
  { code: "HM", label: "Heard Island and McDonald Islands" },
  { code: "HN", label: "Honduras" },
  { code: "HR", label: "Croatia" },
  { code: "HT", label: "Haiti" },
  { code: "HU", label: "Hungary" },
  { code: "ID", label: "Indonesia" },
  { code: "IE", label: "Ireland" },
  { code: "IL", label: "Israel" },
  { code: "IM", label: "Isle of Man" },
  { code: "IN", label: "India" },
  { code: "IO", label: "British Indian Ocean Territory" },
  { code: "IQ", label: "Iraq" },
  { code: "IR", label: "Iran, Islamic Republic of" },
  { code: "IS", label: "Iceland" },
  { code: "IT", label: "Italy" },
  { code: "JE", label: "Jersey" },
  { code: "JM", label: "Jamaica" },
  { code: "JO", label: "Jordan" },
  { code: "JP", label: "Japan" },
  { code: "KE", label: "Kenya" },
  { code: "KG", label: "Kyrgyzstan" },
  { code: "KH", label: "Cambodia" },
  { code: "KI", label: "Kiribati" },
  { code: "KM", label: "Comoros" },
  { code: "KN", label: "Saint Kitts and Nevis" },
  { code: "KP", label: "Korea, Democratic People's Republic of" },
  { code: "KR", label: "Korea, Republic of" },
  { code: "KW", label: "Kuwait" },
  { code: "KY", label: "Cayman Islands" },
  { code: "KZ", label: "Kazakhstan" },
  { code: "LA", label: "Lao People's Democratic Republic" },
  { code: "LB", label: "Lebanon" },
  { code: "LC", label: "Saint Lucia" },
  { code: "LI", label: "Liechtenstein" },
  { code: "LK", label: "Sri Lanka" },
  { code: "LR", label: "Liberia" },
  { code: "LS", label: "Lesotho" },
  { code: "LT", label: "Lithuania" },
  { code: "LU", label: "Luxembourg" },
  { code: "LV", label: "Latvia" },
  { code: "LY", label: "Libya" },
  { code: "MA", label: "Morocco" },
  { code: "MC", label: "Monaco" },
  { code: "MD", label: "Moldova, Republic of" },
  { code: "ME", label: "Montenegro" },
  { code: "MF", label: "Saint Martin (French part)" },
  { code: "MG", label: "Madagascar" },
  { code: "MH", label: "Marshall Islands" },
  { code: "MK", label: "Macedonia, the Former Yugoslav Republic of" },
  { code: "ML", label: "Mali" },
  { code: "MM", label: "Myanmar" },
  { code: "MN", label: "Mongolia" },
  { code: "MO", label: "Macao" },
  { code: "MP", label: "Northern Mariana Islands" },
  { code: "MQ", label: "Martinique" },
  { code: "MR", label: "Mauritania" },
  { code: "MS", label: "Montserrat" },
  { code: "MT", label: "Malta" },
  { code: "MU", label: "Mauritius" },
  { code: "MV", label: "Maldives" },
  { code: "MW", label: "Malawi" },
  { code: "MX", label: "Mexico" },
  { code: "MY", label: "Malaysia" },
  { code: "MZ", label: "Mozambique" },
  { code: "NA", label: "Namibia" },
  { code: "NC", label: "New Caledonia" },
  { code: "NE", label: "Niger" },
  { code: "NF", label: "Norfolk Island" },
  { code: "NG", label: "Nigeria" },
  { code: "NI", label: "Nicaragua" },
  { code: "NL", label: "Netherlands" },
  { code: "NO", label: "Norway" },
  { code: "NP", label: "Nepal" },
  { code: "NR", label: "Nauru" },
  { code: "NU", label: "Niue" },
  { code: "NZ", label: "New Zealand" },
  { code: "OM", label: "Oman" },
  { code: "PA", label: "Panama" },
  { code: "PE", label: "Peru" },
  { code: "PF", label: "French Polynesia" },
  { code: "PG", label: "Papua New Guinea" },
  { code: "PH", label: "Philippines" },
  { code: "PK", label: "Pakistan" },
  { code: "PL", label: "Poland" },
  { code: "PM", label: "Saint Pierre and Miquelon" },
  { code: "PN", label: "Pitcairn" },
  { code: "PR", label: "Puerto Rico" },
  { code: "PS", label: "Palestine, State of" },
  { code: "PT", label: "Portugal" },
  { code: "PW", label: "Palau" },
  { code: "PY", label: "Paraguay" },
  { code: "QA", label: "Qatar" },
  { code: "RE", label: "Reunion" },
  { code: "RO", label: "Romania" },
  { code: "RS", label: "Serbia" },
  { code: "RU", label: "Russian Federation" },
  { code: "RW", label: "Rwanda" },
  { code: "SA", label: "Saudi Arabia" },
  { code: "SB", label: "Solomon Islands" },
  { code: "SC", label: "Seychelles" },
  { code: "SD", label: "Sudan" },
  { code: "SE", label: "Sweden" },
  { code: "SG", label: "Singapore" },
  { code: "SH", label: "Saint Helena" },
  { code: "SI", label: "Slovenia" },
  { code: "SJ", label: "Svalbard and Jan Mayen" },
  { code: "SK", label: "Slovakia" },
  { code: "SL", label: "Sierra Leone" },
  { code: "SM", label: "San Marino" },
  { code: "SN", label: "Senegal" },
  { code: "SO", label: "Somalia" },
  { code: "SR", label: "Suriname" },
  { code: "SS", label: "South Sudan" },
  { code: "ST", label: "Sao Tome and Principe" },
  { code: "SV", label: "El Salvador" },
  { code: "SX", label: "Sint Maarten (Dutch part)" },
  { code: "SY", label: "Syrian Arab Republic" },
  { code: "SZ", label: "Swaziland" },
  { code: "TC", label: "Turks and Caicos Islands" },
  { code: "TD", label: "Chad" },
  { code: "TF", label: "French Southern Territories" },
  { code: "TG", label: "Togo" },
  { code: "TH", label: "Thailand" },
  { code: "TJ", label: "Tajikistan" },
  { code: "TK", label: "Tokelau" },
  { code: "TL", label: "Timor-Leste" },
  { code: "TM", label: "Turkmenistan" },
  { code: "TN", label: "Tunisia" },
  { code: "TO", label: "Tonga" },
  { code: "TR", label: "Turkey" },
  { code: "TT", label: "Trinidad and Tobago" },
  { code: "TV", label: "Tuvalu" },
  { code: "TW", label: "Taiwan, Province of China" },
  { code: "TZ", label: "United Republic of Tanzania" },
  { code: "UA", label: "Ukraine" },
  { code: "UG", label: "Uganda" },
  { code: "US", label: "United States" },
  { code: "UY", label: "Uruguay" },
  { code: "UZ", label: "Uzbekistan" },
  { code: "VA", label: "Holy See (Vatican City State)" },
  { code: "VC", label: "Saint Vincent and the Grenadines" },
  { code: "VE", label: "Venezuela" },
  { code: "VG", label: "British Virgin Islands" },
  { code: "VI", label: "US Virgin Islands" },
  { code: "VN", label: "Vietnam" },
  { code: "VU", label: "Vanuatu" },
  { code: "WF", label: "Wallis and Futuna" },
  { code: "WS", label: "Samoa" },
  { code: "XK", label: "Kosovo" },
  { code: "YE", label: "Yemen" },
  { code: "YT", label: "Mayotte" },
  { code: "ZA", label: "South Africa" },
  { code: "ZM", label: "Zambia" },
  { code: "ZW", label: "Zimbabwe" },
];

export default withRouter(UserProfile);
