import React, { Suspense } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import AccountCircle from "@material-ui/icons/AccountCircle";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
// import LockOutline from "@material-ui/icons/LockOutline";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import LoadingModal from "./Modals/LoadingModal";
import styles from "assets/jss/elite-feats-cms/views/loginPageStyle.js";
import logo from "assets/img/logo-yellow.svg";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { withRouter } from "react-router-dom";
import { Typography, IconButton, Divider } from "@material-ui/core";
import CardIcon from "components/Card/CardIcon";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import Link from "@material-ui/core/Link";
import ForgottenPassword from "./Modals/ForgottenPassword";
import * as Yup from "yup";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LanguageSelector from "Language/language-selector";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function LoginPage(props) {
  const [openError, setOpenError] = React.useState(false);
  const { t } = useTranslation();
  const handleClick = () => {
    setOpenError(true);
  };
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenError(false);
  };

  const initialValues = {
    email: "",
    password: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string().email(t("common:validation.invalidEmail")),
    password: Yup.string()
      .required(t("common:validation.passwordRequired"))
      .min(6, t("common:validation.passwordMin", { count: 6 })),
  });

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [showPassword, setShowPassword] = React.useState("password");
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openReset, setOpenReset] = React.useState(false);

  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 200);

    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  React.useEffect(() => {
    localStorage.clear();
  }, []);

  const handleSubmit = (values) => {
    setOpen(true);
    axios
      .post("/api/auth/authenticate", values)
      .then((res) => {
        localStorage.setItem("token", res.data);
        setOpen(false);
        props.history.push("/admin");
      })
      .catch((err) => {
        setOpen(false);
        setOpenError(true);
      });
  };
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <Card login className={classes[cardAnimaton]}>
            <CardHeader
              color="toolsTwo"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
                marginLeft: 40,
                marginRight: 40,
              }}
            >
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Grid item>
                  <Typography
                    style={{
                      fontSize: 20,

                      color: "#8e4d00",
                      fontWeight: 900,
                    }}
                  >
                    CMS
                  </Typography>
                </Grid>
                <Grid item>
                  <img style={{ height: 100 }} src={logo} />
                </Grid>
                <Grid item>
                  <Typography
                    style={{
                      fontSize: 20,
                      marginTop: 5,
                      color: "#8e4d00",
                      fontWeight: 900,
                    }}
                  >
                    {t("common:signIn")}
                  </Typography>
                </Grid>
              </Grid>
            </CardHeader>
            <CardBody>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                {({ isSubmitting }) => (
                  <Form className={classes.form}>
                    <Field
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      margin="normal"
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email style={{ marginLeft: 8 }} />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="email"
                      as={TextField}
                      id="email"
                      label={t("common:emailAddressLabel")}
                      name="email"
                      autoFocus
                      fullWidth
                      helperText={
                        <ErrorMessage
                          component="div"
                          style={{ color: "#c5473f" }}
                          name="email"
                        >
                          {t("common:emailHelperText")}
                        </ErrorMessage>
                      }
                    />

                    <Field
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      margin="normal"
                      required
                      as={TextField}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              aria-label="toggle password visibility"
                              onClick={() => {
                                if (showPassword.length) {
                                  setShowPassword("");
                                } else {
                                  setShowPassword("password");
                                }
                              }}
                            >
                              {showPassword.length ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      name="password"
                      label={t("common:passwordLabel")}
                      type={`${showPassword}`}
                      id="password"
                      autoComplete="current-password"
                      helperText={
                        <ErrorMessage
                          component="div"
                          style={{ color: "#c5473f" }}
                          name="password"
                        >
                          {t("common:passwordHelperText")}
                        </ErrorMessage>
                      }
                    />

                    <CardFooter className={classes.justifyContentCenter}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="success"
                            style={{ marginTop: 10, marginBottom: 10 }}
                          >
                            {t("common:loginButton")}
                          </Button>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            size="sm"
                            fullWidth
                            color="info"
                            href=""
                            onClick={() => {
                              props.history.push("/auth/register");
                            }}
                            variant="body2"
                          >
                            {t("common:registerButton")}
                          </Button>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 10,
                          }}
                        >
                          <Link
                            size="sm"
                            href="#"
                            onClick={() => {
                              setOpenReset(true);
                            }}
                            variant="body2"
                          >
                            {t("common:forgotPasswordLink")}
                          </Link>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 15,
                          }}
                        >
                          <LanguageSelector />
                        </Grid>
                      </Grid>
                    </CardFooter>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <LoadingModal open={open} setOpen={setOpen} />
      <ForgottenPassword open={openReset} setOpen={setOpenReset} />
      <Snackbar
        open={openError}
        autoHideDuration={7000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="error">{t("common:invalidCredentialsMessage")}</Alert>
      </Snackbar>
    </div>
  );
}

export default withRouter(LoginPage);
