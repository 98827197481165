import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import ClientDetails from "../Modals/ClientDetails";
import dayjs from "dayjs";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#48ac6c",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function CustomizedTables(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [client, setClient] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);
  const rows = props.clients.reverse();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <div>
      <Paper className={classes.root} variant='outlined'>
        <TableContainer style={{ maxHeight: 440 }}>
          <Table className={classes.table} aria-label='customized table'>
            <TableHead>
              <TableRow>
                <StyledTableCell align='left' style={{}}>
                  Name
                </StyledTableCell>
                <StyledTableCell style={{}}>Email</StyledTableCell>
                <StyledTableCell align='center' style={{}}>
                  Created
                </StyledTableCell>
                <StyledTableCell align='center' style={{}}>
                  Assignment Status
                </StyledTableCell>

                <StyledTableCell align='center' style={{}}>
                  Assignments
                </StyledTableCell>
                <StyledTableCell align='center'>LMS Accessed</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <StyledTableRow key={row.email}>
                    <StyledTableCell
                      component='th'
                      scope='row'
                      style={{
                        borderRight: "1px solid #e3e3e3",
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      {row.first_name + " " + row.last_name}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        borderRight: "1px solid #e3e3e3",
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      {row.email}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        borderRight: "1px solid #e3e3e3",
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      {dayjs(row.start_date).format("DD/MM/YYYY")}
                    </StyledTableCell>
                    <StyledTableCell
                      align='center'
                      style={{
                        borderRight: "1px solid #e3e3e3",
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      {!row.hasAssessments ? (
                        <span
                          style={{
                            padding: 3,
                            paddingLeft: 15,
                            paddingRight: 15,
                            borderRadius: 20,
                            fontWeight: "bold",
                            color: "#b94946",
                          }}
                        >
                          None Assigned
                        </span>
                      ) : row.hasPending ? (
                        <span
                          style={{
                            background: "#fcb316",
                            padding: 3,
                            paddingLeft: 15,
                            paddingRight: 15,
                            borderRadius: 20,
                            color: "#fff",
                          }}
                        >
                          Pending
                        </span>
                      ) : (
                        <span
                          style={{
                            background: "#03aab1",
                            padding: 3,
                            paddingLeft: 15,
                            paddingRight: 15,
                            borderRadius: 20,
                            color: "#fff",
                          }}
                        >
                          All Completed
                        </span>
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align='center'
                      style={{
                        borderRight: "1px solid #e3e3e3",
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      {" "}
                      <Button
                        variant='outlined'
                        size='small'
                        disabled={row.hasAssessments ? false : true}
                        onClick={() => {
                          setClient(row);
                          setOpen(true);
                        }}
                      >
                        View
                      </Button>
                    </StyledTableCell>

                    <StyledTableCell
                      align='center'
                      style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      {!row.lms_accessed ? (
                        <CancelIcon style={{ color: " #c73c3c" }} />
                      ) : (
                        <CheckCircleIcon style={{ color: "  #49a87b" }} />
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          <ClientDetails client={client} open={open} setOpen={setOpen} />
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 7, 14, 50]}
          style={{ borderTop: "1px solid #e3e3e3", background: "#fafafa" }}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />{" "}
      </Paper>
    </div>
  );
}
