import React from "react";
// material-ui components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";

import modalStyles from "assets/jss/elite-feats-cms/modalStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Tooltip from "@material-ui/core/Tooltip";

import * as Yup from "yup";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { withRouter } from "react-router-dom";
import { Card, Divider, SvgIcon, Typography } from "@material-ui/core";

import { ReactComponent as Tool1 } from "assets/icons/tool1.svg";
import { ReactComponent as Tool2 } from "assets/icons/tool2.svg";
import { ReactComponent as Tool3 } from "assets/icons/tool3.svg";
import { ReactComponent as Tool4 } from "assets/icons/tool4.svg";
import { ReactComponent as Tool5 } from "assets/icons/tool5.svg";
import { ReactComponent as Cog } from "assets/icons/cog.svg";
import { ReactComponent as Module } from "assets/icons/modicon.svg";
import EmailIcon from "@material-ui/icons/Email";

import { useDebouncedCallback } from "use-debounce";
import AddMembers from "./AddMembers";
import LoadingModal from "./GroupLoading";
import alertStyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";
import assessmentNames from "../../Dashboard/assessmentNamesReversed";
import identifiers from "../../Dashboard/identifiers";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import dayjs from "dayjs";
import Clients from "../SearchList/Clients";
import { useTranslation } from "react-i18next";

const styles = {
  ...modalStyles,
  ...alertStyles,
};

const useStyles = makeStyles(styles);

const TField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#2b8cc6",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#3c9778",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#2b8cc6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#2b8cc6",
      },
    },
  },
})(TextField);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    maxWidth: 275,
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
  body: {
    fontSize: 14,
    maxWidth: 275,
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Modal(props) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [clientDetails, setClientDetails] = React.useState("");
  const [alert, setAlert] = React.useState(null);
  const client = props.assignmentDetails;
  let assigned = [];
  if (client) {
    Object.values(identifiers).forEach((assessment) => {
      if (client[assessment].length) {
        client[assessment].forEach((assignment) => {
          let assigned_object = {
            lms: client.lms_accessed,
            name: t(`assignments:assessmentNames.${assessment}`),
            completed: assignment.completed,
            date: assignment.assigned_date,
            completed_date: assignment.completed_date,
            accessor: assignment.accessor,
            email: client.email,
            first_name: client.first_name,
            assessment_id: assessment,
            group: assignment.group,
          };

          assigned.push(assigned_object);
        });
      }
    });

    assigned.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });
  }

  const hideAlert = (loc) => {
    setAlert(null);
  };
  const basicAlert = (client, assessment, object) => {
    setAlert(
      <SweetAlert
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title={t("assignments:alerts.sendReminderTitle")}
        onConfirm={() => {
          handleSendReminder(object);
          hideAlert();
        }}
        onCancel={() => hideAlert()}
        confirmBtnText={t("assignments:alerts.send")}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.warning}
      >
        <Typography align="justify" style={{ fontWeight: "bold" }}>
          {t("assignments:alerts.sendReminderText", { client: client })}
        </Typography>
        <Typography
          align="left"
          style={{ marginLeft: 10, marginRight: 10, marginTop: 10 }}
        >
          {assessment}
        </Typography>
      </SweetAlert>
    );
  };

  const reportAlert = (client, object) => {
    setAlert(
      <SweetAlert
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title={t("assignments:alerts.sendReportTitle")}
        onConfirm={() => {
          handleSendReport(object);
          hideAlert();
        }}
        onCancel={() => hideAlert()}
        confirmBtnText={t("assignments:alerts.send")}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.warning}
      >
        <Typography align="justify" style={{ fontWeight: "bold" }}>
          {t("assignments:alerts.sendReportText", { client: client })}
        </Typography>
      </SweetAlert>
    );
  };

  const successAlert = (type) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("assignments:alerts.operationCompletedSuccessfully")}
        onConfirm={() => hideAlert("success")}
        onCancel={() => hideAlert("success")}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        {t(`assignments:alerts.${type.toLowerCase()}SentToClient`)}
      </SweetAlert>
    );
  };

  const successAlert2 = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("assignments:alerts.operationCompletedSuccessfully")}
        onConfirm={() => hideAlert("success")}
        onCancel={() => hideAlert("success")}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        {t("assignments:alerts.reminderSentToClient")}
      </SweetAlert>
    );
  };

  React.useEffect(() => {
    if (client) {
      setClientDetails(
        `${client.first_name} ${client.last_name} - ${client.email}`
      );
    }
  });

  const handleSendReport = (values) => {
    axios
      .post("/api/client/send-report", values, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then(() => {
        successAlert("Report");
      });
  };

  const handleSendReminder = (values) => {
    axios
      .post("/api/client/send-reminder", values, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then(() => {
        successAlert("Reminder");
      });
  };

  const handleResendAssignment = (client) => {
    axios
      .post(
        "/api/client/resend-intro-email",
        { ...client, language: i18n.language },
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      )
      .then(() => {
        successAlert2();
      });
  };

  const hasAssessments = (client) => {
    if (client) {
      for (let assessment in client.access) {
        if (client.access[assessment]) {
          return true;
        }
      }
      return false;
    }
  };
  return (
    <div>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        style={{ maxWidth: 2000 }}
        open={props.open}
        maxWidth="lg"
        transition={Transition}
        keepMounted
        disableBackdropClick
        onClose={() => props.setOpen(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
          style={{
            paddingTop: 20,
            borderBottom: "1px solid #dddddd",
            paddingBottom: 20,
          }}
        >
          <Typography style={{ fontWeight: 900, fontSize: 22 }}>
            {t("assignments:allAssigned.title")}
          </Typography>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
          style={{ paddingBottom: 20 }}
        >
          <Typography
            style={{
              fontSize: 18,
              fontWeight: 500,
              color: " #2aaab9",
            }}
          >
            {" "}
            {clientDetails}
          </Typography>
          <TableContainer
            component={Paper}
            style={{ marginTop: 10 }}
            variant="outlined"
          >
            <Table
              className={classes.table}
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ width: 40 }} align="center">
                    {""}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {t("assignments:allAssigned.tableHeaders.assessment")}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {t("assignments:allAssigned.tableHeaders.assignedDate")}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {t("assignments:allAssigned.tableHeaders.completedDate")}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {t("assignments:allAssigned.tableHeaders.report")}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {t("assignments:allAssigned.tableHeaders.assignedToGroup")}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: 40 }} align="center">
                    {""}
                  </StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {assigned.reverse().map((row) => {
                  let color;
                  if (row.name.split(" ")[1][0] === "1") {
                    color = "#bb3737";
                  } else if (row.name.split(" ")[1][0] === "2") {
                    color = "#ef7e28";
                  } else if (row.name.split(" ")[1][0] === "3") {
                    color = "#0c8197";
                  } else if (row.name.split(" ")[1][0] === "4") {
                    color = "#098359";
                  } else if (row.name.split(" ")[1][0] === "5") {
                    color = "#7f4a7f";
                  }

                  let icon;
                  if (row.assessment_id[0] === "m") {
                    icon = Module;
                  } else if (row.assessment_id[0] === "t") {
                    if (row.name.split(" ")[1][0] === "1") {
                      icon = Tool1;
                    } else if (row.name.split(" ")[1][0] === "2") {
                      icon = Tool2;
                    } else if (row.name.split(" ")[1][0] === "3") {
                      icon = Tool3;
                    } else if (row.name.split(" ")[1][0] === "4") {
                      icon = Tool4;
                    } else if (row.name.split(" ")[1][0] === "5") {
                      icon = Tool5;
                    }
                  }

                  return (
                    <StyledTableRow key={`${Math.random()}`}>
                      <StyledTableCell
                        style={{
                          width: 40,
                          padding: 10,
                          paddingLeft: 15,
                        }}
                        align="center"
                      >
                        <SvgIcon
                          component={icon}
                          style={{
                            fontSize: 28,
                            color: `${color}`,
                          }}
                        />
                      </StyledTableCell>

                      <StyledTableCell
                        align="left"
                        style={{
                          borderRight: "1px solid #dddddd",
                          paddingTop: 10,
                          paddingBottom: 10,
                          paddingLeft: 10,
                          paddingRight: 10,
                          fontWeight: 600,
                        }}
                      >
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{
                          borderRight: "1px solid #dddddd",
                        }}
                      >
                        {dayjs(row.date).format("DD/MM/YYYY")}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          borderRight: "1px solid #dddddd",
                        }}
                        align="center"
                      >
                        {row.completed
                          ? dayjs(row.completed_date).format("DD/MM/YYYY")
                          : t("assignments:allAssigned.pending")}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          borderRight: "1px solid #dddddd",
                        }}
                        align="center"
                      >
                        {row.completed ? (
                          <Button
                            size="sm"
                            color="success"
                            onClick={() =>
                              window.open(
                                `https://learn.elitefeats.com.au/report/${row.accessor}`,
                                "_blank"
                              )
                            }
                          >
                            {t("assignments:allAssigned.viewReport")}
                          </Button>
                        ) : (
                          <Typography
                            style={{
                              fontWeight: 900,
                              color: "#c9423a",
                              border: "1px dashed #c9423a",
                              fontSize: 14,
                              paddingLeft: 5,
                              paddingRight: 5,
                              borderRadius: 20,
                            }}
                          >
                            {t("assignments:allAssigned.pending")}
                          </Typography>
                        )}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{
                          borderRight: "1px solid #dddddd",
                        }}
                      >
                        {row.group ? (
                          <Grid
                            item
                            style={{
                              margin: 5,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              align="center"
                              style={{
                                borderRadius: 10,
                                paddingLeft: 10,
                                paddingRight: 10,
                                background: "#40628d",
                                display: "flex",
                                flexGrow: 0,
                                fontSize: 12,
                                fontWeight: 400,

                                color: "#f7f7f7",
                              }}
                            >
                              {row.group}
                            </Typography>
                          </Grid>
                        ) : (
                          t("assignments:allAssigned.no")
                        )}
                      </StyledTableCell>

                      {row.completed ? (
                        <StyledTableCell style={{ width: 40 }} align="center">
                          <Tooltip
                            title={
                              <Typography style={{ fontSize: 14 }}>
                                {t("assignments:allAssigned.sendReport")}
                              </Typography>
                            }
                            placement="top"
                          >
                            <div>
                              <Button
                                justIcon
                                round
                                onClick={() => {
                                  reportAlert(row.first_name, {
                                    name: row.first_name,
                                    accessor: row.accessor,
                                    email: row.email,
                                    language: i18n.language,
                                  });
                                }}
                                simple
                                color="info"
                                className="remove"
                              >
                                <EmailIcon />
                              </Button>
                            </div>
                          </Tooltip>
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell style={{ width: 40 }} align="center">
                          <Tooltip
                            title={
                              row.lms ? (
                                <Typography style={{ fontSize: 14 }}>
                                  {t(
                                    "assignments:allAssigned.sendReminderShort"
                                  )}
                                </Typography>
                              ) : (
                                <Typography style={{ fontSize: 14 }}>
                                  {t(
                                    "assignments:allAssigned.lmsActivationReminder"
                                  )}
                                </Typography>
                              )
                            }
                            placement="top"
                          >
                            <div>
                              <Button
                                justIcon
                                round
                                onClick={() => {
                                  basicAlert(row.first_name, row.name, {
                                    assessment: row.name,
                                    name: row.first_name,
                                    email: row.email,
                                    id: row.assessment_id,
                                    language: i18n.language,
                                  });
                                }}
                                simple
                                disabled={!row.lms}
                                color={row.lms ? "warning" : "darkBlue"}
                                className="remove"
                              >
                                <EmailIcon />
                              </Button>
                            </div>
                          </Tooltip>
                        </StyledTableCell>
                      )}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <Divider light style={{ marginBottom: 10, marginTop: 5 }} />
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: 20,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Button
            style={{ marginRight: 10 }}
            onClick={() => {
              props.setOpen(false);
            }}
          >
            {t("dashboard:close")}
          </Button>
          <Tooltip
            title={
              <Typography style={{ fontSize: 14 }}>
                {hasAssessments(client)
                  ? t("assignments:allAssigned.sendReminder")
                  : t("assignments:allAssigned.allComplete")}
              </Typography>
            }
            placement="top"
          >
            <div>
              <Button
                disabled={!hasAssessments(client)}
                color="success"
                style={{ marginRight: 10 }}
                onClick={() => {
                  handleResendAssignment(client);
                }}
              >
                {t("assignments:allAssigned.fullReminder")}
              </Button>
            </div>
          </Tooltip>
        </DialogActions>{" "}
      </Dialog>

      <LoadingModal open={open} setOpen={setOpen} />
      {alert}
    </div>
  );
}
