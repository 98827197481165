import React from "react";
// material-ui components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
// core components
import Button from "components/CustomButtons/Button.js";
import Checkbox from "@material-ui/core/Checkbox";
import allStyles from "assets/jss/elite-feats-cms/views/extendedTablesStyle.js";

import modalStyles from "assets/jss/elite-feats-cms/modalStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";

import * as Yup from "yup";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { withRouter } from "react-router-dom";
import { Divider, Typography } from "@material-ui/core";
import SearchList from "../SearchList/SearchList";
import CreateClient from "../SearchList/CreateClient";

import { useDebouncedCallback } from "use-debounce";
import LoadingModal from "./EditGroupLoading";
import alertStyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";
import { ContactSupportOutlined } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const styles = { ...modalStyles, ...alertStyles, ...allStyles };

const useStyles = makeStyles(styles);

const TField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#2b8cc6",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#3c9778",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#2b8cc6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#2b8cc6",
      },
    },
  },
})(TextField);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Modal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [alert, setAlert] = React.useState(null);
  const [initialValues, setInitial] = React.useState({
    name: "",
    description: "",
  });
  const [openLoading, setOpenLoading] = React.useState(false);
  const [clients, setClients] = React.useState(props.clients);
  const [checked, setChecked] = React.useState({});

  const [openCreateClient, setOpenCreateClient] = React.useState(false);
  const [clientsChanged, setClientsChanged] = React.useState(0);
  const [reload, setReload] = React.useState(0);
  const firstRender = React.useRef(true);
  const handleToggle = (event) => {
    if (props.tourRun) {
      props.setTriggerStep(20);
    }
    setChecked({ ...checked, [event.target.name]: event.target.checked });
  };

  const deselectAll = () => {
    for (let email in checked) {
      checked[email] = false;
    }
    setChecked(checked);
  };

  React.useEffect(() => {
    if (props.open) {
      if (!clients.length) {
        axios
          .get("/api/client/get-clients", {
            headers: {
              "auth-token": localStorage.getItem("token"),
            },
          })
          .then((res) => {
            let newChecked = {};
            res.data.forEach((client) => {
              newChecked[`${client.email}`] = false;
            });

            setChecked(newChecked);
            setClients(res.data.reverse());
          });
      }
    }
  }, [props.open]);

  React.useEffect(() => {
    if (props.selectedGroup !== null) {
      if (props.shouldOpen) {
        for (let member of props.selectedGroup.members) {
          checked[member] = true;
        }

        setChecked(checked);
        props.setOpen(true);
      }
    }
  }, [props.selectedGroup]);

  /*   React.useEffect(() => {
    if (props.selectedGroup !== null) {
      if (props.shouldOpen) {
        for (let member of props.selectedGroup.members) {
          checked[member] = true;
        }

        setChecked(checked);
        setReload(Math.random());
      }
    }
  }, [clients]); */

  React.useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      axios
        .get("/api/client/get-clients", {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        })
        .then((res) => {
          let newChecked = checked;

          let old_clients = clients.map((client) => client.email);
          res.data.forEach((client) => {
            if (!old_clients.includes(client.email))
              newChecked[`${client.email}`] = true;
          });

          setChecked(newChecked);
          setClients(res.data.reverse());
          props.setClientRecount(Math.random());
        });
    }
  }, [clientsChanged]);

  const hideAlert = (loc) => {
    if (loc === "success") {
      setAlert(null);
      deselectAll();
      props.setGroupsAdded(Math.random());
      /* setInitial({ name: "", description: "" }); */
    }
    setAlert(null);
  };
  const dangerAlert = () => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("groups:errorTitle")}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.danger}
      >
        {t("groups:groupNotUpdated")}
      </SweetAlert>
    );
  };

  const NameAlert = () => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("groups:groupNotAddedTitle")}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.danger}
      >
        {t("groups:enterGroupName")}
      </SweetAlert>
    );
  };

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("groups:groupUpdatedTitle")}
        onConfirm={() => hideAlert("success")}
        onCancel={() => hideAlert("success")}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        {t("groups:groupUpdatedSuccess")}
      </SweetAlert>
    );
  };

  const handleAddMembers = useDebouncedCallback(() => {
    let values = {};
    values["name"] = props.selectedGroup.name;
    props.setSelectedGroup(null);
    props.setShouldOpen(false);
    props.setOpen(false);
    setOpenLoading(true);
    let clients = [];

    for (let email in checked) {
      if (checked[email] === true) {
        clients.push(email);
      }
    }
    values["clients"] = clients;
    axios
      .post("/api/client/update-members", values, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setOpenLoading(false);

        if (res.data === "not added") {
          dangerAlert();
        } else if (res.data === "added") {
          successAlert();
        }
      });

    /*  let newChecked = checked;
    for (let email in newChecked) {
      newChecked[email] = false;
    }
    setChecked(newChecked); */
  }, 500);

  return (
    <div>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={props.open}
        transition={Transition}
        keepMounted
        fullWidth
        maxWidth="sm"
        disableBackdropClick
        onClose={() => props.setOpen(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
          style={{
            paddingTop: 20,
            borderBottom: "1px solid #dddddd",
            paddingBottom: 20,
          }}
        >
          <Typography style={{ fontWeight: 900, fontSize: 22 }}>
            {t("groups:addRemoveMembers")}
          </Typography>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
          style={{ paddingBottom: 20 }}
        >
          <Button
            style={{
              marginLeft: 10,
              marginRight: 10,
              marginTop: 10,
            }}
            color="info"
            onClick={() => {
              setOpenCreateClient(true);
            }}
          >
            {t("groups:createNewClient")}
          </Button>
          <SearchList
            reload={reload}
            clients={clients}
            handleToggle={handleToggle}
            checked={checked}
          />
        </DialogContent>
        <Divider light style={{ marginBottom: 10, marginTop: 5 }} />
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
          style={{
            display: "block",
            justifyContent: "center",
            paddingBottom: 20,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Button
            style={{ marginRight: 10 }}
            onClick={() => {
              deselectAll();
              props.setSelectedGroup(null);
              props.setShouldOpen(false);
              props.setOpen(false);
            }}
          >
            {t("groups:cancel")}
          </Button>

          <Button
            className="update-group"
            color="success"
            style={{ marginRight: 10 }}
            onClick={() => {
              handleAddMembers();
            }}
          >
            {t("groups:updateMembers")}
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingModal open={openLoading} setOpen={setOpenLoading} />
      <CreateClient
        openCreateClient={openCreateClient}
        setOpenCreateClient={setOpenCreateClient}
        setClientsChanged={setClientsChanged}
      />
      {alert}
    </div>
  );
}
