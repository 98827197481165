import Dashboard from "views/Dashboard/Dashboard.js";

import AccountActivity from "views/Activity/AccountActivity.js";
import UserProfile from "views/Pages/UserProfile.js";
import Shop from "views/Shop/Shop";
import CheckoutSuccess from "views/Shop/CheckoutSuccess";
import Admin from "views/Admin/UserAdmin";
import SalesStats from "views/Admin/SalesStats";
import AdminTools from "views/Admin/AdminTools";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import ShoppingBasketRoundedIcon from "@material-ui/icons/ShoppingBasketRounded";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import BuildIcon from "@material-ui/icons/Build";
import DeveloperBoardOutlinedIcon from "@material-ui/icons/DeveloperBoardOutlined";
import TimelineOutlinedIcon from "@material-ui/icons/TimelineOutlined";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Home",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },

  {
    path: "/account-activity",
    name: "Account Activity",
    rtlName: "عالتسعير",
    icon: DonutLargeIcon,
    component: AccountActivity,
    layout: "/admin",
  },
  {
    path: "/account-profile",
    name: "My Profile",
    rtlName: "صودعم رتل",
    icon: AccountTreeOutlinedIcon,
    component: UserProfile,
    layout: "/admin",
  },

  {
    path: "/dashboard",
    name: "My Clients",
    rtlName: "لوحة القيادة",
    icon: AccountCircleRoundedIcon,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/dashboard",
    name: "My Groups",
    rtlName: "إستمارات",
    icon: SupervisedUserCircleIcon,
    component: Dashboard,
    layout: "/admin",
  },

  {
    path: "/shop",
    name: "Shop",
    rtlName: "لوحة القيادة",
    icon: ShoppingBasketRoundedIcon,
    component: Shop,
    layout: "/admin",
  },
  {
    path: "/checkout-success",
    name: "Order Complete",
    rtlName: "لوحة القيادة",
    icon: ShoppingBasketRoundedIcon,
    component: CheckoutSuccess,
    layout: "/admin",
  },
  {
    path: "/user-admin",
    name: "User Information",
    rtlName: "لوحة القيادة",
    icon: DeveloperBoardOutlinedIcon,
    component: Admin,
    layout: "/admin",
  },
  {
    path: "/sales-stats",
    name: "Sales Statistics",
    rtlName: "لوحة القيادة",
    icon: TimelineOutlinedIcon,
    component: SalesStats,
    layout: "/admin",
  },
  {
    path: "/admin-tools",
    name: "Admin Tools",
    rtlName: "لوحة القيادة",
    icon: BuildIcon,
    component: AdminTools,
    layout: "/admin",
  },
];
export default dashRoutes;
