import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Typography, Grid } from "@material-ui/core";

import AppContext from "../../AppContext";
import MemberTable from "./MemberTable";
import { useStripe } from "@stripe/react-stripe-js";

import SweetAlert from "react-bootstrap-sweetalert";
import Chip from "@material-ui/core/Chip";
import Snackbar from "@material-ui/core/Snackbar";

import DoneIcon from "@material-ui/icons/Done";
import MuiAlert from "@material-ui/lab/Alert";

import alertstyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";
import TextField from "@material-ui/core/TextField";

import CustButton from "components/CustomButtons/Button.js";
import axios from "axios";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  ...alertstyles,
});
function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}
const DialogTitle = withStyles(styles)((props) => {

  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function CustomizedDialogs(props) {
  const {t} = useTranslation()
  const classes = useStyles();
  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <div>
      <Dialog
        maxWidth='lg'
        onClose={handleClose}
        style={{ maxWidth: 2000 }}
        aria-labelledby='customized-dialog-title'
        open={props.open}
      >
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          {props.groupName}
        </DialogTitle>
        <DialogContent dividers>
          <MemberTable rows={props.members} />
        </DialogContent>

        <DialogActions
          style={{ paddingTop: 15, paddingBottom: 15, display: "flex" }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <CustButton
                style={{ marginRight: 5 }}
                onClick={() => {
                  props.setOpen(false);
                }}
              >
               {t('dashboard:close')}
              </CustButton>
            </Grid>
          </Grid>
        </DialogActions>

        {alert}
      </Dialog>
    </div>
  );
}

export default withRouter(CustomizedDialogs);
