import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Typography, Grid } from "@material-ui/core";

import AppContext from "../../AppContext";
import CartTable from "./CartTable";
import { useStripe } from "@stripe/react-stripe-js";

import SweetAlert from "react-bootstrap-sweetalert";
import Chip from "@material-ui/core/Chip";
import Snackbar from "@material-ui/core/Snackbar";

import DoneIcon from "@material-ui/icons/Done";
import MuiAlert from "@material-ui/lab/Alert";

import alertstyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";
import TextField from "@material-ui/core/TextField";
import assessmentNames from "./stripe_helpers/assessmentNames";
import assessmentTitles from "./stripe_helpers/assessmentTitles";
import assessmentIcons from "./stripe_helpers/assessmentIcons";
import CartLoading from "./CartLoading";
import axios from "axios";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  ...alertstyles,
});
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function CustomizedDialogs(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const handleClose = () => {
    props.setOpen(false);
  };

  const [alert, setAlert] = React.useState(null);
  const [total, setTotal] = React.useState(0);

  const warningWithConfirmMessage = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("shop:cart.clearAllItemsFromCart")}
        onConfirm={() => handleClear()}
        onCancel={() => {
          hideAlert2();
        }}
        confirmBtnCssClass={classes.button + " " + classes.danger}
        cancelBtnCssClass={classes.button + " "}
        confirmBtnText={t("shop:cart.clear")}
        cancelBtnText={t("shop:cart.cancel")}
        showCancel
      >
        {t("shop:cart.areYouSureRemoveAllItems")}
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    props.setOpen(false);
    setAlert(null);
  };
  const hideAlert2 = () => {
    setAlert(null);
  };

  const handleClear = () => {
    let currentCart = JSON.parse(localStorage.getItem("cart"));
    for (let item in currentCart) {
      currentCart[item] = 0;
    }

    localStorage.setItem("cart", JSON.stringify(currentCart));
    cartContext.setCart(currentCart);

    hideAlert();
  };
  const calculateTotal = () => {
    let cart = JSON.parse(localStorage.getItem("cart"));

    let subtotal = 0;

    for (let item in cart) {
      if (item[0] === "m") {
        let cost = cart[item] * 50;
        subtotal += cost;
      } else if (item[0] === "t") {
        let cost = cart[item] * 20;
        subtotal += cost;
      }
    }

    setTotal(subtotal);
  };

  const cartContext = React.useContext(AppContext);
  const [cartItems, setCartItems] = React.useState(false);
  React.useEffect(() => {
    let cart = cartContext.cart;
    let hasItems = false;
    for (let item in cart) {
      if (cart[item]) {
        hasItems = true;
      }
    }
    setCartItems(hasItems);
  }, [cartContext.cart]);
  React.useEffect(() => {
    if (coupon === "TEST21") {
      setTotal(0);
    }
  });
  const stripe = useStripe();

  const handleCheckout = async () => {
    props.setOpen(false);
    setOpenLoad(true);
    let cart = JSON.parse(localStorage.getItem("cart"));
    for (let item in cart) {
      if (!cart[item]) {
        delete cart[item];
      }
    }
    let line_items = Object.keys(cart).map((item) => {
      let price = 0;
      if (item[0] === "m") {
        price = 5000;
      } else if (item[0] === "t") {
        price = 2000;
      }
      if (cart[item]) {
        return {
          quantity: cart[item],
          price_data: {
            currency: "usd",
            unit_amount: price,
            product_data: {
              name: assessmentTitles[item],
              description: assessmentNames[item],
              images: [assessmentIcons[item]],
            },
          },
        };
      }
    });

    async function getSession() {
      const res = await axios.post(
        "/api/stripe/create-checkout-session",
        line_items,
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );

      return res;
    }

    if (coupon === "TEST21") {
      axios
        .post("/api/stripe/test-discount", line_items, {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        })
        .then((res) => {
          setOpenLoad(false);
          props.history.push("/admin/checkout-success");
        });
    } else {
      const response = await getSession();
      const { sessionId } = response.data;
      const { error } = await stripe.redirectToCheckout({
        sessionId,
      });

      if (error) {
        console.log(error);
      }
    }
  };

  const [value, setValue] = React.useState("");
  const [coupon, setCoupon] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openLoad, setOpenLoad] = React.useState(false);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleDelete = () => {
    setValue("");
    setCoupon("");
    calculateTotal();
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <div>
      <Dialog
        maxWidth="lg"
        onClose={handleClose}
        style={{ maxWidth: 2000 }}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t("shop:cart.yourShoppingCart")}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid
              item
              md={6}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginBottom: 15,
              }}
            >
              {coupon.length ? (
                <div>
                  <span style={{ marginRight: 10 }}>
                    {t("shop:cart.couponsApplied")}:{" "}
                  </span>
                  <Chip
                    icon={<DoneIcon style={{ color: "#fff" }} />}
                    label={coupon}
                    onDelete={handleDelete}
                    style={{ background: "#4caf50", color: "#fff" }}
                  />
                </div>
              ) : null}
            </Grid>
            <Grid
              item
              md={6}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <Typography
                align={"right"}
                style={{
                  fontSize: 20,
                }}
              >
                <span
                  style={{
                    borderRadius: 5,
                    padding: 10,
                    paddingRight: 15,
                    paddingLeft: 15,
                    fontWeight: "bold",
                  }}
                >
                  <span style={{ marginRight: 5 }}>
                    {t("shop:cart.total")}:{" "}
                  </span>
                  <span style={{}}>&#36; {total.toFixed(2)}</span>
                </span>
              </Typography>{" "}
            </Grid>
          </Grid>

          <CartTable total={total} setTotal={setTotal} />
        </DialogContent>
        {cartItems ? (
          <DialogActions
            style={{ paddingTop: 15, paddingBottom: 15, display: "flex" }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <TextField
                  id="standard-"
                  label={t("shop:cart.coupon")}
                  size="small"
                  variant="outlined"
                  value={value}
                  onChange={handleChange}
                />
                <Button
                  autoFocus
                  onClick={() => {
                    if (value === "TEST21") {
                      setCoupon(value);
                      setValue("");
                      setTotal(0);
                    } else {
                      setOpen(true);
                      setCoupon("");
                      setValue("");
                      calculateTotal();
                    }
                  }}
                  color=""
                  variant="outlined"
                  style={{ marginLeft: 10 }}
                >
                  {t("shop:cart.apply")}
                </Button>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  autoFocus
                  onClick={() => {
                    warningWithConfirmMessage();
                  }}
                  color=""
                  variant="outlined"
                  style={{ marginRight: 5 }}
                >
                  {t("shop:cart.clearCart")}
                </Button>
                <Button
                  autoFocus
                  onClick={() => handleCheckout()}
                  color="primary"
                  variant="outlined"
                  style={{ marginLeft: 5, marginRight: 5 }}
                >
                  {t("shop:cart.proceedToCheckout")}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        ) : null}

        {alert}
        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={handleCloseAlert}
        >
          <Alert onClose={handleCloseAlert} severity="error">
            {t("shop:cart.invalidCoupon")}
          </Alert>
        </Snackbar>
      </Dialog>

      <CartLoading open={openLoad} setOpen={setOpenLoad} />
    </div>
  );
}

export default withRouter(CustomizedDialogs);
