import React from "react";
// material-ui components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";

import modalStyles from "assets/jss/elite-feats-cms/modalStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";

import * as Yup from "yup";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { withRouter } from "react-router-dom";
import { Divider, Typography, Box } from "@material-ui/core";

import { useDebouncedCallback } from "use-debounce";
import LoadingModal from "./CreateLoading";
import alertStyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";
import PlayForWorkIcon from "@material-ui/icons/PlayForWork";
import ControlPointDuplicateIcon from "@material-ui/icons/ControlPointDuplicate";
import Stepper from "./MultipleStepper";
import { useTranslation } from "react-i18next";
const styles = { ...modalStyles, ...alertStyles };

const useStyles = makeStyles(styles);

const TField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#63ac1d",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#3c9778",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#63ac1d",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#63ac1d",
      },
    },
  },
})(TextField);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Modal(props) {
  const {t} = useTranslation()
  const classes = useStyles();
  const [alert, setAlert] = React.useState(null);
  const [initialValues, setInitial] = React.useState({
    first: "",
    last: "",
    email: "",
  });
  const [open, setOpen] = React.useState(false);
  const hideAlert = (loc) => {
    if (loc === "close") {
      props.openModal(false);
    }
    setAlert(null);
  };
  const dangerAlert = () => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("clients:alerts.clientNotAdded")}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.danger}
      >
        {t("clients:alerts.existingEmailError")}
      </SweetAlert>
    );
  };

  const warningAlert = () => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title={t("clients:alerts.areYouSure")}
        onConfirm={() => hideAlert("close")}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.warning}
        cancelBtnCssClass={classes.button}
      >
        {t("clients:alerts.anyInformationLost")}
      </SweetAlert>
    );
  };

  return (
    <div>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={props.modal}
        transition={Transition}
        keepMounted
        fullWidth
        maxWidth="lg"
        disableEnforceFocus
        disableBackdropClick
        onClose={() => props.openModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
          style={{ paddingTop: 30, borderBottom: "1px solid #dedede" }}
        >
          <Typography style={{ fontWeight: 900, fontSize: 22 }}>
            {t("clients:alerts.createMultipleClients")}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ paddingBottom: 35 }}>
          <Stepper
            setReloadTable={props.setReloadTable}
            openModal={props.openModal}
          />
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            paddingBottom: 15,
            paddingLeft: 30,
            borderTop: "1px solid #dedede",
          }}
        >
          <Button
            style={{ marginRight: 10, marginTop: 10 }}
            onClick={() => {
              props.openModal(false);
              // Optionally add a warningAlert function if needed
              // warningAlert();
            }}
          >
            {t("clients:alerts.close")}
          </Button>
        </DialogActions>
      </Dialog>

      <LoadingModal open={open} setOpen={setOpen} />
      {alert}
    </div>
  );
}
