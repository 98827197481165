import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import styles from "assets/jss/elite-feats-cms/views/extendedTablesStyle.js";

import product1 from "assets/img/product1.jpg";
import product2 from "assets/img/product2.jpg";
import product3 from "assets/img/product3.jpg";
import AppContext from "../../AppContext";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles(styles);

export default function ExtendedTables(props) {
  const { t } = useTranslation();
  let assignment = JSON.parse(localStorage.getItem("assignment"));
  let order = JSON.parse(localStorage.getItem("order"));
  const { totalPurchased, totalUsed, pending, completed } = props;
  const cartContext = React.useContext(AppContext);
  let initialChecked = [];
  for (let number in assignment) {
    if (assignment[number] === true) {
      initialChecked.push(number);
    }
  }

  const [checked, setChecked] = React.useState(initialChecked);

  React.useEffect(() => {
    if (!order.length && checked.length) {
      setChecked([]);
    }
  });

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value.toString());
    const orderIndex = order.indexOf(value.toString());
    const newChecked = [...checked];

    if (currentIndex === -1 && order) {
      newChecked.push(value.toString());
      assignment[`${value}`] = true;
      order.push(value.toString());
    } else {
      newChecked.splice(currentIndex, 1);
      order.splice(orderIndex, 1);
      assignment[`${value}`] = false;
    }
    setChecked(newChecked);
    localStorage.setItem("assignment", JSON.stringify(assignment));
    localStorage.setItem("order", JSON.stringify(order));
    props.triggerReload();
  };
  const classes = useStyles();
  const fillButtons = [
    { color: "info", icon: Person },
    { color: "success", icon: Edit },
    { color: "danger", icon: Close },
  ].map((prop, key) => {
    return (
      <Button color={prop.color} className={classes.actionButton} key={key}>
        <prop.icon className={classes.icon} />
      </Button>
    );
  });
  const simpleButtons = [
    { color: "info", icon: Person },
    { color: "success", icon: Edit },
    { color: "danger", icon: Close },
  ].map((prop, key) => {
    return (
      <Button
        color={prop.color}
        simple
        className={classes.actionButton}
        key={key}
      >
        <prop.icon className={classes.icon} />
      </Button>
    );
  });
  const roundButtons = [
    { color: "info", icon: Person },
    { color: "success", icon: Edit },
    { color: "danger", icon: Close },
  ].map((prop, key) => {
    return (
      <Button
        round
        color={prop.color}
        className={classes.actionButton + " " + classes.actionButtonRound}
        key={key}
      >
        <prop.icon className={classes.icon} />
      </Button>
    );
  });
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card plain style={{ marginTop: -10, marginBottom: 0 }}>
          <CardBody>
            <Table
              tableHead={[
                t("assignments:tableHeaders.assessment"),
                t("assignments:tableHeaders.purchased"),
                t("assignments:tableHeaders.used"),
                t("assignments:tableHeaders.available"),
                t("assignments:tableHeaders.pending"),
                t("assignments:tableHeaders.completed"),
                t("assignments:tableHeaders.assign"),
              ]}
              tableData={[
                [
                  t("assignments:assessmentNames.module1"),
                  `${totalPurchased.module1}`,
                  `${totalUsed.module1}`,
                  `${totalPurchased.module1 - totalUsed.module1}`,
                  `${pending.module1}`,
                  `${completed.module1}`,
                  totalPurchased.module1 !== totalUsed.module1 ? (
                    <Checkbox
                      className="tour-assessment-checkbox"
                      key="key"
                      tabIndex={-1}
                      style={{ padding: 7 }}
                      onClick={() => {
                        if (props.tourRun) {
                          props.setTriggerStep(-2);
                          setTimeout(() => {
                            props.setTriggerStep(9);
                          }, 800);
                        }
                        handleToggle(1);
                      }}
                      checked={assignment["1"]}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checkedDarkBlue,
                        root: classes.checkRoot,
                      }}
                    />
                  ) : (
                    <Button
                      color="modules"
                      onClick={() => {
                        let currentCart = JSON.parse(
                          localStorage.getItem("cart")
                        );
                        currentCart["module1"] += 1;
                        localStorage.setItem(
                          "cart",
                          JSON.stringify(currentCart)
                        );
                        cartContext.setCart(currentCart);
                      }}
                      round
                      size="sm"
                      style={{ padding: 4, paddingLeft: 15, paddingRight: 15 }}
                    >
                      {t("assignments:addToCart")}
                    </Button>
                  ),
                ],
                [
                  t("assignments:assessmentNames.module2"),
                  `${totalPurchased.module2}`,
                  `${totalUsed.module2}`,
                  `${totalPurchased.module2 - totalUsed.module2}`,
                  `${pending.module2}`,
                  `${completed.module2}`,
                  totalPurchased.module2 !== totalUsed.module2 ? (
                    <Checkbox
                      key="key"
                      tabIndex={-1}
                      style={{ padding: 7 }}
                      onClick={() => handleToggle(2)}
                      checked={assignment["2"]}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checkedDarkBlue,
                        root: classes.checkRoot,
                      }}
                    />
                  ) : (
                    <Button
                      color="modules"
                      onClick={() => {
                        let currentCart = JSON.parse(
                          localStorage.getItem("cart")
                        );
                        currentCart["module2"] += 1;
                        localStorage.setItem(
                          "cart",
                          JSON.stringify(currentCart)
                        );
                        cartContext.setCart(currentCart);
                      }}
                      round
                      size="sm"
                      style={{ padding: 4, paddingLeft: 15, paddingRight: 15 }}
                    >
                      {t("assignments:addToCart")}
                    </Button>
                  ),
                ],
                [
                  t("assignments:assessmentNames.module3"),
                  `${totalPurchased.module3}`,
                  `${totalUsed.module3}`,
                  `${totalPurchased.module3 - totalUsed.module3}`,
                  `${pending.module3}`,
                  `${completed.module3}`,
                  totalPurchased.module3 !== totalUsed.module3 ? (
                    <Checkbox
                      key="key"
                      tabIndex={-1}
                      style={{ padding: 7 }}
                      onClick={() => handleToggle(3)}
                      checked={assignment["3"]}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checkedDarkBlue,
                        root: classes.checkRoot,
                      }}
                    />
                  ) : (
                    <Button
                      color="modules"
                      onClick={() => {
                        let currentCart = JSON.parse(
                          localStorage.getItem("cart")
                        );
                        currentCart["module3"] += 1;
                        localStorage.setItem(
                          "cart",
                          JSON.stringify(currentCart)
                        );
                        cartContext.setCart(currentCart);
                      }}
                      round
                      size="sm"
                      style={{ padding: 4, paddingLeft: 15, paddingRight: 15 }}
                    >
                      {t("assignments:addToCart")}
                    </Button>
                  ),
                ],
                [
                  t("assignments:assessmentNames.module4"),
                  `${totalPurchased.module4}`,
                  `${totalUsed.module4}`,
                  `${totalPurchased.module4 - totalUsed.module4}`,
                  `${pending.module4}`,
                  `${completed.module4}`,
                  totalPurchased.module4 !== totalUsed.module4 ? (
                    <Checkbox
                      key="key"
                      tabIndex={-1}
                      style={{ padding: 7 }}
                      onClick={() => handleToggle(4)}
                      checked={assignment["4"]}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checkedDarkBlue,
                        root: classes.checkRoot,
                      }}
                    />
                  ) : (
                    <Button
                      color="modules"
                      onClick={() => {
                        let currentCart = JSON.parse(
                          localStorage.getItem("cart")
                        );
                        currentCart["module4"] += 1;
                        localStorage.setItem(
                          "cart",
                          JSON.stringify(currentCart)
                        );
                        cartContext.setCart(currentCart);
                      }}
                      round
                      size="sm"
                      style={{ padding: 4, paddingLeft: 15, paddingRight: 15 }}
                    >
                      {t("assignments:addToCart")}
                    </Button>
                  ),
                ],
                [
                  t("assignments:assessmentNames.module5"),
                  `${totalPurchased.module5}`,
                  `${totalUsed.module5}`,
                  `${totalPurchased.module5 - totalUsed.module5}`,
                  `${pending.module5}`,
                  `${completed.module5}`,
                  totalPurchased.module5 !== totalUsed.module5 ? (
                    <Checkbox
                      key="key"
                      tabIndex={-1}
                      style={{ padding: 7 }}
                      onClick={() => handleToggle(5)}
                      checked={assignment["5"]}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checkedDarkBlue,
                        root: classes.checkRoot,
                      }}
                    />
                  ) : (
                    <Button
                      color="modules"
                      onClick={() => {
                        let currentCart = JSON.parse(
                          localStorage.getItem("cart")
                        );
                        currentCart["module5"] += 1;
                        localStorage.setItem(
                          "cart",
                          JSON.stringify(currentCart)
                        );
                        cartContext.setCart(currentCart);
                      }}
                      round
                      size="sm"
                      style={{ padding: 4, paddingLeft: 15, paddingRight: 15 }}
                    >
                      {t("assignments:addToCart")}
                    </Button>
                  ),
                ],
              ]}
              customCellClasses={[
                classes.left,
                classes.center,
                classes.center,
                classes.center,
                classes.center,
                classes.center,
                classes.center,
              ]}
              customClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
              customHeadCellClasses={[
                classes.left,
                classes.center,
                classes.center,
                classes.center,
                classes.center,
                classes.center,
                classes.center,
              ]}
              customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
