import React from "react";
import cx from "classnames";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import Tour from "../views/Dashboard/IntroTour";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { LoggedRoute } from "./Authentication/LoggedRoute";

import routes from "routes.js";
import AppContext from "../AppContext";
import styles from "assets/jss/elite-feats-cms/layouts/adminStyle.js";

var ps;

const useStyles = makeStyles(styles);

function Dashboard(props) {
  const { ...rest } = props;
  const { Provider } = AppContext;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [image, setImage] = React.useState(require("assets/img/sidebar-2.jpg"));
  const [color, setColor] = React.useState("blue");
  const [bgColor, setBgColor] = React.useState("black");
  // const [hasImage, setHasImage] = React.useState(true);
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [logo, setLogo] = React.useState(require("assets/img/logo.svg"));
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      /*     if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      } */
      window.removeEventListener("resize", resizeFunction);
    };
  });
  // functions for changeing the states from components

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <LoggedRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  let cart_init = {
    module1: 0,
    module2: 0,
    module3: 0,
    module4: 0,
    module5: 0,
    tool1_1: 0,
    tool1_2: 0,
    tool1_3: 0,

    tool2_1: 0,
    tool2_2: 0,
    tool2_3: 0,

    tool3_1: 0,
    tool3_2: 0,
    tool3_3: 0,
    tool3_4: 0,

    tool4_1: 0,
    tool4_2: 0,
    tool4_3: 0,
    tool4_4: 0,
    tool4_5: 0,

    tool5_1: 0,
    tool5_2: 0,
    tool5_3: 0,
  };
  const [scrollTo, setScrollTo] = React.useState("");
  const [startTour, setStartTour] = React.useState(0);
  const [active, setActive] = React.useState(0);
  const [cart, setCart] = React.useState(cart_init);

  React.useEffect(() => {
    if (!localStorage.getItem("cart")) {
      localStorage.setItem("cart", JSON.stringify(cart_init));
    } else {
      setCart(JSON.parse(localStorage.getItem("cart")));
    }
  }, []);

  React.useEffect(() => {
    if (localStorage.getItem("cart")) {
      localStorage.setItem("cart", JSON.stringify(cart));
    }
  }, [cart]);
  return (
    <div className={classes.wrapper}>
      <Provider
        value={{
          scrollTo,
          setScrollTo,
          setStartTour,
          cart,
          setCart,
          active,
          setActive,
        }}
      >
        <Sidebar
          routes={routes}
          logoText={"Elite Feats"}
          logo={logo}
          image={image}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          bgColor={bgColor}
          miniActive={miniActive}
          {...rest}
        />
        <div className={mainPanelClasses} ref={mainPanel}>
          <AdminNavbar
            sidebarMinimize={sidebarMinimize.bind(this)}
            miniActive={miniActive}
            brandText={getActiveRoute(routes)}
            handleDrawerToggle={handleDrawerToggle}
            color={"info"}
            position='sticky'
            {...rest}
          />
          <Tour startTour={startTour} />
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>
                <Switch>
                  {getRoutes(routes)}
                  <Redirect from='/admin' to='/admin/dashboard' />
                </Switch>
              </div>
            </div>
          ) : (
            <div className={classes.map}>
              <Switch>
                {getRoutes(routes)}
                <Redirect from='/admin' to='/admin/dashboard' />
              </Switch>
            </div>
          )}
        </div>
      </Provider>
    </div>
  );
}

export default withRouter(Dashboard);
