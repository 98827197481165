import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import moduleNames from "./moduleNames";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function BasicTable(props) {
  const classes = useStyles();
  let modules = [];
  let tools1 = [];
  let tools2 = [];
  let tools3 = [];
  let tools4 = [];
  let tools5 = [];
  let rows = [];
  let user = props.user;

  if (user) {
    for (let assessment in user.total_purchased) {
      let object = {};
      object["name"] = moduleNames[assessment];
      object["purchased"] = user.total_purchased[assessment];
      object["used"] = user.used_assessments[assessment];
      if (assessment[0] === "m") {
        modules.push(object);
      }
      if (assessment[0] === "t") {
        if (assessment[4] === "1") {
          tools1.push(object);
        }
        if (assessment[4] === "2") {
          tools2.push(object);
        }
        if (assessment[4] === "3") {
          tools3.push(object);
        }
        if (assessment[4] === "4") {
          tools4.push(object);
        }
        if (assessment[4] === "5") {
          if (assessment[6] !== "4") {
            tools5.push(object);
          }
        }
      }
    }
  }

  if (props.id === "M") {
    rows = modules;
  }
  if (props.id === "T1") {
    rows = tools1;
  }
  if (props.id === "T2") {
    rows = tools2;
  }
  if (props.id === "T3") {
    rows = tools3;
  }
  if (props.id === "T4") {
    rows = tools4;
  }
  if (props.id === "T5") {
    rows = tools5;
  }

  return (
    <React.Fragment>
      <TableContainer component={Paper} variant={"outlined"}>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  borderRight: "1px solid #f0f0f0",
                  fontWeight: 500,
                  fontSize: 15,
                  background: "#1d2d40",
                  color: "#fff",
                }}
              >
                Assessment Name
              </TableCell>
              <TableCell
                style={{
                  borderRight: "1px solid #f0f0f0",
                  fontWeight: 500,
                  fontSize: 15,
                  background: "#1d2d40",
                  color: "#fff",
                }}
                align='right'
              >
                Purchased Qty
              </TableCell>
              <TableCell
                align='right'
                style={{
                  fontWeight: 500,
                  fontSize: 15,
                  background: "#1d2d40",
                  borderRight: "1px solid #f0f0f0",
                  color: "#fff",
                }}
              >
                Used Qty
              </TableCell>
              <TableCell
                align='right'
                style={{
                  fontWeight: 500,
                  fontSize: 15,
                  background: "#1d2d40",
                  color: "#fff",
                }}
              >
                Available Qty
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell
                  style={{ borderRight: "1px solid #f0f0f0" }}
                  component='th'
                  scope='row'
                >
                  {row.name}
                </TableCell>

                <TableCell
                  style={{ borderRight: "1px solid #f0f0f0" }}
                  align='center'
                >
                  {row.purchased}
                </TableCell>
                <TableCell
                  style={{ borderRight: "1px solid #f0f0f0" }}
                  align='center'
                >
                  {row.used}
                </TableCell>
                <TableCell align='center'>{row.purchased - row.used}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}
