import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import EditIcon from "@material-ui/icons/Edit";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import GroupsReactTable from "../Clients/GroupsReactTable";
import Checkbox from "@material-ui/core/Checkbox";
import allStyles from "assets/jss/elite-feats-cms/views/extendedTablesStyle.js";
import Check from "@material-ui/icons/Check";
import { dataTable } from "variables/general.js";
import Grow from "@material-ui/core/Grow";
import CreateGroup from "../Clients/Modals/CreateGroup";
import SweetAlert from "react-bootstrap-sweetalert";
import alertStyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";
import { cardTitle } from "assets/jss/elite-feats-cms.js";
import Tooltip from "@material-ui/core/Tooltip";

import MUICard from "@material-ui/core/Card";
import MUICardHeader from "@material-ui/core/CardHeader";
import MUITypography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";

import AssignLoading from "../Dashboard/AssignLoading";
import identifiers from "../Dashboard/identifiers";
import { useDebouncedCallback } from "use-debounce";

import EmailIcon from "@material-ui/icons/Email";
import EditModal from "../Clients/Modals/EditGroupModal";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import DeleteLoading from "../Clients/Modals/DeleteGroupsLoading";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import empty from "assets/img/empty-groups.svg";

import AssignAssessments from "../Dashboard/AssignAssessments";
import AddViewMembers from "../Clients/Modals/AddViewMembers";
import RestoreFromTrashIcon from "@material-ui/icons/RestoreFromTrash";
import IconButton from "@material-ui/core/IconButton";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as question } from "assets/icons/question.svg";
import Tour from "./GroupsIntro";
import Restore from "./Modals/RestoreDelete";
import { set } from "mongoose";
import { useTranslation } from "react-i18next";
const styles = {
  ...allStyles,
  ...alertStyles,

  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);

export default function GroupsTable(props) {
  const Question = () => (
    <SvgIcon component={question} style={{ fontSize: 22, padding: 3 }} />
  );

  let selected_groups = JSON.parse(localStorage.getItem("selected_groups"));
  let selected_clients = JSON.parse(localStorage.getItem("selected_clients"));
  const [tourRun, setTourRun] = React.useState(false);
  const [triggerStep, setTriggerStep] = React.useState(-1);
  const [groupTour, setGroupTour] = React.useState(Math.random());
  /* states for client table */
  const [open, setOpen] = React.useState(false);

  const [modal, openModal] = React.useState(false);

  const [alert, setAlert] = React.useState(null);

  const order = JSON.parse(localStorage.getItem("order"));
  const groupActions = React.useRef(null);

  const { t, i18n } = useTranslation();

  const hideAlert = (loc) => {
    if (loc === "deleted") {
      props.setGroupsAdded(Math.random());
      props.setGrowGroupsActions(false);
      props.setReloadTable(Math.random());
      setAlert(null);
    }
    if (loc === "success") {
      setAlert(null);
      localStorage.setItem("order", JSON.stringify([]));
      let assignments = JSON.parse(localStorage.getItem("assignment"));
      for (const assignment in assignments) {
        assignments[assignment] = false;
      }
      localStorage.setItem("assignment", JSON.stringify(assignments));

      props.triggerReload();
      localStorage.setItem("selected_clients", JSON.stringify([]));
      props.setReloadClients(Math.random());
      props.assessmentTrigger(Math.random());
      props.setGrowGroupsActions(false);
      props.setReloadTable(Math.random());
    }
    if (loc === "unsuccessful") {
      setAlert(null);
      props.setReloadClients(Math.random());
    }
    setOpenAssignLoading(false);
    setAlert(null);
  };
  const deleteAlert = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("groups:areYouSure")}
        onConfirm={() => handleDelete()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.danger}
        cancelBtnCssClass={classes.button + " " + classes.default}
        confirmBtnText={t("groups:delete")}
        cancelBtnText={t("groups:cancel")}
        showCancel
      >
        {t("groups:deleteConfirmationText")}
      </SweetAlert>
    );
  };
  const successDelete = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("groups:deleted")}
        onConfirm={() => hideAlert("deleted")}
        onCancel={() => hideAlert("deleted")}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        {t("groups:deleteSuccessMessage")}
      </SweetAlert>
    );
  };

  const handleDelete = () => {
    if (tourRun) {
      setTriggerStep(-2);
    }

    setAlert(null);
    setOpen(true);
    let groups = JSON.parse(localStorage.getItem("selected_groups"));

    axios
      .post("/api/client/delete-groups", groups, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setOpen(false);
        if (res.data === "deleted") {
          let selected_clients = JSON.parse(
            localStorage.getItem("selected_clients")
          );

          groups.forEach((group) => {
            selected_clients = selected_clients.filter((client) => {
              return !group.members.includes(client);
            });
          });

          localStorage.setItem(
            "selected_clients",
            JSON.stringify(selected_clients)
          );
          localStorage.setItem("selected_groups", JSON.stringify([]));
          props.setReloadClients(Math.random());
          if (tourRun) {
            props.setReloadTable();
            props.setGroupsAdded();
            setTimeout(() => {
              setTriggerStep(25);
            }, 800);
          } else {
            setAlert(successDelete);
          }
        }
      });
  };

  /*   React.useEffect(() => {
    if (props.grow) {
      groupActions.current.scrollIntoView({
        block: "start",
      });
    }
  }, [props.grow]); */
  //Assign assessment logic

  const [error, setError] = React.useState([]);
  const [openAssignLoading, setOpenAssignLoading] = React.useState(false);
  const [assignData, setassignData] = React.useState({
    errors: {},
    success: {},
  });
  const firstRender = React.useRef(true);

  const basicAlert = () => {
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={t("groups:noClientsSelected")}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        {t("groups:selectAtLeastOneClient")}
      </SweetAlert>
    );
  };

  const noClientAlert = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("groups:noClientsSelected")}
        onConfirm={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.warning}
        confirmBtnText={t("groups:ok")}
      >
        {t("groups:selectAtLeastOneClient")}
      </SweetAlert>
    );
  };

  const noAssignmentAlert = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("groups:noAssessmentsSelected")}
        onConfirm={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.warning}
        confirmBtnText={t("groups:ok")}
      >
        {t("groups:selectAtLeastOneAssessment")}
      </SweetAlert>
    );
  };

  const dangerAlert = () => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("groups:unableToAssignAssessments")}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.danger}
      >
        {t("groups:insufficientQuantityMessage")}
        <MUICard
          variant="outlined"
          style={{ marginTop: 15, maxHeight: 300, overflow: "auto" }}
        >
          {error.map((assessment, index) => (
            <React.Fragment key={index}>
              <MUITypography
                align="left"
                style={{
                  padding: 5,
                  paddingLeft: 10,
                  paddingRight: 10,
                  fontSize: 14,
                }}
              >
                {t(`assignments:assessmentNames.${assessment}`)}
              </MUITypography>
              {index !== error.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </MUICard>
      </SweetAlert>
    );
  };

  const successWithErrorsAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("groups:operationCompletedWithErrors")}
        onConfirm={() => errorAlert()}
        onCancel={() => hideAlert("success")}
        confirmBtnCssClass={classes.button + " " + classes.warning}
        confirmBtnText={t("groups:viewErrors")}
        cancelBtnCssClass={classes.button + " " + classes.success}
        cancelBtnText={t("groups:ok")}
      >
        {t("groups:assessmentsAssignedWithErrorsInfo")}
        <Grid
          container
          style={{
            maxHeight: 300,
            overflow: "auto",
            border: "1px solid #dedede",
            borderRadius: 5,
            marginTop: 10,
            padding: 10,
            paddingBottom: 20,
          }}
        >
          {Object.keys(assignData.success).map((assessment) => {
            let details = assignData.success[assessment];
            return (
              <MUICard
                variant="outlined"
                style={{ width: "100%", marginTop: 10 }}
                key={assessment}
              >
                <MUICardHeader
                  title={
                    <MUITypography
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      {t(`assignments:assessmentNames.${assessment}`)}
                    </MUITypography>
                  }
                  style={{
                    padding: 5,
                    borderBottom: "1px solid #dedede ",
                    background: "#23934b",
                  }}
                />
                {details.map((client, index) => (
                  <React.Fragment key={index}>
                    <MUITypography>{client}</MUITypography>
                    {index !== details.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </MUICard>
            );
          })}
        </Grid>
      </SweetAlert>
    );
  };

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("groups:operationCompletedSuccessfully")}
        onConfirm={() => hideAlert("success")}
        onCancel={() => hideAlert("success")}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        {t("groups:assessmentsAssignedToClients")}
        <Grid
          container
          style={{
            maxHeight: 300,
            overflow: "auto",
            border: "1px solid #dedede",
            borderRadius: 5,
            marginTop: 10,
            padding: 10,
            paddingBottom: 20,
          }}
        >
          {Object.keys(assignData.success).map((assessment) => {
            let details = assignData.success[assessment];
            return (
              <MUICard
                variant="outlined"
                style={{ width: "100%", marginTop: 10 }}
                key={assessment}
              >
                <MUICardHeader
                  title={
                    <MUITypography
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      {t(`assignments:assessmentNames.${assessment}`)}
                    </MUITypography>
                  }
                  style={{
                    padding: 5,
                    borderBottom: "1px solid #dedede",
                    background: "#23934b",
                  }}
                />
                {details.map((client, index) => (
                  <React.Fragment key={index}>
                    <MUITypography>{client}</MUITypography>
                    {index !== details.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </MUICard>
            );
          })}
        </Grid>
      </SweetAlert>
    );
  };

  const errorAlert = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("groups:assignmentError")}
        onConfirm={() => hideAlert("success")}
        confirmBtnCssClass={classes.button + " " + classes.warning}
        confirmBtnText={t("groups:ok")}
      >
        {t("groups:pendingAssignmentError")}
        <Grid
          container
          style={{
            maxHeight: 300,
            overflow: "auto",
            border: "1px solid #dedede",
            borderRadius: 5,
            marginTop: 10,
            padding: 10,
            paddingBottom: 20,
          }}
        >
          {Object.keys(assignData.errors).map((assessment) => {
            let details = assignData.errors[assessment];
            return (
              <MUICard
                variant="outlined"
                style={{ width: "100%", marginTop: 10 }}
                key={assessment}
              >
                <MUICardHeader
                  title={
                    <MUITypography
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      {t(`assignments:assessmentNames.${assessment}`)}
                    </MUITypography>
                  }
                  style={{
                    padding: 5,
                    borderBottom: "1px solid #dedede",
                    background: "#fcb316",
                  }}
                />
                {details.map((client, index) => (
                  <React.Fragment key={index}>
                    <MUITypography>{client}</MUITypography>
                    {index !== details.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </MUICard>
            );
          })}
        </Grid>
      </SweetAlert>
    );
  };

  const errorAlert2 = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("groups:assignmentError")}
        onConfirm={() => hideAlert("unsuccessful")}
        confirmBtnCssClass={classes.button + " " + classes.warning}
        confirmBtnText={t("groups:ok")}
      >
        {t("groups:pendingAssignmentError")}
        <Grid
          container
          style={{
            maxHeight: 300,
            overflow: "auto",
            border: "1px solid #dedede",
            borderRadius: 5,
            marginTop: 10,
            padding: 10,
            paddingBottom: 20,
          }}
        >
          {Object.keys(assignData.errors).map((assessment) => {
            let details = assignData.errors[assessment];
            return (
              <MUICard
                variant="outlined"
                style={{ width: "100%", marginTop: 10 }}
                key={assessment}
              >
                <MUICardHeader
                  title={
                    <MUITypography
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      {t(`assignments:assessmentNames.${assessment}`)}
                    </MUITypography>
                  }
                  style={{
                    padding: 5,
                    borderBottom: "1px solid #dedede ",
                    background: "#fcb316",
                  }}
                >
                  {details.map((client, index) => (
                    <React.Fragment key={index}>
                      <MUITypography>{client}</MUITypography>
                      {index !== details.length - 1 && <Divider />}
                    </React.Fragment>
                  ))}
                </MUICardHeader>
              </MUICard>
            );
          })}
        </Grid>
      </SweetAlert>
    );
  };

  const handleAssignment = useDebouncedCallback(() => {
    if (JSON.parse(localStorage.getItem("order")).length) {
      let assignment = JSON.parse(localStorage.getItem("assignment"));
      let totalAvailable = {};
      for (let assessment in props.totalUsed) {
        totalAvailable[`${assessment}`] =
          props.totalPurchased[assessment] - props.totalUsed[assessment];
      }
      let numberOfClients = JSON.parse(localStorage.getItem("selected_clients"))
        .length;

      if (numberOfClients > 0) {
        setOpenAssignLoading(true);
        let checkAssignment = {};
        for (let assessmentNo in assignment) {
          if (assignment[assessmentNo] === true) {
            checkAssignment[`${identifiers[assessmentNo]}`] =
              totalAvailable[identifiers[assessmentNo]];
          }
        }

        let deficit = [];

        for (let assessment in totalAvailable) {
          if (checkAssignment[assessment] < numberOfClients) {
            deficit.push(assessment);
          }
        }

        if (deficit.length) {
          setError(deficit);
        } else {
          let assignment = JSON.parse(localStorage.getItem("order"));
          let selected = JSON.parse(localStorage.getItem("selected_clients"));

          let to_send = {
            assignment: assignment,
            selected: selected,
            language: i18n.language,
          };
          axios
            .post("/api/client/assign", to_send, {
              headers: {
                "auth-token": localStorage.getItem("token"),
              },
            })
            .then((res) => {
              setOpenAssignLoading(false);

              localStorage.setItem("selected_clients", JSON.stringify([]));
              localStorage.setItem("selected_groups", JSON.stringify([]));
              props.setReloadClients(Math.random());
              props.setReloadGroups(Math.random());
              setassignData(res.data);
            });
        }
      } else {
        noClientAlert();
      }
    } else {
      noAssignmentAlert();
    }
  }, 1000);

  React.useEffect(() => {
    if (!firstRender.current) {
      if (tourRun) {
        setTriggerStep(-2);
        setTimeout(() => {
          setTriggerStep(20);
        }, 600);
      }
    }
  }, [props.tourGroupSelect]);

  React.useEffect(() => {
    if (!firstRender.current) {
      if (tourRun) {
        setTriggerStep(-2);
        setTimeout(() => {
          setTriggerStep(28);
        }, 600);
      }
    }
  }, [props.tourSelect]);

  React.useEffect(() => {
    if (error.length) {
      dangerAlert();
    }
  }, [error]);

  React.useEffect(() => {
    if (!firstRender.current) {
      if (!Object.values(assignData.success).length) {
        errorAlert2();
      } else if (Object.values(assignData.errors).length) {
        successWithErrorsAlert();
        props.setReloadClients(Math.random());
      } else if (Object.values(assignData.success).length) {
        successAlert();
        props.setReloadClients(Math.random());
      }
    } else {
      firstRender.current = false;
    }
  }, [assignData]);

  const classes = useStyles();

  const [openRestore, setOpenRestore] = React.useState(false);
  const [members, setMembers] = React.useState(0);
  const [clientRecount, setClientRecount] = React.useState(0);
  const memberCount = (groups) => {
    let members = 0;
    groups.forEach((group) => {
      members += group.members.length;
    });
    return members;
  };
  React.useEffect(() => {
    if (!firstRender.current) {
      localStorage.setItem("selected_groups", JSON.stringify([]));
      localStorage.setItem("selected_clients", JSON.stringify([]));

      props.setGrowGroupsActions(false);
      props.setReloadClients(Math.random());
      props.setReloadGroupsTable(Math.random());
    } else {
      firstRender.current = false;
    }
  }, [clientRecount]);
  return (
    <div
      ref={props.clientTable}
      style={{
        width: "100%",
      }}
    >
      <Tour
        triggerStep={triggerStep}
        setTourRun={setTourRun}
        setTriggerStep={setTriggerStep}
        setReloadTable={props.setReloadTable}
        setGroupsAdded={props.setGroupsAdded}
        setGroupTour={setGroupTour}
      />
      <Card className={"groups-table"}>
        <CardHeader color="warning" style={{ padding: 5 }}>
          <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <h4
                style={{
                  color: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: 350,
                  fontSize: 21,
                }}
              >
                <SupervisedUserCircleIcon style={{ marginRight: 10 }} />
                {t("groups:groupsTable")}
              </h4>
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Tooltip title={t("groups:takeGuidedTour")} placement="top">
                <div>
                  <IconButton
                    className="clients-tutorial"
                    style={{ border: "1px solid #8b5816" }}
                    size="small"
                    onClick={() => {
                      setTriggerStep(0);
                      setTourRun(true);
                      setGroupTour(-5);
                    }}
                  >
                    <Question style={{ color: "#3d8641" }} />
                  </IconButton>
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </CardHeader>

        <CardBody>
          <GridContainer>
            <GridItem
              xs={4}
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 10,
              }}
            >
              <Button
                className="add-client-button"
                color="tumblr"
                onClick={() => setOpenRestore(true)}
              >
                <RestoreFromTrashIcon /> {t("groups:restoreDeleted")}
              </Button>
            </GridItem>
            <GridItem
              xs={4}
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 10,
              }}
            >
              <Button
                className="add-group-button"
                color="info"
                onClick={() => {
                  openModal(true);
                  if (tourRun) {
                    setTriggerStep(-2);
                    setTimeout(() => {
                      setTriggerStep(2);
                    }, 500);
                  }
                }}
              >
                <GroupAddIcon /> {t("groups:createGroup")}
              </Button>
            </GridItem>
            <GridItem
              xs={4}
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 10,
              }}
            >
              <Button
                className="goto-clients"
                color="success"
                onClick={() => {
                  props.setGrowGroupsTable(false);
                  setTimeout(() => {
                    props.setReloadTable(Math.random());
                  }, 200);
                }}
              >
                <AccountCircleRoundedIcon /> {t("groups:clients")}
              </Button>
            </GridItem>
          </GridContainer>

          {props.ready ? (
            <Grow
              in={props.grow}
              {...(props.grow ? { timeout: 400 } : { timeout: 400 })}
            >
              <div ref={groupActions} className="tour-group-actions">
                <Card
                  plain
                  style={{
                    marginTop: 5,
                    marginBottom: 10,
                    border: "1px dashed #cecece",
                    height: "auto",
                    transition: "height 0.3s",
                  }}
                >
                  <CardBody style={{ padding: 10 }}>
                    <Typography
                      className="tour-selected-groups"
                      align="center"
                      style={{ fontWeight: "bold" }}
                    >
                      {selected_groups.length > 1
                        ? `${selected_groups.length} ${t(
                            "groups:groupsSelected"
                          )}`
                        : `${selected_groups.length} ${t(
                            "groups:groupSelected"
                          )}`}
                      &#8594;
                      {memberCount(selected_groups) !== 1
                        ? `${memberCount(selected_groups)} ${t(
                            "groups:clients"
                          )}`
                        : `${memberCount(selected_groups)} ${t(
                            "groups:client"
                          )}`}
                    </Typography>

                    <GridContainer style={{ marginTop: 5 }}>
                      <GridItem
                        xs={12}
                        md={4}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          className="tour-group-clear"
                          round
                          size="sm"
                          onClick={() => {
                            localStorage.setItem(
                              "selected_groups",
                              JSON.stringify([])
                            );
                            localStorage.setItem(
                              "selected_clients",
                              JSON.stringify([])
                            );
                            props.setGrowGroupsActions(false);
                            props.setReloadClients(Math.random());
                            props.setReloadGroupsTable(Math.random());
                          }}
                        >
                          {t("groups:clearSelection")}
                        </Button>
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={6}
                        md={4}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          className="tour-group-assessments"
                          color="success"
                          round
                          size="sm"
                          onClick={() => {
                            props.toggleScroll("assignmentTable");
                          }}
                        >
                          {t("groups:selectAssessments")}
                        </Button>
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={6}
                        md={4}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          className="tour-group-delete"
                          color="danger"
                          round
                          size="sm"
                          onClick={() => {
                            if (tourRun) {
                              handleDelete();
                            } else {
                              setAlert(deleteAlert);
                            }
                          }}
                        >
                          {t("groups:deleteGroups")}
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </div>
            </Grow>
          ) : (
            false
          )}
          {props.data.length ? (
            <GroupsReactTable
              columns={[
                {
                  Header: "",
                  accessor: "select",
                },
                {
                  Header: t("groups:name"),
                  accessor: "name",
                },
                {
                  Header: t("groups:description"),
                  accessor: "description",
                },
                {
                  Header: t("groups:numberOfMembers"),
                  accessor: "number",
                },
                {
                  Header: t("groups:actions"),
                  accessor: "actions",
                },
              ]}
              data={props.data}
              reloadClients={props.reloadClients}
              groupTour={groupTour}
            />
          ) : (
            <MUICard
              variant="outlined"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              <img src={empty} style={{ height: 150, marginTop: 25 }} />

              <Typography
                align="center"
                style={{
                  fontSize: 18,
                  marginBottom: 20,
                  marginTop: 15,
                  color: "#808080",
                  fontWeight: 200,
                  fontStyle: "italic",
                }}
              >
                {t("groups:noGroupsCreated")}
              </Typography>
            </MUICard>
          )}
        </CardBody>
      </Card>

      <CreateGroup
        clients={props.clients}
        modal={modal}
        openModal={openModal}
        setGroupsAdded={props.setGroupsAdded}
        setTriggerStep={setTriggerStep}
        tourRun={tourRun}
      />
      <AddViewMembers
        clients={props.clients}
        selectedGroup={props.selectedGroup}
        setSelectedGroup={props.setSelectedGroup}
        setGroupsAdded={props.setGroupsAdded}
        open={props.openAddMembers}
        setOpen={props.setOpenAddMembers}
        shouldOpen={props.shouldOpen}
        setShouldOpen={props.setShouldOpen}
        tourRun={tourRun}
        setTriggerStep={setTriggerStep}
        setClientRecount={setClientRecount}
      />
      <EditModal
        modal={props.openEdit}
        openModal={props.setOpenEdit}
        edit={props.edit}
        setReloadTable={props.setReloadTable}
        setGroupsAdded={props.setGroupsAdded}
      />
      <DeleteLoading open={open} setOpen={setOpen} />
      {alert}
      <AssignLoading open={openAssignLoading} setOpen={setOpenAssignLoading} />
      <Restore
        modal={openRestore}
        openModal={setOpenRestore}
        groupsAdded={props.groupsAdded}
        setGroupsAdded={props.setGroupsAdded}
      />
    </div>
  );
}
