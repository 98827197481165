import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

const Review = (props) => {
  const {t} = useTranslation()
  return (
    <div style={{ marginTop: 10 }}>
    <Typography
      align='left'
      style={{ fontSize: 16, fontWeight: 700, color: "#3f51b5" }}
    >
      {t("clients:alerts.multiClients.clientLabel", { number: props.formName })}
    </Typography>
    <Grid container>
      <Grid item xs={12} style={{ padding: 5 }}>
        <Typography align='left' style={{ fontWeight: 400 }}>
          {t("clients:alerts.multiClients.firstName")}:{" "}
          <span
            style={{ fontStyle: "italic", fontWeight: 300, color: "#6b6b6b" }}
          >
            {props.client.first}
          </span>
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ padding: 5 }}>
        <Typography align='left' style={{ fontWeight: 400 }}>
          {t("clients:alerts.multiClients.lastName")}:{" "}
          <span
            style={{ fontStyle: "italic", fontWeight: 300, color: "#6b6b6b" }}
          >
            {props.client.last}{" "}
          </span>
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ padding: 5 }}>
        <Typography align='left' style={{ fontWeight: 400 }}>
          {t("clients:alerts.multiClients.email")}:{" "}
          <span
            style={{ fontStyle: "italic", fontWeight: 300, color: "#6b6b6b" }}
          >
            {props.client.email}{" "}
          </span>
        </Typography>
      </Grid>
    </Grid>
  </div>
  
  );
};

export default Review;
