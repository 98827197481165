import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    ns: [
      "common",
      "dashboard",
      "clients",
      "assignments",
      "groups",
      "shop",
      "activity",
      "tour",
    ],
    defaultNS: "common",
    debug: true,
    fallbackLng: "en",
    returnObjects: true,
    detection: {
      order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag"],
      caches: ["localStorage", "cookie"],
    },
  });
