import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import { blue } from "@material-ui/core/colors";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import { useTranslation } from "react-i18next";

export default function SimpleDialog(props) {
  const { t } = useTranslation();
  const { handleClose, open, setDateRange, dateRange } = props;

  return (
    <Dialog
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        {t("activity:setDateRangeTitle")}
      </DialogTitle>
      <DateRangePicker
        elevation={0}
        closeOnClickOutside={false}
        open={open}
        toggle={handleClose}
        onChange={(range) => setDateRange(range)}
        initialDateRange={dateRange}
      />
      <DialogActions>
        <Button
          onClick={() => {
            setDateRange({});
            handleClose();
          }}
          color="primary"
        >
          {t("activity:actions.clear")}
        </Button>
        <Button
          onClick={() => {
            handleClose();
          }}
          color="primary"
        >
          {t("activity:actions.cancel")}
        </Button>
        <Button onClick={() => handleClose()} color="primary">
          {t("activity:actions.set")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
