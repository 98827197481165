import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Typography, Grid, Card, Divider } from "@material-ui/core";

import AppContext from "../../../AppContext";

import SweetAlert from "react-bootstrap-sweetalert";
import Chip from "@material-ui/core/Chip";
import Snackbar from "@material-ui/core/Snackbar";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PhoneIcon from "@material-ui/icons/Phone";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import HelpIcon from "@material-ui/icons/Help";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import ThumbDown from "@material-ui/icons/ThumbDown";
import ThumbUp from "@material-ui/icons/ThumbUp";
import { getName } from "country-list";

import Box from "@material-ui/core/Box";

import DoneIcon from "@material-ui/icons/Done";
import MuiAlert from "@material-ui/lab/Alert";

import alertstyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";
import TextField from "@material-ui/core/TextField";
import UserClients from "./UserClients";
import axios from "axios";
import { withRouter } from "react-router";
import AnimateHeight from "react-animate-height";
import UserGroups from "./UserGroups";
import UserPurchases from "./UserPurchases";
import UserInventory from "./UserInventory";

import RecentActorsIcon from "@material-ui/icons/RecentActors";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import GroupRoundedIcon from "@material-ui/icons/GroupRounded";
import PersonIcon from "@material-ui/icons/Person";
import GroupWorkIcon from "@material-ui/icons/GroupWork";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[550],
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  ...alertstyles,
});
function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function CustomizedDialogs(props) {
  const classes = useStyles();
  const handleClose = () => {
    props.setOpen(false);
  };

  const [alert, setAlert] = React.useState(null);
  const [total, setTotal] = React.useState(0);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const cartContext = React.useContext(AppContext);
  const [cartItems, setCartItems] = React.useState(false);

  const [coupon, setCoupon] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openLoad, setOpenLoad] = React.useState(false);

  const handleDelete = () => {
    setValue("");
    setCoupon("");
    /*  calculateTotal(); */
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <div>
      <Dialog
        maxWidth='lg'
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={props.open}
      >
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          {props.user.first_name?.length && props.user.last_name?.length
            ? props.user.first_name + " " + props.user.last_name
            : props.user.username}
        </DialogTitle>

        <DialogContent dividers>
          <Card
            variant='outlined'
            style={{
              background: "#f8f8f8",
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              variant='scrollable'
              scrollButtons='on'
              indicatorColor='primary'
              textColor='primary'
            >
              <Tab label='User Information' icon={<PersonOutlineIcon />} />

              <Tab label='Clients' icon={<AccountCircleRoundedIcon />} />
              <Tab label='Groups' icon={<GroupRoundedIcon />} />
              <Tab label='Purchased Items' icon={<ShoppingBasket />} />
              <Tab label='Inventory' icon={<GroupWorkIcon />} />
            </Tabs>
          </Card>

          <TabPanel value={value} index={0}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Card variant='outlined' style={{ maxWidth: 600, flexShrink: 0 }}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    style={{
                      borderRight: "1px solid #e3e3e3",
                      borderBottom: "1px solid #e3e3e3",
                    }}
                  >
                    <Typography style={{ padding: 10, fontWeight: 550 }}>
                      First Name
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    style={{
                      borderBottom: "1px solid #e3e3e3",
                    }}
                  >
                    <Typography align='center' style={{ padding: 10 }}>
                      {" "}
                      {props.user.first_name?.length
                        ? props.user.first_name
                        : "Not Specified"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    style={{
                      borderRight: "1px solid #e3e3e3",
                      borderBottom: "1px solid #e3e3e3",
                    }}
                  >
                    <Typography style={{ padding: 10, fontWeight: 550 }}>
                      Last Name
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    style={{
                      borderBottom: "1px solid #e3e3e3",
                    }}
                  >
                    <Typography align='center' style={{ padding: 10 }}>
                      {" "}
                      {props.user.last_name?.length
                        ? props.user.last_name
                        : "Not Specified"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    style={{
                      borderRight: "1px solid #e3e3e3",
                      borderBottom: "1px solid #e3e3e3",
                    }}
                  >
                    <Typography style={{ padding: 10, fontWeight: 550 }}>
                      Email
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    style={{
                      borderBottom: "1px solid #e3e3e3",
                    }}
                  >
                    <Typography align='center' style={{ padding: 10 }}>
                      {" "}
                      {props.user.email?.length
                        ? props.user.email
                        : "Not Specified"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    style={{
                      borderRight: "1px solid #e3e3e3",
                      borderBottom: "1px solid #e3e3e3",
                    }}
                  >
                    <Typography style={{ padding: 10, fontWeight: 550 }}>
                      Country
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    style={{
                      borderBottom: "1px solid #e3e3e3",
                    }}
                  >
                    <Typography align='center' style={{ padding: 10 }}>
                      {" "}
                      {props.user.country?.length
                        ? getName(props.user.country)
                        : "Not Specified"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    style={{
                      borderRight: "1px solid #e3e3e3",
                      borderBottom: "1px solid #e3e3e3",
                    }}
                  >
                    <Typography style={{ padding: 10, fontWeight: 550 }}>
                      Phone
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    style={{
                      borderBottom: "1px solid #e3e3e3",
                    }}
                  >
                    <Typography align='center' style={{ padding: 10 }}>
                      {" "}
                      {props.user.phone?.length
                        ? props.user.phone
                        : "Not Specified"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    style={{
                      borderRight: "1px solid #e3e3e3",
                      borderBottom: "1px solid #e3e3e3",
                    }}
                  >
                    <Typography style={{ padding: 10, fontWeight: 550 }}>
                      Address
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    style={{
                      borderBottom: "1px solid #e3e3e3",
                    }}
                  >
                    <Typography align='center' style={{ padding: 10 }}>
                      {" "}
                      {props.user.address?.length
                        ? props.user.address
                        : "Not Specified"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    style={{
                      borderRight: "1px solid #e3e3e3",
                      borderBottom: "1px solid #e3e3e3",
                    }}
                  >
                    <Typography style={{ padding: 10, fontWeight: 550 }}>
                      City/Suburb
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    style={{
                      borderBottom: "1px solid #e3e3e3",
                    }}
                  >
                    <Typography align='center' style={{ padding: 10 }}>
                      {" "}
                      {props.user.suburb?.length
                        ? props.user.suburb
                        : "Not Specified"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    style={{
                      borderRight: "1px solid #e3e3e3",
                      borderBottom: "1px solid #e3e3e3",
                    }}
                  >
                    <Typography style={{ padding: 10, fontWeight: 550 }}>
                      State/Province
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    style={{
                      borderBottom: "1px solid #e3e3e3",
                    }}
                  >
                    <Typography align='center' style={{ padding: 10 }}>
                      {" "}
                      {props.user.state?.length
                        ? props.user.state
                        : "Not Specified"}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <UserClients clients={props.user.clients} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <UserGroups groups={props.user.groups} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <UserPurchases userId={props.user.wordpress_id} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <UserInventory user={props.user} />
          </TabPanel>
        </DialogContent>

        {alert}
      </Dialog>
    </div>
  );
}

export default withRouter(CustomizedDialogs);
