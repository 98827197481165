import React, { useState, createContext } from "react";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clients from "./Clients";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const TField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#63ac1d",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#3c9778",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#63ac1d",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#63ac1d",
      },
    },
  },
})(TextField);

export const FilterCtx = createContext(["", () => {}]);

function SearchList(props) {
  const { t } = useTranslation();
  const [filter, setFilter] = useState("");

  return (
    <div>
      <FilterCtx.Provider value={[filter, setFilter]}>
        <TField
          variant="outlined"
          margin="normal"
          value={filter}
          as={TField}
          id="search_text"
          label={t("common:search")}
          onChange={(e) => setFilter(e.target.value)}
          fullWidth
        />

        <Clients
          data={props.clients}
          handleToggle={props.handleToggle}
          checked={props.checked}
          reload={props.reload}
        />
      </FilterCtx.Provider>
    </div>
  );
}

export default SearchList;
