import React, { useReducer, useEffect } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { Typography, Grid } from "@material-ui/core";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import LiveHelpRoundedIcon from "@material-ui/icons/LiveHelpRounded";
import AssignmentRoundedIcon from "@material-ui/icons/AssignmentRounded";
import axios from "axios";
import { useTranslation } from "react-i18next";

// Define the Tour component
const Tour = (props) => {
  const { t } = useTranslation();
  const firstRender = React.useRef(true);

  // Define the steps
  const TOUR_STEPS = [
    {
      target: ".account-activity-0",
      placement: "center",
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clientActivity.step0.part1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:clientActivity.step0.bold1")}
            </span>
            {t("tour:clientActivity.step0.part2")}
          </Typography>
          <Typography align="justify" style={{ marginTop: 10 }}>
            {t("tour:clientActivity.step0.part3")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:clientActivity.step0.bold2")}
            </span>
            {t("tour:clientActivity.step0.part4")}
          </Typography>
        </React.Fragment>
      ),
      disableOverlayClose: true,
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: ".account-activity-0",
      disableOverlayClose: true,
      hideCloseButton: false,
      showSkipButton: false,
      placement: "right",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clientActivity.step1.part1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:clientActivity.step1.bold1")}
            </span>
            {t("tour:clientActivity.step1.part2")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".account-activity-1",
      disableOverlayClose: true,
      hideCloseButton: false,
      showSkipButton: false,
      placement: "right",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clientActivity.step2.part1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:clientActivity.step2.bold1")}
            </span>
            {t("tour:clientActivity.step2.part2")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".account-activity-2",
      disableOverlayClose: true,
      hideCloseButton: false,
      showSkipButton: false,
      placement: "right",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clientActivity.step3.part1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:clientActivity.step3.bold1")}
            </span>
            {t("tour:clientActivity.step3.part2")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".all-client-activity",
      disableOverlayClose: true,
      hideCloseButton: false,
      showSkipButton: false,
      placement: "top",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clientActivity.step4")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".client-activity-0",
      disableOverlayClose: true,
      hideCloseButton: false,
      showSkipButton: false,
      placement: "left",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clientActivity.step5")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".card-title-0",
      disableOverlayClose: true,
      hideCloseButton: false,
      showSkipButton: false,
      placement: "right",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clientActivity.step6")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".assessment-name-0",
      disableOverlayClose: true,
      hideCloseButton: false,
      showSkipButton: false,
      placement: "left",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clientActivity.step7")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".person-name-0",
      disableOverlayClose: true,
      hideCloseButton: false,
      showSkipButton: false,
      placement: "left",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clientActivity.step8")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".view-report-0",
      disableOverlayClose: true,
      hideCloseButton: false,
      showSkipButton: false,
      placement: "bottom",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clientActivity.step9")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".date-filter",
      disableOverlayClose: true,
      hideCloseButton: false,
      showSkipButton: false,
      placement: "bottom",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clientActivity.step10")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".search",
      disableOverlayClose: true,
      hideCloseButton: false,
      showSkipButton: false,
      placement: "bottom",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clientActivity.step11")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".activity-tour-button",
      disableOverlayClose: true,
      hideCloseButton: false,
      showSkipButton: false,
      placement: "bottom",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clientActivity.step12")}
          </Typography>
        </React.Fragment>
      ),
    },
  ];

  // Define our state
  const INITIAL_STATE = {
    key: new Date(),
    run: false,
    continuous: true,
    loading: false,
    stepIndex: 0,
    steps: TOUR_STEPS,
  };

  // Set up the reducer function
  const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "START":
        return { ...state, run: true };
      case "RESET":
        return { ...state, stepIndex: 0 };
      case "STOP":
        return { ...state, run: false, stepIndex: 0 };
      case "PAUSE":
        return { ...state, run: false };
      case "NEXT_OR_PREV":
        return { ...state, ...action.payload };
      case "RESTART":
        return {
          ...state,
          stepIndex: 0,
          run: true,
          loading: false,
          key: new Date(),
        };
      default:
        return state;
    }
  };
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
  /*   useEffect(() => {
    if (!firstRender.current) {
      dispatch({ type: "START" });
    }
  }, [props.triggerTour]); */

  useEffect(() => {
    if (!firstRender.current) {
      if (props.startTour === 1) {
        dispatch({ type: "RESTART" });
      }
      if (props.startTour === 0) {
        dispatch({ type: "STOP" });
      }
    }
  }, [props.startTour]);

  useEffect(() => {
    if (!firstRender.current) {
      if (props.triggerStep === 0) {
        dispatch({ type: "RESTART" });
      } else {
        if (props.triggerStep === -3) {
          dispatch({ type: "STOP" });
        }
        if (props.triggerStep === -2) {
          dispatch({ type: "PAUSE" });
        } else {
          if (props.triggerStep !== -1) {
            if (tourState.run) {
              dispatch({
                type: "NEXT_OR_PREV",
                payload: { stepIndex: props.triggerStep },
              });
            } else {
              dispatch({
                type: "NEXT_OR_PREV",
                payload: { stepIndex: props.triggerStep, run: true },
              });
            }
          }
        }
      }
    } else {
      firstRender.current = false;
    }
  }, [props.triggerStep]);

  const callback = (data) => {
    const { action, index, type, status } = data;

    if (
      action === ACTIONS.CLOSE ||
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      dispatch({ type: "STOP" });
      props.setStartTour(0);
      axios.post(
        "/api/user/update-tour-info",
        { completed: "clientActivity" },
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      let run = true;

      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: stepIndex, run: true },
      });
    }
  };
  const startTour = () => {
    dispatch({ type: "RESTART" });
  };
  return (
    <>
      <JoyRide
        {...tourState}
        callback={callback}
        scrollOffset={120}
        showSkipButton={false}
        styles={{
          options: {
            zIndex: 2000,
            primaryColor: "#414141",
          },
          tooltipContainer: {
            textAlign: "left",
          },

          buttonBack: {
            marginRight: 10,
          },
        }}
        locale={{
          next: t("common:next"),
          back: t("common:back"),
          skip: t("common:skip"),
          last: t("common:endTour"),
        }}
      />
    </>
  );
};
export default Tour;
