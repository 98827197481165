export default {
  1: "Module 1",
  2: "Module 2",
  3: "Module 3",
  4: "Module 4",
  5: "Module 5",
  1.1: "Tool 1.1",
  1.2: "Tool 1.2",
  1.3: "Tool 1.3",
  2.1: "Tool 2.1",
  2.2: "Tool 2.2",
  2.3: "Tool 2.3",
  3.1: "Tool 3.1",
  3.2: "Tool 3.2",
  3.3: "Tool 3.3",
  3.4: "Tool 3.4",
  4.1: "Tool 4.1",
  4.2: "Tool 4.2",
  4.3: "Tool 4.3",
  4.4: "Tool 4.4",
  4.5: "Tool 4.5",
  5.1: "Tool 5.1",
  5.2: "Tool 5.2",
  5.3: "Tool 5.3",
};
