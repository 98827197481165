import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Typography, SvgIcon } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import UserDetails from "./User/UserDetails";

import RecentActorsIcon from "@material-ui/icons/RecentActors";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import EmailIcon from "@material-ui/icons/Email";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import GroupRoundedIcon from "@material-ui/icons/GroupRounded";
import { ReactComponent as Cog } from "assets/icons/cog.svg";
import { ReactComponent as Module } from "assets/icons/modicon.svg";
import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "variables/charts";

import styles from "assets/jss/elite-feats-cms/views/dashboardStyle.js";
import UserTable from "./UserTable";
import ClientsTable from "./ClientsTable";
import GroupsTable from "./GroupsTable";
import axios from "axios";
import PersonIcon from "@material-ui/icons/Person";
import ClipLoader from "react-spinners/FadeLoader";
import { css } from "@emotion/react";
import TextField from "@material-ui/core/TextField";
import { Box } from "@material-ui/core";
import { getCode, getName } from "country-list";

const useStyles = makeStyles(styles);
const TField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#1dc0d4",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#007e8d",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#1dc0d4",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1dc0d4",
      },
    },
  },
})(TextField);

const TField2 = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#63ac1d",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#3c9778",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#63ac1d",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#63ac1d",
      },
    },
  },
})(TextField);

const TField3 = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#ffa05a",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#ca5600",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#ffa05a",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#ffa05a",
      },
    },
  },
})(TextField);

export default function Dashboard() {
  const [users, setUsers] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  const [groups, setGroups] = React.useState([]);
  const [currentUser, setCurrentUser] = React.useState({});
  const [openUser, setOpenUser] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [userFilter, setUserFilter] = React.useState("");
  const [clientFilter, setClientFilter] = React.useState("");
  const [groupFilter, setGroupFilter] = React.useState("");
  const [reload, setReload] = React.useState(0);

  React.useEffect(() => {
    setLoading(true);
    axios
      .get("/api/user/admin-details", {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setLoading(false);
        setUsers(res.data.users);
        setClients(res.data.clients);
        setGroups(res.data.groups);
      });
  }, [reload]);
  const openUserDetails = (user) => {
    setCurrentUser(user);
    setOpenUser(true);
  };
  const ModuleIcon = () => (
    <SvgIcon style={{ fontSize: 36 }} component={Module} />
  );
  const ToolIcon = () => <SvgIcon style={{ fontSize: 36 }} component={Cog} />;
  const classes = useStyles();
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const filterUsers = (filter) => {
    let filtered = users.filter(
      (entry) =>
        entry.first_name.toLowerCase().includes(filter.toLowerCase()) ||
        entry.last_name.toLowerCase().includes(filter.toLowerCase()) ||
        entry.username.toLowerCase().includes(filter.toLowerCase())
    );

    return filtered;
  };

  const filterClients = (filter) => {
    let filtered = clients.filter(
      (entry) =>
        entry.first_name.toLowerCase().includes(filter.toLowerCase()) ||
        entry.last_name.toLowerCase().includes(filter.toLowerCase()) ||
        entry.email.toLowerCase().includes(filter.toLowerCase()) ||
        entry.user.toLowerCase().includes(filter.toLowerCase()) ||
        entry.groups.join(" ").toLowerCase().includes(filter.toLowerCase())
    );

    return filtered;
  };

  const filterGroups = (filter) => {
    let filtered = groups.filter(
      (entry) =>
        entry.name.toLowerCase().includes(filter.toLowerCase()) ||
        entry.user.toLowerCase().includes(filter.toLowerCase()) ||
        entry.description.toLowerCase().includes(filter.toLowerCase())
    );

    return filtered;
  };

  return (
    <div>
      <GridContainer style={{ justifyContent: "center" }}>
        <GridItem xs={12} sm={6} md lg>
          <Card>
            <CardHeader
              color='info'
              stats
              icon
              style={{
                display: "flex",
                justifyContent: "center",
                marginRight: 0,
              }}
            >
              <CardIcon color='info'>
                <PersonOutlineIcon />
              </CardIcon>
            </CardHeader>
            <Typography className={classes.adminTitle} style={{ fontSize: 22 }}>
              Users
            </Typography>{" "}
            {loading ? (
              <React.Fragment>
                <ClipLoader
                  color={"#398bd6"}
                  loading={loading}
                  css={override}
                  size={150}
                />{" "}
              </React.Fragment>
            ) : (
              <Typography
                className={classes.cardTitle}
                style={{ fontSize: 25, padding: 0, color: "#7c828b" }}
              >
                {users.length}
              </Typography>
            )}
            <CardFooter style={{ marginTop: 10 }} stats></CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md lg>
          <Card>
            <CardHeader
              color='success'
              stats
              icon
              style={{
                display: "flex",
                justifyContent: "center",
                marginRight: 0,
              }}
            >
              <CardIcon color='success'>
                <AccountCircleRoundedIcon />
              </CardIcon>
            </CardHeader>

            <Typography className={classes.adminTitle} style={{ fontSize: 22 }}>
              Clients
            </Typography>
            {loading ? (
              <React.Fragment>
                <ClipLoader
                  color={"#398bd6"}
                  loading={loading}
                  css={override}
                  size={150}
                />{" "}
              </React.Fragment>
            ) : (
              <Typography
                className={classes.cardTitle}
                style={{ fontSize: 25, padding: 0, color: "#7c828b" }}
              >
                {clients.length}
              </Typography>
            )}

            <CardFooter style={{ marginTop: 10 }} stats></CardFooter>
          </Card>
        </GridItem>{" "}
        <GridItem xs={12} sm={6} md lg>
          <Card>
            <CardHeader
              color='warning'
              stats
              icon
              style={{
                display: "flex",
                justifyContent: "center",
                marginRight: 0,
              }}
            >
              <CardIcon color='warning'>
                <GroupRoundedIcon />
              </CardIcon>
            </CardHeader>

            <Typography className={classes.adminTitle} style={{ fontSize: 22 }}>
              Groups
            </Typography>
            {loading ? (
              <React.Fragment>
                <ClipLoader
                  color={"#398bd6"}
                  loading={loading}
                  css={override}
                  size={150}
                />{" "}
              </React.Fragment>
            ) : (
              <Typography
                className={classes.cardTitle}
                style={{ fontSize: 25, padding: 0, color: "#7c828b" }}
              >
                {groups.length}
              </Typography>
            )}
            <CardFooter style={{ marginTop: 10 }} stats></CardFooter>
          </Card>
        </GridItem>{" "}
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color='info' style={{ padding: 0 }}>
              {" "}
              <h4
                style={{
                  color: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: 350,
                  fontSize: 21,
                }}
              >
                <PersonIcon style={{ marginRight: 10 }} />
                All Users
              </h4>
            </CardHeader>
            <CardBody
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                item
                xs={12}
                style={{
                  maxWidth: 500,
                  marginBottom: 10,
                  width: "100%",
                  paddingLeft: 20,
                }}
              >
                <TField
                  className='search'
                  variant='outlined'
                  margin='normal'
                  as={TField}
                  id='search_text'
                  label='Search'
                  onChange={(e) => {
                    setUserFilter(e.target.value);
                  }}
                  fullWidth
                />
              </div>
              <UserTable
                data={filterUsers(userFilter)}
                setUser={openUserDetails}
                setReload={setReload}
                loading={loading}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color='success' style={{ padding: 0 }}>
              {" "}
              <h4
                style={{
                  color: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: 350,
                  fontSize: 21,
                }}
              >
                <AccountCircleRoundedIcon style={{ marginRight: 10 }} />
                All Clients
              </h4>
            </CardHeader>
            <CardBody
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                item
                xs={12}
                style={{
                  maxWidth: 500,
                  marginBottom: 10,
                  width: "100%",
                  paddingLeft: 20,
                }}
              >
                <TField2
                  className='search'
                  variant='outlined'
                  margin='normal'
                  as={TField2}
                  id='search_text'
                  label='Search'
                  onChange={(e) => {
                    setClientFilter(e.target.value);
                  }}
                  fullWidth
                />
              </div>
              <ClientsTable data={filterClients(clientFilter)} />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color='warning' style={{ padding: 0 }}>
              {" "}
              <h4
                style={{
                  color: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: 350,
                  fontSize: 21,
                }}
              >
                <SupervisedUserCircleIcon style={{ marginRight: 10 }} />
                All Groups
              </h4>
            </CardHeader>
            <CardBody
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                item
                xs={12}
                style={{
                  maxWidth: 500,
                  marginBottom: 10,
                  width: "100%",
                  paddingLeft: 20,
                }}
              >
                <TField3
                  className='search'
                  variant='outlined'
                  margin='normal'
                  as={TField3}
                  id='search_text'
                  label='Search'
                  onChange={(e) => {
                    setGroupFilter(e.target.value);
                  }}
                  fullWidth
                />
              </div>
              <GroupsTable data={filterGroups(groupFilter)} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <UserDetails user={currentUser} open={openUser} setOpen={setOpenUser} />
    </div>
  );
}
