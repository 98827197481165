import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Divider from "@material-ui/core/Divider";
import MUIButton from "@material-ui/core/Button";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Box, IconButton } from "@material-ui/core";
import MUICard from "@material-ui/core/Card";
import MUICardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import MUITypography from "@material-ui/core/Typography";
// @material-ui/icons
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import UpdateIcon from "@material-ui/icons/Update";
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import ShoppingBasketRoundedIcon from "@material-ui/icons/ShoppingBasketRounded";
import Typo from "@material-ui/core/Typography";
import VisibilityIcon from "@material-ui/icons/Visibility";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import dayjs from "dayjs";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import Collapse from "@material-ui/core/Collapse";

import { ReactComponent as Tool1 } from "assets/icons/tool1.svg";
import { ReactComponent as Tool2 } from "assets/icons/tool2.svg";
import { ReactComponent as Tool3 } from "assets/icons/tool3.svg";
import { ReactComponent as Tool4 } from "assets/icons/tool4.svg";
import { ReactComponent as Tool5 } from "assets/icons/tool5.svg";
import { ReactComponent as Cog } from "assets/icons/cog.svg";
import { ReactComponent as Module } from "assets/icons/modicon.svg";

import { ReactComponent as Module1Icon } from "assets/icons/module1icon.svg";
import { ReactComponent as Module2Icon } from "assets/icons/module2icon.svg";
import { ReactComponent as Module3Icon } from "assets/icons/module3icon.svg";
import { ReactComponent as Module4Icon } from "assets/icons/module4icon.svg";
import { ReactComponent as Module5Icon } from "assets/icons/module5icon.svg";

import module1 from "assets/img/module1.png";
import module2 from "assets/img/module2.png";
import module3 from "assets/img/module3.png";
import module4 from "assets/img/module4.png";
import module5 from "assets/img/module5.png";

import allStyles from "assets/jss/elite-feats-cms/views/dashboardStyle.js";

import { withRouter } from "react-router-dom";

import { css } from "@emotion/react";
import ClipLoader from "react-spinners/FadeLoader";
import Typography from "components/Typography/Muted.js";
import AnimateHeight from "react-animate-height";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import PriorityHigh from "@material-ui/icons/PriorityHigh";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import AppContext from "../../AppContext";
import { useDebouncedCallback } from "use-debounce";
import SweetAlert from "react-bootstrap-sweetalert";
import alertstyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import axios from "axios";
import { useTranslation } from "react-i18next";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1440,
      xl: 1536,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },

  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

function ShopModules(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  let numbers = [];
  for (let i = 1; i < 51; i++) {
    numbers.push(i);
  }
  const cartContext = React.useContext(AppContext);
  const [mod1, setMod1] = React.useState(1);
  const [mod2, setMod2] = React.useState(1);
  const [mod3, setMod3] = React.useState(1);
  const [mod4, setMod4] = React.useState(1);
  const [mod5, setMod5] = React.useState(1);
  const [alert, setAlert] = React.useState(null);

  const handleChange1 = (event) => {
    setMod1(event.target.value);
  };
  const handleChange2 = (event) => {
    setMod2(event.target.value);
  };
  const handleChange3 = (event) => {
    setMod3(event.target.value);
  };
  const handleChange4 = (event) => {
    setMod4(event.target.value);
  };
  const handleChange5 = (event) => {
    setMod5(event.target.value);
  };

  const updateCart = (id, quantity) => {
    let currentCart = JSON.parse(localStorage.getItem("cart"));
    currentCart[id] += quantity;
    localStorage.setItem("cart", JSON.stringify(currentCart));
    cartContext.setCart(currentCart);
    props.setCartValues([id, quantity]);
    props.setOpen(true);
  };

  return (
    <div>
      {alert}
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={4} style={{ padding: 10 }}>
            <Card product className={classes.cardHover}>
              <CardHeader image className={classes.cardHeaderHover}>
                <img
                  src={module1}
                  style={{ minHeight: 215 }}
                  alt={t("shop:module1Alt")}
                />
              </CardHeader>
              <CardBody>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background: "#bb3737",
                    justifyContent: "center",
                    borderRadius: 50,
                    padding: 5,
                  }}
                >
                  <SvgIcon
                    component={Module1Icon}
                    style={{ fontSize: 32, marginRight: 10, color: "#fff" }}
                  />{" "}
                  <span
                    style={{ fontSize: 20, fontWeight: "bold", color: "#fff" }}
                  >
                    {t("shop:module1")}
                  </span>
                </Box>
                <MUITypography
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    marginBottom: 10,
                    marginTop: 10,
                    minHeight: 54,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {t("shop:module1Title")}
                </MUITypography>

                <MUITypography
                  align="justify"
                  style={{
                    fontWeight: 300,
                    fontSize: 16,
                    height: 170,
                    overflow: "auto",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  {t("shop:module1Description")}
                </MUITypography>
                <Grid container>
                  <Grid item xs={12} md={12} lg={6}>
                    <MUIButton
                      variant="outlined"
                      style={{
                        color: "#c9423a",
                        marginTop: 10,
                        borderColor: "#c9423a",
                      }}
                      onClick={() =>
                        window.open(
                          `https://peek.elitefeats.com.au/module-one`,
                          "_blank"
                        )
                      }
                      size="small"
                      endIcon={<VisibilityIcon />}
                    >
                      {t("shop:sneakPeek")}
                    </MUIButton>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    <MUITypography
                      align="right"
                      style={{
                        fontSize: 17,
                        fontWeight: "bold",
                        marginTop: 15,
                      }}
                    >
                      <span
                        style={{
                          border: "1px solid #d1d1d1",
                          padding: 5,
                          paddingRight: 10,
                          paddingLeft: 10,
                          borderRadius: 5,
                        }}
                      >
                        {t("shop:module1Price")}
                      </span>
                    </MUITypography>
                  </Grid>
                </Grid>
              </CardBody>
              <CardFooter product>
                <FormControl className={classes.formControl}>
                  <Select
                    id="outlined-select"
                    select
                    value={mod1}
                    onChange={handleChange1}
                    SelectProps={{ display: "flex" }}
                    variant="outlined"
                  >
                    {numbers.map((number) => (
                      <MenuItem key={number} value={number}>
                        {number}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className={`${classes.stats} ${classes.productStats}`}>
                  <Button
                    color="info"
                    className={classes.marginRight}
                    onClick={() => {
                      updateCart("module1", mod1);
                    }}
                  >
                    <AddShoppingCartIcon className={classes.icons} />
                    {t("shop:addToCart", { count: mod1 })}
                  </Button>
                </div>
                {cartContext.cart["module1"] ? (
                  <div>
                    <MUITypography>
                      <span
                        style={{
                          background: "#bb3737",
                          color: "#FFF",
                          padding: 3,
                          paddingLeft: 10,
                          paddingRight: 10,
                          borderRadius: 50,
                          fontSize: 16,
                        }}
                      >
                        {t("shop:inCart", {
                          count: cartContext.cart["module1"],
                        })}
                      </span>
                    </MUITypography>
                  </div>
                ) : null}
              </CardFooter>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4} style={{ padding: 10 }}>
            <Card product className={classes.cardHover}>
              <CardHeader image className={classes.cardHeaderHover}>
                <img
                  src={module2}
                  style={{ minHeight: 215 }}
                  alt={t("shop:module2Alt")}
                />
              </CardHeader>
              <CardBody>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background: "#e7842c",
                    justifyContent: "center",
                    borderRadius: 50,
                    padding: 5,
                  }}
                >
                  <SvgIcon
                    component={Module2Icon}
                    style={{ fontSize: 32, marginRight: 10, color: "#fff" }}
                  />{" "}
                  <span
                    style={{ fontSize: 20, fontWeight: "bold", color: "#fff" }}
                  >
                    {t("shop:module2")}
                  </span>
                </Box>
                <MUITypography
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    marginBottom: 10,
                    marginTop: 10,
                    minHeight: 54,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {t("shop:module2Title")}
                </MUITypography>
                <MUITypography
                  align="justify"
                  style={{
                    fontWeight: 300,
                    fontSize: 16,
                    height: 170,
                    overflow: "auto",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  {t("shop:module2Description")}
                </MUITypography>
                <Grid container>
                  <Grid item xs={12} md={12} lg={6}>
                    <MUIButton
                      variant="outlined"
                      style={{
                        color: "#e7842c",
                        marginTop: 10,
                        borderColor: "#e7842c",
                      }}
                      onClick={() =>
                        window.open(
                          `https://peek.elitefeats.com.au/module-two`,
                          "_blank"
                        )
                      }
                      size="small"
                      endIcon={<VisibilityIcon />}
                    >
                      {t("shop:sneakPeek")}
                    </MUIButton>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    <MUITypography
                      align="right"
                      style={{
                        fontSize: 17,
                        fontWeight: "bold",
                        marginTop: 15,
                      }}
                    >
                      <span
                        style={{
                          border: "1px solid #d1d1d1",
                          padding: 5,
                          paddingRight: 10,
                          paddingLeft: 10,
                          borderRadius: 5,
                        }}
                      >
                        {t("shop:module2Price")}
                      </span>
                    </MUITypography>
                  </Grid>
                </Grid>
              </CardBody>
              <CardFooter product>
                <FormControl className={classes.formControl}>
                  <Select
                    id="outlined-select"
                    select
                    value={mod2}
                    onChange={handleChange2}
                    SelectProps={{ display: "flex" }}
                    variant="outlined"
                  >
                    {numbers.map((number) => (
                      <MenuItem key={number} value={number}>
                        {number}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className={`${classes.stats} ${classes.productStats}`}>
                  <Button
                    color="info"
                    className={classes.marginRight}
                    onClick={() => {
                      updateCart("module2", mod2);
                    }}
                  >
                    <AddShoppingCartIcon className={classes.icons} />{" "}
                    {t("shop:addToCart", { count: mod2 })}
                  </Button>
                </div>
                {cartContext.cart["module2"] ? (
                  <div>
                    <MUITypography>
                      <span
                        style={{
                          background: "#e7842c",
                          color: "#FFF",
                          padding: 3,
                          paddingLeft: 10,
                          paddingRight: 10,
                          borderRadius: 50,
                          fontSize: 16,
                        }}
                      >
                        {t("shop:inCart", {
                          count: cartContext.cart["module2"],
                        })}
                      </span>
                    </MUITypography>
                  </div>
                ) : null}
              </CardFooter>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} style={{ padding: 10 }}>
            <Card product className={classes.cardHover}>
              <CardHeader image className={classes.cardHeaderHover}>
                <img
                  src={module3}
                  style={{ minHeight: 215 }}
                  alt={t("shop:module3Alt")}
                />
              </CardHeader>
              <CardBody>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background: "#147385",
                    justifyContent: "center",
                    borderRadius: 50,
                    padding: 5,
                  }}
                >
                  <SvgIcon
                    component={Module3Icon}
                    style={{ fontSize: 32, marginRight: 10, color: "#fff" }}
                  />{" "}
                  <span
                    style={{ fontSize: 20, fontWeight: "bold", color: "#fff" }}
                  >
                    {t("shop:module3")}
                  </span>
                </Box>
                <MUITypography
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    marginBottom: 10,
                    marginTop: 10,
                    minHeight: 54,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {t("shop:module3Title")}
                </MUITypography>
                <MUITypography
                  align="justify"
                  style={{
                    fontWeight: 300,
                    fontSize: 16,
                    height: 170,
                    overflow: "auto",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  {t("shop:module3Description")}
                </MUITypography>
                <Grid container>
                  <Grid item xs={12} md={12} lg={6}>
                    <MUIButton
                      variant="outlined"
                      style={{
                        color: "#147385",
                        marginTop: 10,
                        borderColor: "#147385",
                      }}
                      onClick={() =>
                        window.open(
                          `https://peek.elitefeats.com.au/module-three`,
                          "_blank"
                        )
                      }
                      size="small"
                      endIcon={<VisibilityIcon />}
                    >
                      {t("shop:sneakPeek")}
                    </MUIButton>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    <MUITypography
                      align="right"
                      style={{
                        fontSize: 17,
                        fontWeight: "bold",
                        marginTop: 15,
                      }}
                    >
                      <span
                        style={{
                          border: "1px solid #d1d1d1",
                          padding: 5,
                          paddingRight: 10,
                          paddingLeft: 10,
                          borderRadius: 5,
                        }}
                      >
                        {t("shop:module3Price")}
                      </span>
                    </MUITypography>
                  </Grid>
                </Grid>
              </CardBody>
              <CardFooter product>
                <FormControl className={classes.formControl}>
                  <Select
                    id="outlined-select"
                    select
                    value={mod3}
                    onChange={handleChange3}
                    SelectProps={{ display: "flex" }}
                    variant="outlined"
                  >
                    {numbers.map((number) => (
                      <MenuItem key={number} value={number}>
                        {number}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className={`${classes.stats} ${classes.productStats}`}>
                  <Button
                    color="info"
                    className={classes.marginRight}
                    onClick={() => {
                      updateCart("module3", mod3);
                    }}
                  >
                    <AddShoppingCartIcon className={classes.icons} />{" "}
                    {t("shop:addToCart", { count: mod3 })}
                  </Button>
                </div>
                {cartContext.cart["module3"] ? (
                  <div>
                    <MUITypography>
                      <span
                        style={{
                          background: "#147385",
                          color: "#FFF",
                          padding: 3,
                          paddingLeft: 10,
                          paddingRight: 10,
                          borderRadius: 50,
                          fontSize: 16,
                        }}
                      >
                        {t("shop:inCart", {
                          count: cartContext.cart["module3"],
                        })}
                      </span>
                    </MUITypography>
                  </div>
                ) : null}
              </CardFooter>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} style={{ padding: 10 }}>
            <Card product className={classes.cardHover}>
              <CardHeader image className={classes.cardHeaderHover}>
                <img
                  src={module4}
                  style={{ minHeight: 215 }}
                  alt={t("shop:module4Alt")}
                />
              </CardHeader>
              <CardBody>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background: "#0b7852",
                    justifyContent: "center",
                    borderRadius: 50,
                    padding: 5,
                  }}
                >
                  <SvgIcon
                    component={Module4Icon}
                    style={{ fontSize: 32, marginRight: 10, color: "#fff" }}
                  />{" "}
                  <span
                    style={{ fontSize: 20, fontWeight: "bold", color: "#fff" }}
                  >
                    {t("shop:module4")}
                  </span>
                </Box>
                <MUITypography
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    marginBottom: 10,
                    marginTop: 10,
                    minHeight: 54,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {t("shop:module4Title")}
                </MUITypography>
                <MUITypography
                  align="justify"
                  style={{
                    fontWeight: 300,
                    fontSize: 16,
                    height: 170,
                    overflow: "auto",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  {t("shop:module4Description")}
                </MUITypography>
                <Grid container>
                  <Grid item xs={12} md={12} lg={6}>
                    <MUIButton
                      variant="outlined"
                      style={{
                        color: "#0b7852",
                        marginTop: 10,
                        borderColor: "#0b7852",
                      }}
                      onClick={() =>
                        window.open(
                          `https://peek.elitefeats.com.au/module-four`,
                          "_blank"
                        )
                      }
                      size="small"
                      endIcon={<VisibilityIcon />}
                    >
                      {t("shop:sneakPeek")}
                    </MUIButton>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    <MUITypography
                      align="right"
                      style={{
                        fontSize: 17,
                        fontWeight: "bold",
                        marginTop: 15,
                      }}
                    >
                      <span
                        style={{
                          border: "1px solid #d1d1d1",
                          padding: 5,
                          paddingRight: 10,
                          paddingLeft: 10,
                          borderRadius: 5,
                        }}
                      >
                        {t("shop:module4Price")}
                      </span>
                    </MUITypography>
                  </Grid>
                </Grid>
              </CardBody>
              <CardFooter product>
                <FormControl className={classes.formControl}>
                  <Select
                    id="outlined-select"
                    select
                    value={mod4}
                    onChange={handleChange4}
                    SelectProps={{ display: "flex" }}
                    variant="outlined"
                  >
                    {numbers.map((number) => (
                      <MenuItem key={number} value={number}>
                        {number}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className={`${classes.stats} ${classes.productStats}`}>
                  <Button
                    color="info"
                    className={classes.marginRight}
                    onClick={() => {
                      updateCart("module4", mod4);
                    }}
                  >
                    <AddShoppingCartIcon className={classes.icons} />
                    {t("shop:addToCart", { count: mod4 })}
                  </Button>
                </div>
                {cartContext.cart["module4"] ? (
                  <div>
                    <MUITypography>
                      <span
                        style={{
                          background: "#0b7852",
                          color: "#FFF",
                          padding: 3,
                          paddingLeft: 10,
                          paddingRight: 10,
                          borderRadius: 50,
                          fontSize: 16,
                        }}
                      >
                        {t("shop:inCart", {
                          count: cartContext.cart["module4"],
                        })}
                      </span>
                    </MUITypography>
                  </div>
                ) : null}
              </CardFooter>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} style={{ padding: 10 }}>
            <Card product className={classes.cardHover}>
              <CardHeader image className={classes.cardHeaderHover}>
                <img
                  src={module5}
                  style={{ minHeight: 215 }}
                  alt={t("shop:module5Alt")}
                />
              </CardHeader>
              <CardBody>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background: "#794a80",
                    justifyContent: "center",
                    borderRadius: 50,
                    padding: 5,
                  }}
                >
                  <SvgIcon
                    component={Module5Icon}
                    style={{ fontSize: 32, marginRight: 10, color: "#fff" }}
                  />{" "}
                  <span
                    style={{ fontSize: 20, fontWeight: "bold", color: "#fff" }}
                  >
                    {t("shop:module5")}
                  </span>
                </Box>
                <MUITypography
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    marginBottom: 10,
                    marginTop: 10,
                    minHeight: 54,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {t("shop:module5Title")}
                </MUITypography>
                <MUITypography
                  align="justify"
                  style={{
                    fontWeight: 300,
                    fontSize: 16,
                    height: 170,
                    overflow: "auto",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  {t("shop:module5Description")}
                </MUITypography>
                <Grid container>
                  <Grid item xs={12} md={12} lg={6}>
                    <MUIButton
                      variant="outlined"
                      style={{
                        color: "#794a80",
                        marginTop: 10,
                        borderColor: "#794a80",
                      }}
                      onClick={() =>
                        window.open(
                          `https://peek.elitefeats.com.au/module-five`,
                          "_blank"
                        )
                      }
                      size="small"
                      endIcon={<VisibilityIcon />}
                    >
                      {t("shop:sneakPeek")}
                    </MUIButton>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    <MUITypography
                      align="right"
                      style={{
                        fontSize: 17,
                        fontWeight: "bold",
                        marginTop: 15,
                      }}
                    >
                      <span
                        style={{
                          border: "1px solid #d1d1d1",
                          padding: 5,
                          paddingRight: 10,
                          paddingLeft: 10,
                          borderRadius: 5,
                        }}
                      >
                        {t("shop:module5Price")}
                      </span>
                    </MUITypography>
                  </Grid>
                </Grid>
              </CardBody>
              <CardFooter product>
                <FormControl className={classes.formControl}>
                  <Select
                    id="outlined-select"
                    select
                    value={mod5}
                    onChange={handleChange5}
                    SelectProps={{ display: "flex" }}
                    variant="outlined"
                  >
                    {numbers.map((number) => (
                      <MenuItem key={number} value={number}>
                        {number}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className={`${classes.stats} ${classes.productStats}`}>
                  <Button
                    color="info"
                    className={classes.marginRight}
                    onClick={() => {
                      updateCart("module5", mod5);
                    }}
                  >
                    <AddShoppingCartIcon className={classes.icons} />
                    {t("shop:addToCart", { count: mod5 })}
                  </Button>
                </div>
                {cartContext.cart["module5"] ? (
                  <div>
                    <MUITypography>
                      <span
                        style={{
                          background: "#794a80",
                          color: "#FFF",
                          padding: 3,
                          paddingLeft: 10,
                          paddingRight: 10,
                          borderRadius: 50,
                          fontSize: 16,
                        }}
                      >
                        {t("shop:inCart", {
                          count: cartContext.cart["module5"],
                        })}
                      </span>
                    </MUITypography>
                  </div>
                ) : null}
              </CardFooter>
            </Card>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
}

export default withRouter(ShopModules);
