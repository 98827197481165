import React from "react";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Card as MUICard } from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Schedule from "@material-ui/icons/Schedule";
import Info from "@material-ui/icons/Info";
import LocationOn from "@material-ui/icons/LocationOn";
import Gavel from "@material-ui/icons/Gavel";
import HelpOutline from "@material-ui/icons/HelpOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPillDef.js";
import Accordion from "components/Accordion/Accordion.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import Pagination from "@material-ui/lab/Pagination";
import ClientTimeline from "views/Activity/Timelines/ClientTimeline.js";

import { cardTitle } from "assets/jss/elite-feats-cms.js";
import { Divider, Typography, Tooltip } from "@material-ui/core";

import axios from "axios";
import dayjs from "dayjs";

import buttonStyles from "assets/jss/elite-feats-cms/views/buttonsStyle.js";
import empty from "assets/img/empty-folder.svg";
import historyImg from "assets/img/history.svg";
import purchase from "assets/img/price-tag.svg";
import {
  ControlPointDuplicateRounded,
  LocalActivityOutlined,
  Shop,
} from "@material-ui/icons";
import SweetAlert from "react-bootstrap-sweetalert";
import alertStyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";
import sweetAlertStyle from "assets/jss/elite-feats-cms/views/sweetAlertStyle";
import TextField from "@material-ui/core/TextField";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import DateRangeIcon from "@material-ui/icons/DateRange";

import module1 from "assets/img/module1.png";
import module2 from "assets/img/module2.png";
import module3 from "assets/img/module3.png";
import module4 from "assets/img/module4.png";
import module5 from "assets/img/module5.png";
import PriorityHigh from "@material-ui/icons/PriorityHigh";
import priceImage2 from "assets/img/card-3.jpeg";
import priceImage3 from "assets/img/card-1.jpeg";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";
import CardFooter from "components/Card/CardFooter.js";
import ClipLoader from "react-spinners/PuffLoader";
import { css } from "@emotion/react";
import ShopModules from "./Modules";
import ShopTools from "./Tools";

import { ReactComponent as Cog } from "assets/icons/cog.svg";
import { ReactComponent as Module } from "assets/icons/modicon.svg";
import { ReactComponent as Bulk } from "assets/icons/bulk.svg";
import AppContext from "../../AppContext";
import CartLoading from "./CartLoading";
import Cart from "./Cart";
import { useTranslation } from "react-i18next";

const styles = {
  ...sweetAlertStyle,
  ...buttonStyles,
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
};

const useStyles = makeStyles(styles);

export default function Panels() {
  const { t } = useTranslation();
  const classes = useStyles();
  const ModuleIcon = () => (
    <SvgIcon style={{ fontSize: 36 }} component={Module} />
  );
  const ToolIcon = () => <SvgIcon style={{ fontSize: 36 }} component={Cog} />;
  const BulkIcon = () => <SvgIcon style={{ fontSize: 36 }} component={Bulk} />;
  const appContext = React.useContext(AppContext);
  /* const [active, setActive] = React.useState(0); */

  const [openLoad, setOpenLoad] = React.useState(false);
  const [openCart, setOpenCart] = React.useState(false);
  const [cartValues, setCartValues] = React.useState([0, 0]);
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(null);

  React.useEffect(() => {
    if (open) {
      addedToCartMessage(cartValues[0], cartValues[1]);
    }
  }, [open]);

  const addedToCartMessage = (id, quantity) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("shop:cart.itemsAddedToCart")}
        onConfirm={() => {
          setAlert(null);
          setOpen(false);
          setOpenCart(true);
        }}
        onCancel={() => {
          setOpen(false);
          setAlert(null);
        }}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " "}
        confirmBtnText={t("shop:cart.checkout")}
        cancelBtnText={t("shop:cart.keepShopping")}
        showCancel
      >
        {quantity > 1
          ? t("shop:cart.multipleUnitsAdded", {
              count: quantity,
              item: t(`assignments:assessmentTitles.${id}`),
            })
          : t("shop:cart.singleUnitAdded", {
              item: t(`assignments:assessmentTitles.${id}`),
            })}
      </SweetAlert>
    );
  };

  return (
    <div>
      <GridContainer
        justify="center"
        style={{ marginTop: -10, paddingLeft: 10, paddingRight: 10 }}
      >
        <Grid item xs={12} sm={12} md={12}>
          <NavPills
            color="success"
            active={appContext.active}
            alignCenter
            tabs={[
              {
                tabButton: t("shop:navPills.modules"),
                tabIcon: ModuleIcon,
                tabContent: (
                  <ShopModules
                    setCartValues={setCartValues}
                    setOpen={setOpen}
                  />
                ),
              },
              {
                tabButton: t("shop:navPills.tools"),
                tabIcon: ToolIcon,
                tabContent: (
                  <ShopTools setCartValues={setCartValues} setOpen={setOpen} />
                ),
              },
            ]}
          />
        </Grid>
      </GridContainer>
      <Cart open={openCart} setOpen={setOpenCart} />
      {alert}
    </div>
  );
}
