import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";

import PersonAddIcon from "@material-ui/icons/PersonAdd";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ClientsReactTable from "../Clients/ClientsReactTable.js";
import Checkbox from "@material-ui/core/Checkbox";
import allStyles from "assets/jss/elite-feats-cms/views/extendedTablesStyle.js";
import Check from "@material-ui/icons/Check";
import { dataTable } from "variables/general.js";
import Grow from "@material-ui/core/Grow";
import Modal from "../../components/Modal/Modal";
import SweetAlert from "react-bootstrap-sweetalert";
import alertStyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";
import { cardTitle } from "assets/jss/elite-feats-cms.js";
import Tooltip from "@material-ui/core/Tooltip";
import SvgIcon from "@material-ui/core/SvgIcon";

import MUICard from "@material-ui/core/Card";
import MUICardHeader from "@material-ui/core/CardHeader";
import MUITypography from "@material-ui/core/Typography";
import MUIButton from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import assessmentTitles from "../Dashboard/assessmentTitles";
import AssignLoading from "../Dashboard/AssignLoading";
import identifiers from "../Dashboard/identifiers";
import { useDebouncedCallback } from "use-debounce";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PlayForWorkIcon from "@material-ui/icons/PlayForWork";
import { ReactComponent as question } from "assets/icons/question.svg";

import EmailIcon from "@material-ui/icons/Email";
import EditModal from "../Clients/Modals/EditModal";
import AllAssigned from "../Clients/Modals/AllAssigned";
import DeleteLoading from "../Clients/Modals/DeleteLoading";
import FilterGroups from "./Modals/FilterGroups";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import Tour from "./ClientsIntro";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";

import FaceIcon from "@material-ui/icons/Face";
import DoneIcon from "@material-ui/icons/Done";
import empty from "assets/img/empty-clients.svg";

import NestedMenuItem from "material-ui-nested-menu-item";
import AssignAssessments from "../Dashboard/AssignAssessments";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";

const styles = {
  ...allStyles,
  ...alertStyles,

  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);

export default function ClientsTable(props) {
  const { t } = useTranslation();
  let selected = JSON.parse(localStorage.getItem("selected_clients"));

  /* states for client table */
  const [open, setOpen] = React.useState(false);

  const [modal, openModal] = React.useState(false);
  const [triggerTour, setTriggerTour] = React.useState(0);
  const [alert, setAlert] = React.useState(null);
  const [tourInit, setTourInit] = React.useState(0);

  const Question = () => (
    <SvgIcon component={question} style={{ fontSize: 22, padding: 3 }} />
  );

  const order = JSON.parse(localStorage.getItem("order"));
  const clientActions = React.useRef(null);
  const [data, setData] = React.useState(
    props.clients.map((prop, key) => {
      return {
        id: key,

        first: prop.first_name,
        last: prop.last_name,
        email: prop.email,
        group: prop.groups.length < 1 ? " - " : prop.groups.join(", "),
        actions: (
          <GridContainer style={{ display: "flex", justifyContent: "center" }}>
            {/* Tooltip for editing details */}
            <Tooltip title={t("dashboard:editDetails")} placement="top">
              <div>
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    let obj = data.find((o) => o.id === key);
                    props.setEdit(obj);
                    props.setOpenEdit(true);
                  }}
                  color="info"
                  className="like"
                >
                  <EditIcon />
                </Button>
              </div>
            </Tooltip>
            {/* Tooltip for assessments */}
            <Tooltip title={t("dashboard:assessments")} placement="top">
              <div>
                <Button justIcon round simple color="warning" className="edit">
                  <EmailIcon />
                </Button>
              </div>
            </Tooltip>
            {/* Tooltip for sending email */}
            <Tooltip title={t("dashboard:sendEmail")} placement="top">
              <div>
                <Button justIcon round simple color="danger" className="remove">
                  <EmailIcon />
                </Button>
              </div>
            </Tooltip>
          </GridContainer>
        ),
      };
    })
  );
  const [tourRun, setTourRun] = React.useState(false);
  const [triggerStep, setTriggerStep] = React.useState(-1);
  const [clientTour, setClientTour] = React.useState(Math.random());

  const [openFilters, setOpenFilters] = React.useState(null);
  const [filterGroups, setFilterGroups] = React.useState([]);
  const [completed, setCompleted] = React.useState("");
  const [pending, setPending] = React.useState("");
  const [notAccessed, setNotAccessed] = React.useState("");
  const [activity, setActivity] = React.useState(0);
  const [openFilterGroups, setOpenFilterGroups] = React.useState(false);

  const handleFiltersClick = (event) => {
    setOpenFilters(event.currentTarget);
  };

  const handleFiltersClose = () => {
    setOpenFilters(null);
  };

  const hideAlert = (loc) => {
    if (loc === "deleted") {
      props.setReloadTable(Math.random());
      props.setGroupsAdded(Math.random());
      setAlert(null);
    }
    if (loc === "deleteCancel") {
      if (tourRun) {
        setTourRun(false);
        setTriggerStep(-2);
      }
    }

    setAlert(null);
  };

  const deleteAlert = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("dashboard:alerts.areYouSure")}
        onConfirm={() => handleDelete()}
        onCancel={() => hideAlert("deleteCancel")}
        confirmBtnCssClass={classes.button + " " + classes.danger}
        cancelBtnCssClass={
          classes.button + " " + classes.default + "custom-clasname"
        }
        confirmBtnText={t("dashboard:alerts.delete")}
        cancelBtnText={t("dashboard:alerts.cancel")}
        showCancel
      >
        {t("dashboard:alerts.deleteWarning")}
      </SweetAlert>
    );
  };
  const successDelete = () => {
    if (!tourRun) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("dashboard:alerts.deleted")}
          onConfirm={() => hideAlert("deleted")}
          onCancel={() => hideAlert("deleted")}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          {t("dashboard:alerts.clientDeleted")}
        </SweetAlert>
      );
    }
  };

  const handleDelete = () => {
    setTriggerStep(-2);

    setAlert(null);
    setOpen(true);
    let clients = JSON.parse(localStorage.getItem("selected_clients"));
    axios
      .post("/api/client/delete-clients", clients, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setOpen(false);
        if (res.data === "deleted") {
          localStorage.setItem("selected_clients", JSON.stringify([]));
          setAlert(successDelete);
          props.setReloadData(Math.random());
          if (tourRun) {
            props.setReloadTable(Math.random());
            props.setGroupsAdded(Math.random());
            setTimeout(() => {
              setTriggerStep(21);
            }, 700);
          }
        }
      });
  };

  const firstRender = React.useRef(true);

  //Useeffect to trigger tutorial step if client checkbox is selected from dashboard
  React.useEffect(() => {
    if (!firstRender.current) {
      if (tourRun) {
        setTriggerStep(-2);
        setTimeout(() => {
          setTriggerStep(13);
        }, 500);
      }
    } else {
      firstRender.current = false;
    }
  }, [props.tourSelect]);

  const applyFilter = (clients) => {
    let filteredClients = [];

    filteredClients = clients.filter((client) => {
      if (filterGroups.length) {
        if (client.group !== " - ") {
          return client.group.some((r) => filterGroups.includes(r));
        }
      } else {
        return true;
      }
    });

    filteredClients = filteredClients.filter((client) => {
      if (completed.length) {
        let latest_entry = client.full[`${completed}`].length;

        if (latest_entry) {
          return client.full[`${completed}`][latest_entry - 1].completed;
        }
      } else {
        return true;
      }
    });

    filteredClients = filteredClients.filter((client) => {
      if (pending.length) {
        let latest_entry = client.full[`${pending}`].length;

        if (latest_entry) {
          return !client.full[`${pending}`][latest_entry - 1].completed;
        }
      } else {
        return true;
      }
    });
    filteredClients = filteredClients.filter((client) => {
      if (notAccessed) {
        return !client.full.lms_accessed;
      } else {
        return true;
      }
    });

    filteredClients = filteredClients.filter((client) => {
      if (activity === 7 || activity === 30) {
        for (let title in assessmentTitles) {
          let assessment_set = client.full[title];
          let today = new Date();
          let range = new Date(
            today.getTime() - activity * 24 * 60 * 60 * 1000
          );
          for (let assessment of assessment_set) {
            let completed_date = new Date(assessment.completed_date);
            if (completed_date >= range && completed_date <= today) {
              return true;
            }
          }
        }
      } else {
        return true;
      }
    });

    return filteredClients;
  };
  const classes = useStyles();
  return (
    <div
      ref={props.clientTable}
      style={{
        width: "100%",
      }}
    >
      <Tour
        triggerStep={triggerStep}
        setTourRun={setTourRun}
        setTriggerStep={setTriggerStep}
        setClientTour={setClientTour}
      />
      <Card className={"clients-table"}>
        <CardHeader color="success" style={{ padding: 0 }}>
          <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <h4
                style={{
                  color: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: 350,
                  fontSize: 21,
                }}
              >
                <AccountCircleRoundedIcon style={{ marginRight: 10 }} />
                {t("clients:clientTable")}
              </h4>
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Tooltip title={t("clients:takeGuidedTour")} placement="top">
                <div>
                  <IconButton
                    className="clients-tutorial"
                    style={{ border: "1px solid #2a642d" }}
                    size="small"
                    onClick={() => {
                      setTriggerStep(0);
                      setTourRun(true);
                      setClientTour(-5);
                    }}
                  >
                    <Question style={{ color: "#3d8641" }} />
                  </IconButton>
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </CardHeader>

        <CardBody>
          <Grid container>
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 10,
              }}
            >
              <Button color="tumblr" onClick={handleFiltersClick}>
                <PlayForWorkIcon /> {t("clients:filters.advancedFilters")}
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={openFilters}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                open={Boolean(openFilters)}
                onClose={handleFiltersClose}
              >
                <MenuItem
                  onClick={() => {
                    setOpenFilterGroups(true);
                  }}
                >
                  {t("clients:filters.byGroup")}
                </MenuItem>
                <NestedMenuItem
                  label={t("clients:filters.byActivity")}
                  parentMenuOpen={!!openFilters}
                >
                  <NestedMenuItem
                    label={t("clients:filters.completedAssessments")}
                    parentMenuOpen={!!openFilters}
                  >
                    <MenuItem
                      onClick={() => {
                        setActivity(7);
                        handleFiltersClose();
                      }}
                    >
                      {t("clients:filters.last7Days")}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setActivity(30);
                        handleFiltersClose();
                      }}
                    >
                      {t("clients:filters.last30Days")}
                    </MenuItem>
                  </NestedMenuItem>
                  <MenuItem
                    onClick={() => {
                      setNotAccessed(true);
                      handleFiltersClose();
                    }}
                  >
                    {t("clients:filters.lmsNotAccessed")}
                  </MenuItem>
                </NestedMenuItem>
                <NestedMenuItem
                  label={t("clients:filters.byAssessments")}
                  parentMenuOpen={!!openFilters}
                >
                  <NestedMenuItem
                    label={t("clients:filters.completed")}
                    parentMenuOpen={!!openFilters}
                  >
                    <NestedMenuItem
                      label={t("clients:filters.modules")}
                      parentMenuOpen={!!openFilters}
                    >
                      <MenuItem
                        onClick={() => {
                          setCompleted("module1");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.module1")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setCompleted("module2");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.module2")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setCompleted("module3");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.module3")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setCompleted("module4");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.module4")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setCompleted("module5");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.module5")}
                      </MenuItem>
                    </NestedMenuItem>
                    <NestedMenuItem
                      label={t("clients:filters.tools1")}
                      parentMenuOpen={!!openFilters}
                    >
                      <MenuItem
                        onClick={() => {
                          setCompleted("tool1_1");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool1_1")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setCompleted("tool1_2");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool1_2")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setCompleted("tool1_3");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool1_3")}
                      </MenuItem>
                    </NestedMenuItem>
                    <NestedMenuItem
                      label={t("clients:filters.tools2")}
                      parentMenuOpen={!!openFilters}
                    >
                      <MenuItem
                        onClick={() => {
                          setCompleted("tool2_1");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool2_1")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setCompleted("tool2_2");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool2_2")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setCompleted("tool2_3");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool2_3")}
                      </MenuItem>
                    </NestedMenuItem>
                    <NestedMenuItem
                      label={t("clients:filters.tools3")}
                      parentMenuOpen={!!openFilters}
                    >
                      <MenuItem
                        onClick={() => {
                          setCompleted("tool3_1");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool3_1")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setCompleted("tool3_2");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool3_2")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setCompleted("tool3_3");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool3_3")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setCompleted("tool3_4");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool3_4")}
                      </MenuItem>
                    </NestedMenuItem>
                    <NestedMenuItem
                      label={t("clients:filters.tools4")}
                      parentMenuOpen={!!openFilters}
                    >
                      <MenuItem
                        onClick={() => {
                          setCompleted("tool4_1");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool4_1")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setCompleted("tool4_2");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool4_2")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setCompleted("tool4_3");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool4_3")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setCompleted("tool4_4");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool4_4")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setCompleted("tool4_5");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool4_5")}
                      </MenuItem>
                    </NestedMenuItem>
                    <NestedMenuItem
                      label={t("clients:filters.tools5")}
                      parentMenuOpen={!!openFilters}
                    >
                      <MenuItem
                        onClick={() => {
                          setCompleted("tool5_1");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool5_1")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setCompleted("tool5_2");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool5_2")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setCompleted("tool5_3");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool5_3")}
                      </MenuItem>
                    </NestedMenuItem>
                  </NestedMenuItem>
                  <NestedMenuItem
                    label={t("clients:filters.pending")}
                    parentMenuOpen={!!openFilters}
                  >
                    <NestedMenuItem
                      label={t("clients:filters.modules")}
                      parentMenuOpen={!!openFilters}
                    >
                      <MenuItem
                        onClick={() => {
                          setPending("module1");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.module1")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setPending("module2");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.module2")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setPending("module3");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.module3")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setPending("module4");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.module4")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setPending("module5");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.module5")}
                      </MenuItem>
                    </NestedMenuItem>
                    <NestedMenuItem
                      label={t("clients:filters.tools1")}
                      parentMenuOpen={!!openFilters}
                    >
                      <MenuItem
                        onClick={() => {
                          setPending("tool1_1");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool1_1")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setPending("tool1_2");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool1_2")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setPending("tool1_3");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool1_3")}
                      </MenuItem>
                    </NestedMenuItem>
                    <NestedMenuItem
                      label={t("clients:filters.tools2")}
                      parentMenuOpen={!!openFilters}
                    >
                      <MenuItem
                        onClick={() => {
                          setPending("tool2_1");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool2_1")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setPending("tool2_2");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool2_2")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setPending("tool2_3");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool2_3")}
                      </MenuItem>
                    </NestedMenuItem>
                    <NestedMenuItem
                      label={t("clients:filters.tools3")}
                      parentMenuOpen={!!openFilters}
                    >
                      <MenuItem
                        onClick={() => {
                          setPending("tool3_1");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool3_1")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setPending("tool3_2");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool3_2")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setPending("tool3_3");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool3_3")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setPending("tool3_4");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool3_4")}
                      </MenuItem>
                    </NestedMenuItem>
                    <NestedMenuItem
                      label={t("clients:filters.tools4")}
                      parentMenuOpen={!!openFilters}
                    >
                      <MenuItem
                        onClick={() => {
                          setPending("tool4_1");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool4_1")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setPending("tool4_2");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool4_2")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setPending("tool4_3");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool4_3")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setPending("tool4_4");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool4_4")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setPending("tool4_5");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool4_5")}
                      </MenuItem>
                    </NestedMenuItem>
                    <NestedMenuItem
                      label={t("clients:filters.tools5")}
                      parentMenuOpen={!!openFilters}
                    >
                      <MenuItem
                        onClick={() => {
                          setPending("tool5_1");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool5_1")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setPending("tool5_2");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool5_2")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setPending("tool5_3");
                          handleFiltersClose();
                        }}
                      >
                        {t("clients:filters.tool5_3")}
                      </MenuItem>
                    </NestedMenuItem>
                  </NestedMenuItem>
                </NestedMenuItem>
              </Menu>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 10,
              }}
            >
              <Button
                className="add-client-button"
                color="info"
                onClick={() => {
                  openModal(true);
                  if (tourRun) {
                    setTriggerStep(-2);
                    setTimeout(() => {
                      setTriggerStep(2);
                    }, 500);
                  }
                }}
              >
                <PersonAddIcon /> {t("clients:addNewClient")}
              </Button>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 10,
              }}
            >
              <Button
                className="tour-client-groups-button"
                color="warning"
                onClick={() => {
                  props.setGrowClientsTable(false);
                  setTimeout(() => {
                    props.setReloadGroupsTable(Math.random());
                  }, 200);
                }}
              >
                <SupervisedUserCircleIcon /> {t("clients:groups")}
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            {filterGroups.length ||
            completed.length ||
            pending.length ||
            notAccessed ||
            activity === 7 ||
            activity === 30 ? (
              <Grid
                item
                xs={props.ready ? 4 : 12}
                style={{ paddingLeft: 15, paddingRight: 5, paddingBottom: 15 }}
              >
                <Card
                  plain
                  variant="outlined"
                  style={{
                    padding: 10,
                    marginTop: 5,
                    marginBottom: 10,
                    border: "1px dashed #cecece",
                    height: "100%",
                  }}
                >
                  <Typography align="center" style={{ fontWeight: "bold" }}>
                    {t("clients:activeFilters")}
                  </Typography>
                  <Grid
                    container
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {filterGroups.length ? (
                      <Grid item>
                        <Tooltip
                          title={
                            <Typography>
                              {t("clients:showingGroups") +
                                filterGroups.join(", ")}
                            </Typography>
                          }
                          placement="top"
                        >
                          <div>
                            <Chip
                              style={{ margin: 5 }}
                              label={t("clients:group")}
                              color="primary"
                              onDelete={() => {
                                setFilterGroups([]);
                              }}
                            />
                          </div>
                        </Tooltip>
                      </Grid>
                    ) : null}
                    {completed.length ? (
                      <Grid item>
                        <Tooltip
                          title={
                            <Typography>
                              {t("clients:completedClients") +
                                t(`clients:filters.${completed}`)}
                            </Typography>
                          }
                          placement="top"
                        >
                          <div>
                            <Chip
                              style={{ margin: 5 }}
                              label={
                                t("clients:completed") +
                                ": " +
                                t(`clients:filters.${completed}`)
                              }
                              color="primary"
                              onDelete={() => {
                                setCompleted("");
                              }}
                            />
                          </div>
                        </Tooltip>
                      </Grid>
                    ) : null}
                    {pending.length ? (
                      <Grid item>
                        <Tooltip
                          title={
                            <Typography>
                              {t("clients:pendingClients") +
                                t(`clients:filters.${pending}`)}
                            </Typography>
                          }
                          placement="top"
                        >
                          <div>
                            <Chip
                              style={{ margin: 5 }}
                              label={
                                t("clients:pending") +
                                ": " +
                                t(`clients:filters.${pending}`)
                              }
                              color="primary"
                              onDelete={() => {
                                setPending("");
                              }}
                            />
                          </div>
                        </Tooltip>
                      </Grid>
                    ) : null}
                    {notAccessed ? (
                      <Grid item>
                        <Tooltip
                          title={
                            <Typography>{t("clients:notAccessed")}</Typography>
                          }
                          placement="top"
                        >
                          <div>
                            <Chip
                              style={{ margin: 5 }}
                              label={t("clients:lmsNotAccessed")}
                              color="primary"
                              onDelete={() => {
                                setNotAccessed(false);
                              }}
                            />
                          </div>
                        </Tooltip>
                      </Grid>
                    ) : null}
                    {activity === 7 || activity === 30 ? (
                      <Grid item>
                        <Tooltip
                          title={
                            <Typography>
                              {t("clients:recentActivity", { days: activity })}
                            </Typography>
                          }
                          placement="top"
                        >
                          <div>
                            <Chip
                              style={{ margin: 5 }}
                              label={t("clients:lastDays", { days: activity })}
                              color="primary"
                              onDelete={() => {
                                setActivity(0);
                              }}
                            />
                          </div>
                        </Tooltip>
                      </Grid>
                    ) : null}
                  </Grid>
                </Card>
              </Grid>
            ) : null}

            <Grid
              item
              xs={
                filterGroups.length ||
                completed.length ||
                pending.length ||
                notAccessed ||
                activity === 7 ||
                activity === 30
                  ? 8
                  : 12
              }
              style={{ paddingLeft: 5, paddingRight: 15, paddingBottom: 15 }}
            >
              {props.ready ? (
                <Grow
                  in={props.grow}
                  {...(props.grow ? { timeout: 400 } : { timeout: 400 })}
                >
                  <div
                    ref={clientActions}
                    className="tour-client-actions"
                    style={{ height: "100%" }}
                  >
                    <Card
                      plain
                      style={{
                        marginTop: 5,
                        marginBottom: 10,
                        border: "1px dashed #cecece",
                        height: "100%",
                      }}
                    >
                      <CardBody style={{ padding: 10, height: "100%" }}>
                        <Typography
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          {`${selected.length}  ${t("clients:selected")}`}
                        </Typography>
                        <GridContainer style={{ marginTop: 5 }}>
                          <GridItem
                            xs={12}
                            md={4}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <MUIButton
                              className="tour-client-clear"
                              size="small"
                              style={{
                                fontSize: 12,
                                paddingLeft: 15,
                                paddingRight: 15,
                                minHeight: 0,
                                minWidth: 0,
                                height: 30,
                              }}
                              variant="outlined"
                              onClick={() => {
                                localStorage.setItem(
                                  "selected_clients",
                                  JSON.stringify([])
                                );
                                localStorage.setItem(
                                  "selected_groups",
                                  JSON.stringify([])
                                );
                                props.hideAssignments();
                                props.setReloadClients(Math.random());
                              }}
                            >
                              {t("clients:clearSelection")}
                            </MUIButton>
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={6}
                            md={4}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              className="tour-client-select-assessments"
                              color="success"
                              size="sm"
                              onClick={() => {
                                if (tourRun) {
                                  setTriggerStep(-2);
                                  setTimeout(() => {
                                    setTriggerStep(15);
                                  }, 600);
                                }
                                props.toggleScroll("assignmentTable");
                              }}
                            >
                              {t("clients:selectAssessments")}
                            </Button>
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={6}
                            md={4}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              className="tour-client-delete"
                              color="danger"
                              size="sm"
                              onClick={() => {
                                setAlert(deleteAlert);
                                if (tourRun) {
                                  setTriggerStep(-2);
                                  setTimeout(() => {
                                    setTriggerStep(20);
                                  }, 600);
                                }
                              }}
                            >
                              {t("clients:deleteClients")}
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </div>
                </Grow>
              ) : (
                false
              )}
            </Grid>
          </Grid>
          {!applyFilter(props.data).length ? (
            <MUICard
              variant="outlined"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                marginLeft: 10,
              }}
            >
              <img src={empty} style={{ height: 150, marginTop: 5 }} />
              {!props.data.length ? (
                <Typography
                  align="center"
                  style={{
                    fontSize: 18,
                    marginBottom: 10,
                    color: "#808080",
                    fontWeight: 200,
                    fontStyle: "italic",
                  }}
                >
                  {t("clients:emptyClientTableMessage")}
                </Typography>
              ) : (
                <Typography
                  align="center"
                  style={{
                    fontSize: 18,
                    marginBottom: 10,
                    color: "#808080",
                    fontWeight: 200,
                    fontStyle: "italic",
                  }}
                >
                  {t("clients:noClientsFoundMessage")}
                </Typography>
              )}
            </MUICard>
          ) : (
            <ClientsReactTable
              columns={[
                {
                  Header: t("clients:headerSelect"),
                  accessor: "select",
                },
                {
                  Header: t("clients:headerFirstName"),
                  accessor: "first",
                },
                {
                  Header: t("clients:headerLastName"),
                  accessor: "last",
                },
                {
                  Header: t("clients:headerEmail"),
                  accessor: "email",
                },
                {
                  Header: t("clients:headerGroups"),
                  accessor: "group",
                },
                {
                  Header: t("clients:headerActions"),
                  accessor: "actions",
                },
              ]}
              data={applyFilter(props.data)}
              reloadClients={props.reloadClients}
              clientTour={clientTour}
            />
          )}
        </CardBody>
      </Card>

      <Modal
        modal={modal}
        openModal={openModal}
        setReloadTable={props.setReloadTable}
        triggerStep={triggerStep}
        setTriggerStep={setTriggerStep}
        tourRun={tourRun}
      />
      <EditModal
        modal={props.openEdit}
        openModal={props.setOpenEdit}
        edit={props.edit}
        setReloadTable={props.setReloadTable}
      />
      <AllAssigned
        assignmentDetails={props.assignmentDetails}
        open={props.openAssigned}
        setOpen={props.setOpenAssigned}
      />
      <FilterGroups
        open={openFilterGroups}
        setOpen={setOpenFilterGroups}
        filterGroups={filterGroups}
        setFilterGroups={setFilterGroups}
        handleClose={handleFiltersClose}
      />
      <DeleteLoading open={open} setOpen={setOpen} />
      {alert}
    </div>
  );
}
