import React from "react";
// material-ui components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
// core components
import Button from "components/CustomButtons/Button.js";
import Checkbox from "@material-ui/core/Checkbox";
import allStyles from "assets/jss/elite-feats-cms/views/extendedTablesStyle.js";

import modalStyles from "assets/jss/elite-feats-cms/modalStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";

import * as Yup from "yup";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { withRouter } from "react-router-dom";
import { Divider, Typography, Card } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";

import { useDebouncedCallback } from "use-debounce";

import alertStyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";
import { ContactSupportOutlined } from "@material-ui/icons";
import ClipLoader from "react-spinners/FadeLoader";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

const styles = { ...modalStyles, ...alertStyles, ...allStyles };

const useStyles = makeStyles(styles);

const TField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#2b8cc6",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#3c9778",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#2b8cc6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#2b8cc6",
      },
    },
  },
})(TextField);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Modal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  const [allGroups, setAllGroups] = React.useState([]);
  const [filterGroups, setFilterGroups] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const handleDelete = (groupToRemove) => {
    let filter = filterGroups.slice(0);
    let groups = allGroups.slice(0);
    groups.push(groupToRemove);

    filter = filter.filter((group) => {
      return group !== groupToRemove;
    });

    setFilterGroups(filter);
    setAllGroups(groups);
  };

  const handleClick = (group) => {
    let filter = filterGroups.slice(0);

    filter.push(group);

    setFilterGroups(filter);
  };

  const deselectAll = () => {
    /*  for (let email in checked) {
      checked[email] = false;
    }
    setChecked(checked); */
  };

  React.useEffect(() => {
    if (props.open) {
      setLoading(true);
      axios
        .get("/api/client/get-groups", {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        })
        .then((res) => {
          let groups = [];
          res.data.forEach((group) => {
            groups.push(group.name);
          });

          groups = groups.filter((group) => {
            return !props.filterGroups.includes(group);
          });

          setAllGroups(groups);
          setLoading(false);
        });
    }
  }, [props.open]);

  React.useEffect(() => {
    if (props.open) {
      let groups = allGroups.slice(0);
      groups = groups.filter((group) => {
        return !filterGroups.includes(group);
      });

      setAllGroups(groups);
    }
  }, [filterGroups]);

  React.useEffect(() => {
    if (!props.filterGroups.length) {
      setFilterGroups([]);
    }
  }, [props.filterGroups]);

  return (
    <div>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={props.open}
        transition={Transition}
        keepMounted
        fullWidth
        maxWidth="sm"
        onClose={() => props.setOpen(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
          style={{
            paddingTop: 20,
            borderBottom: "1px solid #dddddd",
            paddingBottom: 20,
          }}
        >
          <Typography style={{ fontWeight: 900, fontSize: 22 }}>
            {t("groups:selectGroupsToFilterBy")}
          </Typography>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
          style={{ paddingBottom: 20 }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: 20,
              }}
            >
              <ClipLoader
                color={"#398bd6"}
                loading={loading}
                css={override}
                size={150}
              />
              <Typography
                align="center"
                style={{ marginTop: 10, color: "#398bd6" }}
              >
                {t("groups:retrievingGroupsList")}
              </Typography>
            </div>
          ) : (
            <div>
              <Typography
                align="left"
                style={{ fontSize: 20, fontWeight: "bold", marginBottom: 5 }}
              >
                {t("groups:myGroups")}
              </Typography>
              <Typography
                align="left"
                style={{
                  fontSize: 15,
                  marginBottom: 5,
                  fontStyle: "italic",
                  color: "#b2b2b2",
                }}
              >
                {t("groups:clickOnGroupToAdd")}
              </Typography>
              <Card variant="outlined" style={{ padding: 10 }}>
                {allGroups.map((group) => (
                  <Chip
                    label={group}
                    onClick={() => {
                      handleClick(group);
                    }}
                    style={{
                      marginRight: 5,
                      marginLeft: 5,
                      marginBottom: 5,
                      marginTop: 5,
                      color: "#fff",
                      background: "#ff9800",
                    }}
                  />
                ))}
              </Card>

              <Typography
                align="left"
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  marginBottom: 5,
                  marginTop: 20,
                }}
              >
                {t("groups:filterList")}
              </Typography>

              <Card variant="outlined" style={{ padding: 10 }}>
                {filterGroups.map((group) => (
                  <Chip
                    label={group}
                    onClick={() => {
                      handleClick(group);
                    }}
                    onDelete={() => {
                      handleDelete(group);
                    }}
                    style={{
                      marginRight: 5,
                      marginLeft: 5,
                      marginBottom: 5,
                      marginTop: 5,
                      color: "#fff",
                      background: "#40628d",
                    }}
                  />
                ))}
              </Card>
            </div>
          )}
        </DialogContent>
        <Divider light style={{ marginBottom: 10, marginTop: 5 }} />
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
          style={{
            display: "block",
            justifyContent: "center",
            paddingBottom: 20,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Button
            style={{ marginRight: 10 }}
            onClick={() => {
              props.setOpen(false);
              props.handleClose();
            }}
          >
            {t("groups:cancel")}
          </Button>

          <Button
            className="update-group"
            round
            color="success"
            style={{ marginRight: 10 }}
            onClick={() => {
              props.setFilterGroups(filterGroups);
              props.setOpen(false);
              props.handleClose();
            }}
          >
            {t("groups:setFilter")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
