import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Divider from "@material-ui/core/Divider";
import SvgIcon from "@material-ui/core/SvgIcon";
import { IconButton } from "@material-ui/core";
import MUICard from "@material-ui/core/Card";
import MUICardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import MUITypography from "@material-ui/core/Typography";
// @material-ui/icons
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import UpdateIcon from "@material-ui/icons/Update";
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import ShoppingBasketRoundedIcon from "@material-ui/icons/ShoppingBasketRounded";
import Typo from "@material-ui/core/Typography";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import dayjs from "dayjs";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import Collapse from "@material-ui/core/Collapse";

import { ReactComponent as Tool1 } from "assets/icons/tool1.svg";
import { ReactComponent as Tool2 } from "assets/icons/tool2.svg";
import { ReactComponent as Tool3 } from "assets/icons/tool3.svg";
import { ReactComponent as Tool4 } from "assets/icons/tool4.svg";
import { ReactComponent as Tool5 } from "assets/icons/tool5.svg";
import { ReactComponent as Cog } from "assets/icons/cog.svg";
import { ReactComponent as Module } from "assets/icons/modicon.svg";

import allStyles from "assets/jss/elite-feats-cms/views/dashboardStyle.js";

import { withRouter } from "react-router-dom";
import axios from "axios";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/FadeLoader";
import Typography from "components/Typography/Muted.js";
import AnimateHeight from "react-animate-height";

import assessmentNames from "./assessmentNamesReversed";

import identifiers from "./identifiers";
import SweetAlert from "react-bootstrap-sweetalert";
import alertStyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";
import AssignLoading from "./AssignLoading";
import { useDebouncedCallback } from "use-debounce";

import GroupMembers from "../Activity/GroupMembers";
import GroupLoading from "../Activity/GroupLoading";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },

  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

function LatestActivity(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(true);

  const [openMembers, setOpenMembers] = React.useState(false);
  const [members, setMembers] = React.useState([]);
  const [groupName, setGroupName] = React.useState("");
  const [openGroupLoading, setOpenGroupLoading] = React.useState(false);

  const getGroupMembers = (group_name) => {
    setOpenGroupLoading(true);
    axios
      .post(
        "/api/client/get-members",
        { group: group_name },
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setMembers(res.data);
        setOpenGroupLoading(false);
        setGroupName(group_name);
        setOpenMembers(true);
      });
  };

  let activity = props.data;
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <Card style={{ marginBottom: 60 }}>
        <CardHeader color="activity" style={{ padding: 0 }}>
          <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <h4
                style={{
                  color: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: 350,
                  fontSize: 21,
                }}
              >
                <UpdateIcon style={{ marginRight: 10 }} />{" "}
                {t("assignments:latestActivity.latestActivity")}
              </h4>
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Tooltip
                title={
                  expanded
                    ? t("assignments:latestActivity.collapse")
                    : t("assignments:latestActivity.expand")
                }
                placement="top"
              >
                <div>
                  <IconButton
                    style={{ border: "1px solid rgba(0,0,0, 0.5)" }}
                    size="small"
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon style={{ color: "rgba(0,0,0, 0.5)" }} />
                  </IconButton>
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </CardHeader>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardBody>
            <div style={{ maxHeight: 350, overflow: "auto" }}>
              {activity.length ? (
                activity.map((item, index) => {
                  let color;
                  let description = t(
                    `assignments:assessmentNames.${item.assessment}`
                  );
                  let title = t(
                    `assignments:assessmentTitles.${item.assessment}`
                  );
                  let date = dayjs(item.date).format("MMM D, YYYY");
                  let time = dayjs(item.date).format("h:mm A");

                  if (title.split(" ")[1][0] === "1") {
                    color = "#bb3737";
                  } else if (title.split(" ")[1][0] === "2") {
                    color = "#ef7e28";
                  } else if (title.split(" ")[1][0] === "3") {
                    color = "#0c8197";
                  } else if (title.split(" ")[1][0] === "4") {
                    color = "#098359";
                  } else if (title.split(" ")[1][0] === "5") {
                    color = "#7f4a7f";
                  }

                  let icon;
                  if (item.assessment[0] === "m") {
                    icon = Module;
                  } else if (item.assessment[0] === "t") {
                    if (title.split(" ")[1][0] === "1") {
                      icon = Tool1;
                    } else if (title.split(" ")[1][0] === "2") {
                      icon = Tool2;
                    } else if (title.split(" ")[1][0] === "3") {
                      icon = Tool3;
                    } else if (title.split(" ")[1][0] === "4") {
                      icon = Tool4;
                    } else if (title.split(" ")[1][0] === "5") {
                      icon = Tool5;
                    }
                  }
                  return (
                    <React.Fragment>
                      <Grid
                        container
                        style={{
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        <Grid
                          item
                          xs={9}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <SvgIcon
                            component={icon}
                            style={{
                              fontSize: 22,
                              color: `${color}`,
                            }}
                          />
                          <Divider
                            orientation="vertical"
                            flexItem
                            style={{ marginLeft: 10, marginRight: 10 }}
                          />
                          <MUITypography>
                            <span style={{ fontSize: 15, fontWeight: 500 }}>
                              {item.name}
                            </span>
                            {item.group ? (
                              <React.Fragment>
                                <span>
                                  {" "}
                                  {t(
                                    "assignments:latestActivity.fromGroup"
                                  )}{" "}
                                </span>
                                <span
                                  onClick={() => {
                                    getGroupMembers(item.group);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    fontSize: 15,
                                    fontWeight: 600,
                                    color: "#466c9c",
                                  }}
                                >
                                  {item.group}
                                </span>
                              </React.Fragment>
                            ) : null}
                            <span style={{ fontSize: 15, fontWeight: 400 }}>
                              {" "}
                              {t("assignments:latestActivity.completed")}{" "}
                            </span>
                            <Tooltip title={description} placement="top">
                              <span
                                style={{
                                  fontSize: 15,
                                  fontWeight: 500,
                                }}
                              >
                                {title}
                              </span>
                            </Tooltip>
                            <span style={{ fontSize: 15, fontWeight: 400 }}>
                              {" "}
                              {t("assignments:latestActivity.on")}{" "}
                            </span>
                            <span style={{ fontSize: 15, fontWeight: 500 }}>
                              {date}
                            </span>
                            <span style={{ fontSize: 15, fontWeight: 400 }}>
                              {" "}
                              {t("assignments:latestActivity.at")}{" "}
                            </span>
                            <span style={{ fontSize: 15, fontWeight: 500 }}>
                              {time}
                            </span>
                          </MUITypography>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            paddingRight: 20,
                          }}
                        >
                          <Button
                            size="sm"
                            color="info"
                            onClick={() =>
                              window.open(
                                `https://learn.elitefeats.com.au/report/${item.accessor}`,
                                "_blank"
                              )
                            }
                          >
                            {t("assignments:latestActivity.viewReport")}
                          </Button>
                        </Grid>
                      </Grid>
                      {index !== activity.length - 1 ? <Divider /> : null}
                    </React.Fragment>
                  );
                })
              ) : (
                <MUITypography
                  align="center"
                  style={{ fontWeight: 300, color: "#575757" }}
                >
                  {t("assignments:latestActivity.noActivity")}
                </MUITypography>
              )}
            </div>
          </CardBody>
        </Collapse>
      </Card>
      <GroupMembers
        open={openMembers}
        setOpen={setOpenMembers}
        members={members}
        groupName={groupName}
      />
      <GroupLoading open={openGroupLoading} setOpen={setOpenGroupLoading} />
    </div>
  );
}

export default withRouter(LatestActivity);
