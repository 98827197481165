import React, { useReducer, useEffect } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { Typography, Grid } from "@material-ui/core";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import LiveHelpRoundedIcon from "@material-ui/icons/LiveHelpRounded";
import AssignmentRoundedIcon from "@material-ui/icons/AssignmentRounded";
import axios from "axios";
import { useTranslation } from "react-i18next";

// Define the Tour component
const Tour = (props) => {
  const firstRender = React.useRef(true);

  const [slowLoad, setSlowLoad] = React.useState(false);

  const { t } = useTranslation();
  // Define the steps
  const TOUR_STEPS = [
    {
      target: ".clients-table",
      placement: "center",
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clients.step0Explanation")}
          </Typography>
          <Typography
            align="justify"
            style={{ marginTop: 10, color: "#bb3737" }}
          >
            {t("tour:clients.step0ActionRequired")}
          </Typography>
        </React.Fragment>
      ),
      disableOverlayClose: true,
      disableBeacon: true,
    },
    {
      target: ".add-client-button",
      disableOverlayClose: true,
      disableBeacon: true,
      hideCloseButton: false,
      showSkipButton: false,
      hideFooter: true,
      placement: "top",
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clients.step1Introduction")}
          </Typography>
          <Typography
            align="center"
            style={{ marginTop: 20, color: "#bb3737" }}
          >
            {t("tour:clients.step1ClickInstruction")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".first-name",
      showSkipButton: false,
      disableOverlayClose: true,
      disableScrolling: true,
      hideBackButton: true,
      placement: "right",
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clients.step2FirstNameEntry")}
          </Typography>
          <Typography align="center" style={{ marginTop: 20 }}>
            {t("tour:clients.step2NextInstruction")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".last-name",
      showSkipButton: false,
      disableOverlayClose: true,
      disableScrolling: true,
      placement: "right",
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clients.step3LastNameEntry")}
          </Typography>
          <Typography align="center" style={{ marginTop: 20 }}>
            {t("tour:clients.step3NextInstruction")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".client-email",
      showSkipButton: false,
      disableOverlayClose: true,
      disableScrolling: true,
      placement: "right",
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clients.step4EmailEntry")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".client-create",
      showSkipButton: false,
      disableOverlayClose: true,
      disableScrolling: true,
      hideCloseButton: true,
      placement: "right",
      spotlightClicks: true,
      styles: {
        buttonNext: {
          display: "none",
        },
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography
            align="justify"
            style={{ marginTop: 20, color: "#bb3737" }}
          >
            {t("tour:clients.step5CreateClient")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".sweet-alert",
      showSkipButton: false,
      disableOverlayClose: true,
      disableScrolling: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: "right",
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography
            align="justify"
            style={{ marginTop: 20, color: "#bb3737" }}
          >
            {t("tour:clients.step6SuccessMessage")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".latest-client",
      showSkipButton: false,
      disableBeacon: true,
      hideBackButton: true,
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clients.step7ClientCreated")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".tour-edit-0",
      showSkipButton: false,
      disableOverlayClose: true,
      placement: "top",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clients.step8EditClient")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".tour-assessments-0",
      showSkipButton: false,
      disableOverlayClose: true,
      placement: "bottom",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clients.step9ViewAssessments")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".tour-assessments-0",
      showSkipButton: false,
      disableOverlayClose: true,
      placement: "bottom",
      styles: {
        options: {
          zIndex: 10000,
          width: 1000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify" style={{ fontWeight: "bold" }}>
            {t("tour:clients.step10AssessmentProgression")}
          </Typography>
          <Grid
            container
            style={{
              border: "1px dashed #cecece",
              borderRadius: 10,
              marginTop: 20,
            }}
          >
            <Grid
              item
              xs={3}
              align="center"
              justify="center"
              style={{
                padding: 15,
                borderRight: "1px dashed #cecece",
              }}
            >
              <AssignmentRoundedIcon style={{ color: "#b0b0b0" }} />
              <Typography align="justify" style={{ fontSize: 14 }}>
                {t("tour:clients.noAssessments")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              align="center"
              justify="center"
              style={{
                padding: 15,
                borderRight: "1px dashed #cecece",
              }}
            >
              <WarningRoundedIcon style={{ color: "#f44336" }} />
              <Typography align="justify" style={{ fontSize: 14 }}>
                {t("tour:clients.assignedNoAccount")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              align="center"
              justify="center"
              style={{
                padding: 15,
                borderRight: "1px dashed #cecece",
              }}
            >
              <LiveHelpRoundedIcon style={{ color: "#ff9800" }} />
              <Typography align="justify" style={{ fontSize: 14 }}>
                {t("tour:clients.startedAssessment")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              align="center"
              justify="center"
              style={{
                padding: 15,
              }}
            >
              <PlaylistAddCheckIcon style={{ color: "#49a44d" }} />
              <Typography align="justify" style={{ fontSize: 14 }}>
                {t("tour:clients.completedAssessment")}
              </Typography>
            </Grid>
          </Grid>
        </React.Fragment>
      ),
    },
    {
      target: ".tour-mail-0",
      showSkipButton: false,
      disableOverlayClose: true,
      placement: "top",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clients.sendMessage")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".tour-select-0",
      showSkipButton: false,
      disableOverlayClose: true,
      disableScrolling: true,
      hideCloseButton: true,
      placement: "top",
      spotlightClicks: true,
      styles: {
        buttonNext: {
          display: "none",
        },
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography
            align="justify"
            style={{ marginTop: 20, color: "#bb3737" }}
          >
            {t("tour:clients.selectClient")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".tour-client-actions",
      showSkipButton: false,
      disableOverlayClose: true,
      disableScrolling: true,
      hideCloseButton: true,
      hideBackButton: true,
      placement: "top",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">{t("tour:clients.openMenu")}</Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".tour-client-select-assessments",
      showSkipButton: false,
      disableOverlayClose: true,
      disableScrolling: true,
      hideCloseButton: true,
      spotlightClicks: true,
      placement: "top",
      styles: {
        buttonNext: {
          display: "none",
        },
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography
            align="justify"
            style={{ marginTop: 20, color: "#bb3737" }}
          >
            {t("tour:clients.assignAssessments")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".assignment-table",
      showSkipButton: false,
      disableOverlayClose: true,
      disableScrolling: true,
      hideCloseButton: true,
      placement: "top",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clients.chooseAssessments")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".assignment-table",
      showSkipButton: false,
      disableOverlayClose: true,
      disableScrolling: true,
      hideCloseButton: true,
      placement: "top",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clients.keepTourContained")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".assessment-tutorial",
      showSkipButton: false,
      disableOverlayClose: true,
      disableScrolling: true,
      hideCloseButton: true,
      placement: "top",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clients.accessTutorial")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".tour-client-clear",
      showSkipButton: false,
      disableOverlayClose: true,
      disableScrolling: true,
      hideCloseButton: true,
      placement: "top",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clients.deselectClients")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".tour-client-delete",
      showSkipButton: false,
      disableOverlayClose: true,
      disableScrolling: true,
      hideCloseButton: true,
      spotlightClicks: true,
      placement: "top",
      styles: {
        buttonNext: {
          display: "none",
        },
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography
            align="justify"
            style={{ marginTop: 20, color: "#bb3737" }}
          >
            {t("tour:clients.deleteClient")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".sweet-alert",
      showSkipButton: false,
      disableOverlayClose: true,
      disableScrolling: true,
      hideCloseButton: true,
      spotlightClicks: true,
      placement: "right",
      styles: {
        buttonNext: {
          display: "none",
        },
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography
            align="justify"
            style={{ marginTop: 20, color: "#bb3737" }}
          >
            {t("tour:clients.confirmDelete")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".clients-table",
      showSkipButton: false,
      disableOverlayClose: true,
      disableScrolling: true,
      hideCloseButton: true,
      placement: "top",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clients.clientTableNoClient")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".tour-client-groups-button",
      showSkipButton: false,
      disableOverlayClose: true,
      disableScrolling: true,
      hideCloseButton: true,
      placement: "top",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:clients.groupsButton")}
          </Typography>
        </React.Fragment>
      ),
    },
  ];

  // Define our state
  const INITIAL_STATE = {
    key: new Date(),
    run: false,
    continuous: true,
    loading: false,
    stepIndex: 0,
    steps: TOUR_STEPS,
  };

  // Set up the reducer function
  const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "START":
        return { ...state, run: true };
      case "RESET":
        return { ...state, stepIndex: 0 };
      case "STOP":
        return { ...state, run: false, stepIndex: 0 };
      case "PAUSE":
        return { ...state, run: false };
      case "NEXT_OR_PREV":
        return { ...state, ...action.payload };
      case "RESTART":
        return {
          ...state,
          stepIndex: 0,
          run: true,
          loading: false,
          key: new Date(),
        };
      default:
        return state;
    }
  };

  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
  /*   useEffect(() => {
    if (!firstRender.current) {
      dispatch({ type: "START" });
    }
  }, [props.triggerTour]); */

  useEffect(() => {
    if (!firstRender.current) {
      if (slowLoad) {
        setTimeout(() => {
          dispatch({ type: "START" });
        }, 400);
        setSlowLoad(false);
      }
    }
  }, [tourState]);

  useEffect(() => {
    if (!firstRender.current) {
      if (props.triggerStep === 0) {
        dispatch({ type: "RESTART" });
      } else {
        if (props.triggerStep === -3) {
          dispatch({ type: "STOP" });
        }
        if (props.triggerStep === -2) {
          dispatch({ type: "PAUSE" });
        } else {
          if (props.triggerStep !== -1) {
            if (tourState.run) {
              dispatch({
                type: "NEXT_OR_PREV",
                payload: { stepIndex: props.triggerStep },
              });
            } else {
              dispatch({
                type: "NEXT_OR_PREV",
                payload: { stepIndex: props.triggerStep, run: true },
              });
            }
          }
        }
      }
    } else {
      firstRender.current = false;
    }
  }, [props.triggerStep]);

  const callback = (data) => {
    const { action, index, type, status } = data;

    if (
      action === ACTIONS.CLOSE ||
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      dispatch({ type: "STOP" });

      props.setClientTour(-10);
      props.setTourRun(false);
      props.setTriggerStep(-1);
      axios.post(
        "/api/user/update-tour-info",
        { completed: "clientsTable" },
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      let run = true;

      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: stepIndex, run: true },
      });
    }
  };
  const startTour = () => {
    dispatch({ type: "RESTART" });
  };
  return (
    <>
      <JoyRide
        {...tourState}
        callback={callback}
        scrollOffset={120}
        showSkipButton={false}
        styles={{
          options: {
            zIndex: 2000,
            primaryColor: "#414141",
          },
          tooltipContainer: {
            textAlign: "left",
          },

          buttonBack: {
            marginRight: 10,
          },
        }}
        locale={{
          next: t("common:next"),
          back: t("common:back"),
          skip: t("common:skip"),
          last: t("common:endTour"),
        }}
      />
    </>
  );
};
export default Tour;
