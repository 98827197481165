import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Divider from "@material-ui/core/Divider";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Box, IconButton } from "@material-ui/core";
import MUICard from "@material-ui/core/Card";
import MUICardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import MUITypography from "@material-ui/core/Typography";
// @material-ui/icons
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import UpdateIcon from "@material-ui/icons/Update";
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import ShoppingBasketRoundedIcon from "@material-ui/icons/ShoppingBasketRounded";
import Typo from "@material-ui/core/Typography";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import dayjs from "dayjs";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import Collapse from "@material-ui/core/Collapse";

import { ReactComponent as Tool1 } from "assets/icons/tool1.svg";
import { ReactComponent as Tool2 } from "assets/icons/tool2.svg";
import { ReactComponent as Tool3 } from "assets/icons/tool3.svg";
import { ReactComponent as Tool4 } from "assets/icons/tool4.svg";
import { ReactComponent as Tool5 } from "assets/icons/tool5.svg";
import { ReactComponent as Cog } from "assets/icons/cog.svg";
import { ReactComponent as Module } from "assets/icons/modicon.svg";

import { ReactComponent as Tool1Icon } from "assets/icons/tool1icon.svg";
import { ReactComponent as Tool2Icon } from "assets/icons/tool2icon.svg";
import { ReactComponent as Tool3Icon } from "assets/icons/tool3icon.svg";
import { ReactComponent as Module4Icon } from "assets/icons/module4icon.svg";
import { ReactComponent as Module5Icon } from "assets/icons/module5icon.svg";

import module1 from "assets/img/module1.png";
import module2 from "assets/img/module2.png";
import module3 from "assets/img/module3.png";
import module4 from "assets/img/module4.png";
import module5 from "assets/img/module5.png";

import allStyles from "assets/jss/elite-feats-cms/views/dashboardStyle.js";

import { withRouter } from "react-router-dom";
import axios from "axios";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/FadeLoader";
import Typography from "components/Typography/Muted.js";
import AnimateHeight from "react-animate-height";

import SweetAlert from "react-bootstrap-sweetalert";
import alertStyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import PriorityHigh from "@material-ui/icons/PriorityHigh";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import AppContext from "../../../AppContext";
import { useDebouncedCallback } from "use-debounce";
import MUIButton from "@material-ui/core/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },

  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

function ShopModules(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  let numbers = [];
  for (let i = 1; i < 51; i++) {
    numbers.push(i);
  }
  const cartContext = React.useContext(AppContext);
  const [tool2_1, setTool2_1] = React.useState(1);
  const [tool2_2, setTool2_2] = React.useState(1);
  const [tool2_3, setTool2_3] = React.useState(1);

  const handleChange1 = (event) => {
    setTool2_1(event.target.value);
  };
  const handleChange2 = (event) => {
    setTool2_2(event.target.value);
  };
  const handleChange3 = (event) => {
    setTool2_3(event.target.value);
  };

  const updateCart = (id, quantity) => {
    let currentCart = JSON.parse(localStorage.getItem("cart"));
    currentCart[id] += quantity;
    localStorage.setItem("cart", JSON.stringify(currentCart));
    cartContext.setCart(currentCart);
    props.setCartValues([id, quantity]);
    props.setOpen(true);
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={4} style={{ padding: 10 }}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <img
                src={module2}
                style={{ minHeight: 215 }}
                alt={t("shop:tools.tool2_1Alt")}
              />
            </CardHeader>
            <CardBody>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "#e7842c",
                  justifyContent: "center",
                  borderRadius: 50,
                  padding: 5,
                }}
              >
                <SvgIcon
                  component={Tool2Icon}
                  style={{ fontSize: 32, marginRight: 10, color: "#fff" }}
                />{" "}
                <span
                  style={{ fontSize: 20, fontWeight: "bold", color: "#fff" }}
                >
                  {t("shop:tools.tool2_1")}
                </span>
              </Box>
              <MUITypography
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  marginBottom: 10,
                  marginTop: 10,
                  minHeight: 54,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {t("shop:tools.tool2_1Title")}
              </MUITypography>

              <MUITypography
                align="justify"
                style={{
                  fontWeight: 300,
                  fontSize: 16,
                  height: 170,
                  overflow: "auto",
                  padding: "0px 10px 0px 10px",
                }}
              >
                {t("shop:tools.tool2_1Description")}
              </MUITypography>
              <Grid container>
                <Grid item xs={12} md={12} lg={6}>
                  <MUIButton
                    variant="outlined"
                    style={{
                      color: "#e7842c",
                      marginTop: 10,
                      borderColor: "#e7842c",
                    }}
                    onClick={() =>
                      window.open(
                        `https://peek.elitefeats.com.au/tool2_1/start`,
                        "_blank"
                      )
                    }
                    size="small"
                    endIcon={<VisibilityIcon />}
                  >
                    {t("shop:sneakPeek")}
                  </MUIButton>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <MUITypography
                    align="right"
                    style={{
                      fontSize: 17,
                      fontWeight: "bold",
                      marginTop: 15,
                    }}
                  >
                    <span
                      style={{
                        border: "1px solid #d1d1d1",
                        padding: 5,
                        paddingRight: 10,
                        paddingLeft: 10,
                        borderRadius: 5,
                      }}
                    >
                      {t("shop:tools.tool2_1Price")}
                    </span>
                  </MUITypography>
                </Grid>
              </Grid>
            </CardBody>
            <CardFooter product>
              <FormControl className={classes.formControl}>
                <Select
                  id="outlined-select"
                  select
                  value={tool2_1}
                  onChange={handleChange1}
                  SelectProps={{ display: "flex" }}
                  variant="outlined"
                >
                  {numbers.map((number) => (
                    <MenuItem key={number} value={number}>
                      {number}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className={`${classes.stats} ${classes.productStats}`}>
                <Button
                  color="info"
                  className={classes.marginRight}
                  onClick={() => {
                    updateCart("tool2_1", tool2_1);
                  }}
                >
                  <AddShoppingCartIcon className={classes.icons} />{" "}
                  {t("shop:addToCart", { count: tool2_1 })}
                </Button>
              </div>
              {cartContext.cart["tool2_1"] ? (
                <div>
                  <MUITypography>
                    <span
                      style={{
                        background: "#e7842c",
                        color: "#FFF",
                        padding: 3,
                        paddingLeft: 10,
                        paddingRight: 10,
                        borderRadius: 50,
                        fontSize: 16,
                      }}
                    >
                      {t("shop:inCart", {
                        count: cartContext.cart["tool2_1"],
                      })}
                    </span>
                  </MUITypography>
                </div>
              ) : null}
            </CardFooter>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} style={{ padding: 10 }}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <img
                src={module2}
                style={{ minHeight: 215 }}
                alt={t("shop:tools.tool2_2Alt")}
              />
            </CardHeader>
            <CardBody>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "#e7842c",
                  justifyContent: "center",
                  borderRadius: 50,
                  padding: 5,
                }}
              >
                <SvgIcon
                  component={Tool2Icon}
                  style={{ fontSize: 32, marginRight: 10, color: "#fff" }}
                />{" "}
                <span
                  style={{ fontSize: 20, fontWeight: "bold", color: "#fff" }}
                >
                  {t("shop:tools.tool2_2")}
                </span>
              </Box>
              <MUITypography
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  marginBottom: 10,
                  marginTop: 10,
                  minHeight: 54,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {t("shop:tools.tool2_2Title")}
              </MUITypography>

              <MUITypography
                align="justify"
                style={{
                  fontWeight: 300,
                  fontSize: 16,
                  height: 170,
                  overflow: "auto",
                  padding: "0px 10px 0px 10px",
                }}
              >
                {t("shop:tools.tool2_2Description")}
              </MUITypography>
              <Grid container>
                <Grid item xs={12} md={12} lg={6}>
                  <MUIButton
                    variant="outlined"
                    style={{
                      color: "#e7842c",
                      marginTop: 10,
                      borderColor: "#e7842c",
                    }}
                    onClick={() =>
                      window.open(
                        `https://peek.elitefeats.com.au/tool2_2/start`,
                        "_blank"
                      )
                    }
                    size="small"
                    endIcon={<VisibilityIcon />}
                  >
                    {t("shop:sneakPeek")}
                  </MUIButton>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <MUITypography
                    align="right"
                    style={{
                      fontSize: 17,
                      fontWeight: "bold",
                      marginTop: 15,
                    }}
                  >
                    <span
                      style={{
                        border: "1px solid #d1d1d1",
                        padding: 5,
                        paddingRight: 10,
                        paddingLeft: 10,
                        borderRadius: 5,
                      }}
                    >
                      {t("shop:tools.tool2_2Price")}
                    </span>
                  </MUITypography>
                </Grid>
              </Grid>
            </CardBody>
            <CardFooter product>
              <FormControl className={classes.formControl}>
                <Select
                  id="outlined-select"
                  select
                  value={tool2_2}
                  onChange={handleChange2}
                  SelectProps={{ display: "flex" }}
                  variant="outlined"
                >
                  {numbers.map((number) => (
                    <MenuItem key={number} value={number}>
                      {number}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className={`${classes.stats} ${classes.productStats}`}>
                <Button
                  color="info"
                  className={classes.marginRight}
                  onClick={() => {
                    updateCart("tool2_2", tool2_2);
                  }}
                >
                  <AddShoppingCartIcon className={classes.icons} />{" "}
                  {t("shop:addToCart", { count: tool2_2 })}
                </Button>
              </div>
              {cartContext.cart["tool2_2"] ? (
                <div>
                  <MUITypography>
                    <span
                      style={{
                        background: "#e7842c",
                        color: "#FFF",
                        padding: 3,
                        paddingLeft: 10,
                        paddingRight: 10,
                        borderRadius: 50,
                        fontSize: 16,
                      }}
                    >
                      {t("shop:inCart", {
                        count: cartContext.cart["tool2_2"],
                      })}
                    </span>
                  </MUITypography>
                </div>
              ) : null}
            </CardFooter>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} style={{ padding: 10 }}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <img
                src={module2}
                style={{ minHeight: 215 }}
                alt={t("shop:tools.tool2_3Alt")}
              />
            </CardHeader>
            <CardBody>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "#e7842c",
                  justifyContent: "center",
                  borderRadius: 50,
                  padding: 5,
                }}
              >
                <SvgIcon
                  component={Tool2Icon}
                  style={{ fontSize: 32, marginRight: 10, color: "#fff" }}
                />{" "}
                <span
                  style={{ fontSize: 20, fontWeight: "bold", color: "#fff" }}
                >
                  {t("shop:tools.tool2_3")}
                </span>
              </Box>
              <MUITypography
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  marginBottom: 10,
                  marginTop: 10,
                  minHeight: 54,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {t("shop:tools.tool2_3Title")}
              </MUITypography>

              <MUITypography
                align="justify"
                style={{
                  fontWeight: 300,
                  fontSize: 16,
                  height: 170,
                  overflow: "auto",
                  padding: "0px 10px 0px 10px",
                }}
              >
                {t("shop:tools.tool2_3Description")}
              </MUITypography>
              <Grid container>
                <Grid item xs={12} md={12} lg={6}>
                  <MUIButton
                    variant="outlined"
                    style={{
                      color: "#e7842c",
                      marginTop: 10,
                      borderColor: "#e7842c",
                    }}
                    onClick={() =>
                      window.open(
                        `https://peek.elitefeats.com.au/tool2_3/start`,
                        "_blank"
                      )
                    }
                    size="small"
                    endIcon={<VisibilityIcon />}
                  >
                    {t("shop:sneakPeek")}
                  </MUIButton>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <MUITypography
                    align="right"
                    style={{
                      fontSize: 17,
                      fontWeight: "bold",
                      marginTop: 15,
                    }}
                  >
                    <span
                      style={{
                        border: "1px solid #d1d1d1",
                        padding: 5,
                        paddingRight: 10,
                        paddingLeft: 10,
                        borderRadius: 5,
                      }}
                    >
                      {t("shop:tools.tool2_3Price")}
                    </span>
                  </MUITypography>
                </Grid>
              </Grid>
            </CardBody>
            <CardFooter product>
              <FormControl className={classes.formControl}>
                <Select
                  id="outlined-select"
                  select
                  value={tool2_3}
                  onChange={handleChange3}
                  SelectProps={{ display: "flex" }}
                  variant="outlined"
                >
                  {numbers.map((number) => (
                    <MenuItem key={number} value={number}>
                      {number}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className={`${classes.stats} ${classes.productStats}`}>
                <Button
                  color="info"
                  className={classes.marginRight}
                  onClick={() => {
                    updateCart("tool2_3", tool2_3);
                  }}
                >
                  <AddShoppingCartIcon className={classes.icons} />{" "}
                  {t("shop:addToCart", { count: tool2_3 })}
                </Button>
              </div>
              {cartContext.cart["tool2_3"] ? (
                <div>
                  <MUITypography>
                    <span
                      style={{
                        background: "#e7842c",
                        color: "#FFF",
                        padding: 3,
                        paddingLeft: 10,
                        paddingRight: 10,
                        borderRadius: 50,
                        fontSize: 16,
                      }}
                    >
                      {t("shop:inCart", {
                        count: cartContext.cart["tool2_3"],
                      })}
                    </span>
                  </MUITypography>
                </div>
              ) : null}
            </CardFooter>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default withRouter(ShopModules);
