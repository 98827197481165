export default {
  module1: "Module 1 - Engineering Your Success In Sport",
  module2:
    "Module 2 - Making the Athlete-Coach Relationship a Winning Partnership",
  module3: "Module 3 - Achieving Peak Performance",
  module4: "Module 4 - Winning The Mental Game",
  module5: "Module 5 - Parents In Sport",
  tool1_1: "Tool 1.1 - Evaluation of Strengths and Weaknesses",
  tool1_2: "Tool 1.2 - Making it happen. Goal Setting and Goal Achieving",
  tool1_3: "Tool 1.3 - Knowledge, Skills and Resources Audit",
  tool2_1: "Tool 2.1 - Coach - Athlete Audit and Checklist",
  tool2_2:
    "Tool 2.2 - Keeping The Coach Athlete Relationship Real. Assessment of Coach Athlete Compatibility.",
  tool2_3:
    "Tool 2.3 - Effective Communication. Being an Effective Communicator",
  tool3_1: "Tool 3.1 - Creating Your Performance Profile",
  tool3_2: "Tool 3.2 - Determining Your Ideal Performance State",
  tool3_3:
    "Tool 3.3 - Consolidating Well Being (The Foundation of Sustained Performance)",
  tool3_4:
    "Tool 3.4 - Raising Self Awareness, Clarifying Your Foundational Attributes",
  tool4_1: "Tool 4.1 - Raising Mental Toughness",
  tool4_2: "Tool 4.2 - Building Cognitive Fitness",
  tool4_3: "Tool 4.3 - Priming Performance & Reframing Events",
  tool4_4: "Tool 4.4 - Scripting Self Talk (Your Performance Dialogue)",
  tool4_5: "Tool 4.5 - Exercising Imagery",
  tool5_1:
    "Tool 5.1 - Assessment of Parent Effectiveness In Fulfilling Their Parental Responsibility In Sport",
  tool5_2:
    "Tool 5.2 - Respecting Your Child’s Basic Sporting Rights, Check List. Leveraging Your Experience In Sport",
  tool5_3:
    "Tool 5.3 - Assessing The Coach - Athlete - Parent Relationship (CAP)",
};
