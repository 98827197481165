export default {
  module1: " Engineering Your Success In Sport",
  module2: "Making the Athlete-Coach Relationship a Winning Partnership",
  module3: "Achieving Peak Performance",
  module4: " Winning The Mental Game",
  module5: "Parents In Sport",
  tool1_1: "Evaluation of Strengths and Weaknesses",
  tool1_2: "Making it happen. Goal Setting and Goal Achieving",
  tool1_3: "Knowledge, Skills and Resources Audit",
  tool2_1: "Coach - Athlete Audit and Checklist",
  tool2_2:
    "Keeping The Coach Athlete Relationship Real. Assessment of Coach Athlete Compatibility.",
  tool2_3: "Effective Communication. Being an Effective Communicator",
  tool3_1: "Creating Your Performance Profile",
  tool3_2: "Determining Your Ideal Performance State",
  tool3_3: "Consolidating Well Being (The Foundation of Sustained Performance)",
  tool3_4: "Raising Self Awareness, Clarifying Your Foundational Attributes",
  tool4_1: "Raising Mental Toughness",
  tool4_2: "Building Cognitive Fitness",
  tool4_3: "Priming Performance & Reframing Events",
  tool4_4: "Scripting Self Talk (Your Performance Dialogue)",
  tool4_5: "Exercising Imagery",
  tool5_1:
    "Assessment of Parent Effectiveness In Fulfilling Their Parental Responsibility In Sport",
  tool5_2:
    "Respecting Your Child’s Basic Sporting Rights, Check List. Leveraging Your Experience In Sport",
  tool5_3: "Assessing The Coach - Athlete - Parent Relationship (CAP)",
};
