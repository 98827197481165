import React from "react";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Card as MUICard } from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Schedule from "@material-ui/icons/Schedule";
import Info from "@material-ui/icons/Info";
import LocationOn from "@material-ui/icons/LocationOn";
import Gavel from "@material-ui/icons/Gavel";
import HelpOutline from "@material-ui/icons/HelpOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPillDef.js";
import Accordion from "components/Accordion/Accordion.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import Pagination from "@material-ui/lab/Pagination";
import ClientTimeline from "views/Activity/Timelines/ClientTimeline.js";

import { cardTitle } from "assets/jss/elite-feats-cms.js";
import { Divider, Typography, Tooltip } from "@material-ui/core";

import axios from "axios";
import dayjs from "dayjs";

import buttonStyles from "assets/jss/elite-feats-cms/views/buttonsStyle.js";
import empty from "assets/img/empty-folder.svg";
import historyImg from "assets/img/history.svg";
import purchase from "assets/img/price-tag.svg";
import {
  ControlPointDuplicateRounded,
  LocalActivityOutlined,
  Shop,
} from "@material-ui/icons";
import SweetAlert from "react-bootstrap-sweetalert";
import alertStyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";
import sweetAlertStyle from "assets/jss/elite-feats-cms/views/sweetAlertStyle";
import TextField from "@material-ui/core/TextField";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import DateRangeIcon from "@material-ui/icons/DateRange";

import module1 from "assets/img/module1.png";
import module2 from "assets/img/module2.png";
import module3 from "assets/img/module3.png";
import module4 from "assets/img/module4.png";
import module5 from "assets/img/module5.png";
import PriorityHigh from "@material-ui/icons/PriorityHigh";
import priceImage2 from "assets/img/card-3.jpeg";
import priceImage3 from "assets/img/card-1.jpeg";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";
import CardFooter from "components/Card/CardFooter.js";
import ClipLoader from "react-spinners/PuffLoader";
import { css } from "@emotion/react";
import ShopModules from "./Modules";
import ShopTools from "./Tools";
import { isShorthandPropertyAssignment } from "typescript";
import { ReactComponent as Cog } from "assets/icons/cog.svg";
import { ReactComponent as Module } from "assets/icons/modicon.svg";
import { ReactComponent as Bulk } from "assets/icons/bulk.svg";
import AppContext from "../../AppContext";
import checkout from "assets/img/checkout.svg";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";
const styles = {
  ...sweetAlertStyle,
  ...buttonStyles,
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
};

const useStyles = makeStyles(styles);

function Checkout({ history }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const cartContext = React.useContext(AppContext);
  React.useEffect(() => {
    let currentCart = JSON.parse(localStorage.getItem("cart"));
    for (let item in currentCart) {
      currentCart[item] = 0;
    }

    localStorage.setItem("cart", JSON.stringify(currentCart));
    cartContext.setCart(currentCart);

    setTimeout(() => {
      history.push("/");
    }, 3000);
  }, []);
  return (
    <div>
      <Card
        style={{
          width: "100%",
          minHeight: "calc(100vh - 160px)",
        }}
      >
        <img src={checkout} style={{ height: 250, marginTop: 50 }} />
        <Typography align="center" style={{ fontSize: 33, fontWeight: "bold" }}>
          {t("shop:thankYouForYourOrder")}
        </Typography>
        <Typography align="center" style={{ fontSize: 22, marginTop: 20 }}>
          {t("shop:orderPlacedSuccess")}
        </Typography>
        <Typography align="center" style={{ fontSize: 22, marginTop: 20 }}>
          {t("shop:accountUpdateNotice")}
        </Typography>
      </Card>
    </div>
  );
}

export default withRouter(Checkout);
