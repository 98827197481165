import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import AccountCircle from "@material-ui/icons/AccountCircle";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
// import LockOutline from "@material-ui/icons/LockOutline";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import RegisterLoading from "./Modals/RegisterLoading";
import styles from "assets/jss/elite-feats-cms/views/loginPageStyle.js";
import logo from "assets/img/logo-yellow.svg";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { withRouter } from "react-router-dom";
import { Typography, IconButton } from "@material-ui/core";
import CardIcon from "components/Card/CardIcon";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import Link from "@material-ui/core/Link";
import RegCms from "./../../assets/img/registration.svg";
import ForgottenPassword from "./Modals/ForgottenPassword";
import * as Yup from "yup";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function LoginPage(props) {
  const [openError, setOpenError] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState("password");
  const [errorMessage, setErrorMessage] = React.useState("");
  const { t } = useTranslation();
  const handleClick = () => {
    setOpenError(true);
  };
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenError(false);
  };

  const initialValues = {
    email: "",
    passwordConfirmation: "",
    password: "",
    fname: "",
    lname: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string().email(t("common:invalidEmail")),
    fname: Yup.string().required(t("common:firstNameRequired")),
    lname: Yup.string().required(t("common:lastNameRequired")),
    password: Yup.string()
      .required(t("common:passwordRequired"))
      .min(6, t("common:passwordMinLength")),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], t("common:passwordsMustMatch"))
      .min(6, t("common:passwordMinLength")),
  });

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openReset, setOpenReset] = React.useState(false);

  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 200);

    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  React.useEffect(() => {
    localStorage.clear();
  }, []);

  const handleSubmit = (values) => {
    setOpen(true);
    axios
      .post("/api/user/create-new-user", values)
      .then((res) => {
        setOpen(false);
        if (res.data === "successful") {
          localStorage.setItem("token", res.headers["auth-token"]);
          props.history.push("/admin");
        } else {
          setErrorMessage("Error creating account plese check inputs");
          setOpenError(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setOpen(false);
        setErrorMessage("Server error please contact an administrator");
        setOpenError(true);
      });
  };

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem
          xs={12}
          sm={6}
          md={4}
          style={{ flexBasis: "100%", maxWidth: "50%" }}
        >
          <Card login className={classes[cardAnimaton]}>
            <CardBody
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                align="center"
                style={{ marginBottom: 15, fontSize: 20, fontWeight: "bold" }}
              >
                {t("common:registrationTitle")}
              </Typography>
              <img src={RegCms} style={{ height: 100, marginBottom: 15 }} />
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                {({ isSubmitting }) => (
                  <Form className={classes.form}>
                    <Field
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      margin="normal"
                      required
                      as={TextField}
                      id="fname"
                      label={t("common:firstNameLabel")}
                      name="fname"
                      autoFocus
                      fullWidth
                      helperText={
                        <ErrorMessage
                          component="div"
                          style={{ color: "#c5473f" }}
                          name="fname"
                        />
                      }
                    />
                    <Field
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      margin="normal"
                      required
                      as={TextField}
                      id="lname"
                      label={t("common:lastNameLabel")}
                      name="lname"
                      autoFocus
                      fullWidth
                      helperText={
                        <ErrorMessage
                          component="div"
                          style={{ color: "#c5473f" }}
                          name="lname"
                        />
                      }
                    />
                    <Field
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      margin="normal"
                      required
                      autoComplete="email"
                      as={TextField}
                      id="email"
                      label={t("common:emailAddressLabel")}
                      name="email"
                      autoFocus
                      fullWidth
                      helperText={
                        <ErrorMessage
                          component="div"
                          style={{ color: "#c5473f" }}
                          name="email"
                        />
                      }
                    />
                    <Field
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                if (showPassword.length) {
                                  setShowPassword("");
                                } else {
                                  setShowPassword("password");
                                }
                              }}
                            >
                              {showPassword.length ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      margin="normal"
                      required
                      as={TextField}
                      fullWidth
                      name="password"
                      label={t("common:passwordLabel")}
                      type={`${showPassword}`}
                      id="password"
                      helperText={
                        <ErrorMessage
                          component="div"
                          style={{ color: "#c5473f" }}
                          name="password"
                        />
                      }
                    />
                    <Field
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                if (showPassword.length) {
                                  setShowPassword("");
                                } else {
                                  setShowPassword("password");
                                }
                              }}
                            >
                              {showPassword.length ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      margin="normal"
                      required
                      as={TextField}
                      fullWidth
                      name="passwordConfirmation"
                      label={t("common:confirmPasswordLabel")}
                      type={`${showPassword}`}
                      id="passwordConfirmation"
                      helperText={
                        <ErrorMessage
                          component="div"
                          style={{ color: "#c5473f" }}
                          name="passwordConfirmation"
                        />
                      }
                    />
                    <CardFooter className={classes.justifyContentCenter}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="success"
                            style={{ marginTop: 10, marginBottom: 10 }}
                          >
                            {t("common:registerButton")}
                          </Button>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Link
                            href=""
                            onClick={() => {
                              props.history.push("/auth/login");
                            }}
                            variant="body2"
                          >
                            {t("common:alreadyHaveAccount")}
                          </Link>
                        </Grid>
                      </Grid>
                    </CardFooter>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <RegisterLoading open={open} setOpen={setOpen} />
      <ForgottenPassword open={openReset} setOpen={setOpenReset} />
      <Snackbar
        open={openError}
        autoHideDuration={7000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
    </div>
  );
}

export default withRouter(LoginPage);
