import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Divider from "@material-ui/core/Divider";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Box, IconButton } from "@material-ui/core";
import MUICard from "@material-ui/core/Card";
import MUICardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import MUITypography from "@material-ui/core/Typography";
// @material-ui/icons
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import UpdateIcon from "@material-ui/icons/Update";
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import ShoppingBasketRoundedIcon from "@material-ui/icons/ShoppingBasketRounded";
import Typo from "@material-ui/core/Typography";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import dayjs from "dayjs";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import Collapse from "@material-ui/core/Collapse";

import { ReactComponent as Tool1 } from "assets/icons/tool1.svg";
import { ReactComponent as Tool2 } from "assets/icons/tool2.svg";
import { ReactComponent as Tool3 } from "assets/icons/tool3.svg";
import { ReactComponent as Tool4 } from "assets/icons/tool4.svg";
import { ReactComponent as Tool5 } from "assets/icons/tool5.svg";
import { ReactComponent as Cog } from "assets/icons/cog.svg";
import { ReactComponent as Module } from "assets/icons/modicon.svg";

import { ReactComponent as Tool1Icon } from "assets/icons/tool1icon.svg";
import { ReactComponent as Tool2Icon } from "assets/icons/tool2icon.svg";
import { ReactComponent as Tool3Icon } from "assets/icons/tool3icon.svg";
import { ReactComponent as Module4Icon } from "assets/icons/module4icon.svg";
import { ReactComponent as Module5Icon } from "assets/icons/module5icon.svg";

import module1 from "assets/img/module1.png";
import module2 from "assets/img/module2.png";
import module3 from "assets/img/module3.png";
import module4 from "assets/img/module4.png";
import module5 from "assets/img/module5.png";

import allStyles from "assets/jss/elite-feats-cms/views/dashboardStyle.js";

import { withRouter } from "react-router-dom";
import axios from "axios";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/FadeLoader";
import Typography from "components/Typography/Muted.js";
import AnimateHeight from "react-animate-height";

import SweetAlert from "react-bootstrap-sweetalert";
import alertStyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import PriorityHigh from "@material-ui/icons/PriorityHigh";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import AppContext from "../../../../AppContext";
import { useDebouncedCallback } from "use-debounce";
import MUIButton from "@material-ui/core/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SalesGrid from "../SalesGrid";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import { getName } from "country-list";
import Country from "../Country";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },

  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

function ShopModules(props) {
  const classes = useStyles();
  const data = props.data;
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [assessment, setAssessment] = React.useState("");
  const [breakdown, setBreakdown] = React.useState({});

  React.useEffect(() => {
    let assess_obj = {};
    for (let assessment in data) {
      let rows = [];
      for (let country in data[assessment]) {
        let obj = { country: "", coaches: [], quantity: 0 };
        if (data[assessment][country].coaches) {
          if (getName(country)) {
            obj["country"] = getName(country);
            obj["coaches"] = data[assessment][country].full_coaches;
            obj["quantity"] = data[assessment][country].quantity;
          } else {
            continue;
            /* obj["country"] = "Unspecified";
            obj["coaches"] = data[assessment][country].full_coaches;
            obj["quantity"] += data[assessment][country].quantity; */
          }
        }
        if (obj.country) {
          rows.push(obj);
        }
      }
      assess_obj[assessment] = rows;
    }
    setBreakdown(assess_obj);
  }, [data]);

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={4} style={{ padding: 10 }}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <img src={module3} style={{ minHeight: 215 }} alt='...' />
            </CardHeader>
            <CardBody>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "#147385",
                  justifyContent: "center",
                  borderRadius: 50,
                  padding: 5,
                }}
              >
                <SvgIcon
                  component={Tool3Icon}
                  style={{ fontSize: 32, marginRight: 10, color: "#fff" }}
                />{" "}
                <span
                  style={{ fontSize: 20, fontWeight: "bold", color: "#fff" }}
                >
                  {" "}
                  Tool 3.1
                </span>
              </Box>
              <MUITypography
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  marginBottom: 10,
                  marginTop: 10,
                  minHeight: 75,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Creating Your Performance Profile
              </MUITypography>
              <SalesGrid data={props.data} id={"tool3_1"} />
            </CardBody>
            <CardFooter
              product
              style={{
                justifyContent: "center",
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <div className={`${classes.stats} ${classes.productStats}`}>
                <Button
                  color='success'
                  className={classes.marginRight}
                  onClick={() => {
                    if (breakdown.tool3_1) {
                      setAssessment("Tool 3.1");
                      setRows(breakdown.tool3_1);
                      setOpen(true);
                    }
                  }}
                >
                  <PublicOutlinedIcon className={classes.icons} /> More Info
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} style={{ padding: 10 }}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <img src={module3} style={{ minHeight: 215 }} alt='...' />
            </CardHeader>
            <CardBody>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "#147385",
                  justifyContent: "center",
                  borderRadius: 50,
                  padding: 5,
                }}
              >
                <SvgIcon
                  component={Tool3Icon}
                  style={{ fontSize: 32, marginRight: 10, color: "#fff" }}
                />{" "}
                <span
                  style={{ fontSize: 20, fontWeight: "bold", color: "#fff" }}
                >
                  {" "}
                  Tool 3.2
                </span>
              </Box>
              <MUITypography
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  marginBottom: 10,
                  marginTop: 10,
                  minHeight: 75,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Determining Your Ideal Performance State
              </MUITypography>

              <SalesGrid data={props.data} id={"tool3_2"} />
            </CardBody>
            <CardFooter
              product
              style={{
                justifyContent: "center",
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <div className={`${classes.stats} ${classes.productStats}`}>
                <Button
                  color='success'
                  className={classes.marginRight}
                  onClick={() => {
                    if (breakdown.tool3_2) {
                      setAssessment("Tool 3.2");
                      setRows(breakdown.tool3_2);
                      setOpen(true);
                    }
                  }}
                >
                  <PublicOutlinedIcon className={classes.icons} /> More Info
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={4} style={{ padding: 10 }}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <img src={module3} style={{ minHeight: 215 }} alt='...' />
            </CardHeader>
            <CardBody>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "#147385",
                  justifyContent: "center",
                  borderRadius: 50,
                  padding: 5,
                }}
              >
                <SvgIcon
                  component={Tool3Icon}
                  style={{ fontSize: 32, marginRight: 10, color: "#fff" }}
                />{" "}
                <span
                  style={{ fontSize: 20, fontWeight: "bold", color: "#fff" }}
                >
                  {" "}
                  Tool 3.3
                </span>
              </Box>
              <MUITypography
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  marginBottom: 10,
                  marginTop: 10,
                  minHeight: 75,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Consolidating Well Being (The Foundation of Sustained
                Performance)
              </MUITypography>

              <SalesGrid data={props.data} id={"tool3_3"} />
            </CardBody>
            <CardFooter
              product
              style={{
                justifyContent: "center",
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <div className={`${classes.stats} ${classes.productStats}`}>
                <Button
                  color='success'
                  className={classes.marginRight}
                  onClick={() => {
                    if (breakdown.tool3_3) {
                      setAssessment("Tool 3.3");
                      setRows(breakdown.tool3_3);
                      setOpen(true);
                    }
                  }}
                >
                  <PublicOutlinedIcon className={classes.icons} /> More Info
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} style={{ padding: 10 }}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <img src={module3} style={{ minHeight: 215 }} alt='...' />
            </CardHeader>
            <CardBody>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "#147385",
                  justifyContent: "center",
                  borderRadius: 50,
                  padding: 5,
                }}
              >
                <SvgIcon
                  component={Tool3Icon}
                  style={{ fontSize: 32, marginRight: 10, color: "#fff" }}
                />{" "}
                <span
                  style={{ fontSize: 20, fontWeight: "bold", color: "#fff" }}
                >
                  {" "}
                  Tool 3.4
                </span>
              </Box>
              <MUITypography
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  marginBottom: 10,
                  marginTop: 10,
                  minHeight: 75,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Raising Self Awareness, Clarifying Your Foundational Attributes
              </MUITypography>

              <SalesGrid data={props.data} id={"tool3_4"} />
            </CardBody>
            <CardFooter
              product
              style={{
                justifyContent: "center",
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <div className={`${classes.stats} ${classes.productStats}`}>
                <Button
                  color='success'
                  className={classes.marginRight}
                  onClick={() => {
                    if (breakdown.tool3_4) {
                      setAssessment("Tool 3.4");
                      setRows(breakdown.tool3_4);
                      setOpen(true);
                    }
                  }}
                >
                  <PublicOutlinedIcon className={classes.icons} /> More Info
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Grid>
      </Grid>
      <Country
        assessment={assessment}
        data={rows}
        open={open}
        setOpen={setOpen}
      />
    </div>
  );
}

export default withRouter(ShopModules);
