import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Fade from "@material-ui/core/Fade";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import AnimateHeight from "react-animate-height";

import styles from "assets/jss/elite-feats-cms/components/customTabsStyle.js";

const useStyles = makeStyles(styles);

export default function CustomTabs(props) {
  const [value, setValue] = React.useState(props.value);

  const [color, setColor] = React.useState("modules");
  const handleChange = (event, value) => {
    if (value === 0) {
      setColor("modules");
    } else if (value === 1) {
      setColor("toolsOne");
    } else if (value === 2) {
      setColor("toolsTwo");
    } else if (value === 3) {
      setColor("toolsThree");
    } else if (value === 4) {
      setColor("toolsFour");
    } else if (value === 5) {
      setColor("toolsFive");
    }

    setValue(value);
    props.triggerReload();
  };
  const classes = useStyles();
  const { headerColor, plainTabs, tabs, title, rtlActive } = props;
  const cardTitle = classNames({
    [classes.cardTitle]: true,
    [classes.cardTitleRTL]: rtlActive,
  });

  return (
    <Card plain={plainTabs} style={{ minHeight: 375 }}>
      <CardHeader color={color} plain={plainTabs} style={{ padding: 8 }}>
        {title !== undefined ? <div className={cardTitle}>{title}</div> : null}
        <Tabs
          value={value}
          onChange={props.changeValue ? props.changeValue : handleChange}
          classes={{
            root: classes.tabsRoot,
            indicator: classes.displayNone,
          }}
          variant='scrollable'
          scrollButtons='auto'
        >
          {tabs.map((prop, key) => {
            var icon = {};

            if (prop.tabIcon) {
              icon = {
                icon: <prop.tabIcon />,
              };
            }
            return (
              <Tab
                classes={{
                  root: classes.tabRootButton,
                  selected: classes.tabSelected,
                  wrapper: classes.tabWrapper,
                }}
                key={"" + key}
                label={prop.tabName}
                {...icon}
              />
            );
          })}
        </Tabs>
      </CardHeader>{" "}
      <CardBody
        style={{
          display: "flex",
          alignItems: "center",
          flexShrink: 0,
          justifyContent: "center",
        }}
      >
        {tabs.map((prop, key) => {
          if (key === value) {
            return (
              <Fade in={true} {...(true ? { timeout: 1000 } : {})}>
                <div style={{ width: "100%" }} key={key}>
                  {" "}
                  {prop.tabContent}
                </div>
              </Fade>
            );
          }
          return null;
        })}{" "}
      </CardBody>{" "}
    </Card>
  );
}

CustomTabs.defaultProps = {
  value: 0,
};

CustomTabs.propTypes = {
  // the default opened tab - index starts at 0
  value: PropTypes.number,
  // function for changing the value
  // note, if you pass this function,
  // the default function that changes the tabs will no longer work,
  // so you need to create the changing functionality as well
  changeValue: PropTypes.func,
  headerColor: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose",
    "modules",
    "toolsOne",
    "toolsTwo",
  ]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node.isRequired,
    })
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool,
};
