import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
const TField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#63ac1d",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#3c9778",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#63ac1d",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#63ac1d",
      },
    },
  },
})(TextField);
const FormA = (props) => {
  const {t} = useTranslation()
  let formik = props.formik;

  const [values, setValues] = React.useState(props.values);
  const handleChange = (event, form) => {
    let object = { ...values };

    object[`client${form}`][event.target.name] = event.target.value;
    setValues(object);
  };
  return (
    <div style={{ marginTop: 10 }}>
    <Typography
      style={{ fontSize: 16, fontWeight: "bold", color: "#3f51b5" }}
    >
      {t("clients:alerts.multiClients.clientLabel", { number: props.formName })}
    </Typography>
    <Grid container>
      <Grid item xs={12} md={6} lg={4} style={{ padding: 15 }}>
        <TField
          id={`first${props.formName}`}
          label={t("clients:alerts.multiClients.firstName")}
          name='first'
          disableAutoFocus
          InputLabelProps={{
            shrink: true,
          }}
          value={values[`client${props.formName}`].first}
          onChange={(e) => handleChange(e, props.formName)}
          onBlur={(e) => {
            props.onBlur(e, props.formName);
          }}
          fullWidth
          variant='outlined'
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4} style={{ padding: 15 }}>
        <TField
          id={`last${props.formName}`}
          label={t("clients:alerts.multiClients.lastName")}
          name='last'
          disableAutoFocus
          InputLabelProps={{
            shrink: true,
          }}
          value={values[`client${props.formName}`].last}
          onChange={(e) => handleChange(e, props.formName)}
          onBlur={(e) => {
            props.onBlur(e, props.formName);
          }}
          fullWidth
          variant='outlined'
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4} style={{ padding: 15 }}>
        <TField
          id={`email${props.formName}`}
          label={t("clients:alerts.multiClients.email")}
          name='email'
          disableAutoFocus
          InputLabelProps={{
            shrink: true,
          }}
          value={values[`client${props.formName}`].email}
          onChange={(e) => handleChange(e, props.formName)}
          onBlur={(e) => {
            props.onBlur(e, props.formName);
          }}
          fullWidth
          variant='outlined'
        />
      </Grid>
    </Grid>
  </div>
  
  );
};

export default FormA;
