import {
  containerFluid,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  toolsThreeColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor,
} from "assets/jss/elite-feats-cms.js";

const headerStyle = () => ({
  appBar: {
    position: "fixed !important",
    backgroundColor: "transparent",
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    position: "absolute",
    width: "100%",
    paddingTop: "0px",
    zIndex: "1029",
    color: whiteColor[6],
    border: "0",
    borderRadius: "0px",
    padding: "0px 0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block",
  },
  container: {
    ...containerFluid,
    minHeight: "50px",
  },
  flex: {
    flex: 1,
  },
  title: {
    ...defaultFont,
    lineHeight: "30px",
    fontSize: "23px",
    fontWeight: "bold",
    borderRadius: 0,
    textTransform: "none",
    color: "inherit",
    paddingTop: "0.625rem",
    paddingBottom: "0.625rem",
    margin: "0 !important",

    letterSpacing: "unset",
    "&:hover,&:focus": {
      background: "transparent",
    },
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  info: {
    background: "linear-gradient(60deg, " + "#0f0d0a" + ", " + "#414141" + ")",

    ...defaultBoxShadow,
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  sidebarMinimize: {
    float: "left",
    padding: "0 0 0 15px",
    display: "block",
    color: grayColor[6],
  },
  sidebarMinimizeRTL: {
    padding: "0 15px 0 0 !important",
  },
  sidebarMiniIcon: {
    width: "20px",
    height: "17px",
  },
});

export default headerStyle;
