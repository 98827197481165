import React, { useReducer, useEffect } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { Typography } from "@material-ui/core";
import axios from "axios";
import { ContactSupportOutlined } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

// Define the Tour component
const Tour = (props) => {
  const { t } = useTranslation();
  const TOUR_STEPS = [
    {
      target: ".tour-logo",
      disableOverlayClose: true,
      content: (
        <React.Fragment>
          <Typography align="justify">
            {t("tour:intro.step1Welcome")}
          </Typography>
          <Typography align="center" style={{ marginTop: 20 }}>
            {t("tour:intro.step1ClickNext1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:intro.step1ClickNext2")}
            </span>
            {t("tour:intro.step1ClickNext3")}
          </Typography>
        </React.Fragment>
      ),
      disableBeacon: true,
      placement: "bottom-end",
      styles: {
        tooltip: {
          marginLeft: 30,
        },
      },
    },
    {
      target: ".my-account",
      disableOverlayClose: true,
      content: (
        <React.Fragment>
          <Typography align="center" style={{ marginTop: 20 }}>
            {t("tour:intro.step2MyAccount1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:intro.step2MyAccount2")}
            </span>
            {t("tour:intro.step2MyAccount3")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:intro.step2MyAccount4")}
            </span>
            {t("tour:intro.step2MyAccount5")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:intro.step2MyAccount6")}
            </span>
            {t("tour:intro.step2MyAccount7")}
          </Typography>
        </React.Fragment>
      ),
      placement: "bottom",
      styles: {
        spotlight: {
          height: "295px !important",
          marginTop: -10,
          padding: 20,
        },
      },
    },
    {
      target: ".my-clients",
      disableOverlayClose: true,
      content: (
        <React.Fragment>
          <Typography align="justify" style={{ marginTop: 20 }}>
            {t("tour:intro.step3MyClients1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:intro.step3MyClients2")}
            </span>
            {t("tour:intro.step3MyClients3")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:intro.step3MyClients4")}
            </span>
            {t("tour:intro.step3MyClients5")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:intro.step3MyClients6")}
            </span>
            {t("tour:intro.step3MyClients7")}
          </Typography>
        </React.Fragment>
      ),
      placement: "bottom",
      styles: {
        spotlight: {
          height: "295px !important",
          marginTop: -10,
          padding: 20,
        },
      },
    },
    {
      target: ".my-groups",
      disableOverlayClose: true,
      content: (
        <React.Fragment>
          <Typography align="justify" style={{ marginTop: 20 }}>
            {t("tour:intro.step4MyGroups1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:intro.step4MyGroups2")}
            </span>
            {t("tour:intro.step4MyGroups3")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:intro.step4MyGroups4")}
            </span>
            {t("tour:intro.step4MyGroups5")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:intro.step4MyGroups6")}
            </span>
            {t("tour:intro.step4MyGroups7")}
          </Typography>
        </React.Fragment>
      ),
      placement: "bottom",
      styles: {
        spotlight: {
          height: "295px !important",
          marginTop: -10,
          padding: 20,
        },
      },
    },
    {
      target: ".shop",
      disableOverlayClose: true,
      content: (
        <React.Fragment>
          <Typography align="justify" style={{ marginTop: 20 }}>
            {t("tour:intro.step5Shop1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:intro.step5Shop2")}
            </span>
            {t("tour:intro.step5Shop3")}
          </Typography>
        </React.Fragment>
      ),
      placement: "bottom",
      styles: {
        spotlight: {
          height: "295px !important",
          marginTop: -10,
          padding: 20,
        },
      },
    },
    {
      target: ".tour-sidebar",
      disableOverlayClose: true,
      content: (
        <React.Fragment>
          <Typography align="justify" style={{ marginTop: 20 }}>
            {t("tour:intro.step6Sidebar")}
          </Typography>
        </React.Fragment>
      ),
      placement: "right-start",
    },
    {
      target: ".latest-activity",
      disableOverlayClose: true,
      content: (
        <React.Fragment>
          <Typography align="justify" style={{ marginTop: 20 }}>
            {t("tour:intro.step7LatestActivity1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:intro.step7LatestActivity2")}
            </span>
            {t("tour:intro.step7LatestActivity3")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:intro.step7LatestActivity4")}
            </span>
            {t("tour:intro.step7LatestActivity5")}
          </Typography>
        </React.Fragment>
      ),
      placement: "top",
      styles: {
        spotlight: {
          height: "295px !important",
          marginTop: -10,
        },
      },
    },
    {
      target: ".clients-table",
      disableOverlayClose: true,
      content: (
        <React.Fragment>
          <Typography align="justify" style={{ marginTop: 20 }}>
            {t("tour:intro.step8ClientTable1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:intro.step8ClientTable2")}
            </span>
            {t("tour:intro.step8ClientTable3")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:intro.step8ClientTable4")}
            </span>
            {t("tour:intro.step8ClientTable5")}
          </Typography>
        </React.Fragment>
      ),
      placement: "top",
      styles: {
        spotlight: {
          height: "295px !important",
          marginTop: -10,
        },
      },
    },
    {
      target: ".clients-tutorial",
      disableOverlayClose: true,
      content: (
        <React.Fragment>
          <Typography align="justify" style={{ marginTop: 20 }}>
            {t("tour:intro.step9ClientTableTutorial1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:intro.step9ClientTableTutorial2")}
            </span>
            {t("tour:intro.step9ClientTableTutorial3")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".assignment-table",
      disableOverlayClose: true,
      placement: "auto",
      content: (
        <React.Fragment>
          <Typography align="justify" style={{ marginTop: 20 }}>
            {t("tour:intro.step10AssessmentTable1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:intro.step10AssessmentTable2")}
            </span>
            {t("tour:intro.step10AssessmentTable3")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".assessment-tutorial",
      disableOverlayClose: true,
      content: (
        <React.Fragment>
          <Typography align="justify" style={{ marginTop: 20 }}>
            {t("tour:intro.step11AssessmentTableTutorial1")}
          </Typography>
        </React.Fragment>
      ),
    },
    {
      target: ".logout",
      disableOverlayClose: true,
      content: (
        <React.Fragment>
          <Typography align="justify" style={{ marginTop: 20 }}>
            {t("tour:intro.step12Logout1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:intro.step12Logout2")}
            </span>
            {t("tour:intro.step12Logout3")}
          </Typography>
          <Typography align="justify" style={{ marginTop: 20 }}>
            {t("tour:intro.step12RetakeTour1")}
            <span style={{ fontWeight: "bold" }}>
              {t("tour:intro.step12RetakeTour2")}
            </span>
            {t("tour:intro.step12RetakeTour3")}
          </Typography>
        </React.Fragment>
      ),
    },
  ];
  // Define our state
  const INITIAL_STATE = {
    key: new Date(),
    run: false,
    continuous: true,
    loading: false,
    stepIndex: 0,
    steps: TOUR_STEPS,
  };

  // Set up the reducer function
  const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "START":
        return { ...state, run: true };
      case "RESET":
        return { ...state, stepIndex: 0 };
      case "STOP":
        return { ...state, run: false };
      case "NEXT_OR_PREV":
        return { ...state, ...action.payload };
      case "RESTART":
        return {
          ...state,
          stepIndex: 0,
          run: true,
          loading: false,
          key: new Date(),
        };
      default:
        return state;
    }
  };
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
  useEffect(() => {
    if (props.startTour !== 0) {
      dispatch({ type: "RESTART" });
    }
  }, [props.startTour]);
  const callback = (data) => {
    const { action, index, type, status } = data;
    if (
      action === ACTIONS.CLOSE ||
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      dispatch({ type: "STOP" });

      axios.post(
        "/api/user/update-tour-info",
        { completed: "intro" },
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      );
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
  };
  const startTour = () => {
    dispatch({ type: "RESTART" });
  };
  return (
    <>
      <JoyRide
        {...tourState}
        callback={callback}
        scrollOffset={120}
        showSkipButton={false}
        styles={{
          options: {
            zIndex: 2000,
            primaryColor: "#414141",
          },
          tooltipContainer: {
            textAlign: "left",
          },

          buttonBack: {
            marginRight: 10,
          },
        }}
        locale={{
          next: t("common:next"),
          back: t("common:back"),
          skip: t("common:skip"),
          last: t("common:endTour"),
        }}
      />
    </>
  );
};
export default Tour;
