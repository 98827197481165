import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { Grid, Typography } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import dayjs from "dayjs";
import MUIButton from "@material-ui/core/Button";
import { getName } from "country-list";
import Users from "./Users";
import { typography } from "@material-ui/system";

const columns = [
  { id: "country", label: "Country", minWidth: 170, maxWidth: 200 },
  { id: "quantity", label: "Quantity", minWidth: 100, maxWidth: 250 },

  {
    id: "coaches",
    label: "Coaches/Handlers",
    maxWidth: 130,
    minWidth: 120,
    align: "center",
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export default function StickyHeadTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [client, setClient] = React.useState({});
  const [users, setUsers] = React.useState([]);
  const [country, setCountry] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(7);
  const { data } = props;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root} variant='outlined'>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                    fontSize: 17,
                    background: " #f5f5f5",
                    borderRight: "1px solid #e3e3e3",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow role='checkbox' tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return column.id === "coaches" ? (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            maxWidth: column.maxWidth,
                            borderRight: "1px solid #e3e3e3",
                            paddingTop: 8,
                            paddingBottom: 8,
                          }}
                        >
                          {value.length ? (
                            <MUIButton
                              variant='outlined'
                              size='small'
                              onClick={() => {
                                setUsers(value);
                                setOpen(true);
                                setCountry(row.country);
                              }}
                              style={{
                                border: "1px solid #9c9c9c",
                                color: "#757575",
                              }}
                            >
                              View
                            </MUIButton>
                          ) : (
                            <typography
                              align='center'
                              style={{
                                color: "#af4343",

                                fontSize: 13,
                              }}
                            >
                              No Information
                            </typography>
                          )}
                        </TableCell>
                      ) : (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            maxWidth: column.maxWidth,
                            borderRight: "1px solid #e3e3e3",
                            paddingTop: 8,
                            paddingBottom: 8,
                          }}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {/*   <ClientDetails client={client} open={open} setOpen={setOpen} /> */}
      <TablePagination
        rowsPerPageOptions={[5, 7, 25, 100]}
        style={{ borderTop: "1px solid #e3e3e3", background: "#f5f5f5" }}
        component='div'
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Users country={country} data={users} open={open} setOpen={setOpen} />
    </Paper>
  );
}
