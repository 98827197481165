import React from "react";
// material-ui components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";

import modalStyles from "assets/jss/elite-feats-cms/modalStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";

import * as Yup from "yup";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { withRouter } from "react-router-dom";
import { Divider, Typography, Box } from "@material-ui/core";

import { useDebouncedCallback } from "use-debounce";
import LoadingModal from "./CreateLoading";
import alertStyles from "assets/jss/elite-feats-cms/views/sweetAlertStyle.js";
import PlayForWorkIcon from "@material-ui/icons/PlayForWork";
import ControlPointDuplicateIcon from "@material-ui/icons/ControlPointDuplicate";
import Multiple from "./CreateMultiple";
import { useTranslation } from "react-i18next";
const styles = { ...modalStyles, ...alertStyles };

const useStyles = makeStyles(styles);

const TField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#63ac1d",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#3c9778",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#63ac1d",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#63ac1d",
      },
    },
  },
})(TextField);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Modal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [alert, setAlert] = React.useState(null);
  const [initialValues, setInitial] = React.useState({
    first: "",
    last: "",
    email: "",
  });
  const [open, setOpen] = React.useState(false);
  const [openMulti, setOpenMulti] = React.useState(false);
  const hideAlert = (loc) => {
    if (loc === "success") {
      if (props.tourRun) {
        props.setTriggerStep(-2);
        setTimeout(() => {
          props.setTriggerStep(7);
        }, 1000);
      }

      setAlert(null);
      props.setReloadTable(Math.random());
      setInitial({ first: "", last: "", email: "" });
    }
    setAlert(null);
  };
  const dangerAlert = () => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("clients:alerts.clientNotAdded")}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.danger}
      >
        {t("clients:alerts.emailExistsError")}
      </SweetAlert>
    );
  };

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("clients:alerts.clientAdded")}
        onConfirm={() => hideAlert("success")}
        onCancel={() => hideAlert("success")}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        {t("clients:alerts.clientListUpdate")}
      </SweetAlert>
    );
  };

  React.useEffect(() => {
    if (props.tourRun && alert !== null) {
      if (props.triggerStep !== -3) {
        setTimeout(() => {
          props.setTriggerStep(6);
        }, 400);
      }
    }
  }, [alert]);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t("clients:alerts.validation.emailInvalid"))
      .required(t("clients:alerts.validation.emailRequired")),
    first: Yup.string()
      .min(2, t("clients:alerts.validation.firstNameMin"))
      .required(t("clients:alerts.validation.firstNameRequired")),
    last: Yup.string()
      .min(2, t("clients:alerts.validation.lastNameMin"))
      .required(t("clients:alerts.validation.lastNameRequired")),
  });

  const handleSubmit = useDebouncedCallback((values) => {
    if (props.tourRun) {
      props.setTriggerStep(-2);
    }
    props.openModal(false);
    setOpen(true);

    axios
      .post("/api/client/create-client", values, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setOpen(false);
        if (res.data === "client exists") {
          if (props.tourRun) {
            props.setTriggerStep(-3);
          }
          dangerAlert();
        } else if (res.data === "added") {
          successAlert();
        }
      });

    setInitial({
      first: "",
      last: "",
      email: "",
    });
  }, 500);

  return (
    <div>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={props.modal}
        transition={Transition}
        keepMounted
        disableEnforceFocus
        disableBackdropClick
        onClose={() => props.openModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
          style={{ paddingTop: 30, borderBottom: "1px solid #dedede" }}
        >
          <Typography style={{ fontWeight: 900, fontSize: 22 }}>
            {t("clients:alerts.createNewClient")}
          </Typography>
        </DialogTitle>
        <Box style={{ marginTop: 30 }}>
          <Button
            className="add-client-button"
            color="info"
            onClick={() => {
              setOpenMulti(true);
              props.openModal(false);
            }}
          >
            <ControlPointDuplicateIcon />{" "}
            {t("clients:alerts.createMultipleClients")}
          </Button>
        </Box>

        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleSubmit(values);
            resetForm(initialValues);
            setSubmitting(false);
          }}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form style={{ maxWidth: 400 }}>
              <DialogContent
                id="modal-slide-description"
                className={classes.modalBody}
              >
                <Field
                  className="first-name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  margin="normal"
                  as={TField}
                  id="first"
                  label={t("clients:alerts.formLabels.firstName")}
                  name="first"
                  autoFocus
                  fullWidth
                  helperText={
                    <ErrorMessage
                      component="div"
                      style={{ color: "#c5473f" }}
                      name="first"
                    />
                  }
                />

                <Field
                  className="last-name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  margin="normal"
                  as={TField}
                  fullWidth
                  name="last"
                  label={t("clients:alerts.formLabels.lastName")}
                  id="last"
                  helperText={
                    <ErrorMessage
                      component="div"
                      style={{ color: "#c5473f" }}
                      name="last"
                    />
                  }
                />

                <Field
                  className="client-email"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  margin="normal"
                  autoComplete="email"
                  as={TField}
                  id="email"
                  label={t("clients:alerts.formLabels.emailAddress")}
                  name="email"
                  autoFocus
                  fullWidth
                  helperText={
                    <ErrorMessage
                      component="div"
                      style={{ color: "#c5473f" }}
                      name="email"
                    />
                  }
                />

                <Grid
                  style={{
                    marginBottom: "3em",
                    display: "flex",
                    justifyContent: "center",
                  }}
                ></Grid>
              </DialogContent>
              <Divider light style={{ marginBottom: 10 }} />
              <DialogActions
                className={
                  classes.modalFooter + " " + classes.modalFooterCenter
                }
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: 20,
                }}
              >
                <Button
                  round
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    props.openModal(false);
                  }}
                >
                  {t("clients:cancel")}
                </Button>
                <Button
                  className="client-create"
                  round
                  style={{ marginLeft: 10 }}
                  type="submit"
                  color="success"
                >
                  {t("clients:create")}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>

      <LoadingModal open={open} setOpen={setOpen} />
      <Multiple
        modal={openMulti}
        openModal={setOpenMulti}
        setReloadTable={props.setReloadTable}
      />
      {alert}
    </div>
  );
}
