import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Slide from "@material-ui/core/Slide";
import Divider from "@material-ui/core/Divider";
import SvgIcon from "@material-ui/core/SvgIcon";
import { IconButton, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
// @material-ui/icons
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import { ReactComponent as Tool1 } from "assets/icons/tool1.svg";
import { ReactComponent as Tool2 } from "assets/icons/tool2.svg";
import { ReactComponent as Tool3 } from "assets/icons/tool3.svg";
import { ReactComponent as Tool4 } from "assets/icons/tool4.svg";
import { ReactComponent as Tool5 } from "assets/icons/tool5.svg";
import { ReactComponent as Cog } from "assets/icons/cog.svg";
import { ReactComponent as Assign } from "assets/icons/test.svg";
import { ReactComponent as Module } from "assets/icons/modicon.svg";
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
import Link from "@material-ui/core/Link";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import GroupRoundedIcon from "@material-ui/icons/GroupRounded";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import ShoppingBasketRoundedIcon from "@material-ui/icons/ShoppingBasketRounded";
import EditIcon from "@material-ui/icons/Edit";
import EmailIcon from "@material-ui/icons/Email";
import Typo from "@material-ui/core/Typography";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import BugReport from "@material-ui/icons/BugReport";

import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import Tasks from "components/Tasks/Tasks.js";
import ModTab from "../Tables/ModulesTable";
import Tool1Tab from "../Tables/Tools1Table";
import Tool2Tab from "../Tables/Tools2Table";
import Tool3Tab from "../Tables/Tools3Table";
import Tool4Tab from "../Tables/Tools4Table";
import Tool5Tab from "../Tables/Tools5Table";
import { bugs, website, server } from "variables/general.js";
import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "variables/charts";

import dashboardStyles from "assets/jss/elite-feats-cms/views/dashboardStyle.js";
import allStyles from "assets/jss/elite-feats-cms/views/extendedTablesStyle.js";

import { withRouter } from "react-router-dom";
import axios from "axios";
import socketIO from "socket.io-client";

import { css } from "@emotion/react";
import ClipLoader from "react-spinners/FadeLoader";
import Typography from "components/Typography/Muted.js";
import AnimateHeight from "react-animate-height";
import AssignAssessments from "./AssignAssessments";
import ClientsTable from "./ClientsTable";
import GroupsTable from "./GroupsTable";
import { Endpoint } from "aws-sdk";
import Dvr from "@material-ui/icons/Dvr";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import LiveHelpRoundedIcon from "@material-ui/icons/LiveHelpRounded";
import AssignmentRoundedIcon from "@material-ui/icons/AssignmentRounded";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import LatestActivity from "./LatestActivity";
import AppContext from "../../AppContext";
import Tour from "./IntroTour";
import SendMailClient from "./Modals/SendMailClient";
import SendMailGroup from "./Modals/SendMailGroup";
import { useTranslation } from "react-i18next";

const styles = { ...allStyles, ...dashboardStyles };

const useStyles = makeStyles(styles);

let total_purchased_init = {
  module1: 0,
  module2: 0,
  module3: 0,
  module4: 0,
  module5: 0,
  tool1_1: 0,
  tool1_2: 0,
  tool1_3: 0,

  tool2_1: 0,
  tool2_2: 0,
  tool2_3: 0,

  tool3_1: 0,
  tool3_2: 0,
  tool3_3: 0,
  tool3_4: 0,

  tool4_1: 0,
  tool4_2: 0,
  tool4_3: 0,
  tool4_4: 0,
  tool4_5: 0,

  tool5_1: 0,
  tool5_2: 0,
  tool5_3: 0,
  tool5_4: 0,
};
let used_assessments_init = {
  module1: 0,
  module2: 0,
  module3: 0,
  module4: 0,
  module5: 0,
  tool1_1: 0,
  tool1_2: 0,
  tool1_3: 0,

  tool2_1: 0,
  tool2_2: 0,
  tool2_3: 0,

  tool3_1: 0,
  tool3_2: 0,
  tool3_3: 0,
  tool3_4: 0,

  tool4_1: 0,
  tool4_2: 0,
  tool4_3: 0,
  tool4_4: 0,
  tool4_5: 0,

  tool5_1: 0,
  tool5_2: 0,
  tool5_3: 0,
  tool5_4: 0,
};
let pending_assessments_init = {
  module1: 0,
  module2: 0,
  module3: 0,
  module4: 0,
  module5: 0,
  tool1_1: 0,
  tool1_2: 0,
  tool1_3: 0,

  tool2_1: 0,
  tool2_2: 0,
  tool2_3: 0,

  tool3_1: 0,
  tool3_2: 0,
  tool3_3: 0,
  tool3_4: 0,

  tool4_1: 0,
  tool4_2: 0,
  tool4_3: 0,
  tool4_4: 0,
  tool4_5: 0,

  tool5_1: 0,
  tool5_2: 0,
  tool5_3: 0,
  tool5_4: 0,
};
let completed_assessments_init = {
  module1: 0,
  module2: 0,
  module3: 0,
  module4: 0,
  module5: 0,
  tool1_1: 0,
  tool1_2: 0,
  tool1_3: 0,

  tool2_1: 0,
  tool2_2: 0,
  tool2_3: 0,

  tool3_1: 0,
  tool3_2: 0,
  tool3_3: 0,
  tool3_4: 0,

  tool4_1: 0,
  tool4_2: 0,
  tool4_3: 0,
  tool4_4: 0,
  tool4_5: 0,

  tool5_1: 0,
  tool5_2: 0,
  tool5_3: 0,
  tool5_4: 0,
};
let assignment = {
  "1": false,
  "2": false,
  "3": false,
  "4": false,
  "5": false,
  "1.1": false,
  "1.2": false,
  "1.3": false,
  "2.1": false,
  "2.2": false,
  "2.3": false,
  "3.1": false,
  "3.2": false,
  "3.3": false,
  "3.4": false,
  "4.1": false,
  "4.2": false,
  "4.3": false,
  "4.4": false,
  "4.5": false,
  "5.1": false,
  "5.2": false,
  "5.3": false,
};

function Dashboard(props) {
  const { t } = useTranslation();
  const scrollContext = React.useContext(AppContext);

  let selected = JSON.parse(localStorage.getItem("selected_clients"));
  let selected_groups = JSON.parse(localStorage.getItem("selected_groups"));
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const [reloadData, setReloadData] = React.useState(Math.random());
  /* Assign table states */
  const [totalPurchased, setPurchased] = React.useState(total_purchased_init);
  const [totalUsed, setUsed] = React.useState(used_assessments_init);
  const [pending, setPending] = React.useState(pending_assessments_init);
  const [completed, setCompleted] = React.useState(completed_assessments_init);
  const [width, setWidth] = React.useState("100%");
  const [growAssessmentSummary, setGrowAssessmentSummary] = React.useState(
    false
  );
  const [height, setHeight] = React.useState("0px");
  const [loading, setLoading] = React.useState(true);
  const [winSize, setWinSize] = React.useState(0);
  const [trigger, setTrigger] = React.useState(0);
  const [assessmentTrigger, setAssessmentTrigger] = React.useState(0);
  const order = JSON.parse(localStorage.getItem("order"));

  /* States for latest activity  */
  const [latestActivity, setLatestActivity] = React.useState([]);

  /* States for clients table */
  const [assign, setAssign] = React.useState(
    JSON.parse(localStorage.getItem("assign"))
  );

  /* states for client table */
  const [open, setOpen] = React.useState(false);
  const [reload, setReload] = React.useState(0);
  const [clients, setClients] = React.useState([]);
  const [modal, openModal] = React.useState(false);
  const [growClientsTable, setGrowClientsTable] = React.useState(false);
  const [growClientActions, setGrowClientActions] = React.useState(false);
  const [ready, setReady] = React.useState(false);
  const [assignmentDetails, setAssignmentDetails] = React.useState(null);
  const [alert, setAlert] = React.useState(null);
  const [animateIn, setAnimateIn] = React.useState(false);
  const [growAssignmentTable, setGrowAssignmentTable] = React.useState(false);
  const [openAssigned, setOpenAssigned] = React.useState(false);
  const [edit, setEdit] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
  });
  const [openEdit, setOpenEdit] = React.useState(false);
  const [groupEdit, setGroupEdit] = React.useState({
    name: "",
    description: "",
  });
  const [openGroupEdit, setOpenGroupEdit] = React.useState(false);
  const [scroll, triggerScroll] = React.useState(0);
  const [reloadTable, setReloadTable] = React.useState(Math.random());
  const [reloadClients, setReloadClients] = React.useState(0);

  const firstRender = React.useRef(true);
  const assignmentTable = React.useRef(null);
  const clientTable = React.useRef(null);

  const [data, setData] = React.useState(
    clients.map((prop, key) => {
      return {
        id: key,

        first: prop.first_name,
        last: prop.last_name,
        email: prop.email,
        full: prop,
        group: prop.groups.length < 1 ? " - " : prop.groups,
        actions: (
          <GridContainer style={{ display: "flex", justifyContent: "center" }}>
            {/* use this button to add a like kind of action */}
            <Tooltip
              title={
                <Typo style={{ fontSize: 14 }}>
                  {t("dashboard:editDetails")}
                </Typo>
              }
              placement="top"
            >
              <div>
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    let obj = data.find((o) => o.id === key);
                    setEdit(obj);
                    setOpenEdit(true);
                  }}
                  color="info"
                  className="like"
                >
                  <EditIcon />
                </Button>
              </div>
            </Tooltip>
            {/* use this button to add a edit kind of action */}
            <Tooltip
              title={
                <Typo style={{ fontSize: 14 }}>
                  {t("dashboard:assessments")}
                </Typo>
              }
              placement="top"
            >
              <div>
                <Button justIcon round simple color="warning" className="edit">
                  <EmailIcon />
                </Button>{" "}
              </div>
            </Tooltip>
            {/* use this button to remove the data row */}
            <Tooltip
              title={
                <Typo style={{ fontSize: 14 }}>{t("dashboard:sendEmail")}</Typo>
              }
              placement="top"
            >
              <div>
                <Button justIcon round simple color="danger" className="remove">
                  <EmailIcon />
                </Button>
              </div>
            </Tooltip>{" "}
          </GridContainer>
        ),
      };
    })
  );

  const applyFilters = (groups) => {};

  //Groups states

  const [groups, setGroups] = React.useState([]);
  const [groupsData, setGroupsData] = React.useState(
    groups.map((prop, key) => {
      return {
        id: key,
        name: prop.name,
        description: prop.description.length < 1 ? "-" : prop.description,
        number: prop.members.length,
        actions: (
          <GridContainer style={{ display: "flex", justifyContent: "center" }}>
            {/* use this button to add a like kind of action */}
            <Tooltip
              title={
                <Typo style={{ fontSize: 14 }}>
                  {t("dashboard:editDetails")}
                </Typo>
              }
              placement="top"
            >
              <div>
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    setGroupEdit({
                      name: prop.name,
                      description: prop.description,
                    });
                    setOpenGroupEdit(true);
                  }}
                  color="info"
                  className="like"
                >
                  <EditIcon />
                </Button>
              </div>
            </Tooltip>
            {/* use this button to add a edit kind of action */}
            <Tooltip
              title={
                <Typo style={{ fontSize: 14 }}>
                  {t("dashboard:assessments")}
                </Typo>
              }
              placement="top"
            >
              <div>
                <Button justIcon round simple color="warning" className="edit">
                  <EmailIcon />
                </Button>{" "}
              </div>
            </Tooltip>
            {/* use this button to remove the data row */}
            <Tooltip
              title={
                <Typo style={{ fontSize: 14 }}>{t("dashboard:sendEmail")}</Typo>
              }
              placement="top"
            >
              <div>
                <Button justIcon round simple color="danger" className="remove">
                  <EmailIcon />
                </Button>
              </div>
            </Tooltip>{" "}
          </GridContainer>
        ),
      };
    })
  );
  const [groupsAdded, setGroupsAdded] = React.useState(0);
  const [groupsTable, setGroupsTable] = React.useState(false);

  const [reloadGroups, setReloadGroups] = React.useState(0);
  const [reloadGroupsTable, setReloadGroupsTable] = React.useState(
    Math.random()
  );
  const [growGroupsTable, setGrowGroupsTable] = React.useState(false);
  const [growGroupsActions, setGrowGroupsActions] = React.useState(false);
  const [groupsReady, setGroupsReady] = React.useState(false);

  const [shouldOpen, setShouldOpen] = React.useState(false);
  const [openAddMembers, setOpenAddMembers] = React.useState(false);
  const [selectedGroup, setSelectedGroup] = React.useState(null);

  //Socket States & ref
  const socketRef = React.useRef();
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackMessage, setSnackMesssage] = React.useState("");
  //

  //Tour
  const [tourSelect, setTourSelect] = React.useState(Math.random());
  const [tourAddMembers, setTourAddMembers] = React.useState(Math.random());
  const [tourGroupSelect, setTourGroupSelect] = React.useState(Math.random());

  //Mail
  const [clientToMail, setClientToMail] = React.useState(null);
  const [openClientMail, setOpenClientMail] = React.useState(false);
  const [groupToMail, setGroupToMail] = React.useState(null);
  const [openGroupMail, setOpenGroupMail] = React.useState(false);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
    setSnackMesssage("");
  };
  //handle checkbox toggle for Clients table
  const handleToggle = (email) => {
    const selectedIndex = selected.indexOf(email);
    if (selectedIndex === -1) {
      selected.push(email);
    } else {
      selected.splice(selectedIndex, 1);
    }

    localStorage.setItem("selected_clients", JSON.stringify(selected));
    localStorage.setItem("selected_groups", JSON.stringify([]));
    setReload(Math.random());
  };

  //handle checkbox toggle for Groups table
  const handleGroupsToggle = (group) => {
    let selected_clients = JSON.parse(localStorage.getItem("selected_clients"));

    const selectedIndex = selected_groups
      .map((e) => {
        return e.name;
      })
      .indexOf(group.name);

    if (selectedIndex === -1) {
      selected_groups.push(group);
      /*  group.members.map((member) => {
        if (!selected_clients.includes(member)) {
          selected_clients.push(member);
        }
      }); */
    } else {
      selected_groups.splice(selectedIndex, 1);
      /*  selected_clients = selected_clients.filter((client) => {
        return !group.members.includes(client);
      });
      selected_groups.forEach((group) => {
        group.members.forEach((member) => {
          if (!selected_clients.includes(member)) {
            selected_clients.push(member);
          }
        });
      }); */
    }

    localStorage.setItem("selected_clients", JSON.stringify([]));
    localStorage.setItem("selected_groups", JSON.stringify(selected_groups));
    setReload(Math.random());
    setReloadClients(Math.random());
  };

  const showAssignments = () => {
    setAssign(true);
    triggerScroll(Math.random());
  };

  const hideAssignments = () => {
    triggerScroll(Math.random());
  };

  const hideAssignments2 = () => {
    setAssign(false);
    setGrowClientsTable(false);
    setGrowGroupsTable(false);
    triggerScroll(Math.random());
  };

  const triggerReload = () => {
    setTrigger(Math.random());
  };

  React.useEffect(() => {
    axios
      .get("/api/user/get-tour-info", {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        let tourInfo = res.data;
        if (!tourInfo.intro) {
          scrollContext.setStartTour(Math.random());
        }
      });
  }, []);

  React.useEffect(() => {
    if (scrollContext.scrollTo.split("+")[0] === "clientsTable") {
      setGrowGroupsTable(false);
      setGrowClientsTable(true);

      clientTable.current.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
    } else if (scrollContext.scrollTo.split("+")[0] === "groupsTable") {
      setGrowClientsTable(false);
      setTimeout(() => {
        clientTable.current.scrollIntoView({
          block: "nearest",
          behavior: "smooth",
        });
      }, 500);
    }
  }, [scrollContext.scrollTo]);
  /* Effect to animate in clients table after assessment table */
  React.useEffect(() => {
    if (firstRender.current) {
      setTimeout(() => {
        setAnimateIn(true);
      }, 700);

      firstRender.current = false;
    } else {
      setAnimateIn(true);
    }
  }, []);

  /* initial effect to get order info if it is users first time */
  React.useEffect(() => {
    if (localStorage.getItem("order") === null) {
      localStorage.setItem("order", JSON.stringify([]));
    }
    if (localStorage.getItem("selected_clients") === null) {
      localStorage.setItem("selected_clients", JSON.stringify([]));
    }
    if (localStorage.getItem("selected_groups") === null) {
      localStorage.setItem("selected_groups", JSON.stringify([]));
    }

    axios
      .get("/api/auth/check-first", {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data === true) {
          axios
            .get("/api/auth/update-info", {
              headers: {
                "auth-token": localStorage.getItem("token"),
              },
            })
            .then((res) => {
              setPurchased(res.data.total_purchased);
              setUsed(res.data.used_assessments);
              setPending(res.data.pending_assessments);
              setCompleted(res.data.completed_assessments);
              setLoading(false);
            });
        } else {
          axios
            .get("/api/auth/get-info", {
              headers: {
                "auth-token": localStorage.getItem("token"),
              },
            })
            .then((res) => {
              setPurchased(res.data.total_purchased);
              setUsed(res.data.used_assessments);
              setPending(res.data.pending_assessments);
              setCompleted(res.data.completed_assessments);
              setLoading(false);
            });
        }
      });

    if (JSON.parse(localStorage.getItem("assignment")) === null) {
      localStorage.setItem("assignment", JSON.stringify(assignment));
    }

    let elHeight = document.getElementById("assessTable").clientHeight;

    setHeight(elHeight);
  }, []);

  //Effect to get client & groups
  React.useEffect(() => {
    axios
      .get("/api/client/get-clients", {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setClients(res.data.reverse());
      });

    axios
      .get("/api/client/get-groups", {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setGroups(res.data.reverse());
      });

    if (localStorage.getItem("assign") === null) {
      localStorage.setItem("assign", JSON.stringify(false));
    }

    setAssign(JSON.parse(localStorage.getItem("assign")));

    axios
      .get("/api/client/get-latest-activity", {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setLatestActivity(res.data.reverse());
        scrollContext.setScrollTo(
          `${scrollContext.scrollTo.split("+")[0]}+${Math.random()}`
        );
      });
    scrollContext.setScrollTo(``);
  }, [reloadData]);

  /* Effect to instantiate socket and update assign table on socket response */
  React.useEffect(() => {
    socketRef.current = socketIO("/", {
      query: `token=${localStorage.getItem("token")}`,
    });
    socketRef.current.on("lmsAccessedFirst", async (client) => {
      setSnackMesssage(t("dashboard:firstLogin", { name: client.name }));
      setOpenSnack(true);
      setReloadClients(Math.random());
      setReloadTable(Math.random());
    });
    socketRef.current.on("lmsAccessed", async (client) => {
      setSnackMesssage(t("dashboard:login", { name: client.name }));
      setOpenSnack(true);
      setReloadClients(Math.random());
      setReloadTable(Math.random());
    });
    socketRef.current.on("completedAssessment", async (completedAssessment) => {
      await axios
        .get("/api/client/get-latest-activity", {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        })
        .then((res) => {
          setLatestActivity(res.data.reverse());
        });
      setSnackMesssage(
        t("dashboard:completedAssessment", {
          name: completedAssessment.name,
          assessment: t(
            `assignments:assessmentTitles.${completedAssessment.assessment}`
          ),
        })
      );
      setOpenSnack(true);
      setReloadClients(Math.random());
      setReloadTable(Math.random());
    });

    socketRef.current.on("OrderUpdate", (hasNewOrder) => {
      if (hasNewOrder) {
        axios
          .get("/api/auth/update-info", {
            headers: {
              "auth-token": localStorage.getItem("token"),
            },
          })
          .then((res) => {
            setPurchased(res.data.total_purchased);
            setUsed(res.data.used_assessments);
            setPending(res.data.pending_assessments);
            setCompleted(res.data.completed_assessments);
            setSnackMesssage(t("dashboard:orderUpdate"));
            setOpenSnack(true);
          });
      }
    });
    socketRef.current.on("readyForPoll", () => {
      socketRef.current.emit("startPolling");
      socketRef.current.on("OrderUpdate", (hasNewOrder) => {
        if (hasNewOrder) {
          axios
            .get("/api/auth/get-info", {
              headers: {
                "auth-token": localStorage.getItem("token"),
              },
            })
            .then((res) => {
              setPurchased(res.data.total_purchased);
              setUsed(res.data.used_assessments);
              setPending(res.data.pending_assessments);
              setCompleted(res.data.completed_assessments);
            });
        }
      });
    });
    socketRef.current.emit("tester");

    return () => {
      socketRef.current.emit("cleanup");
      socketRef.current.disconnect();
    };
  }, []);

  /* Effect to ensure "Selected Assessments" table is of equal height to Selection table  */

  React.useEffect(() => {
    let elHeight = document.getElementById("assessTable").clientHeight;
    setHeight(elHeight);
  }, [loading, winSize, growAssessmentSummary, trigger]);

  /* Event listener to check window size change */
  React.useEffect(() => {
    function updateSize() {
      setWinSize(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
  }, [winSize]);

  /* Effect that animates "selected assessments" table into view  */

  React.useEffect(() => {
    let assignment = JSON.parse(localStorage.getItem("assignment"));
    let values = Object.values(assignment);
    let selected = false;
    for (const value of values) {
      if (value === true) {
        selected = true;
      }
    }
    if (selected) {
      setWidth("70%");

      setTimeout(function () {
        setGrowAssessmentSummary(true);
      }, 410);
    } else {
      setWidth("100%");
      setGrowAssessmentSummary(false);
    }
  }, [trigger, growAssessmentSummary]);

  /* Client Table effects */

  React.useEffect(() => {
    localStorage.setItem("assign", JSON.stringify(assign));
    setGrowClientsTable(true);
  }, [assign]);

  //Effect to get client & groups
  React.useEffect(() => {
    axios
      .get("/api/client/get-clients", {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setClients(res.data.reverse());
      });

    axios
      .get("/api/client/get-groups", {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setGroups(res.data.reverse());
      });

    if (localStorage.getItem("assign") === null) {
      localStorage.setItem("assign", JSON.stringify(false));
    }

    setAssign(JSON.parse(localStorage.getItem("assign")));

    axios
      .get("/api/client/get-latest-activity", {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setLatestActivity(res.data.reverse());
        scrollContext.setScrollTo(
          `${scrollContext.scrollTo.split("+")[0]}+${Math.random()}`
        );
      });
    scrollContext.setScrollTo(``);
  }, [reloadData]);

  React.useEffect(() => {
    if (!firstRender.current) {
      axios
        .get("/api/client/get-groups", {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        })
        .then((res) => {
          setGroups(res.data.reverse());
        });
    }
  }, [groupsAdded]);

  const toggleScroll = (table) => {
    if (table === "clientsTable") {
      setGrowGroupsTable(false);

      setGrowClientsTable(true);

      clientTable.current.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
    }
    if (table === "assignmentTable") {
      assignmentTable.current.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
    }
    if (table === "assignmentTableClients") {
      if (growGroupsTable) {
        setGrowGroupsTable(false);
        setGrowClientsTable(true);
      }

      assignmentTable.current.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
    }
    if (table === "assignmentTableGroups") {
      if (!growGroupsTable) {
        setGrowClientsTable(false);
        setGroupsTable(true);
        setGrowGroupsTable(true);
      }

      assignmentTable.current.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
    }
    if (table === "groupsTable") {
      setGrowClientsTable(false);
      setGroupsTable(true);
      setGrowGroupsTable(true);
      clientTable.current.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
    }
  };

  /* Effect to reload client table (*mainly checkboxes) */
  React.useEffect(() => {
    let selected = JSON.parse(localStorage.getItem("selected_clients"));

    setData(
      clients.map((prop, key) => {
        return {
          id: key,
          select: (
            <div className={`tour-select-${key}`}>
              <Checkbox
                key={key + `${Math.random()}`}
                style={{ padding: 7 }}
                defaultChecked={selected.includes(prop.email)}
                onClick={() => {
                  setTourSelect(Math.random());
                  handleToggle(prop.email);
                }}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checkedGreen,
                  root: classes.checkGreen,
                }}
              />
            </div>
          ),
          first: prop.first_name,
          last: prop.last_name,
          email: prop.email,
          full: prop,
          group: prop.groups.length < 1 ? " - " : prop.groups,
          actions: (
            <Grid container>
              {/* use this button to add a like kind of action */}
              <Tooltip
                title={
                  <Typo style={{ fontSize: 14 }}>
                    {t("dashboard:editDetails")}
                  </Typo>
                }
                placement="top"
              >
                <div className={`tour-edit-${key}`}>
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() => {
                      setEdit({
                        first_name: prop.first_name,
                        last_name: prop.last_name,
                        email: prop.email,
                      });
                      setOpenEdit(true);
                    }}
                    color="info"
                    className="like"
                  >
                    <EditIcon />
                  </Button>
                </div>
              </Tooltip>
              {/* Button variations for assessments */}

              <div>
                {!prop.hasAssessments ? (
                  <Tooltip
                    title={<Typo>{t("dashboard:noAssessmentsAssigned")}</Typo>}
                    placement="top"
                  >
                    <div className={`tour-assessments-${key}`}>
                      <Button
                        justIcon
                        color="darkBlue"
                        round
                        simple
                        className="edit"
                        disabled
                      >
                        <AssignmentRoundedIcon />
                      </Button>{" "}
                    </div>
                  </Tooltip>
                ) : prop.hasPending && !prop.lms_accessed ? (
                  <Tooltip
                    title={
                      <React.Fragment>
                        <div>
                          <Typo style={{ fontSize: 14 }}>
                            {t("dashboard:noAssessmentStarted")}
                          </Typo>
                        </div>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Typo style={{ fontSize: 14 }}>
                            {t("dashboard:clickToView")}
                          </Typo>
                        </div>
                      </React.Fragment>
                    }
                    placement="top"
                  >
                    <div className={`tour-assessments-${key}`}>
                      <Button
                        justIcon
                        round
                        onClick={() => {
                          setAssignmentDetails(prop);
                          setOpenAssigned(true);
                        }}
                        simple
                        color="danger"
                        className="edit"
                      >
                        <WarningRoundedIcon />
                      </Button>{" "}
                    </div>
                  </Tooltip>
                ) : prop.hasPending && prop.lms_accessed ? (
                  <Tooltip
                    title={
                      <React.Fragment>
                        <div className={`tour-assessments-${key}`}>
                          <Typo style={{ fontSize: 14 }}>
                            {t("dashboard:pendingAssessments")}
                          </Typo>
                        </div>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Typo style={{ fontSize: 14 }}>
                            {t("dashboard:clickToView")}
                          </Typo>
                        </div>
                      </React.Fragment>
                    }
                    placement="top"
                  >
                    <div className={`tour-assessments-${key}`}>
                      <Button
                        justIcon
                        round
                        simple
                        color="warning"
                        onClick={() => {
                          setAssignmentDetails(prop);
                          setOpenAssigned(true);
                        }}
                        className="edit"
                      >
                        <LiveHelpRoundedIcon />
                      </Button>{" "}
                    </div>
                  </Tooltip>
                ) : !prop.hasPending && prop.lms_accessed ? (
                  <Tooltip
                    title={
                      <React.Fragment>
                        <div>
                          <Typo style={{ fontSize: 14 }}>
                            {t("dashboard:allAssessmentsCompleted")}
                          </Typo>
                        </div>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Typo style={{ fontSize: 14 }}>
                            {t("dashboard:clickToView")}
                          </Typo>
                        </div>
                      </React.Fragment>
                    }
                    placement="top"
                  >
                    <div className={`tour-assessments-${key}`}>
                      <Button
                        justIcon
                        round
                        simple
                        color="success"
                        onClick={() => {
                          setAssignmentDetails(prop);
                          setOpenAssigned(true);
                        }}
                        className="edit"
                      >
                        <PlaylistAddCheckIcon />
                      </Button>{" "}
                    </div>
                  </Tooltip>
                ) : null}
              </div>

              {/* use this button to remove the data row */}
              <Tooltip
                title={
                  <Typo style={{ fontSize: 14 }}>
                    {t("dashboard:sendEmail")}
                  </Typo>
                }
                placement="top"
              >
                <div className={`tour-mail-${key}`}>
                  <Button
                    justIcon
                    round
                    onClick={() => {
                      setOpenClientMail(true);
                      setClientToMail(prop);
                      /* window.open(`mailto:${prop.email}`) */
                    }}
                    simple
                    color="darkBlue"
                    className="remove"
                  >
                    <EmailIcon />
                  </Button>
                </div>
              </Tooltip>
            </Grid>
          ),
          completed: (
            <Tooltip
              title={
                <Typo>
                  {t("dashboard:pendingAssessmentsCount", {
                    count: prop.pending,
                  })}
                </Typo>
              }
              placement="top"
            >
              <div>
                <Typo
                  align="center"
                  style={{ fontWeight: 400, fontSize: 15, color: "#747474" }}
                >
                  {t("dashboard:completedAssessments", {
                    completed: prop.completed,
                    total: prop.pending + prop.completed,
                  })}
                </Typo>
              </div>
            </Tooltip>
          ),
        };
      })
    );
  }, [clients, reloadClients]);

  React.useEffect(() => {
    let selected_groups = JSON.parse(localStorage.getItem("selected_groups"));
    setGroupsData(
      groups.map((prop, key) => {
        return {
          id: key,
          select: (
            <Checkbox
              className={`group-checkbox-${key}`}
              key={key + `${Math.random()}`}
              style={{ padding: 7 }}
              defaultChecked={selected_groups
                .map((e) => {
                  return e.name;
                })
                .includes(prop.name)}
              onClick={() => {
                handleGroupsToggle(prop);
                setTourGroupSelect(Math.random());
              }}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checkedYellow,
                root: classes.checkYellow,
              }}
            />
          ),
          name: prop.name,
          number: prop.members.length,
          description: prop.description.length < 1 ? "-" : prop.description,

          actions: (
            <GridContainer
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Tooltip
                title={
                  <Typo style={{ fontSize: 14 }}>
                    {t("dashboard:editGroupDetails")}
                  </Typo>
                }
                placement="top"
              >
                <div className={`group-edit-${key}`}>
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() => {
                      setGroupEdit({
                        name: prop.name,
                        description: prop.description,
                      });
                      setOpenGroupEdit(true);
                    }}
                    color="info"
                    className="like"
                  >
                    <EditIcon />
                  </Button>
                </div>
              </Tooltip>
              <Tooltip
                title={
                  <Typo style={{ fontSize: 14 }}>
                    {t("dashboard:viewAddMembers")}
                  </Typo>
                }
                placement="top"
              >
                <div className={`group-add-${key}`}>
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() => {
                      setSelectedGroup(prop);
                      setShouldOpen(true);
                    }}
                    color="warning"
                    className="like"
                  >
                    <GroupRoundedIcon />
                  </Button>
                </div>
              </Tooltip>
              {/* use this button to remove the data row */}
              <Tooltip
                title={
                  <Typo style={{ fontSize: 14 }}>
                    {t("dashboard:sendEmail")}
                  </Typo>
                }
                placement="top"
              >
                <div className={`group-mail-${key}`}>
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() => {
                      setOpenGroupMail(true);
                      setGroupToMail(prop);
                      /*  window.open(`mailto:${prop.members.join(";")}`) */
                    }}
                    color="darkBlue"
                    className="remove"
                  >
                    <EmailIcon />
                  </Button>
                </div>
              </Tooltip>
            </GridContainer>
          ),
        };
      })
    );
  }, [groups, reloadGroups, reloadGroupsTable]);

  /* Effect to update client table info after adding ,editin or deleting new client */
  React.useEffect(() => {
    axios
      .get("/api/client/get-clients", {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setClients(res.data.reverse());
      });
  }, [reloadTable]);

  //Effect to grow client actions if one or more clients are selected
  React.useEffect(() => {
    let selected = JSON.parse(localStorage.getItem("selected_clients"));

    if (selected.length) {
      setReady(true);
      setGrowClientActions(true);
    } else {
      setGrowClientActions(false);
      /* hideAssignments(); */
      setTimeout(function () {
        setReady(false);
      }, 410);
    }
  }, [reload, growClientActions, reloadClients, reloadTable]);

  //Effect to grow group actions if one or more groups are selected
  React.useEffect(() => {
    let selected_groups = JSON.parse(localStorage.getItem("selected_groups"));

    if (selected_groups.length) {
      setGroupsReady(true);
      setGrowGroupsActions(true);
    } else {
      setGrowGroupsActions(false);
      /* hideAssignments(); */
      setTimeout(function () {
        setGroupsReady(false);
      }, 410);
    }
  }, [reload, growClientActions, reloadClients, reloadTable]);

  const ModulesIcon = () => <SvgIcon component={Module} />;
  const Tool1Icon = () => <SvgIcon component={Tool1} />;
  const Tool2Icon = () => <SvgIcon component={Tool2} />;
  const Tool3Icon = () => <SvgIcon component={Tool3} />;
  const Tool4Icon = () => <SvgIcon component={Tool4} />;
  const Tool5Icon = () => <SvgIcon component={Tool5} />;
  const classes = useStyles();
  /* Effect to update table info after assignment */
  React.useEffect(() => {
    setLoading(false);
    axios
      .get("/api/auth/get-info", {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setPurchased(res.data.total_purchased);
        setUsed(res.data.used_assessments);
        setPending(res.data.pending_assessments);
        setCompleted(res.data.completed_assessments);
        setLoading(false);
      });
  }, [assessmentTrigger]);

  return (
    <div>
      <GridContainer>
        {/* My Account */}
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card className="my-account">
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Icon>
                  <AccountTreeOutlinedIcon />
                </Icon>
              </CardIcon>
              <p className={classes.cardCategory}></p>
              <h3 className={classes.cardTitle}>{t("dashboard:account")}</h3>
            </CardHeader>
            <Grid
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                minHeight: 150,
              }}
            >
              <Button
                onClick={() => {
                  props.history.push("/admin/account-activity");
                }}
                style={{
                  marginTop: 20,
                  marginLeft: 30,
                  marginRight: 30,
                  padding: 7,
                }}
                color="info"
                round
              >
                {t("dashboard:accountActivity")}
              </Button>
              <Button
                onClick={() => {
                  props.history.push("/admin/account-profile");
                }}
                style={{
                  marginTop: 10,
                  marginLeft: 30,
                  marginRight: 30,
                  padding: 7,
                }}
                color="info"
                round
              >
                {t("dashboard:myProfile")}
              </Button>
            </Grid>
            <CardFooter stats>
              <div className={classes.stats}></div>
            </CardFooter>
          </Card>
        </GridItem>

        {/* My Clients */}
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card className="my-clients">
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Icon>
                  <AccountCircleRoundedIcon />
                </Icon>
              </CardIcon>
              <p className={classes.cardCategory}></p>
              <h3 className={classes.cardTitle}>{t("dashboard:clients")}</h3>
            </CardHeader>
            <Grid
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                minHeight: 150,
                justifyContent: "center",
              }}
            >
              <Button
                style={{
                  marginTop: 20,
                  marginLeft: 30,
                  marginRight: 30,
                  padding: 7,
                }}
                color="success"
                onClick={() => {
                  toggleScroll("clientsTable");
                }}
                round
              >
                {t("dashboard:manageClients")}
              </Button>
              <Button
                style={{
                  marginTop: 10,
                  marginLeft: 30,
                  marginRight: 30,
                  padding: 7,
                }}
                onClick={() => {
                  toggleScroll("assignmentTableClients");
                }}
                color="success"
                round
              >
                {t("dashboard:assignAssessments")}
              </Button>
            </Grid>
            <CardFooter stats>
              <div className={classes.stats}></div>
            </CardFooter>
          </Card>
        </GridItem>

        {/* My Groups */}
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card className="my-groups">
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>
                  <SupervisedUserCircleIcon />
                </Icon>
              </CardIcon>
              <p className={classes.cardCategory}></p>
              <h3 className={classes.cardTitle}>{t("dashboard:groups")}</h3>
            </CardHeader>
            <Grid
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                minHeight: 150,
                justifyContent: "center",
              }}
            >
              <Button
                style={{
                  marginTop: 20,
                  marginLeft: 30,
                  marginRight: 30,
                  padding: 7,
                }}
                color="warning"
                onClick={() => {
                  toggleScroll("groupsTable");
                }}
                round
              >
                {t("dashboard:manageGroups")}
              </Button>
              <Button
                style={{
                  marginTop: 10,
                  marginLeft: 30,
                  marginRight: 30,
                  padding: 7,
                }}
                onClick={() => {
                  toggleScroll("assignmentTableGroups");
                }}
                color="warning"
                round
              >
                {t("dashboard:assignAssessments")}
              </Button>
            </Grid>
            <CardFooter stats>
              <div className={classes.stats}></div>
            </CardFooter>
          </Card>
        </GridItem>

        {/* Shop */}
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card className="shop">
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>
                  <ShoppingBasketRoundedIcon />
                </Icon>
              </CardIcon>
              <p className={classes.cardCategory}></p>
              <h3 className={classes.cardTitle}>{t("dashboard:shop")}</h3>
            </CardHeader>
            <Grid
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                minHeight: 150,
              }}
            >
              <Button
                style={{
                  marginTop: 20,
                  marginLeft: 30,
                  marginRight: 30,
                  padding: 7,
                }}
                color="danger"
                round
                onClick={() => {
                  scrollContext.setActive(0);
                  props.history.push("/admin/shop");
                }}
              >
                {t("dashboard:getMoreModules")}
              </Button>
              <Button
                style={{
                  marginTop: 10,
                  marginLeft: 30,
                  marginRight: 30,
                  padding: 7,
                }}
                color="danger"
                round
                onClick={() => {
                  scrollContext.setActive(1);
                  props.history.push("/admin/shop");
                }}
              >
                {t("dashboard:getMoreTools")}
              </Button>
            </Grid>
            <CardFooter stats>
              <div className={classes.stats}></div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <div className="latest-activity">
        <LatestActivity data={latestActivity} />
      </div>
      {/*   {assign === true && animateIn ? ( */}
      <div ref={clientTable} style={{ width: "100%" }}>
        <Slide
          direction="right"
          mountOnEnter
          unmountOnExit
          onExited={() => {
            setGroupsTable(true);
            setGrowGroupsTable(true);
          }}
          in={growClientsTable}
          {...(growClientsTable ? { timeout: 200 } : { timeout: 100 })}
        >
          <div>
            <ClientsTable
              setReloadTable={setReloadTable}
              setReloadGroups={setReloadGroups}
              setReloadData={setReloadData}
              setReloadGroupsTable={setReloadGroupsTable}
              setGroupsAdded={setGroupsAdded}
              setReload={setReload}
              totalPurchased={totalPurchased}
              totalUsed={totalUsed}
              setOpen={setOpen}
              data={data}
              openEdit={openEdit}
              setOpenEdit={setOpenEdit}
              edit={edit}
              reloadClients={reloadClients}
              ready={ready}
              grow={growClientActions}
              clients={clients}
              triggerReload={() => {
                triggerReload();
              }}
              toggleScroll={toggleScroll}
              assessmentTrigger={setAssessmentTrigger}
              hideAssignments={hideAssignments}
              setReloadClients={setReloadClients}
              showAssignments={showAssignments}
              setGroupsTable={setGroupsTable}
              setGrowGroupsTable={setGrowGroupsTable}
              setGrowClientsTable={setGrowClientsTable}
              openAssigned={openAssigned}
              setOpenAssigned={setOpenAssigned}
              assignmentDetails={assignmentDetails}
              tourSelect={tourSelect}
            />
          </div>
        </Slide>
        {groupsTable ? (
          <Slide
            direction="left"
            mountOnEnter
            onExited={() => {
              setGroupsTable(false);
              setGrowClientsTable(true);
            }}
            unmountOnExit
            in={growGroupsTable}
            {...(growGroupsTable ? { timeout: 200 } : { timeout: 100 })}
          >
            <div>
              {" "}
              <GroupsTable
                groupsAdded={groupsAdded}
                setGroupsAdded={setGroupsAdded}
                setReloadTable={setReloadTable}
                setReloadGroupsTable={setReloadGroupsTable}
                setReloadGroups={setReloadGroups}
                setReload={setReload}
                totalPurchased={totalPurchased}
                totalUsed={totalUsed}
                setOpen={setOpen}
                data={groupsData}
                openEdit={openGroupEdit}
                setOpenEdit={setOpenGroupEdit}
                edit={groupEdit}
                reloadClients={reloadClients}
                ready={groupsReady}
                grow={growGroupsActions}
                groups={groups}
                triggerReload={() => {
                  triggerReload();
                }}
                toggleScroll={toggleScroll}
                assessmentTrigger={setAssessmentTrigger}
                hideAssignments={hideAssignments}
                setReloadClients={setReloadClients}
                showAssignments={showAssignments}
                setGroupsTable={setGroupsTable}
                setGrowGroupsTable={setGrowGroupsTable}
                setGrowClientsTable={setGrowClientsTable}
                setGrowGroupsActions={setGrowGroupsActions}
                clients={clients}
                shouldOpen={shouldOpen}
                setShouldOpen={setShouldOpen}
                openAddMembers={openAddMembers}
                setOpenAddMembers={setOpenAddMembers}
                selectedGroup={selectedGroup}
                setSelectedGroup={setSelectedGroup}
                tourAddMembers={tourAddMembers}
                tourGroupSelect={tourGroupSelect}
                tourSelect={tourSelect}
              />
            </div>
          </Slide>
        ) : null}
      </div>
      {/* ) : (
              <div id={"assessTable"}></div>
            )} */}
      {/* Assign assessment Table  */}{" "}
      <div ref={assignmentTable} className="assignment-table">
        <AssignAssessments
          totalPurchased={totalPurchased}
          totalUsed={totalUsed}
          pending={pending}
          completed={completed}
          width={width}
          grow={growAssessmentSummary}
          loading={loading}
          height={height}
          order={order}
          triggerReload={() => {
            triggerReload();
          }}
          assessmentTrigger={setAssessmentTrigger}
          showAssignments={showAssignments}
          hideAssignments={hideAssignments2}
          setReloadClients={setReloadClients}
          setReloadTable={setReloadTable}
          setReloadGroups={setReloadGroups}
        />
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnack}
        message={<Typo align="center">{snackMessage}</Typo>}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
      />
      <SendMailClient
        open={openClientMail}
        setOpen={setOpenClientMail}
        client={clientToMail}
      />
      <SendMailGroup
        open={openGroupMail}
        setOpen={setOpenGroupMail}
        group={groupToMail}
      />
    </div>
  );
}

export default withRouter(Dashboard);
