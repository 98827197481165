import React from "react";
import { useTranslation } from "react-i18next";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const languages = [
  { code: "en", lang: "English" },
  { code: "it", lang: "Italiano" },
];

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
    // window.location.reload();
  };

  return (
    <div>
      <FormControl size="small">
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={i18n.language}
          onChange={changeLanguage}
          variant="outlined"
          autoWidth
          displayEmpty
          renderValue={(value) =>
            languages.find((lng) => lng.code === value)?.lang || "English"
          }
          defaultValue={i18n.language}
          style={{ backgroundColor: "white" }}
        >
          {languages.map((lng) => (
            <MenuItem key={lng.code} value={lng.code}>
              {lng.lang}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
export default LanguageSelector;

// <div className="btn-container">
//     {languages.map((lng) => {
//         return <button className={lng.code === i18n.lang ? "selected" : ""} key={lng.code} onClick={() =>changeLanguage(lng.code)}>{lng.lang}</button>
//     })}
// </div>
