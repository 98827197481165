import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  whiteColor,
  modulesColor,
  toolsOneColor,
  toolsTwoColor,
  toolsThreeColor,
  toolsFourColor,
  toolsFiveColor,
} from "assets/jss/elite-feats-cms.js";

const badgeStyle = {
  badge: {
    borderRadius: "12px",
    padding: "5px 12px",
    textTransform: "uppercase",
    fontSize: "10px",
    fontWeight: "700",
    lineHeight: "1",
    color: whiteColor,
    textAlign: "center",
    verticalAlign: "baseline",
    display: "inline-block",
  },
  primary: {
    backgroundColor: primaryColor[0],
  },
  warning: {
    backgroundColor: warningColor[0],
  },
  danger: {
    backgroundColor: dangerColor[0],
  },
  success: {
    backgroundColor: successColor[0],
  },
  info: {
    backgroundColor: infoColor[0],
  },
  rose: {
    backgroundColor: roseColor[0],
  },
  gray: {
    backgroundColor: grayColor[0],
  },

  tools1: {
    backgroundColor: toolsOneColor[2],
  },
  tools2: {
    backgroundColor: toolsTwoColor[1],
  },
  tools3: {
    backgroundColor: toolsThreeColor[1],
  },
  tools4: {
    backgroundColor: toolsFourColor[1],
  },
  tools5: {
    backgroundColor: toolsFiveColor[3],
  },
};

export default badgeStyle;
