/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import {
  NavLink,
  Redirect,
  withRouter,
  useHistory,
  Link,
} from "react-router-dom";

import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import MenuItem from "@material-ui/core/MenuItem";
import { withTranslation } from 'react-i18next';

// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";

import sidebarStyle from "assets/jss/elite-feats-cms/components/sidebarStyle.js";

import avatar from "assets/img/faces/avatar.jpg";

import AppContext from "../../AppContext";
import axios from "axios";
var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.

class SidebarWrapper extends React.Component {
  sidebarWrapper = React.createRef();
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebarWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    const { className, headerLinks, links } = this.props;
    return (
      <div className={className} ref={this.sidebarWrapper}>
        {headerLinks}
        {links}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      admin: false,
      openAvatar: false,
      miniActive: true,
      ...this.getCollapseStates(props.routes),
    };
  }
  componentDidMount() {
    const scrollContext = this.context;
    this.checkAdmin();
  }
  checkAdmin() {
    const result = axios
      .get("/api/user/check-admin", {
        headers: { "auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        this.setState({ admin: res.data });
      });
  }

  mainPanel = React.createRef();
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  };
  openCollapse(collapse) {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes, t) => {
    const { classes, color, rtlActive } = this.props;
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        const navLinkClasses =
          classes.itemLink +
          " " +
          cx({
            [" " + classes.collapseActive]: this.getCollapseInitialState(
              prop.views
            ),
          });
        const itemText =
          classes.itemText +
          " " +
          cx({
            [classes.itemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.itemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.itemTextRTL]: rtlActive,
          });
        const collapseItemText =
          classes.collapseItemText +
          " " +
          cx({
            [classes.collapseItemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextRTL]: rtlActive,
          });
        const itemIcon =
          classes.itemIcon +
          " " +
          cx({
            [classes.itemIconRTL]: rtlActive,
          });
        const caret =
          classes.caret +
          " " +
          cx({
            [classes.caretRTL]: rtlActive,
          });
        const collapseItemMini =
          classes.collapseItemMini +
          " " +
          cx({
            [classes.collapseItemMiniRTL]: rtlActive,
          });
        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <NavLink
              to={"#"}
              className={navLinkClasses}
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === "string" ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>
                  {rtlActive ? prop.rtlMini : prop.mini}
                </span>
              )}
              <ListItemText
                primary={rtlActive ? prop.rtlName : t(`dashboard:routes.${prop.name}`)}
                secondary={
                  <b
                    className={
                      caret +
                      " " +
                      (this.state[prop.state] ? classes.caretActive : "")
                    }
                  />
                }
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </NavLink>
            <Collapse in={this.state[prop.state]} unmountOnExit>
              <List className={classes.list + " " + classes.collapseList}>
                {this.createLinks(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        );
      }
      const innerNavLinkClasses =
        classes.collapseItemLink +
        " " +
        cx({
          [" " + classes[color]]: this.activeRoute(prop.path),
        });
      const collapseItemMini =
        classes.collapseItemMini +
        " " +
        cx({
          [classes.collapseItemMiniRTL]: rtlActive,
        });
      const navLinkClasses = classes.itemLink;

      const itemText =
        classes.itemText +
        " " +
        cx({
          [classes.itemTextMini]:
            this.props.miniActive && this.state.miniActive,
          [classes.itemTextMiniRTL]:
            rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.itemTextRTL]: rtlActive,
        });
      const collapseItemText =
        classes.collapseItemText +
        " " +
        cx({
          [classes.collapseItemTextMini]:
            this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextMiniRTL]:
            rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextRTL]: rtlActive,
        });
      const itemIcon =
        classes.itemIcon +
        " " +
        cx({
          [classes.itemIconRTL]: rtlActive,
        });
      return (
        <ListItem
          key={key}
          className={cx(
            { [classes.item]: prop.icon !== undefined },
            { [classes.collapseItem]: prop.icon === undefined }
          )}
        >
          {window.location.pathname === "/admin/dashboard" ? (
            prop.name === "My Clients" ||
            prop.name === "Home" ||
            prop.name === "My Groups" ? (
              <MenuItem
                className={cx(
                  { [navLinkClasses]: prop.icon !== undefined },
                  { [innerNavLinkClasses]: prop.icon === undefined }
                )}
                onClick={() => {
                  if (prop.name === "My Clients") {
                    this.context.setScrollTo(`clientsTable+${Math.random()}`);
                  } else if (prop.name === "Home") {
                    this.context.setScrollTo("home");
                  } else if (prop.name === "My Groups") {
                    this.context.setScrollTo(`groupsTable+${Math.random()}`);
                  }
                }}
              >
                {prop.icon !== undefined ? (
                  typeof prop.icon === "string" ? (
                    <Icon className={itemIcon}>{prop.icon}</Icon>
                  ) : (
                    <prop.icon className={itemIcon} />
                  )
                ) : (
                  <span className={collapseItemMini}>
                    {rtlActive ? prop.rtlMini : prop.mini}
                  </span>
                )}
                <ListItemText
                  primary={rtlActive ? prop.rtlName : t(`dashboard:routes.${prop.name}`)}
                  disableTypography={true}
                  className={cx(
                    { [itemText]: prop.icon !== undefined },
                    { [collapseItemText]: prop.icon === undefined }
                  )}
                />
              </MenuItem>
            ) : prop.name !== "Order Complete" ? (
              prop.name === "User Information" ||
              prop.name === "Sales Statistics" ||
              prop.name === "Admin Tools" ? (
                this.state.admin ? (
                  <Link to={prop.layout + prop.path}>
                    {" "}
                    <MenuItem
                      className={cx(
                        { [navLinkClasses]: prop.icon !== undefined },
                        { [innerNavLinkClasses]: prop.icon === undefined }
                      )}
                      style={{
                        color: "#fff",
                      }}
                      onClick={() => {
                        if (prop.name === "My Clients") {
                          this.context.setScrollTo(
                            `clientsTable+${Math.random()}`
                          );
                        } else if (prop.name === "Home") {
                          this.context.setScrollTo("home");
                        } else if (prop.name === "My Groups") {
                          this.context.setScrollTo(
                            `groupsTable+${Math.random()}`
                          );
                        } else {
                          /*   console.log(prop.layout + prop.path); */
                        }
                      }}
                    >
                      {prop.icon !== undefined ? (
                        typeof prop.icon === "string" ? (
                          <Icon className={itemIcon}>{prop.icon}</Icon>
                        ) : (
                          <prop.icon className={itemIcon} />
                        )
                      ) : (
                        <span className={collapseItemMini}>
                          {rtlActive ? prop.rtlMini : prop.mini}
                        </span>
                      )}
                      <ListItemText
                        primary={rtlActive ? prop.rtlName : t(`dashboard:routes.${prop.name}`)}
                        disableTypography={true}
                        className={cx(
                          { [itemText]: prop.icon !== undefined },
                          { [collapseItemText]: prop.icon === undefined }
                        )}
                      />
                    </MenuItem>
                  </Link>
                ) : null
              ) : (
                <Link to={prop.layout + prop.path}>
                  <MenuItem
                    className={cx(
                      { [navLinkClasses]: prop.icon !== undefined },
                      { [innerNavLinkClasses]: prop.icon === undefined }
                    )}
                    style={{
                      color: "#fff",
                    }}
                    onClick={() => {
                      if (prop.name === "My Clients") {
                        this.context.setScrollTo(
                          `clientsTable+${Math.random()}`
                        );
                      } else if (prop.name === "Home") {
                        this.context.setScrollTo("home");
                      } else if (prop.name === "My Groups") {
                        this.context.setScrollTo(
                          `groupsTable+${Math.random()}`
                        );
                      } else {
                        /*   console.log(prop.layout + prop.path); */
                      }
                    }}
                  >
                    {prop.icon !== undefined ? (
                      typeof prop.icon === "string" ? (
                        <Icon className={itemIcon}>{prop.icon}</Icon>
                      ) : (
                        <prop.icon className={itemIcon} />
                      )
                    ) : (
                      <span className={collapseItemMini}>
                        {rtlActive ? prop.rtlMini : prop.mini}
                      </span>
                    )}
                    <ListItemText
                      primary={rtlActive ? prop.rtlName : t(`dashboard:routes.${prop.name}`)}
                      disableTypography={true}
                      className={cx(
                        { [itemText]: prop.icon !== undefined },
                        { [collapseItemText]: prop.icon === undefined }
                      )}
                    />
                  </MenuItem>
                </Link>
              )
            ) : null
          ) : prop.name !== "Order Complete" ? (
            prop.name === "User Information" ||
            prop.name === "Sales Statistics" ||
            prop.name === "Admin Tools" ? (
              this.state.admin ? (
                <Link to={prop.layout + prop.path}>
                  {" "}
                  <MenuItem
                    className={cx(
                      { [navLinkClasses]: prop.icon !== undefined },
                      { [innerNavLinkClasses]: prop.icon === undefined }
                    )}
                    style={{
                      color: "#fff",
                    }}
                    onClick={() => {
                      if (prop.name === "My Clients") {
                        this.context.setScrollTo(
                          `clientsTable+${Math.random()}`
                        );
                      } else if (prop.name === "Home") {
                        this.context.setScrollTo("home");
                      } else if (prop.name === "My Groups") {
                        this.context.setScrollTo(
                          `groupsTable+${Math.random()}`
                        );
                      } else {
                        /*   console.log(prop.layout + prop.path); */
                      }
                    }}
                  >
                    {prop.icon !== undefined ? (
                      typeof prop.icon === "string" ? (
                        <Icon className={itemIcon}>{prop.icon}</Icon>
                      ) : (
                        <prop.icon className={itemIcon} />
                      )
                    ) : (
                      <span className={collapseItemMini}>
                        {rtlActive ? prop.rtlMini : prop.mini}
                      </span>
                    )}
                    <ListItemText
                      primary={rtlActive ? prop.rtlName : t(`dashboard:routes.${prop.name}`)}
                      disableTypography={true}
                      className={cx(
                        { [itemText]: prop.icon !== undefined },
                        { [collapseItemText]: prop.icon === undefined }
                      )}
                    />
                  </MenuItem>
                </Link>
              ) : null
            ) : (
              <Link to={prop.layout + prop.path}>
                <MenuItem
                  className={cx(
                    { [navLinkClasses]: prop.icon !== undefined },
                    { [innerNavLinkClasses]: prop.icon === undefined }
                  )}
                  style={{
                    color: "#fff",
                  }}
                  onClick={() => {
                    if (prop.name === "My Clients") {
                      this.context.setScrollTo(`clientsTable+${Math.random()}`);
                    } else if (prop.name === "Home") {
                      this.context.setScrollTo("home");
                    } else if (prop.name === "My Groups") {
                      this.context.setScrollTo(`groupsTable+${Math.random()}`);
                    } else {
                      /*   console.log(prop.layout + prop.path); */
                    }
                  }}
                >
                  {prop.icon !== undefined ? (
                    typeof prop.icon === "string" ? (
                      <Icon className={itemIcon}>{prop.icon}</Icon>
                    ) : (
                      <prop.icon className={itemIcon} />
                    )
                  ) : (
                    <span className={collapseItemMini}>
                      {rtlActive ? prop.rtlMini : prop.mini}
                    </span>
                  )}
                  <ListItemText
                    primary={rtlActive ? prop.rtlName : t(`dashboard:routes.${prop.name}`)}
                    disableTypography={true}
                    className={cx(
                      { [itemText]: prop.icon !== undefined },
                      { [collapseItemText]: prop.icon === undefined }
                    )}
                  />
                </MenuItem>
              </Link>
            )
          ) : null}
        </ListItem>
      );
    });
  };

  render() {
    const {
      classes,
      logo,
      image,
      logoText,
      routes,
      bgColor,
      rtlActive,
      t
    } = this.props;
    const itemText =
      classes.itemText +
      " " +
      cx({
        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
        [classes.itemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.itemTextRTL]: rtlActive,
      });
    const collapseItemText =
      classes.collapseItemText +
      " " +
      cx({
        [classes.collapseItemTextMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextRTL]: rtlActive,
      });
    const userWrapperClass =
      classes.user +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white",
      });
    const caret =
      classes.caret +
      " " +
      cx({
        [classes.caretRTL]: rtlActive,
      });
    const collapseItemMini =
      classes.collapseItemMini +
      " " +
      cx({
        [classes.collapseItemMiniRTL]: rtlActive,
      });
    const photo =
      classes.photo +
      " " +
      cx({
        [classes.photoRTL]: rtlActive,
      });

    var links = (
      <List className={classes.list + " " + "tour-sidebar"}>
        {this.createLinks(routes, t)}
      </List>
    );

    const logoNormal =
      classes.logoNormal +
      " " +
      cx({
        [classes.logoNormalSidebarMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.logoNormalSidebarMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.logoNormalRTL]: rtlActive,
      });
    const logoMini =
      classes.logoMini +
      " " +
      cx({
        [classes.logoMiniRTL]: rtlActive,
      });
    const logoClasses =
      classes.logo +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white",
      });
    var brand = (
      <div className={logoClasses + " " + "tour-logo"}>
        <div className={logoMini}>
          <img src={logo} alt='logo' className={classes.img} />
        </div>
        <div className={logoNormal}>{logoText}</div>
      </div>
    );
    const drawerPaper =
      classes.drawerPaper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.drawerPaperRTL]: rtlActive,
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1,
      });
    return (
      <div ref={this.mainPanel}>
        <Hidden mdUp implementation='css'>
          <Drawer
            variant='temporary'
            anchor={rtlActive ? "left" : "right"}
            open={this.props.open}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"],
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              headerLinks={<AdminNavbarLinks rtlActive={rtlActive} />}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation='css'>
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? "right" : "left"}
            variant='permanent'
            open
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"],
            }}
          >
            {brand}
            <SidebarWrapper className={sidebarWrapper} links={links} />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "blue",
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose",
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  miniActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};

SidebarWrapper.propTypes = {
  className: PropTypes.string,

  headerLinks: PropTypes.object,
  links: PropTypes.object,
};

export default withRouter(withStyles(sidebarStyle)(withTranslation()(Sidebar)));
