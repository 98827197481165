export default {
  module1: "https://i.ibb.co/XLBFpr6/M1.png",
  module2: "https://i.ibb.co/R0KpHNT/M2.png",
  module3: "https://i.ibb.co/C9ZT8dG/M3.png",
  module4: "https://i.ibb.co/r2qQmb0/M4.png",
  module5: "https://i.ibb.co/pzBcqMJ/M5.png",
  tool1_1: "https://i.ibb.co/S7qg68f/T1.png",
  tool1_2: "https://i.ibb.co/S7qg68f/T1.png",
  tool1_3: "https://i.ibb.co/S7qg68f/T1.png",
  tool2_1: "https://i.ibb.co/tZw45Bk/T2.png",
  tool2_2: "https://i.ibb.co/tZw45Bk/T2.png",
  tool2_3: "https://i.ibb.co/tZw45Bk/T2.png",
  tool3_1: "https://i.ibb.co/WxxpDyp/T3.png",
  tool3_2: "https://i.ibb.co/WxxpDyp/T3.png",
  tool3_3: "https://i.ibb.co/WxxpDyp/T3.png",
  tool3_4: "https://i.ibb.co/WxxpDyp/T3.png",
  tool4_1: "https://i.ibb.co/7RCN042/T4.png",
  tool4_2: "https://i.ibb.co/7RCN042/T4.png",
  tool4_3: "https://i.ibb.co/7RCN042/T4.png",
  tool4_4: "https://i.ibb.co/7RCN042/T4.png",
  tool4_5: "https://i.ibb.co/7RCN042/T4.png",
  tool5_1: "https://i.ibb.co/3BTJL8w/T5.png",
  tool5_2: "https://i.ibb.co/3BTJL8w/T5.png",
  tool5_3: "https://i.ibb.co/3BTJL8w/T5.png",
};
