import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function BasicTable(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <TableContainer component={Paper} variant={"outlined"}>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  borderRight: "1px solid #f0f0f0",
                  fontWeight: 500,
                  fontSize: 15,
                  background: "#1d2d40",
                  color: "#fff",
                }}
              >
                Assessment Purchased
              </TableCell>
              <TableCell
                style={{
                  borderRight: "1px solid #f0f0f0",
                  fontWeight: 500,
                  fontSize: 15,
                  background: "#1d2d40",
                  color: "#fff",
                }}
                align='right'
              >
                Quantity
              </TableCell>
              <TableCell
                align='right'
                style={{
                  fontWeight: 500,
                  fontSize: 15,
                  background: "#1d2d40",
                  color: "#fff",
                }}
              >
                Cost&nbsp;({props.currency})
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows?.map((row) => (
              <TableRow key={row.id}>
                <TableCell
                  style={{ borderRight: "1px solid #f0f0f0" }}
                  component='th'
                  scope='row'
                >
                  {row.name}
                </TableCell>

                <TableCell
                  style={{ borderRight: "1px solid #f0f0f0" }}
                  align='right'
                >
                  {row.quantity}
                </TableCell>
                <TableCell align='right'>{row.subtotal}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography
        align={"right"}
        style={{
          marginTop: 5,
          fontSize: 14,
          paddingRight: 15,
          textDecorationLine: "underline",
          textDecorationStyle: "double",
          textDecorationColor: "#b2b2b2",
          textUnderlineOffset: 2,
        }}
      >
        {" "}
        {(
          parseFloat(props.total) ||
          0 + parseFloat(props.discount) ||
          0
        ).toFixed(2)}
      </Typography>
    </React.Fragment>
  );
}
